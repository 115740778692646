export type ButtonSize = "small" | "medium" | "large";

export enum PasswordInputType {
    REGISTER,
    LOGIN
};

export enum PopupEnum {
    INVENTORY,
    VILLAGE_UPGRADE,
    TOKEN_TRANSFER,
    ASSET_INFO,
    REFERRAL_REWARDS,
    CLAIM_RESOURCES,
    SELECTED_ASSET,
    LEADERBOARD,
}

export type PopupState = {
    type: PopupEnum;
    state: any;
    removeWhenInactive?: boolean;
}