import {FarmHarvestStatus} from "../../../common/types/resources";
import RewardResourceIcon from "../RewardResourceIcon";
import {classNames, formatUpgradeTime} from "../../../utils/presentation";
import {FcInfo} from "react-icons/fc";
import HarvestProgressBar from "./HarvestProgressBar";

export default function ResourceHarvestItem({resource, loading}: {
    resource: FarmHarvestStatus,
    loading?: boolean,
}) {
    return (
        <div className="w-full">
            <div className="flex items-center justify-between pb-2">
                <div className="flex items-center">
                    <RewardResourceIcon token={resource.token}/>
                    <div className={classNames(
                        "flex items-center space-x-1 text-theme-text-light font-semibold text-sm ml-3",
                        loading ? "animate-pulse" : "",
                    )}>
                        <span>{`${resource.harvested_amount}`}</span>
                        <span>/</span>
                        <span>{`${resource.max_amount}`}</span>
                        {resource.harvested_amount > resource.max_amount && (
                            <FcInfo
                                className="w-4 h-4"
                                data-tooltip-id="extra-resources-tooltip"
                                data-tooltip-content={"Includes unclaimed resources from removed farms"}
                            />
                        )}
                    </div>
                </div>
                <span className="pl-2 text-xs inline-flex items-center">
                    {resource.seconds_until_full === 0
                        ? "Farms are full"
                        : formatUpgradeTime(resource.seconds_until_full) + " until full"
                    }
                </span>
            </div>
            <HarvestProgressBar
                value={resource.harvested_amount}
                maxValue={resource.max_amount}
                token={resource.token}
            />
        </div>
    );
};