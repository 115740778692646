import {isHouse as isBuildingHouse} from "../../../utils/assets";
import HouseAssetDialog from "./HouseAssetDialog";
import FarmAssetDialog from "./FarmAssetDialog";
import {PartialVillageAsset} from "../../../common/types/repository";
import {usePopups} from "../../../hooks/usePopups";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {PopupEnum} from "../../../common/types/components";

type Props = {
    onClose: () => void
};

export default function SelectedAssetDialog({onClose}: Props) {
    const {popupState: getPopupState, isActive, closePopup} = usePopups();
    const asset = useSelector((state: RootState) => state.village.selectedAsset);
    const isOpen = isActive(PopupEnum.SELECTED_ASSET);
    if (!asset || !isOpen) {return null;}
    const close = () => {
        closePopup(PopupEnum.SELECTED_ASSET);
        onClose();
    }

    if (isBuildingHouse(asset.asset_token)) {
        return <HouseAssetDialog asset={asset} onClose={close}/>
    }
    return <FarmAssetDialog asset={asset} onClose={close}/>
};


