import ActionContext from "./ActionContext";
import {gameAction} from "../../config/actions";
import {getNftIdFromToken} from "../../utils/nfts";

export default class AddGiantsToHouseFromStakingContext extends ActionContext<"ADD_STAKED_GIANTS_TO_HOUSE"> {
    protected type = gameAction.ADD_STAKED_GIANTS_TO_HOUSE;
    protected hasTransaction = true;

    public getUsedItems(): Record<string, number> {
        const items: Record<string, number> = {};
        for (const giant of this.payload.giants) {
            items[getNftIdFromToken(giant)] = 1;
        }

        return items;
    }

};