import * as tweetnacl from "tweetnacl";


export default class MessageSigner {
    private readonly key: Uint8Array;

    constructor(key: Uint8Array) {this.key = key;}

    sign(message: Uint8Array): Uint8Array {
        let signature = tweetnacl.sign(message, this.key);

        return signature.slice(0, signature.length - message.length);

    }
};