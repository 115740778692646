import Scrollbars from "react-custom-scrollbars-2";
import {PropsWithChildren} from "react";

type Props = {
    hideTracksWhenNotNeeded?: boolean;
    autoHide?: boolean;
    minHeight?: string;
    autoHeight?: boolean;
    autoHeightMin?: number;
    autoHeightMax?: number;
}
export default function Scrollbar(
    {
        children,
        hideTracksWhenNotNeeded,
        autoHide = true,
        autoHeight = false,
        minHeight = "200px",
        autoHeightMin,
        autoHeightMax
    }: PropsWithChildren<Props>
) {
    return (
        <Scrollbars
            className="flex-grow"
            style={{width: "100%", height: "auto", minHeight}}
            autoHeight={autoHeight}
            autoHeightMin={autoHeightMin}
            autoHeightMax={autoHeightMax}
            autoHide={autoHide}
            thumbSize={120}
            hideTracksWhenNotNeeded={hideTracksWhenNotNeeded}
            renderTrackVertical={() => (
                <div
                    style={{
                        opacity: 1,
                        position: "absolute",
                        width: "6px",
                        transition: "opacity 200ms ease 0s",
                        right: "-2px",
                        bottom: "2px",
                        top: "0px",
                        borderRadius: "3px",
                    }}
                ></div>
            )}
            renderThumbVertical={() => <div className="bg-secondary w-1"></div>}
        >
            {children}
        </Scrollbars>
    );
}