import {PropsWithChildren} from "react";
import InfiniteScroll from "react-infinite-scroller";
import Scrollbar from "./Scrollbar";

type Props = {
    next: () => void;
    hasMore: boolean;
    initialLoad?: boolean;
    useWindow?: boolean;
    autoHeightMax?: number | string;
}

export default function InfiniteScroller(props: PropsWithChildren<Props>) {
    const {
        children,
        next,
        hasMore,
        initialLoad = false,
        useWindow = false,
        autoHeightMax
    } = props;

    return (
        <Scrollbar>
            <InfiniteScroll
                loadMore={next}
                hasMore={hasMore}
                loader={<p className="text-center my-4 text-gray-400" key={0}>Loading more...</p>}
                useWindow={useWindow}
                initialLoad={initialLoad}
            >
                {children}
            </InfiniteScroll>
        </Scrollbar>
    );
}