import {SVGProps} from "react";

export default function IconLandManagement({...props}: SVGProps<any>) {
    return (
        <svg width="62" height="42" viewBox="0 0 62 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_507_34)">
                <path
                    d="M50.1689 18.6006L40.7589 13.1672L40.7691 13.1622L32.6375 8.46733C31.3655 7.73389 29.7993 7.73516 28.5285 8.47115L20.4338 13.1622L25.5081 16.1023L20.4147 13.1622L11.0315 18.6006H1.23053V24.9253C1.23053 25.3073 1.41898 25.6906 1.79717 25.9096L10.2471 30.8069L10.2662 30.8069L20.4134 36.6884L20.4325 36.6885L28.5272 41.3794C29.798 42.1154 31.3642 42.1167 32.6363 41.3833L40.7678 36.6884L40.7577 36.6834L50.9342 30.8081L50.924 30.8031L59.3943 25.9121C59.7738 25.6931 59.9635 25.3098 59.9635 24.9266V18.6019H50.1664L50.1689 18.6006ZM35.6757 14.1044L31.3668 16.6027H31.3502L35.677 14.1044H35.6757ZM20.4351 22.9261L25.5094 25.8663L20.4262 22.9312L20.4364 22.9261H20.4351ZM30.5926 28.8128L30.6027 28.8077L30.5926 28.8026L35.6757 25.8676L30.6015 28.8077L35.6757 31.7479L30.5926 28.8128ZM45.8434 25.8663L40.7602 22.9312L40.7704 22.9261L45.8447 25.8663H45.8434Z"
                    fill="#CAA977"/>
                <path
                    d="M50.169 18.6006L40.759 13.1672L40.7692 13.1622L32.6376 8.46733C32.0047 8.10188 31.298 7.91852 30.5926 7.91852V30.8018L35.6758 27.8667L30.6015 30.8069L35.6758 33.747L30.5926 30.812V41.9321C31.2993 41.9321 32.0047 41.7487 32.6376 41.3833L40.7692 36.6884L40.759 36.6834L50.9355 30.8081L50.9253 30.8031L59.3956 25.9121C59.7751 25.6931 59.9648 25.3099 59.9648 24.9266V18.6019H50.1677L50.169 18.6006ZM31.3655 18.6006H31.349L35.6758 16.1023L31.3668 18.6006H31.3655ZM40.759 24.9304L40.7692 24.9253L45.8434 27.8654L40.7602 24.9304H40.759Z"
                    fill="#95723D"/>
                <path d="M30.5913 28.8026V28.8147L30.6021 28.808L30.5913 28.8026Z" fill="#9E645C"/>
                <path
                    d="M41.127 31.8281L30.5811 25.739L20.076 31.8281L28.4559 36.6846C29.7713 37.4474 31.3935 37.4486 32.7089 36.6884L41.127 31.8281Z"
                    fill="#F9F9A2"/>
                <path
                    d="M51.6537 25.739L41.1066 19.6511L30.6015 25.739L41.1066 31.8281L51.6537 25.739Z"
                    fill="#5A9159"/>
                <path
                    d="M60.4117 18.6312L51.632 13.562L41.127 19.6511L51.632 25.7402L60.4117 20.6711C61.1974 20.2177 61.1974 19.0845 60.4117 18.6312Z"
                    fill="#5A9159"/>
                <path
                    d="M30.6014 25.739L20.0556 19.6511L9.55054 25.739L20.0556 31.8281L30.6014 25.739Z"
                    fill="#F9F9A2"/>
                <path
                    d="M41.127 19.6511L30.5811 13.562L20.076 19.6511L30.5811 25.739L41.127 19.6511Z"
                    fill="#5A9159"/>
                <path
                    d="M20.076 19.6511L9.53021 13.562L0.782337 18.6312C-0.000769214 19.0845 -0.000769214 20.2152 0.782337 20.6698L9.53021 25.739L20.076 19.6498V19.6511Z"
                    fill="#F9F9A2"/>
                <path
                    d="M30.6014 13.562L20.0556 7.47285L9.55054 13.562L20.0556 19.6511L30.6014 13.562Z"
                    fill="#5A9159"/>
                <path
                    d="M41.127 7.47285L32.7089 2.6125C31.3923 1.85231 29.77 1.85359 28.4559 2.61632L20.076 7.47285L30.5811 13.562L41.127 7.47285Z"
                    fill="#5A9159"/>
                <path
                    d="M51.6537 13.562L41.1066 7.47285L30.6015 13.562L41.1066 19.6511L51.6537 13.562Z"
                    fill="#5A9159"/>
                <path d="M61.0013 17.7538H0.195312V19.7784H61.0013V17.7538Z" fill="#F9F9A2"/>
                <path d="M30.5913 25.7414L30.595 25.7389L30.5913 25.7377V25.7414Z" fill="#458444"/>
                <path
                    d="M58.8863 17.7538L51.627 13.562L44.3956 17.7538H30.5926V19.7784H40.8825L30.5964 25.7402L35.8502 28.7848L30.5926 25.7492V37.2576C31.3209 37.2551 32.0506 37.0666 32.7038 36.6897L41.1219 31.8294L41.1117 31.823L51.6473 25.7402L51.6371 25.7339L60.4067 20.6711C60.7568 20.4686 60.9504 20.1312 60.9873 19.7784H60.9949V17.7538H58.8863ZM41.3218 19.7784H41.3409L46.3744 22.6957L41.3218 19.7784Z"
                    fill="#BFBF45"/>
                <path d="M30.5913 24.3547L30.595 24.3522L30.5913 24.351V24.3547Z" fill="#367F34"/>
                <path
                    d="M41.127 29.8531L30.5811 23.764L20.076 29.8531L30.5811 35.941L41.127 29.8531Z"
                    fill="#EBEB72"/>
                <path
                    d="M51.6537 23.764L41.1066 17.6749L30.6015 23.764L41.1066 29.8531L51.6537 23.764Z"
                    fill="#F4E172"/>
                <path
                    d="M60.4003 16.6486L51.6333 11.587L41.1282 17.6762L51.6333 23.7653L60.4003 18.7037C61.191 18.2466 61.191 17.1057 60.4003 16.6498V16.6486Z"
                    fill="#EBEB72"/>
                <path
                    d="M30.6014 23.764L20.0556 17.6749L9.55054 23.764L20.0556 29.8531L30.6014 23.764Z"
                    fill="#FDF483"/>
                <path
                    d="M41.127 17.6749L30.5811 11.587L20.076 17.6749L30.5811 23.764L41.127 17.6749Z"
                    fill="#D6D65F"/>
                <path
                    d="M20.076 17.6749L9.53021 11.5858L0.782337 16.6562C-0.000769214 17.1095 -0.000769214 18.2402 0.782337 18.6948L9.53021 23.764L20.076 17.6749Z"
                    fill="#E9E570"/>
                <path
                    d="M30.6014 11.587L20.0556 5.4979L9.55054 11.587L20.0556 17.6749L30.6014 11.587Z"
                    fill="#FDF483"/>
                <path
                    d="M41.127 5.4979L32.7089 0.637544C31.3923 -0.122642 29.77 -0.121369 28.4559 0.641364L20.076 5.4979L30.5811 11.587L41.127 5.4979Z"
                    fill="#EAD065"/>
                <path
                    d="M51.6537 11.587L41.1066 5.4979L30.6015 11.587L41.1066 17.6749L51.6537 11.587Z"
                    fill="#FDF483"/>
                <circle cx="30.501" cy="18.5" r="10.5" fill="white"/>
                <path
                    d="M36.001 17.225H31.776V13H29.226V17.225H25.001V19.775H29.226V24H31.776V19.775H36.001V17.225Z"
                    fill="#95723D"/>
            </g>
            <defs>
                <clipPath id="clip0_507_34">
                    <rect width="60.806" height="41.8637" fill="white"
                          transform="translate(0.195312 0.0683594)"/>
                </clipPath>
            </defs>
        </svg>

    );
};