import {AxiosInstance} from "axios";
import GridAsset from "../gameboard/components/GridAsset";
import {villageAssetsPath} from "../utils/api-routes";
import {gridAssetFromAssetInfo} from "../utils/assets";
import GridTile from "../gameboard/components/GridTile";
import {VillageAssetsResponse} from "../common/types/responses";
import {VillageAsset} from "../common/types/entities";

export default class VillageService {
    constructor(private api: AxiosInstance) {}

    public async getAssets(villageId: string): Promise<{ "building": GridAsset[], "land": GridTile[] }> {
        const {data: {assets}} = await this.api.get<VillageAssetsResponse>(
            villageAssetsPath(villageId)
        );

        const reducer = async (
            assets: Promise<{ "building": GridAsset[], "land": GridTile[] }>,
            asset: VillageAsset
        ) => {
            const gridAssets = await assets;
            const gridAsset = await gridAssetFromAssetInfo(asset);
            if (gridAsset instanceof GridTile) {
                gridAssets.land.push(gridAsset);
            } else {
                gridAssets.building.push(gridAsset);
            }

            return gridAssets;
        };

        return await assets.reduce(reducer, Promise.resolve({
            "building": [],
            "land": []
        }));
    }
};