import { useEffect } from "react";
import {useSelector} from "react-redux";
import {useAuth} from "../../hooks/useAuth";
import {useAppDispatch} from "../../hooks/useStore";
import {
  fetchMainWalletAssets,
  filterMainWalletAssets,
  hasMoreWalletAssets,
  selectAssetToTransfer,
} from "../../redux/slices/walletAssetsSlice";
import {RootState} from "../../redux/store";
import {classNames} from "../../utils/presentation";
import Loader from "../shared/Loader";
import AssetsList from "./AssetsList";
import SignTxInfo from "../SignTxInfo";
import {selectShowTxSignInfo} from "../../redux/slices/transactionsSlice";
import WalletTokensBalance from "./WalletTokensBalance";

export default function WalletAssets({enabled}: { enabled: boolean }) {
  const {account, mainWallet} = useAuth();
  const status = useSelector((state: RootState) => state.walletAssets.status);
  const assets = useSelector((state: RootState) => state.walletAssets.assets);
  const assetsToTransfer = useSelector((state: RootState) => state.walletAssets.assetsToTransfer);
  const hasMore = useSelector((state: RootState) => hasMoreWalletAssets(state));
  const typeFilter = useSelector((state: RootState) => state.walletAssets.filters.type);
  const showSignTxInfo = useSelector(selectShowTxSignInfo);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!account) {
      return;
    }
    if (status === "idle") {
      dispatch(
        fetchMainWalletAssets({
          address: account.primary_wallet!,
          reset: true,
        })
      );
    }
  }, [account, status]);


  return (
    <div
      className={classNames(
          "flex flex-col items-center justify-start py-4 w-full h-full overflow-auto"
      )}
    >
      <div className="flex items-center justify-between w-full mb-8">
        <WalletTokensBalance mode="wallet"/>
        {status === "loading" && <div className="ml-2"><Loader size="medium"/></div>}
      </div>
      {showSignTxInfo && <div className="flex items-center justify-center w-full h-full">
        <SignTxInfo/>
      </div>}
      {!showSignTxInfo && <>
        <div className="flex items-center justify-start w-full mb-6">
          <div className="flex items-center space-x-3 font-bold text-sm">
            <button
                className={classNames(typeFilter === undefined ? "text-theme-text" : "text-gray-500 hover:underline")}
                onClick={() => dispatch(filterMainWalletAssets({}))}
            >
              All
            </button>
            <button
                className={classNames(typeFilter === "assets" ? "text-theme-text" : "text-gray-500 hover:underline")}
                onClick={() => dispatch(filterMainWalletAssets({type: "assets"}))}
            >
              Assets
            </button>
            <button
                className={classNames(typeFilter === "resources" ? "text-theme-text" : "text-gray-500 hover:underline")}
                onClick={() => dispatch(filterMainWalletAssets({type: "resources"}))}
            >
              Resources
            </button>
            <button
                className={classNames(typeFilter === "giants" ? "text-theme-text" : "text-gray-500 hover:underline")}
                onClick={() => dispatch(filterMainWalletAssets({type: "giants"}))}
            >
              Giants
            </button>
          </div>
        </div>
        <AssetsList
            enabled={enabled}
            items={assets}
            selectedItems={assetsToTransfer}
            next={() => {
              if (status === "loading") {return;}

              return dispatch(fetchMainWalletAssets({})).unwrap();
            }}
            hasMore={hasMore}
            onClick={() => {}}
            onItemChange={(token: string, qty: number) => {
              dispatch(selectAssetToTransfer({token, qty}));
            }}
        />
      </>}
    </div>
  );
}
