import ActionContext from "./ActionContext";
import {gameAction} from "../../config/actions";
import {getNftIdFromToken} from "../../utils/nfts";

export default class SetBuildingSkinContext extends ActionContext<"SET_BUILDING_SKIN"> {
    protected type = gameAction.SET_BUILDING_SKIN;
    protected hasTransaction = true;


    public getUsedItems(): Record<string, number> {
        return {
            [getNftIdFromToken(this.payload.skinToken)]: 1
        };
    }

};