import { AxiosInstance } from 'axios';

import Wallet from '../../auth/Wallet';
import {GameActionEnum, GameActionKey, TxActionPayload} from '../../common/types/actions';
import { CommitTransactionResponse } from '../../common/types/responses';
import TxActionBuilder from './TxActionBuilder';
import {ChainConfig} from "../../common/types";
import ActionContext from "../contexts/ActionContext";
import { Transaction } from "@multiversx/sdk-core/out";

export default class TxActionService {
  constructor(
      private api: AxiosInstance,
      private actionBuilder: TxActionBuilder,
      private chainConfig: ChainConfig
  ) {}

  public async commitAction(actionContext: ActionContext<GameActionKey>, wallet: Wallet) {
    const payload: any = {
      action: {
        type: actionContext.actionType,
        payload: actionContext.payload,
      },
    }
    let tx: Transaction | undefined = undefined;
    if (actionContext.requiresTransaction) {
      const txAction = await this.actionBuilder.build(wallet, actionContext, this.chainConfig);
      const txData: {
        tx: Transaction,
        gasLimit: number,
      } = await txAction.buildTransaction();
      payload.txData = {
        tx: txData.tx.toSendable(),
        gasLimit: txData.gasLimit,
      };
      tx = txData.tx;
    }
    const commit = this.api.post<CommitTransactionResponse>("actions", payload);

    try {
      if (tx) {wallet.incrementNonce();}
      const {data: {action, state, txHash, jobId}} = await commit;

      return {
        action,
        state,
        actionContext,
        tx,
        txHash,
        jobId
      };
    } catch (e) {
      if (actionContext.requiresTransaction) {wallet.decrementNonce();}
      throw e;
    }
  }
}
