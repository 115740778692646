import { TOKEN_FOOD, TOKEN_GIANTS_CURRENCY, TOKEN_STONE, TOKEN_VILLAGE_COIN, TOKEN_WOOD } from "../../config/assets";
import IconFood from "../icons/IconFood";
import IconGiantCurrency from "../icons/IconGiantCurrency";
import IconStone from "../icons/IconStone";
import IconVillageCoin from "../icons/IconVillageCoin";
import IconWood from "../icons/IconWood";
import {classNames} from "../../utils/presentation";

export default function RewardResourceIcon({
  token,
  size = "4",
  rounded = true,
  padding,
  background = "[#BBCE84]",
}: {
  token: string;
  size?: string;
  rounded?: boolean;
  padding?: number;
  background?: string;
}) {
  switch (token) {
    case TOKEN_WOOD:
      return <IconWood className={`w-${size} h-${size}`} rounded={rounded} padding={padding} />;
    case TOKEN_STONE:
      return <IconStone className={`w-${size} h-${size}`} rounded={rounded} padding={padding} />;
    case TOKEN_FOOD:
      return <IconFood className={`w-${size} h-${size}`} rounded={rounded} padding={padding} />;
    case TOKEN_VILLAGE_COIN:
      return (
        <IconVillageCoin
          className={`w-${size} h-${size}`}
          rounded={rounded}
          padding={padding}
          background={background}
        />
      );
    case TOKEN_GIANTS_CURRENCY:
      return (
        <div className={classNames(
            `flex items-center justify-center rounded-full`,
            background ? `bg-${background}` : ``,
            padding ? `p-${padding}` : `p-1`
        )}>
          <IconGiantCurrency className={`w-${size} h-${size}`} />
        </div>
      );
    default:
      return null;
  }
}
