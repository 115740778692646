import {Npc, UserQuest} from "@elrond-giants/game-db/types/entities";
import QuestRequirements from "./requirements/QuestRequirements";
import {classNames, formatAmount} from "../../../utils/presentation";
import {useSelector} from "react-redux";
import {selectRewardsResources} from "../../../redux/slices/inventorySlice";
import {useMemo, useState} from "react";
import {GameActionEnum, TxActionPayload} from "../../../common/types/actions";
import {useAuth} from "../../../hooks/useAuth";
import Manager from "../../../gameboard/Manager";
import {useQuestsState} from "../../../hooks/useQuestsState";
import IconVillageCoin from "../../icons/IconVillageCoin";
import {QUEST_TYPE_DELIVER} from "../../../config/assets";
import {useApi} from "../../../hooks/useApi";
import {ValidateQuestActionResponse} from "../../../common/types/responses";
import {validateQuestPath} from "../../../utils/api-routes";
import CompleteQuestContext from "../../../services/contexts/CompleteQuestContext";
import BigNumber from "bignumber.js";
import IconGiantCurrency from "../../icons/IconGiantCurrency";
import { RootState } from "../../../redux/store";

export default function QuestListItem(
    { quest, npc}: { quest: UserQuest, npc: Npc}
) {
    const {village} = useAuth();
    const {api} = useApi();
    const {waitingForQuests, setQuestStatus, setWaitingForQuests} = useQuestsState();
    const resources = useSelector(selectRewardsResources);
    const [completing, setCompleting] = useState(false);
    const canComplete = useMemo(() => {
        if (waitingForQuests) {return false;}
        if (quest.type !== QUEST_TYPE_DELIVER) {return false;}
        if (!resources) {return false;}
        // @ts-ignore
        return quest.requirements.payments.every(requirement => {
            const current = resources[requirement.token_identifier] ?? 0;
            return current >= requirement.amount;
        });
    }, [ quest.type, quest.requirements, resources, waitingForQuests ]);


    const commitAction = async (quest: UserQuest) => {
        if (!canComplete || !village) {return;}
        const actionContext = new CompleteQuestContext({
            npc: {
                id: quest.npc_id,
                contract_id: npc.contract_id,
            },
            quest: quest,
            villageId: village.id,
        });
        setQuestStatus(quest.id, "pending");
        await Manager.getInstance().commitAction(actionContext);
    }



    const completeQuest = async () => {
        if (!canComplete || !village) {return;}
        setCompleting(true);
        setWaitingForQuests(true);
        let validQuest = quest;
        if (quest.status === "new") {
            try {
                const {data: {quest: validatedQuest}} = await api.post<ValidateQuestActionResponse>(
                    validateQuestPath(quest.id)
                );
                validQuest = validatedQuest;
            } catch (e) {
                setCompleting(false);
                setWaitingForQuests(false);
            }
        }
        if (!validQuest) {
            setWaitingForQuests(false);
            return;
        }

        await commitAction(validQuest);
    }


    return (
        <li
            className="flex flex-col md:flex-row items-center py-3 px-3 md:px-5"
        >
            <div className="flex items-start w-full md:w-9/12">
                {/*Rewards Icon*/}
                <div
                    className="w-16 h-16 bg-[#BBCE84] border-secondary border-2 relative flex items-center justify-center">
                    <IconVillageCoin className="w-10 h-10"/>
                    <div
                        className="h-4 min-w-4 p-0.5 absolute top-0 right-0 bg-secondary flex items-center justify-center">
                        <span className="text-theme-text text-xs font-semibold">
                            {quest.reward_qty}
                        </span>
                    </div>
                    <div
                        className="h-4 w-full p-0.5 absolute bottom-0 right-0 bg-secondary flex items-center justify-center space-x-1"
                    >
                         <span className="text-theme-text text-xs font-semibold">
                            {
                                Number(quest.giant_qty) > 0 ?  formatAmount(
                                    new BigNumber(quest.giant_qty).shiftedBy(-18).toNumber(),
                                    1,
                                    true
                                ) : 0
                            }
                        </span>
                        <IconGiantCurrency className="w-4 h-4"/>
                    </div>
                </div>
                {/*Requirements*/}
                <div className="flex flex-col items-start space-y-1 px-4 flex-grow">
                    <span className="text-theme-text text-xs">
                        [{quest.npc_reward_qty}xp] {quest.name}
                    </span>
                    <QuestRequirements quest={quest}/>
                </div>
            </div>
            <div className="flex items-center justify-center w-full md:w-3/12 mt-4 md:mt-0">
                {"completed" === quest.status && (
                    <div
                        className="flex items-center justify-center uppercase font-semibold py-1.5 px-3 text-sm w-full bg-gray-300"
                    >
                        completed
                    </div>
                )}
                {"completed" !== quest.status && <button
                    onClick={completeQuest}
                    className={classNames(
                        "flex items-center justify-center py-1.5 px-3 w-full",
                        "uppercase text-sm font-semibold",
                        "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
                        "focus-visible: outline-[#BBCE84]",
                        "disabled:bg-gray-300 disabled:text-white disabled:cursor-not-allowed",
                        "new" === quest.status
                            ? "bg-[#BBCE84] text-theme-text"
                            : "bg-gray-300 text-white",
                        (completing || quest.status === "pending") ? "animate-pulse" : ""
                    )}
                    disabled={!canComplete || quest.status === "pending" || completing}
                >
                    {completing && "pending"}
                    {!completing && (quest.status === "new" || quest.status === "validated") && "complete"}
                    {!completing && quest.status === "pending" && "pending"}
                </button>
                }
            </div>
        </li>
    );
}
