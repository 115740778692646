import { Npc } from "@elrond-giants/game-db";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FcDepartment, FcVoicePresentation } from "react-icons/fc";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";

import {GameActionEnum, TxActionPayload} from "../../../common/types/actions";
import { PopupEnum } from "../../../common/types/components";
import { TOKEN_STAR } from "../../../config/assets";
import Manager from "../../../gameboard/Manager";
import { useApi } from "../../../hooks/useApi";
import { useAuth } from "../../../hooks/useAuth";
import { usePopups } from "../../../hooks/usePopups";
import { useVillageState } from "../../../hooks/useVillageState";
import { selectRewardsResources } from "../../../redux/slices/inventorySlice";
import { getNpcsPath } from "../../../utils/api-routes";
import { getMaxLandQtyForVillageLevel, starsRequiredForLevelUp } from "../../../utils/game";
import { formatAddress } from "../../../utils/presentation";
import IconLandManagement from "../../icons/IconLandManagement";
import IconStar from "../../icons/IconStar";
import ProgressBar from "../../ProgressBar";
import Popup from "../../shared/Popup";
import Level from "../Level";
import UpgradeVillageContext from "../../../services/contexts/UpgradeVillageContext";

export default function UpgradeVillageDialog() {
  const { account, village, setVillage } = useAuth();
  const { api } = useApi();
  const [npcs, setNpcs] = useState<Npc[]>([]);
  const { reloadVillage } = useVillageState();
  const { isActive, closePopup } = usePopups();
  const [avatarUrl, setAvatarUrl] = useState<string>("./no-avatar.jpeg");
  const resources = useSelector(selectRewardsResources);
  const villageStars = useMemo(() => {
    return resources[TOKEN_STAR] ?? 0;
  }, [resources]);
  const requiredStars = useMemo(() => {
    return starsRequiredForLevelUp((village?.level ?? 0) + 1);
  }, [village?.level]);
  const upgrading = useMemo(() => {
    return (village?.upgrade_started_at ?? null) !== null;
  }, [village]);

  const gameManager = Manager.getInstance();

  const upgradeVillage = useCallback(async () => {
    if (!village) {
      return;
    }
    const actionContext = new UpgradeVillageContext({
      villageId: village.id,
      payment: {
        id: TOKEN_STAR,
        quantity: requiredStars,
        nonce: 1,
      },
    });

    setVillage({ ...village, upgrade_started_at: new Date() });
    await Manager.getInstance().commitAction(actionContext);
  }, [requiredStars, village]);

  useEffect(() => {
    if (!gameManager?.initialised) {
      return;
    }
    const actionCommitHandler = () => {
      reloadVillage();
    };
    gameManager.subscribeToAction("GAME_ACTION_COMMIT_ERROR", actionCommitHandler);

    return () => {
      gameManager.unsubscribeFromAction("GAME_ACTION_COMMIT_ERROR", actionCommitHandler);
    };
  }, [gameManager, reloadVillage]);

  useEffect(() => {
    if (account?.primary_wallet) {
      setAvatarUrl(`https://id.maiar.com/users/photos/profile/${account.primary_wallet}`);
    }
  }, [account?.primary_wallet]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(getNpcsPath());
      setNpcs(data);
    })();
  }, []);

  const upgradeBenefits = useMemo(() => {
    const currentVillageLevel = village?.level ?? 0;
    const nextVillageLevel = currentVillageLevel + 1;
    return {
      extraLand: getMaxLandQtyForVillageLevel(nextVillageLevel) - getMaxLandQtyForVillageLevel(currentVillageLevel),
      newNpcs: npcs.filter((npc) => npc.min_village_level === nextVillageLevel).length,
      newBuildings: nextVillageLevel === 1 || nextVillageLevel === 3 ? 1 : 0,
    };
  }, [village?.level, npcs]);

  return (
    <Popup
      open={isActive(PopupEnum.VILLAGE_UPGRADE)}
      setOpen={() => {
        closePopup(PopupEnum.VILLAGE_UPGRADE);
      }}
      widthClass="w-full md:w-100"
    >
      <div className="w-full h-full flex flex-col">
        <div className="flex items-center w-full">
          {account?.avatar_url ? (
            <img src={account.avatar_url} className="w-20 h-20 object-center object-contain" />
          ) : (
            <img
              src={avatarUrl}
              onError={() => setAvatarUrl("./no-avatar.jpeg")}
              className="w-20 h-20 object-center object-contain"
            />
          )}
          <div className="flex flex-col flex-grow h-full justify-center items-start px-3 gap-y-2 w-full">
            <span className="text-xl text-red font-semibold">{formatAddress(account!.primary_wallet)}</span>
            <div className="flex items-center justify-start space-x-2 w-full">
              <Level level={village?.level ?? 0} theme="blue" />
              <ProgressBar
                value={Math.min(villageStars, requiredStars)}
                maxValue={requiredStars}
                showTextProgress={true}
                label="Village Upgrade Progress"
                height="medium"
                theme="blue"
                icon={<IconStar className="w-3 h-3" />}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-end w-full flex-grow py-2 mt-4">
          <div className="mb-4 flex items-center space-x-6 text-sm font-semibold text-theme-text mx-auto">
            <div
              className="flex items-center"
              data-tooltip-id="village-upgrade-popup-tooltip"
              data-tooltip-content="Place more land"
            >
              <IconLandManagement className="w-8 h-8 mr-2" /> <span>+{upgradeBenefits.extraLand}</span>
            </div>
            {upgradeBenefits.newNpcs > 0 && (
              <div
                className="flex items-center"
                data-tooltip-id="village-upgrade-popup-tooltip"
                data-tooltip-content="Unlock new NPCs"
              >
                <FcVoicePresentation className="w-8 h-8 mr-1" /> <span>+{upgradeBenefits.newNpcs}</span>
              </div>
            )}
            {upgradeBenefits.newBuildings > 0 && (
              <div
                className="flex items-center"
                data-tooltip-id="village-upgrade-popup-tooltip"
                data-tooltip-content="Unlock new buildings"
              >
                <FcDepartment className="w-7 h-7 mr-1" /> <span>+{upgradeBenefits.newBuildings}</span>
              </div>
            )}
          </div>

          <button
            className="flex flex-col justify-center items-center bg-secondary disabled:cursor-not-allowed disabled:bg-gray-300 h-16 w-full"
            onClick={upgradeVillage}
            disabled={upgrading || villageStars < requiredStars}
          >
            {upgrading ? (
              <span className="uppercase">upgrading</span>
            ) : (
              <>
                <span className="uppercase">upgrade village</span>
                <div className="flex items-center space-x-2">
                  <div className="rounded-full bg-blue w-5 h-5">
                    <IconStar className="w-full h-full" />
                  </div>
                  <span className="font-normal">{requiredStars}</span>
                </div>
              </>
            )}
          </button>
        </div>

        <Tooltip id="village-upgrade-popup-tooltip" className="base-tooltip" />
      </div>
    </Popup>
  );
}
