import { SVGProps } from "react";

import { classNames } from "../../utils/presentation";

export default function IconVillageCoin({
  rounded = true,
  padding = 1,
  background = "[#BBCE84]",
  ...props
}: SVGProps<any> & {
  rounded?: boolean;
  padding?: number;
  background?: string;
}) {
  return (
    <div
      className={classNames(
        "flex items-center justify-center rounded-full",
        rounded ? "rounded-full" : "rounded-none",
        padding ? `p-${padding}` : "",
        background ? `bg-${background}` : ""
      )}
    >
      <svg viewBox="0 0 1445 1092" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_624_2)">
          <path
            opacity="0.7"
            d="M978.3 1091.89C1235.69 1091.89 1444.34 1006.91 1444.34 902.08C1444.34 797.251 1235.69 712.27 978.3 712.27C720.913 712.27 512.26 797.251 512.26 902.08C512.26 1006.91 720.913 1091.89 978.3 1091.89Z"
            fill="url(#paint0_radial_624_2)"
          />
          <path
            d="M842.94 604.97C771.86 541.56 644.64 499.31 499.51 499.31C354.38 499.31 227.15 541.56 156.08 604.97H96.08C96.08 670.33 102.55 692.55 103.61 721.74C108.1 844.51 276.7 944.18 499.51 944.18C722.32 944.18 894.2 844.49 899.17 721.74C900.35 692.48 902.94 675.31 902.94 604.68L842.94 604.96V604.97Z"
            fill="#C66628"
          />
          <path
            d="M660.82 746.06C656.13 829.87 660.82 925.34 660.82 925.34C745.49 904.4 813.81 867.36 855.27 821.18C902.94 755.3 895.44 686.83 895.44 622.35C895.44 622.35 665.21 667.66 660.82 746.06Z"
            fill="#A24B24"
          />
          <path
            d="M252.83 548.22C214.98 562.87 182.3 581.17 156.52 602.29L96.5499 600.35C94.3999 664.44 100.13 686.44 100.24 715.1C100.51 786.66 158.86 852.1 252.83 894.98V548.23V548.22Z"
            fill="#A24B24"
          />
          <path
            d="M137.06 601.66L96.56 600.35C94.41 664.44 100.14 686.44 100.25 715.1C100.38 748.86 113.44 781.27 137.07 810.58V601.66H137.06Z"
            fill="#C66628"
          />
          <path
            d="M902.94 604.69C902.94 727.54 722.32 838.71 499.51 838.71C276.7 838.71 96.08 727.82 96.08 604.97C96.08 482.12 276.7 382.53 499.51 382.53C722.32 382.53 902.94 481.83 902.94 604.68V604.69Z"
            fill="url(#paint1_radial_624_2)"
          />
          <path
            d="M386.28 487.81L403.44 476.7L347.64 398.84C337.02 401.22 326.62 403.85 316.47 406.7L386.28 487.81Z"
            fill="#F8F4AF"
          />
          <path
            opacity="0.7"
            d="M680.85 520.63L848.92 719.12C858.93 708.91 865.73 700.86 874.68 688.51L605.46 390.31C587.01 387.55 568.03 385.49 548.62 384.19L680.86 520.63H680.85Z"
            fill="#F8F4AF"
          />
          <path
            d="M507.04 779.13C673.167 779.13 807.84 704.876 807.84 613.28C807.84 521.684 673.167 447.43 507.04 447.43C340.913 447.43 206.24 521.684 206.24 613.28C206.24 704.876 340.913 779.13 507.04 779.13Z"
            fill="#F8F4AF"
          />
          <path
            d="M499.5 771.73C666.544 771.73 801.96 697.069 801.96 604.97C801.96 512.871 666.544 438.21 499.5 438.21C332.456 438.21 197.04 512.871 197.04 604.97C197.04 697.069 332.456 771.73 499.5 771.73Z"
            fill="#FBAA19"
          />
          <path
            opacity="0.3"
            d="M747.06 509.14C767.73 531.7 779.37 557.21 779.37 584.21C779.37 676.31 643.95 750.97 476.91 750.97C374.56 750.97 284.09 722.94 229.36 680.04C279.18 734.43 381.45 771.73 499.52 771.73C666.56 771.73 801.98 697.07 801.98 604.97C801.98 569.3 781.67 536.25 747.07 509.13L747.06 509.14Z"
            fill="#EA8424"
          />
          <path
            opacity="0.25"
            d="M108.53 549.92C146.34 618.32 261.68 668.08 398.09 668.08C565.25 668.08 700.76 593.37 700.76 501.2C700.76 460.68 674.56 423.53 631 394.62C589.78 386.79 545.54 382.54 499.5 382.54C311.16 382.54 152.96 453.7 108.52 549.92H108.53Z"
            fill="#FEFDED"
          />
          <path
            d="M534.08 607.05C587.25 609.31 722.88 620.34 633.14 673.45C539.11 729.09 511.54 678.85 501.39 637.11C491.24 678.85 463.67 729.1 369.64 673.45C279.9 620.35 415.53 609.32 468.7 607.05C415.53 604.79 279.9 593.76 369.64 540.65C463.67 485.01 487.47 540.28 497.63 582.03C507.78 540.29 539.11 485.01 633.15 540.65C722.89 593.75 587.26 604.78 534.09 607.05H534.08Z"
            fill="#C66628"
          />
          <path
            d="M530.32 602.9C583.49 605.16 719.12 616.19 629.38 669.3C535.35 724.94 507.78 674.7 497.63 632.96C487.48 674.7 459.91 724.95 365.88 669.3C276.14 616.2 411.77 605.17 464.94 602.9C411.77 600.64 276.14 589.61 365.88 536.5C459.91 480.86 487.48 531.1 497.63 572.84C507.78 531.1 535.35 480.85 629.38 536.5C719.12 589.6 583.49 600.63 530.32 602.9Z"
            fill="#FBE13E"
          />
          <path
            d="M434.12 604.81C381.56 609.17 311.35 621.11 344.53 653.74C315.85 617.46 447.1 605.96 434.12 604.81Z"
            fill="#F8F4AF"
          />
          <path
            d="M501.39 577C511.54 535.26 539.11 485.01 633.14 540.66C642.68 546.31 649.66 551.48 654.47 556.21C649.87 550.39 641.78 543.85 629.37 536.5C539.03 483.04 510.04 527.32 498.9 567.89C499.83 570.94 500.65 573.99 501.38 576.99L501.39 577Z"
            fill="#F8F4AF"
          />
          <path
            d="M564.89 605.14C553.34 606.1 636.54 613.59 657.08 631.15C646.76 615.62 602.21 608.45 564.89 605.14Z"
            fill="#F8F4AF"
          />
          <path
            d="M369.64 540.66C427.2 506.6 459.85 512.22 478.85 531.27C460.5 509.03 427.43 500.08 365.87 536.51C333.83 555.47 330.53 569.06 341.93 578.81C335.71 569.45 341.91 557.07 369.63 540.66H369.64Z"
            fill="#F8F4AF"
          />
          <path
            opacity="0.25"
            d="M621.66 613.69C592.61 606.56 553.57 603.89 530.31 602.9C583.48 600.64 719.11 589.61 629.37 536.5C535.34 480.86 507.77 531.1 497.62 572.84C487.47 531.1 459.9 480.85 365.87 536.5C276.13 589.6 411.76 600.63 464.93 602.9C412.57 605.13 280.24 615.86 361.93 666.9C373.79 667.68 385.85 668.08 398.09 668.08C429.33 668.08 459.46 665.47 487.82 660.62C492.29 651.8 495.38 642.17 497.62 632.95C499.58 640.99 502.18 649.35 505.78 657.2C550.43 647.82 590.02 632.79 621.66 613.68V613.69Z"
            fill="#FEFDED"
          />
          <path
            d="M204.58 611.2C204.58 519.1 340 444.44 507.04 444.44C609.68 444.44 700.37 472.63 755.05 515.73C701.41 469.14 607.01 438.21 499.5 438.21C332.46 438.21 197.04 512.87 197.04 604.97C197.04 640.48 217.17 673.4 251.49 700.45C221.78 674.64 204.58 644.03 204.58 611.2Z"
            fill="#CE7428"
          />
          <g style={{ mixBlendMode: "multiply" }}>
            <path
              d="M241.01 655.14C241.01 735.47 326.57 804.78 450.18 836.88C466.35 838.08 482.81 838.71 499.5 838.71C722.31 838.71 902.93 727.54 902.93 604.69C902.93 573.92 891.6 544.62 871.11 518C804.66 478.84 710.09 454.37 605.15 454.37C404.04 454.37 241 544.26 241 655.15L241.01 655.14Z"
              fill="#916C5E"
            />
          </g>
          <path
            d="M746.86 421.41C675.79 358 548.56 315.75 403.43 315.75C258.3 315.75 131.07 358 60 421.41H0C0 486.77 6.47 508.99 7.53 538.18C12.02 660.95 180.62 760.62 403.43 760.62C626.24 760.62 798.12 660.93 803.09 538.18C804.27 508.92 806.86 491.75 806.86 421.12L746.86 421.4V421.41Z"
            fill="#C66628"
          />
          <path
            d="M564.75 562.49C560.06 646.3 564.75 741.77 564.75 741.77C649.42 720.83 717.74 683.79 759.2 637.61C806.87 571.73 799.37 503.26 799.37 438.78C799.37 438.78 569.14 484.09 564.75 562.49Z"
            fill="#A24B24"
          />
          <path
            d="M156.76 358.84C118.91 373.77 86.23 392.43 60.45 413.95L0.480003 411.97C-1.67 477.29 4.06 499.72 4.17 528.93C4.44 601.87 62.79 668.57 156.76 712.27V358.84Z"
            fill="#A24B24"
          />
          <path
            d="M40.98 413.3L0.480003 411.97C-1.67 477.29 4.06 499.72 4.17 528.93C4.3 563.34 17.36 596.37 40.99 626.25V413.31L40.98 413.3Z"
            fill="#C66628"
          />
          <path
            d="M806.86 421.12C806.86 543.97 626.24 655.14 403.43 655.14C180.62 655.14 0 544.26 0 421.41C0 298.56 180.62 198.97 403.43 198.97C626.24 198.97 806.86 298.27 806.86 421.12Z"
            fill="url(#paint2_radial_624_2)"
          />
          <path
            d="M290.2 304.25L307.36 293.14L251.56 215.28C240.94 217.66 230.54 220.29 220.39 223.14L290.2 304.25Z"
            fill="#F8F4AF"
          />
          <path
            opacity="0.7"
            d="M584.77 337.07L752.84 535.56C762.85 525.35 769.65 517.3 778.6 504.95L509.38 206.75C490.93 203.99 471.95 201.93 452.54 200.63L584.78 337.07H584.77Z"
            fill="#F8F4AF"
          />
          <path
            d="M410.97 595.57C577.097 595.57 711.77 521.316 711.77 429.72C711.77 338.124 577.097 263.87 410.97 263.87C244.843 263.87 110.17 338.124 110.17 429.72C110.17 521.316 244.843 595.57 410.97 595.57Z"
            fill="#F8F4AF"
          />
          <path
            d="M403.43 588.17C570.474 588.17 705.89 513.509 705.89 421.41C705.89 329.311 570.474 254.65 403.43 254.65C236.386 254.65 100.97 329.311 100.97 421.41C100.97 513.509 236.386 588.17 403.43 588.17Z"
            fill="#FBAA19"
          />
          <path
            opacity="0.3"
            d="M650.98 325.57C671.65 348.13 683.29 373.64 683.29 400.64C683.29 492.74 547.87 567.4 380.83 567.4C278.48 567.4 188.01 539.37 133.28 496.47C183.1 550.86 285.37 588.16 403.44 588.16C570.48 588.16 705.9 513.5 705.9 421.4C705.9 385.73 685.59 352.68 650.99 325.56L650.98 325.57Z"
            fill="#EA8424"
          />
          <path
            opacity="0.25"
            d="M12.4499 366.35C50.2599 434.75 165.6 484.51 302.01 484.51C469.17 484.51 604.68 409.8 604.68 317.63C604.68 277.11 578.49 239.96 534.92 211.05C493.7 203.22 449.46 198.97 403.42 198.97C215.08 198.97 56.8799 270.13 12.4399 366.35H12.4499Z"
            fill="#FEFDED"
          />
          <path
            d="M438.01 423.49C491.18 425.75 626.81 436.78 537.07 489.89C443.04 545.53 415.47 495.29 405.32 453.55C395.17 495.29 367.6 545.54 273.57 489.89C183.83 436.79 319.46 425.76 372.63 423.49C319.46 421.23 183.83 410.2 273.57 357.09C367.6 301.45 391.4 356.72 401.56 398.47C411.71 356.73 443.04 301.45 537.08 357.09C626.82 410.19 491.19 421.22 438.02 423.49H438.01Z"
            fill="#C66628"
          />
          <path
            d="M434.24 419.33C487.41 421.59 623.04 432.62 533.3 485.73C439.27 541.37 411.7 491.13 401.55 449.39C391.4 491.13 363.83 541.38 269.8 485.73C180.06 432.63 315.69 421.6 368.86 419.33C315.69 417.07 180.06 406.04 269.8 352.93C363.83 297.29 391.4 347.53 401.55 389.27C411.7 347.53 439.27 297.28 533.3 352.93C623.04 406.03 487.41 417.06 434.24 419.33Z"
            fill="#FBE13E"
          />
          <path
            d="M338.05 421.25C285.49 425.61 215.28 437.55 248.46 470.18C219.78 433.9 351.03 422.4 338.05 421.25Z"
            fill="#F8F4AF"
          />
          <path
            d="M405.32 393.43C415.47 351.69 443.04 301.44 537.07 357.09C546.61 362.74 553.59 367.91 558.4 372.64C553.8 366.82 545.71 360.28 533.3 352.93C442.96 299.47 413.97 343.75 402.83 384.32C403.76 387.37 404.58 390.42 405.31 393.42L405.32 393.43Z"
            fill="#F8F4AF"
          />
          <path
            d="M468.81 421.57C457.26 422.53 540.46 430.02 561 447.58C550.68 432.05 506.13 424.88 468.81 421.57Z"
            fill="#F8F4AF"
          />
          <path
            d="M273.56 357.09C331.12 323.03 363.77 328.65 382.77 347.7C364.42 325.46 331.35 316.51 269.79 352.94C237.75 371.9 234.45 385.49 245.85 395.24C239.63 385.88 245.83 373.5 273.55 357.09H273.56Z"
            fill="#F8F4AF"
          />
          <path
            opacity="0.25"
            d="M525.59 430.12C496.54 422.99 457.5 420.32 434.24 419.33C487.41 417.07 623.04 406.04 533.3 352.93C439.27 297.29 411.7 347.53 401.55 389.27C391.4 347.53 363.83 297.28 269.8 352.93C180.06 406.03 315.69 417.06 368.86 419.33C316.5 421.56 184.17 432.29 265.86 483.33C277.72 484.11 289.78 484.51 302.02 484.51C333.26 484.51 363.39 481.9 391.75 477.05C396.22 468.23 399.31 458.6 401.55 449.38C403.51 457.42 406.11 465.78 409.71 473.63C454.36 464.25 493.95 449.22 525.59 430.11V430.12Z"
            fill="#FEFDED"
          />
          <path
            d="M108.51 427.64C108.51 335.54 243.93 260.88 410.97 260.88C513.61 260.88 604.3 289.07 658.98 332.17C605.34 285.58 510.94 254.65 403.43 254.65C236.39 254.65 100.97 329.31 100.97 421.41C100.97 456.92 121.1 489.84 155.42 516.89C125.71 491.08 108.51 460.47 108.51 427.64Z"
            fill="#CE7428"
          />
          <g style={{ mixBlendMode: "multiply" }}>
            <path
              d="M796.94 470.96C776.39 390.83 653.59 326.09 503.11 321.14C336.16 315.64 198.36 385.8 195.32 477.86C192.48 564.17 309.14 639.08 461.33 652.62C627.19 638.03 760.46 562.1 796.94 470.97V470.96Z"
              fill="#916C5E"
            />
          </g>
          <path
            d="M792.95 234.02C724 168.3 598.23 121.89 453.18 117.11C308.13 112.33 179.58 150.37 106.46 211.41L46.49 209.43C44.34 274.75 50.07 297.18 50.18 326.39C50.63 449.24 215.85 554.4 438.54 561.74C661.23 569.08 836.3 475.1 845.31 352.58C847.46 323.38 850.6 306.3 852.93 235.71L792.95 234.02Z"
            fill="#C66628"
          />
          <path
            d="M203.18 156.31C165.33 171.24 132.65 189.9 106.87 211.42L46.9 209.44C44.75 274.76 50.48 297.19 50.59 326.4C50.86 399.34 109.21 466.04 203.18 509.74V156.31Z"
            fill="#A24B24"
          />
          <path
            d="M606.28 369.04C598.83 452.65 600.38 548.22 600.38 548.22C685.7 530.08 755.2 495.31 798.15 450.51C847.96 386.24 842.72 317.56 844.85 253.11C844.85 253.11 613.25 290.82 606.28 369.03V369.04Z"
            fill="#A24B24"
          />
          <path
            d="M87.4 210.77L46.9 209.44C44.75 274.76 50.48 297.19 50.59 326.4C50.72 360.81 63.7801 393.84 87.41 423.72V210.78L87.4 210.77Z"
            fill="#C66628"
          />
          <path
            d="M852.92 235.72C848.88 358.5 664.69 463.67 442 456.33C219.31 448.99 42.44 332.22 46.48 209.44C50.53 86.66 234.34 -6.93001 457.02 0.399989C679.71 7.73999 856.96 112.93 852.92 235.71V235.72Z"
            fill="url(#paint3_radial_624_2)"
          />
          <path
            d="M757.621 241.207C760.642 149.66 628.492 71.0053 462.455 65.5263C296.418 60.0473 159.37 129.819 156.349 221.365C153.328 312.912 285.478 391.567 451.515 397.046C617.552 402.525 754.601 332.753 757.621 241.207Z"
            fill="#F8F4AF"
          />
          <path
            d="M752.026 232.705C755.063 140.657 622.183 61.5701 455.23 56.0608C288.277 50.5516 150.472 120.706 147.435 212.755C144.397 304.804 277.277 383.89 444.23 389.399C611.184 394.909 748.988 324.754 752.026 232.705Z"
            fill="#FFC840"
          />
          <path
            d="M653.49 170.99C653.49 163.23 644.92 156.94 634.34 156.94C623.76 156.94 615.19 163.23 615.19 170.99C615.19 173.18 615.87 175.24 617.09 177.09L574.14 207.04C556.71 184.09 530.47 168.19 500.46 164.41L472.45 134.44C477.59 130.69 480.77 125.51 480.77 119.77C480.77 108.35 468.15 99.09 452.58 99.09C437.01 99.09 424.39 108.35 424.39 119.77C424.39 126.46 428.72 132.4 435.43 136.18L412.4 163.72C381.21 165.45 353.5 180.19 334.59 202.59L289.22 174.63C289.64 173.47 289.87 172.25 289.87 170.99C289.87 163.23 281.3 156.94 270.72 156.94C260.14 156.94 251.57 163.23 251.57 170.99C251.57 178.18 258.94 184.11 268.44 184.93L295.08 276.35H287.63C277.86 276.35 269.27 280.24 264.59 286C262.2 288.94 260.82 292.36 260.82 296.01C260.82 301.42 263.83 306.33 268.69 309.9C273.55 313.47 280.25 315.68 287.63 315.68H619.43C634.17 315.68 646.23 306.83 646.23 296.02C646.23 291 643.63 286.39 639.36 282.91C639.03 282.64 638.7 282.39 638.36 282.14C633.84 278.83 627.75 276.69 621 276.4C620.48 276.37 619.96 276.36 619.43 276.36H618.32L639.77 184.47C647.7 182.75 653.49 177.37 653.49 171V170.99Z"
            fill="#A24B24"
          />
          <path
            d="M408.22 154.46H496.95C551.87 154.46 596.45 199.05 596.45 253.96V264.73H308.72V253.96C308.72 199.04 353.31 154.46 408.22 154.46Z"
            fill="#FCB33B"
          />
          <path
            d="M653.49 161.91C653.49 154.15 644.92 147.86 634.34 147.86C623.76 147.86 615.19 154.15 615.19 161.91C615.19 164.1 615.87 166.16 617.09 168.01L553.65 212.25L472.46 125.36C477.6 121.61 480.78 116.43 480.78 110.69C480.78 99.27 468.16 90.01 452.59 90.01C437.02 90.01 424.4 99.27 424.4 110.69C424.4 117.38 428.73 123.32 435.44 127.1L364.5 211.94L289.23 165.55C289.65 164.39 289.88 163.17 289.88 161.91C289.88 154.15 281.31 147.86 270.73 147.86C260.15 147.86 251.58 154.15 251.58 161.91C251.58 169.1 258.95 175.03 268.45 175.85L295.09 267.27H287.64C277.87 267.27 269.28 271.16 264.6 276.92C262.21 279.86 260.83 283.28 260.83 286.93C260.83 292.34 263.84 297.25 268.7 300.82C273.56 304.39 280.26 306.6 287.64 306.6H619.44C634.18 306.6 646.24 297.75 646.24 286.94C646.24 281.92 643.64 277.31 639.37 273.83C639.04 273.56 638.71 273.31 638.37 273.06C633.85 269.75 627.76 267.61 621.01 267.32C620.49 267.29 619.97 267.28 619.44 267.28H618.33L639.78 175.39C647.71 173.67 653.5 168.29 653.5 161.92L653.49 161.91Z"
            fill="#FBE13E"
          />
          <path
            d="M335.03 256.33C340.271 256.33 344.52 253.214 344.52 249.37C344.52 245.526 340.271 242.41 335.03 242.41C329.789 242.41 325.54 245.526 325.54 249.37C325.54 253.214 329.789 256.33 335.03 256.33Z"
            fill="#A24B24"
          />
          <path
            d="M392.56 256.33C397.801 256.33 402.05 253.214 402.05 249.37C402.05 245.526 397.801 242.41 392.56 242.41C387.319 242.41 383.07 245.526 383.07 249.37C383.07 253.214 387.319 256.33 392.56 256.33Z"
            fill="#A24B24"
          />
          <path
            d="M521.7 256.33C526.941 256.33 531.19 253.214 531.19 249.37C531.19 245.526 526.941 242.41 521.7 242.41C516.459 242.41 512.21 245.526 512.21 249.37C512.21 253.214 516.459 256.33 521.7 256.33Z"
            fill="#A24B24"
          />
          <path
            d="M456.96 256.33C462.201 256.33 466.45 253.214 466.45 249.37C466.45 245.526 462.201 242.41 456.96 242.41C451.719 242.41 447.47 245.526 447.47 249.37C447.47 253.214 451.719 256.33 456.96 256.33Z"
            fill="#A24B24"
          />
          <path
            d="M579.22 256.33C584.461 256.33 588.71 253.214 588.71 249.37C588.71 245.526 584.461 242.41 579.22 242.41C573.979 242.41 569.73 245.526 569.73 249.37C569.73 253.214 573.979 256.33 579.22 256.33Z"
            fill="#A24B24"
          />
          <path
            d="M335.03 252.75C340.271 252.75 344.52 249.634 344.52 245.79C344.52 241.946 340.271 238.83 335.03 238.83C329.789 238.83 325.54 241.946 325.54 245.79C325.54 249.634 329.789 252.75 335.03 252.75Z"
            fill="#FCB33B"
          />
          <path
            d="M392.56 252.75C397.801 252.75 402.05 249.634 402.05 245.79C402.05 241.946 397.801 238.83 392.56 238.83C387.319 238.83 383.07 241.946 383.07 245.79C383.07 249.634 387.319 252.75 392.56 252.75Z"
            fill="#FCB33B"
          />
          <path
            d="M521.7 252.75C526.941 252.75 531.19 249.634 531.19 245.79C531.19 241.946 526.941 238.83 521.7 238.83C516.459 238.83 512.21 241.946 512.21 245.79C512.21 249.634 516.459 252.75 521.7 252.75Z"
            fill="#FCB33B"
          />
          <path
            d="M579.22 252.75C584.461 252.75 588.71 249.634 588.71 245.79C588.71 241.946 584.461 238.83 579.22 238.83C573.979 238.83 569.73 241.946 569.73 245.79C569.73 249.634 573.979 252.75 579.22 252.75Z"
            fill="#FCB33B"
          />
          <path
            d="M456.96 252.75C462.201 252.75 466.45 249.634 466.45 245.79C466.45 241.946 462.201 238.83 456.96 238.83C451.719 238.83 447.47 241.946 447.47 245.79C447.47 249.634 451.719 252.75 456.96 252.75Z"
            fill="#FCB33B"
          />
          <path
            opacity="0.3"
            d="M700.28 135.08C720.19 158.31 730.99 184.18 730.1 211.17C727.07 303.22 589.27 373.39 422.31 367.89C320.02 364.52 230.51 333.53 177.23 288.85C225.23 344.85 326.22 385.5 444.22 389.39C611.17 394.89 748.97 324.73 752.01 232.67C753.18 197.02 733.97 163.31 700.29 135.08H700.28Z"
            fill="#EA8424"
          />
          <path
            opacity="0.25"
            d="M60.75 154.82C96.29 224.42 209.92 277.95 346.27 282.45C513.34 287.95 651.23 217.74 654.27 125.63C655.6 85.13 630.65 47.14 588.06 16.82C547.12 7.63003 503.04 1.92003 457.02 0.400026C268.78 -5.79997 108.33 60.12 60.75 154.82Z"
            fill="#FEFDED"
          />
          <path
            d="M154.73 219.24C157.76 127.19 295.56 57.02 462.52 62.52C565.1 65.9 654.82 97.06 708.05 141.93C655.97 93.59 562.64 59.58 455.19 56.04C288.24 50.54 150.44 120.71 147.4 212.76C146.23 248.25 165.27 281.82 198.67 309.98C169.83 283.21 153.64 252.05 154.72 219.24H154.73Z"
            fill="#CE7428"
          />
          <path
            d="M1260.39 737.24C1320.11 593.52 1258.08 406.35 1102.79 291.46C947.5 176.58 750.37 172.02 630.41 271.18L623.81 266.29L583.82 320.34C474.81 467.69 530.42 693.65 708.03 825.05C885.64 956.45 1117.99 943.52 1227 796.17L1266.99 742.12L1260.39 737.23V737.24Z"
            fill="#C66628"
          />
          <path
            d="M1252.81 580.72L950.71 749.3L845.79 895.78C992.07 942.05 1144.7 907.43 1227 796.18L1266.99 742.13L1260.39 737.24C1270.42 713.11 1277.01 687.75 1280.31 661.71L1252.82 580.72H1252.81Z"
            fill="#A24B24"
          />
          <path
            d="M1266.98 742.031C1375.97 594.676 1320.34 368.713 1142.73 237.33C965.106 105.947 732.757 118.894 623.759 266.25C514.762 413.605 570.391 639.567 748.011 770.951C925.63 902.334 1157.98 889.387 1266.98 742.031Z"
            fill="url(#paint4_radial_624_2)"
          />
          <path
            d="M1183.78 695.905C1265.05 586.038 1223.57 417.562 1091.14 319.603C958.706 221.645 785.469 231.298 704.201 341.165C622.933 451.032 664.41 619.509 796.842 717.467C929.274 815.426 1102.51 805.772 1183.78 695.905Z"
            fill="#F8F4AF"
          />
          <path
            d="M1186.48 682.482C1268.2 572.006 1226.5 402.598 1093.33 304.097C960.166 205.596 785.97 215.304 704.252 325.779C622.534 436.255 664.24 605.663 797.405 704.164C930.57 802.664 1104.77 792.957 1186.48 682.482Z"
            fill="#FCB33B"
          />
          <path
            opacity="0.3"
            d="M1227.77 535.24C1224.22 574.49 1210.87 611.94 1186.91 644.33C1105.18 754.8 930.99 764.49 797.83 665.98C716.24 605.62 668.99 518.65 663.43 434.92C654.88 529.54 703.3 634.59 797.42 704.22C930.58 802.73 1104.77 793.04 1186.5 682.57C1218.15 639.79 1231.29 588.16 1227.77 535.24Z"
            fill="#EA8424"
          />
          <path
            d="M704.77 337.75C786.5 227.28 960.69 217.59 1093.85 316.1C1175.67 376.63 1222.95 463.92 1228.3 547.87C1226.89 460.35 1179.07 367.59 1093.38 304.19C960.22 205.68 786.02 215.37 704.3 325.84C672.79 368.43 659.63 419.79 662.98 472.46C662.2 423.99 675.65 377.13 704.78 337.75H704.77Z"
            fill="#CE7428"
          />
          <path
            d="M1120.13 437.66C1118.36 427.53 1108.87 419.32 1098.93 419.32C1088.99 419.32 1082.28 427.54 1083.94 437.68C1084.41 440.53 1085.49 443.24 1087.04 445.65L1052.29 484.78C1031.19 454.81 1003.39 434.07 974.42 429.14L943.26 390.02C947.44 385.13 949.56 378.35 948.62 370.86C946.77 355.95 933.68 343.87 919.4 343.88C905.12 343.89 894.84 355.99 896.45 370.89C897.39 379.62 902.26 387.37 909.05 392.3L891.35 428.27C862.18 430.54 837.74 449.79 822.19 479.05L776.28 442.57C776.58 441.05 776.68 439.46 776.57 437.82C775.87 427.7 767.26 419.49 757.32 419.5C747.38 419.51 739.8 427.72 740.38 437.84C740.92 447.22 748.36 454.95 757.45 456.03L791.13 575.32H783.79C774.16 575.32 766.02 580.39 761.86 587.92C759.73 591.75 758.64 596.22 758.92 600.98C759.34 608.04 762.73 614.45 767.86 619.1C773.01 623.76 779.9 626.64 787.27 626.64H1119.07C1133.81 626.64 1143.88 615.08 1141.44 600.95C1140.31 594.39 1136.7 588.37 1131.7 583.82C1131.32 583.47 1130.93 583.13 1130.54 582.81C1125.35 578.49 1118.87 575.69 1112.16 575.32C1111.64 575.29 1111.13 575.27 1110.61 575.27H1109.52L1110.15 455.24C1117.3 452.99 1121.57 445.96 1120.11 437.64L1120.13 437.66Z"
            fill="#A24B24"
          />
          <path
            d="M969.44 416.16L886.17 416.21C834.59 416.24 795.95 458.99 799.86 511.69L803.46 560.19L1085.6 560.14L1077.93 511.61C1069.59 458.87 1021.02 416.14 969.44 416.16Z"
            fill="#E69D31"
          />
          <path
            d="M1118.06 425.81C1116.29 415.68 1106.83 407.47 1096.93 407.47C1087.03 407.47 1080.35 415.69 1082.01 425.83C1082.48 428.68 1083.56 431.39 1085.1 433.8L1033.64 491.6L941.81 378.18C945.97 373.29 948.08 366.51 947.15 359.02C945.3 344.11 932.26 332.03 918.03 332.04C903.8 332.05 893.57 344.15 895.18 359.06C896.12 367.79 900.97 375.54 907.74 380.47L851.92 491.26L775.48 430.74C775.78 429.22 775.88 427.63 775.77 425.99C775.07 415.87 766.49 407.66 756.59 407.67C746.69 407.67 739.14 415.89 739.72 426.01C740.26 435.39 747.68 443.12 756.74 444.2L790.33 563.49H783.02C773.43 563.49 765.32 568.57 761.17 576.09C759.05 579.92 757.96 584.39 758.25 589.15C758.67 596.21 762.05 602.62 767.17 607.27C772.3 611.93 779.17 614.81 786.51 614.81H1117.17C1131.86 614.8 1141.88 603.24 1139.44 589.11C1138.31 582.55 1134.7 576.53 1129.72 571.98C1129.34 571.63 1128.96 571.29 1128.57 570.97C1123.4 566.65 1116.94 563.85 1110.25 563.48C1109.73 563.45 1109.22 563.43 1108.7 563.43H1107.61L1108.17 443.4C1115.29 441.15 1119.54 434.12 1118.08 425.8L1118.06 425.81Z"
            fill="#FBE13E"
          />
          <path
            d="M836.86 540.12C837.28 545.14 833.48 549.21 828.35 549.21C823.22 549.21 818.75 545.14 818.36 540.13C817.96 535.11 821.77 531.04 826.87 531.04C831.97 531.04 836.44 535.11 836.86 540.12Z"
            fill="#A24B24"
          />
          <path
            d="M892.93 540.11C893.44 545.13 889.7 549.2 884.58 549.2C879.46 549.2 874.92 545.13 874.43 540.12C873.95 535.1 877.69 531.03 882.78 531.03C887.87 531.03 892.42 535.1 892.93 540.11Z"
            fill="#A24B24"
          />
          <path
            d="M1018.81 540.08C1019.51 545.1 1015.92 549.17 1010.8 549.17C1005.68 549.17 1000.98 545.1 1000.31 540.08C999.64 535.06 1003.23 530.99 1008.32 530.99C1013.41 530.99 1018.11 535.06 1018.81 540.08Z"
            fill="#A24B24"
          />
          <path
            d="M955.7 540.09C956.3 545.11 952.64 549.18 947.52 549.18C942.4 549.18 937.78 545.11 937.2 540.09C936.62 535.07 940.29 531 945.38 531C950.47 531 955.09 535.07 955.7 540.09Z"
            fill="#A24B24"
          />
          <path
            d="M1074.88 540.07C1075.66 545.09 1072.14 549.16 1067.02 549.16C1061.9 549.16 1057.13 545.09 1056.38 540.07C1055.63 535.05 1059.14 530.98 1064.24 530.98C1069.34 530.98 1074.1 535.05 1074.88 540.07Z"
            fill="#A24B24"
          />
          <path
            d="M836.47 535.44C836.89 540.46 833.09 544.53 827.98 544.53C822.87 544.53 818.4 540.46 818 535.45C817.6 530.44 821.41 526.36 826.49 526.36C831.57 526.36 836.05 530.43 836.47 535.44Z"
            fill="#FCB33B"
          />
          <path
            d="M892.46 535.43C892.97 540.45 889.23 544.52 884.12 544.52C879.01 544.52 874.47 540.45 873.99 535.44C873.51 530.42 877.24 526.35 882.33 526.35C887.42 526.35 891.95 530.42 892.46 535.43Z"
            fill="#FCB33B"
          />
          <path
            d="M1018.16 535.4C1018.86 540.42 1015.28 544.49 1010.16 544.49C1005.04 544.49 1000.36 540.42 999.69 535.4C999.02 530.38 1002.6 526.31 1007.69 526.31C1012.78 526.31 1017.47 530.38 1018.16 535.4Z"
            fill="#FCB33B"
          />
          <path
            d="M1074.15 535.39C1074.93 540.41 1071.42 544.48 1066.31 544.48C1061.2 544.48 1056.44 540.41 1055.68 535.39C1054.93 530.37 1058.44 526.3 1063.52 526.3C1068.6 526.3 1073.37 530.37 1074.15 535.39Z"
            fill="#FCB33B"
          />
          <path
            d="M955.14 535.42C955.74 540.44 952.09 544.51 946.97 544.51C941.85 544.51 937.24 540.44 936.67 535.42C936.1 530.4 939.75 526.33 944.84 526.33C949.93 526.33 954.54 530.4 955.14 535.42Z"
            fill="#FCB33B"
          />
          <path
            opacity="0.25"
            d="M682.58 207.6C652.03 311.93 699.81 439.64 808.56 520.08C941.81 618.66 1116.13 608.96 1197.91 498.41C1233.87 449.81 1245.95 389.8 1236.87 329.44C1210.96 295.74 1179.47 264.55 1142.77 237.4C992.63 126.33 803.38 118.39 682.58 207.59V207.6Z"
            fill="#FEFDED"
          />
          <path
            d="M852.92 487.81C868.49 451.98 886.15 416.53 905.41 382.54C889.85 418.38 872.17 453.81 852.92 487.81Z"
            fill="white"
          />
          <path
            d="M364.49 211.94C386.44 182.49 410.32 153.91 435.43 127.1C413.49 156.56 389.59 185.12 364.49 211.94Z"
            fill="white"
          />
          <path
            d="M1033.27 490.59C1040.98 480.59 1049.79 470.84 1058.37 461.56C1067.06 452.37 1076.19 442.94 1085.66 434.58C1077.95 444.58 1069.14 454.33 1060.56 463.61C1051.86 472.8 1042.74 482.23 1033.27 490.59Z"
            fill="white"
          />
          <path
            d="M787.29 614.3C869.65 612.25 953.07 612.24 1035.43 614.3C953.07 616.36 869.65 616.35 787.29 614.3Z"
            fill="white"
          />
          <path
            d="M553.65 212.25C563.36 204.02 574.1 196.25 584.51 188.9C595.01 181.66 605.99 174.27 617.09 168.01C607.38 176.25 596.64 184.01 586.23 191.36C575.72 198.59 564.74 205.98 553.65 212.25Z"
            fill="white"
          />
          <path
            d="M287.63 306.6C368.47 304.55 450.35 304.54 531.19 306.6C450.35 308.66 368.47 308.65 287.63 306.6Z"
            fill="white"
          />
          <path d="M307.36 437.77V544.43" stroke="#FFC840" strokeWidth="3" strokeMiterlimit="10" />
          <path d="M344.52 652.13V758.79" stroke="#FFC840" strokeWidth="3" strokeMiterlimit="10" />
          <path d="M308.57 810.58V917.24" stroke="#FFC840" strokeWidth="3" strokeMiterlimit="10" />
        </g>
        <defs>
          <radialGradient
            id="paint0_radial_624_2"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(978.938 902.398) scale(466.938 189.941)"
          >
            <stop stopOpacity="0.7" />
            <stop offset="0.31" stopColor="#10231B" stopOpacity="0.5" />
            <stop offset="0.6" stopColor="#305146" stopOpacity="0.23" />
            <stop offset="1" stopColor="#66C5B1" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_624_2"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(499.5 610.62) scale(327.7 327.7)"
          >
            <stop stopColor="#F9C020" />
            <stop offset="0.46" stopColor="#F5891F" />
            <stop offset="1" stopColor="#FBE13E" />
          </radialGradient>
          <radialGradient
            id="paint2_radial_624_2"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(403.43 427.06) scale(327.7 327.7)"
          >
            <stop stopColor="#F9C020" />
            <stop offset="0.46" stopColor="#F5891F" />
            <stop offset="1" stopColor="#FBE13E" />
          </radialGradient>
          <radialGradient
            id="paint3_radial_624_2"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(449.484 228.4) rotate(1.89) scale(327.69 327.69)"
          >
            <stop stopColor="#F9C020" />
            <stop offset="0.46" stopColor="#F5891F" />
            <stop offset="1" stopColor="#FBE13E" />
          </radialGradient>
          <radialGradient
            id="paint4_radial_624_2"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(946.052 503.081) rotate(36.49) scale(400.05 332.041)"
          >
            <stop stopColor="#F9C020" />
            <stop offset="0.46" stopColor="#F26D22" />
            <stop offset="1" stopColor="#FBE13E" />
          </radialGradient>
          <clipPath id="clip0_624_2">
            <rect width="1444.34" height="1091.89" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
