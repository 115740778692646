import ActionContext from "./ActionContext";
import {gameAction} from "../../config/actions";
import {decToHexStr} from "../../utils/converters";

export default class CompleteQuestContext extends ActionContext<"COMPLETE_QUEST"> {
    protected type = gameAction.COMPLETE_QUEST;
    protected hasTransaction = true;


    public getUsedItems(): Record<string, number> {
        const items: Record<string, number> = {};
        //@ts-ignore
        for (const payment of this.payload.quest.requirements!.payments) {
            items[`${payment.token_identifier}-${decToHexStr(payment.token_nonce)}`] = payment.amount;
        }

        return items;
    }

};