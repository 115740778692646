import ActionContext from "../services/contexts/ActionContext";
import {GameActionKey, GameActionValue} from "../common/types/actions";
import PlaceLandContext from "../services/contexts/PlaceLandContext";
import PlaceBuildingContext from "../services/contexts/PlaceBuildingContext";
import RemoveLandContext from "../services/contexts/RemoveLandContext";
import RemoveBuildingContext from "../services/contexts/RemoveBuildingContext";
import UpgradeBuildingContext from "../services/contexts/UpgradeBuildingContext";
import FinalizeUpgradeBuildingContext from "../services/contexts/FinalizeUpgradeBuildingContext";
import AddGiantsToHouseContext from "../services/contexts/AddGiantsToHouseContext";
import AddGiantsToHouseFromStakingContext
    from "../services/contexts/AddGiantsToHouseFromStakingContext";
import RemoveGiantsFromHouseContext from "../services/contexts/RemoveGiantsFromHouseContext";
import RemoveGiantsFromHouseToStakingContext
    from "../services/contexts/RemoveGiantsFromHouseToStakingContext";
import RemoveGiantsFromWorkContext from "../services/contexts/RemoveGiantsFromWorkContext";
import AddGiantsToWorkContext from "../services/contexts/AddGiantsToWorkContext";
import ClaimRewardsContext from "../services/contexts/ClaimRewardsContext";
import CompleteQuestContext from "../services/contexts/CompleteQuestContext";
import UpgradeVillageContext from "../services/contexts/UpgradeVillageContext";
import ClaimReferralRewardsContext from "../services/contexts/ClaimReferralRewardsContext";
import SetBuildingSkinContext from "../services/contexts/SetBuildingSkinContext";
import RemoveBuildingSkinContext from "../services/contexts/RemoveBuildingSkinContext";
import AssetsTransferContext from "../services/contexts/AssetsTransferContext";
import ClaimResourcesContext from "../services/contexts/ClaimResourcesContext";
import ClaimLeaderboardPrizesContext from "../services/contexts/ClaimLeaderboardPrizesContext";
import ClaimReferralSpendingsContext from "../services/contexts/ClaimReferralSpendingsContext";

export const buildContextFromRequestParams = (action: {
    type: GameActionValue;
    payload: any;
}): ActionContext<GameActionKey> => {
    switch (action.type) {
        case "placeLand":
            return new PlaceLandContext(action.payload);
        case "placeBuilding":
            return new PlaceBuildingContext(action.payload);
        case "removeLand":
            return new RemoveLandContext(action.payload);
        case "removeBuilding":
            return new RemoveBuildingContext(action.payload);
        case "upgradeBuilding":
            return new UpgradeBuildingContext(action.payload);
        case "finalizeUpgradeBuilding":
            return new FinalizeUpgradeBuildingContext(action.payload);
        case "addGiantsToHouse":
            return new AddGiantsToHouseContext(action.payload);
        case "addGiantsToHouseFromStaking":
            return new AddGiantsToHouseFromStakingContext(action.payload);
        case "removeGiantsFromHouse":
            return new RemoveGiantsFromHouseContext(action.payload);
        case "removeGiantsFromHouseToStaking":
            return new RemoveGiantsFromHouseToStakingContext(action.payload);
        case "removeGiantsFromWork":
            return new RemoveGiantsFromWorkContext(action.payload);
        case "addGiantsToWork":
            return new AddGiantsToWorkContext(action.payload);
        case "claimRewards":
            return new ClaimRewardsContext(action.payload);
        case "claimResources":
            return new ClaimResourcesContext(action.payload);
        case "completeQuest":
            return new CompleteQuestContext(action.payload);
        case "upgradeVillage":
            return new UpgradeVillageContext(action.payload);
        case "claimReferralRewards":
            return new ClaimReferralRewardsContext(action.payload);
        case "setBuildingSkin":
            return new SetBuildingSkinContext(action.payload);
        case "removeBuildingSkin":
            return new RemoveBuildingSkinContext(action.payload);
        case "assetsTransfer":
            return new AssetsTransferContext(action.payload);
        case "claimPrize":
            return new ClaimLeaderboardPrizesContext(action.payload);
        case "claimReferralSpendings":
            return new ClaimReferralSpendingsContext(action.payload);
    }

    return assertNever(action.type);
}

const assertNever = (x: never): never => {
    throw new Error(`Unknown action type: ${x}`);
};