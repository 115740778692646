import {useCallback, useState} from "react";
import {HiMinus, HiPlus} from "react-icons/hi2";

import Manager from "../gameboard/Manager";
import {selectTilesQty} from "../redux/slices/inventorySlice";
import store, {RootState} from "../redux/store";
import IconLandManagement from "./icons/IconLandManagement";
import {useSelector} from "react-redux";
import {useVillageState} from "../hooks/useVillageState";
import {useAuth} from "../hooks/useAuth";
import {computeMaxLandQty, getMaxLandQtyForVillageLevel} from "../utils/game";

export default function TilesMenu({disabled}: { disabled: boolean }) {
    const [open, setOpen] = useState(false);
    const {maxVillageLandQty} = useVillageState();

    return (
        <div className="flex items-center bg-white h-16 p-1">
            <div className="flex items-center border border-primary-darkest h-full">
                <button
                    className="w-14 h-14 focus:outline-none focus:outline-primary-darkest p-1 focus:outline-offset-[-1px]"
                    onClick={(event) => {
                        event.currentTarget.blur();
                        setOpen(!open);
                    }}>
                    <IconLandManagement className="w-full h-full"/>
                </button>
                {open && (
                    <div className="flex items-center justify-center space-x-4 px-2">
                        <button
                            className="w-10 h-10 border border-primary-darkest text-primary-darkest disabled:text-gray-300 disabled:border-gray-300 disabled:cursor-not-allowed p-1 hover:shadow focus:outline-none focus:ring-2 focus:ring-primary-darkest"
                            disabled={disabled}
                            onClick={() => {
                                Manager.getInstance().publishEvent(
                                    "INIT_PLACE_LAND",
                                    {availableTilesQty: maxVillageLandQty}
                                );
                                setOpen(false);
                            }}
            >
              <HiPlus className="w-full h-full" />
            </button>
            <button
              className="w-10 h-10 border border-primary-darkest text-primary-darkest disabled:text-gray-300 disabled:border-gray-300 disabled:cursor-not-allowed p-1 hover:shadow focus:outline-none focus:ring-2 focus:ring-primary-darkest"
              disabled={disabled}
              onClick={() => {
                Manager.getInstance().publishEvent("INIT_REMOVE_LAND", true);
                setOpen(false);
              }}
            >
              <HiMinus className="w-full h-full" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
