import {Container, IDestroyOptions, ISize} from "pixi.js";
import {AssetType, IPosition, IScene, ScaleSize} from "../../common/types/gameboard";
import Village from "../components/Village";
import Manager from "../Manager";
import Grid from "../Grid";
import GridAsset from "../components/GridAsset";
import PositionAssetContainer from "../PositionAssetContainer";
import {
    ActionCommitError,
    ActionCommitSuccess,
    AssetEventPayload,
    GameActionEnum, GameActionKey, GameActionValue,
    IToken,
    TxActionPayload
} from "../../common/types/actions";
import {gridAssetFromAssetInfo} from "../../utils/assets";
import VillageService from "../../services/VillageService";
import GridTile from "../components/GridTile";
import {defaultAssetAttributes, TOKEN_LAND} from "../../config/assets";
import {computeAssetScreenPosition} from "../../utils/game";
import {PartialVillageAsset} from "../../common/types/repository";
import {InventoryAsset} from "../../common/types/inventory";
import {AssetAttributes} from "@elrond-giants/game-db/types/entities";
import {gameAction} from "../../config/actions";
import PlaceLandContext from "../../services/contexts/PlaceLandContext";
import PlaceBuildingContext from "../../services/contexts/PlaceBuildingContext";
import RemoveLandContext from "../../services/contexts/RemoveLandContext";
import RemoveBuildingContext from "../../services/contexts/RemoveBuildingContext";
import UpgradeBuildingContext from "../../services/contexts/UpgradeBuildingContext";
import FinalizeUpgradeBuildingContext from "../../services/contexts/FinalizeUpgradeBuildingContext";
import SetBuildingSkinContext from "../../services/contexts/SetBuildingSkinContext";
import RemoveBuildingSkinContext from "../../services/contexts/RemoveBuildingSkinContext";


export default class VillageScene extends Container implements IScene {
    protected village!: Village;
    protected villageId: string;
    protected positioningContainer: PositionAssetContainer | null = null;
    protected villageService: VillageService;
    protected selectedTilesQty = 0;
    protected availableTilesQty = 0;
    protected viewportScale = 1;

    constructor(villageId: string, villageService: VillageService) {
        super();
        this.villageId = villageId;
        this.villageService = villageService;
    }

    async init(): Promise<boolean> {
        const villageAssets = await this.villageService.getAssets(this.villageId);
        const land = await Grid.fromTiles(villageAssets.land);
        this.createVillage(land, villageAssets.building);
        this.subscribeToActions();
        return true;
    }

    destroy(_options?: IDestroyOptions | boolean) {
        this.unsubscribeFromActions();
        super.destroy(_options);
    }

    onResize(): void {
        // this.computeOffset();
        // this.positionLand();
    }

    onTick(delta: number): void {
        if (this.positioningContainer?.moved()) {
            this.village.hooverAsset(this.positioningContainer.asset);
        }
    }

    onCameraMove(payload: {
        size: ISize,
        scale: ScaleSize,
        position: IPosition
    }): void {
        this.positioningContainer?.onCameraMove(payload);
        this.viewportScale = payload.scale.x;
    }

    onZoom({scale}: { scale: ScaleSize }): void {
        this.village.onZoom({scale});
        this.positioningContainer?.onZoom({scale});
    }


    private createVillage(land: Grid, assets: GridAsset[]) {
        this.village = new Village(land, assets);
        this.positionLand();
        this.addChild(this.village);
    }


    private positionLand(): void {
        const screenSize = Manager.getInstance().screenSize;
        this.village.x = (screenSize.width - this.village.screenWidth) / 2;
        this.village.y = 200;
    }

    protected async onPlaceAsset({asset}: {asset: GridAsset}) {
        const canPlace = this.village.canPlaceAsset(asset);
        const _asset = this.village.placeAsset(asset);
        if (_asset) {
            this.clearPositioningContainer();
            this.village.clearLandAvailability();
            await this.commitAsset(_asset);
        }
    }

    protected onInitPlaceAsset({asset: _asset}: { asset: InventoryAsset }) {
        const asset = gridAssetFromAssetInfo({
            position: {x: 0, y: 0},
            status: "complete",
            asset_token: _asset.collection,
            asset_token_nonce: _asset.nonce,
            attributes: _asset.attributes ?? {} as AssetAttributes,
            upgrade_start_date: null,
            upgrade_finish_date: null,
            upgrade_payment: null,
            upgrade_instant_finish_date: null,
            upgrade_instant_finish_tax_payment: null,
        });


        const onMovingComplete = () => {
            if (!this.positioningContainer) {return;}
            const placeTiles = this.village.findAssetPlaceTiles(this.positioningContainer.asset);
            if (!placeTiles) {
                this.positioningContainer.setCanConfirm(false);
                return;
            }
            const tileGlobalPosition = placeTiles[0].getGlobalPosition();
            const screenPosition = this.toLocal(
                computeAssetScreenPosition(asset, tileGlobalPosition, this.viewportScale)
            );

            this.positioningContainer.setAssetPosition({
                screen: screenPosition,
                grid: placeTiles[0].position
            });
            this.positioningContainer.setCanConfirm(true);
        };


        const manager = Manager.getInstance();
        const initialAssetPosition = this.toLocal({
            x: (manager.screenSize.width - asset.screenWidth) / 2,
            y: (manager.screenSize.height - asset.screenHeight) / 2
        })

        this.positioningContainer = new PositionAssetContainer(asset, onMovingComplete);
        this.positioningContainer.setAssetPosition({
            screen: initialAssetPosition,
            grid: {x: 0, y: 0}
        });
        this.addChild(this.positioningContainer);
    }

    protected onCancelPlaceAsset() {
        this.clearPositioningContainer();
        this.village.clearLandAvailability();
    }

    protected onInitRemoveLand() {
        this.village.setInteractive(true);
    }

    protected onCancelRemoveLand() {
        this.village.setInteractive(false);
    }

    protected async onGameAction(event: AssetEventPayload) {

        if (event.action.status === "pending") {return;}
        if (event.action.status === "failed") {
            // todo: handle error case
        }

        switch (event.action.action) {
            case GameActionEnum.PLACE_LAND:
            case GameActionEnum.PLACE_BUILDING:
            case GameActionEnum.SET_BUILDING_SKIN:
            case GameActionEnum.REMOVE_BUILDING_SKIN:
                return this.onAddedAssets(event);
            case GameActionEnum.REMOVE_LAND:
            case GameActionEnum.REMOVE_BUILDING:
                return this.onRemovedAssets(event);
            case GameActionEnum.INIT_UPGRADE:
                return this.onUpgradingStarted(event);
            case GameActionEnum.COMPLETE_UPGRADE:
                return this.onUpgradedAsset(event);

        }
    }

    protected onInitPlaceLand({availableTilesQty}: { availableTilesQty: number }) {
        this.selectedTilesQty = 0;
        this.availableTilesQty = availableTilesQty;
        this.village.showBlankLand();
    }

    protected onCancelPlaceLand() {
        this.selectedTilesQty = 0;
        this.village.hideBlankLand();
    }

    protected onConfirmPlaceLand(payload: TxActionPayload<GameActionEnum.PLACE_LAND>) {
        this.availableTilesQty -= this.selectedTilesQty;
        this.selectedTilesQty = 0;
        this.village.hideBlankLand();
        const {
            positions,
            token
        } = payload;

        const assetsData: PartialVillageAsset[] = positions.map(p => ({
            position: {...p},
            asset_token: token.id,
            asset_token_nonce: token.nonce,
            status: "pending",
            type: "land",
            attributes: defaultAssetAttributes,
            upgrade_start_date: null,
            upgrade_finish_date: null,
            upgrade_payment: null,
            upgrade_instant_finish_date: null,
            upgrade_instant_finish_tax_payment: null
        }));

        this.replaceAssets(assetsData);
    }

    protected onConfirmRemoveLand(payload: TxActionPayload<GameActionEnum.REMOVE_LAND>) {
        this.village.hideBlankLand();
        this.village.setInteractive(false);
        const {
            positions,
            token
        } = payload;

        const assetsData: PartialVillageAsset[] = positions.map(p => ({
            position: {...p},
            asset_token: token.id,
            asset_token_nonce: token.nonce,
            status: "pending",
            type: "land",
            attributes: defaultAssetAttributes,
            upgrade_start_date: null,
            upgrade_finish_date: null,
            upgrade_payment: null,
            upgrade_instant_finish_date: null,
            upgrade_instant_finish_tax_payment: null
        }));

        this.replaceAssets(assetsData);
    }

    protected onLandClicked({asset}: { asset: GridTile }) {
        if (asset.isBlankLand) {
            if (asset.selected) {
                const canRemove = this.village.canRemoveLand(asset);
                if (canRemove) {
                    this.onBlankLandUnselected(asset);
                }
            } else {
                if (this.selectedTilesQty < this.availableTilesQty) {
                    this.onBlankLandSelected(asset);
                }
            }
            return;
        }

        if (asset.selected) {
            if (this.village.hasPathToMainTile(asset)) {
                Manager.getInstance().publishEvent("LAND_TILE_UNSELECTED", {asset});
                asset.selected = false;
            }
        } else {
            const canRemove = this.village.canRemoveLand(asset);
            if (canRemove) {
                Manager.getInstance().publishEvent("LAND_TILE_SELECTED", {asset});
                asset.selected = true;
            }
        }
    }


    protected onBlankLandSelected(asset: GridTile) {
        const token: IToken = {
            id: TOKEN_LAND,
            nonce: 1
        };
        const _asset = gridAssetFromAssetInfo({
            position: {...asset.position},
            asset_token: token.id,
            asset_token_nonce: token.nonce,
            status: "complete",
            attributes: defaultAssetAttributes,
            upgrade_start_date: null,
            upgrade_finish_date: null,
            upgrade_payment: null,
            upgrade_instant_finish_date: null,
            upgrade_instant_finish_tax_payment: null
        });
        if (!(_asset instanceof GridTile)) {return;}
        _asset.isBlankLand = true;
        _asset.selected = true;
        _asset.setInteractive(true);
        this.village.removeAsset({
            position: asset.position,
            type: "land",
            redraw: false
        });
        this.village.addAssets([_asset]);
        this.selectedTilesQty += 1;
        if (this.selectedTilesQty >= this.availableTilesQty) {
            this.village.hideBlankLand(true);
        }
        Manager.getInstance().publishEvent("LAND_TILE_SELECTED", {asset});

    }

    protected onBlankLandUnselected(asset: GridTile) {
        this.village.removeAsset({
            position: asset.position,
            type: "land"
        });
        this.selectedTilesQty -= 1;
        if (this.selectedTilesQty < this.availableTilesQty) {
            // this.village.showBlankLand()
        }
        Manager.getInstance().publishEvent("LAND_TILE_UNSELECTED", {asset});
    }

    protected onAddedAssets(event: AssetEventPayload) {
        if (event.assets) {
            this.replaceAssets(event.assets);
        }
    }

    protected async onRemovedAssets(event: AssetEventPayload) {
        const assetsData = (event.assets ?? []).map(asset => ({
            position: asset.position,
            type: asset.type
        }));
        this.village.removeAssets(assetsData);
    }

    protected async onUpgradedAsset(event: AssetEventPayload) {
        if (event.assets) {
            this.replaceAssets(event.assets);
        }
    }

    protected async onUpgradingStarted(event: AssetEventPayload) {
        if (event.assets) {
            this.replaceAssets(event.assets);
        }
    }

    protected onCommitActionSuccess<T extends GameActionKey>(result: ActionCommitSuccess<T>) {
        const {actionContext, state} = result;
        switch (true) {
            case actionContext instanceof PlaceBuildingContext: {
                const {position, token, attributes} = actionContext.payload;

                this.replaceAssets([{
                    attributes,
                    position: {...position},
                    asset_token: token.id,
                    asset_token_nonce: token.nonce,
                    status: "pending",
                    type: "building",
                    upgrade_start_date: null,
                    upgrade_finish_date: null,
                    upgrade_payment: null,
                    upgrade_instant_finish_date: null,
                    upgrade_instant_finish_tax_payment: null
                }]);

                return;
            }
            case actionContext instanceof RemoveLandContext: {
                return;
            }
            case actionContext instanceof RemoveBuildingContext: {
                const {position} = actionContext.payload
                const assets = [{
                    position,
                    type: "building" as AssetType
                }];
                this.village.removeAssets(assets, true);
                return;
            }
            case actionContext instanceof UpgradeBuildingContext: {
                /**
                 * In the case of upgrading a building, we know for sure that there is no
                 * transaction and the action will be completed immediately, so the result will
                 * contain the new state of the asset.
                 */
                const asset = state![0] as PartialVillageAsset;
                this.replaceAssets([asset]);

                return;
            }
            case actionContext instanceof FinalizeUpgradeBuildingContext: {
                const {position, token, attributes} = actionContext.payload;

                this.replaceAssets([{
                    attributes,
                    position: {...position},
                    asset_token: token.id,
                    asset_token_nonce: token.nonce,
                    status: "finalizing-upgrade",
                    type: "building",
                    upgrade_start_date: null,
                    upgrade_finish_date: null,
                    upgrade_payment: null,
                    upgrade_instant_finish_date: null,
                    upgrade_instant_finish_tax_payment: null
                }]);
                return;
            }
            case actionContext instanceof PlaceLandContext: {
                return;
            }
            case actionContext instanceof SetBuildingSkinContext: {
                const {buildingToken, skinToken,} = actionContext.payload;
                const asset = this.village.getAssetByToken(buildingToken);
                if (!asset) {return;}
                this.replaceAssets([{
                    attributes: {
                        ...asset.attributes,
                        skin: {
                            value: {...skinToken},
                            status: "pending"
                        }
                    },
                    position: {...asset.position},
                    asset_token: asset.token!.id,
                    asset_token_nonce: asset.token!.nonce,
                    status: asset.status,
                    type: asset.type,
                    upgrade_start_date: asset.upgradeState.startDate,
                    upgrade_finish_date: asset.upgradeState.finishDate,
                    upgrade_payment: asset.upgradeState.payment,
                    upgrade_instant_finish_date: asset.upgradeState.instantFinishDate,
                    upgrade_instant_finish_tax_payment: asset.upgradeState.instantFinishTaxPayment
                }]);
                return;
            }
            case actionContext instanceof RemoveBuildingSkinContext: {
                const {buildingToken} = actionContext.payload;
                const asset = this.village.getAssetByToken(buildingToken);
                if (!asset) {return;}
                this.replaceAssets([{
                    attributes: {
                        ...asset.attributes,
                        skin: undefined
                    },
                    position: {...asset.position},
                    asset_token: asset.token!.id,
                    asset_token_nonce: asset.token!.nonce,
                    status: asset.status,
                    type: asset.type,
                    upgrade_start_date: asset.upgradeState.startDate,
                    upgrade_finish_date: asset.upgradeState.finishDate,
                    upgrade_payment: asset.upgradeState.payment,
                    upgrade_instant_finish_date: asset.upgradeState.instantFinishDate,
                    upgrade_instant_finish_tax_payment: asset.upgradeState.instantFinishTaxPayment
                }]);
                return;
            }
        }
    }

    protected onCommitActionError<T extends GameActionKey>(result: ActionCommitError<T>) {
        const {actionContext, error} = result;
        switch (true) {
            case actionContext instanceof PlaceBuildingContext: {
                // If the PLACE_BUILDING action failed,
                // we need to remove the asset from the village
                const {position} = actionContext.payload;
                const assets = [{
                    position,
                    type: "building" as AssetType
                }];
                this.village.removeAssets(assets, true);
                return;
            }
            case actionContext instanceof RemoveLandContext: {
                // If the REMOVE_LAND action failed,
                // we need to add the land tiles back to the village
                const {positions, token} = actionContext.payload;

                const assetsData: PartialVillageAsset[] = positions.map(p => ({
                    position: {...p},
                    asset_token: token.id,
                    asset_token_nonce: token.nonce,
                    status: "complete",
                    type: "land",
                    attributes: defaultAssetAttributes,
                    upgrade_start_date: null,
                    upgrade_finish_date: null,
                    upgrade_payment: null,
                    upgrade_instant_finish_date: null,
                    upgrade_instant_finish_tax_payment: null
                }));

                this.replaceAssets(assetsData);
                return;
            }
            case actionContext instanceof RemoveBuildingContext: {
                // If the REMOVE_BUILDING action failed,
                // we need to add the asset back to the village
                const {position, token, attributes, upgradeState} = actionContext.payload;

                this.replaceAssets([{
                    attributes,
                    position: {...position},
                    asset_token: token.id,
                    asset_token_nonce: token.nonce,
                    status: "complete",
                    type: "building",
                    upgrade_start_date: upgradeState.startDate,
                    upgrade_finish_date: upgradeState.finishDate,
                    upgrade_payment: upgradeState.payment,
                    upgrade_instant_finish_date: upgradeState.instantFinishDate,
                    upgrade_instant_finish_tax_payment: upgradeState.instantFinishTaxPayment
                }]);

                return;
            }
            case actionContext instanceof UpgradeBuildingContext: {
                // If the INIT_UPGRADE action failed,
                // we need to mark the asset as complete again
                const {position, asset_token, attributes} = actionContext.payload;

                this.replaceAssets([{
                    attributes,
                    position: {...position},
                    asset_token: asset_token.id,
                    asset_token_nonce: asset_token.nonce,
                    status: "complete",
                    type: "building",
                    upgrade_start_date: null,
                    upgrade_finish_date: null,
                    upgrade_payment: null,
                    upgrade_instant_finish_date: null,
                    upgrade_instant_finish_tax_payment: null
                }]);
                return;
            }
            case actionContext instanceof PlaceLandContext:
                // If the PLACE_LAND action failed,
                // we need to remove the land tiles from the village
                const {positions,} = actionContext.payload

                const assetsData = positions.map(p => ({
                    position: {...p},
                    type: "land" as AssetType,
                }));

                this.village.removeAssets(assetsData);

                return;
        }
    }

    protected async commitAsset(asset: GridAsset) {
        let actionContext;
        if (asset.type === "land") {
            actionContext = new PlaceLandContext({
                villageId: this.villageId,
                positions: [asset.position],
                token: {
                    ...asset.token!,
                    quantity: 1,
                },
            });
        } else {
            actionContext = new PlaceBuildingContext({
                villageId: this.villageId,
                position: asset.position,
                token: {
                    ...asset.token!,
                    quantity: 1,
                },
                attributes: asset.attributes
            });
        }

        await Manager.getInstance().commitAction(actionContext);
    }

    private clearPositioningContainer() {
        if (!this.positioningContainer) {return;}
        this.positioningContainer.destroy();
        this.removeChild(this.positioningContainer);
        this.positioningContainer = null;
    }

    private subscribeToActions() {
        Manager.getInstance()
            .subscribeToAction("GAME_ACTION", this.onGameAction.bind(this))
            .subscribeToAction("INIT_PLACE_LAND", this.onInitPlaceLand.bind(this))
            .subscribeToAction("CANCEL_PLACE_LAND", this.onCancelPlaceLand.bind(this))
            .subscribeToAction("CONFIRM_PLACE_LAND", this.onConfirmPlaceLand.bind(this))
            .subscribeToAction("INIT_PLACE_ASSET", this.onInitPlaceAsset.bind(this))
            .subscribeToAction("CANCEL_PLACE_ASSET", this.onCancelPlaceAsset.bind(this))
            .subscribeToAction("CONFIRM_PLACE_ASSET", this.onPlaceAsset.bind(this))
            .subscribeToAction("INIT_REMOVE_LAND", this.onInitRemoveLand.bind(this))
            .subscribeToAction("CANCEL_REMOVE_LAND", this.onCancelRemoveLand.bind(this))
            .subscribeToAction("CONFIRM_REMOVE_LAND", this.onConfirmRemoveLand.bind(this))
            .subscribeToAction("LAND_CLICKED", this.onLandClicked.bind(this))
            .subscribeToAction(
                "GAME_ACTION_COMMIT_SUCCESS",
                this.onCommitActionSuccess.bind(this)
            )
            .subscribeToAction(
                "GAME_ACTION_COMMIT_PENDING",
                this.onCommitActionSuccess.bind(this)
            )
            .subscribeToAction(
                "GAME_ACTION_COMMIT_ERROR",
                this.onCommitActionError.bind(this)
            );
    }

    private unsubscribeFromActions() {
        Manager.getInstance()
            .unsubscribeFromAction("GAME_ACTION", this.onGameAction.bind(this))
            .unsubscribeFromAction("INIT_PLACE_LAND", this.onInitPlaceLand.bind(this))
            .unsubscribeFromAction("CANCEL_PLACE_LAND", this.onCancelPlaceLand.bind(this))
            .unsubscribeFromAction("CONFIRM_PLACE_LAND", this.onConfirmPlaceLand.bind(this))
            .unsubscribeFromAction("INIT_PLACE_ASSET", this.onInitPlaceAsset.bind(this))
            .unsubscribeFromAction("CANCEL_PLACE_ASSET", this.onCancelPlaceAsset.bind(this))
            .unsubscribeFromAction("CONFIRM_PLACE_ASSET", this.onPlaceAsset.bind(this))
            .unsubscribeFromAction("INIT_REMOVE_LAND", this.onInitRemoveLand.bind(this))
            .unsubscribeFromAction("CANCEL_REMOVE_LAND", this.onCancelRemoveLand.bind(this))
            .unsubscribeFromAction("CONFIRM_REMOVE_LAND", this.onConfirmRemoveLand.bind(this))
            .unsubscribeFromAction("LAND_CLICKED", this.onLandClicked.bind(this))
            .unsubscribeFromAction(
                "GAME_ACTION_COMMIT_SUCCESS",
                this.onCommitActionSuccess.bind(this)
            )
            .unsubscribeFromAction(
                "GAME_ACTION_COMMIT_PENDING",
                this.onCommitActionSuccess.bind(this)
            )
            .unsubscribeFromAction(
                "GAME_ACTION_COMMIT_ERROR",
                this.onCommitActionError.bind(this)
            );
    }

    private replaceAssets(assets: PartialVillageAsset[]) {
        const newAssets = assets.reduce((
            assets: Array<GridAsset | GridTile>,
            asset
        ) => {
            const gridAsset = gridAssetFromAssetInfo(asset);
            const acc = assets;
            acc.push(gridAsset);

            return acc;
        }, []);

        // todo: Improve this. Should not do another map
        if (newAssets.length) {
            const removable = newAssets.map(a => ({
                position: a.position,
                type: a.type
            }));
            this.village.removeAssets(removable, false);
            this.village.addAssets(newAssets);
        }
    }
};
