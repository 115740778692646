import {PropsWithChildren} from "react";
import {classNames} from "../utils/presentation";
import {ButtonSize} from "../common/types/components";

type ButtonProps = PropsWithChildren<{
    onClick: () => void,
    size?: ButtonSize
    disabled?: boolean
}>;
export default function ButtonSquare(
    {children, onClick, disabled, size = "medium"}: ButtonProps
) {

    const sizeClass = (size: ButtonSize): string => {
        switch (size) {
            case "large":
                return "w-16 h-16";
            default:
                return "w-10 h-10";
        }
    };

    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={classNames(
                disabled ? "cursor-not-allowed" : "cursor-pointer",
                sizeClass(size),
                "flex items-center justify-center bg-white hover:shadow-md p-1 focus:outline-none group"
            )}>
            <div
                className="w-full h-full border border-primary-darkest flex items-center justify-center group-focus:ring-1 group-focus:ring-primary-darkest">
                {children}
            </div>
        </button>
    );
};