import {Container} from "pixi.js";
import Button from "./Button";

export default class ConfirmationMenu extends Container {
    private buttonConfirm: Button;
    private buttonCancel: Button;

    constructor(onConfirm: () => void, onCancel: () => void) {
        super();
        this.buttonConfirm = Button.create({
                icon: "/icon-asset-check.svg",
                color: 0x6CCDD3,
                width: 30,
                height: 30
            })
            .setClickHandler(onConfirm)
            .setPosition(10, 0);

        this.buttonCancel = Button.create({
                icon: "/icon-asset-cancel.svg",
                color: 0xE65E5E,
                width: 30,
                height: 30
            })
            .setClickHandler(onCancel)
            .setPosition(50, 0);

        this.addChild(this.buttonConfirm, this.buttonCancel);

    }

    public setCanConfirm(value: boolean) {
        if (value) {
            this.buttonConfirm.alpha = 1;
            this.buttonConfirm.interactive = true;
        } else {
            this.buttonConfirm.alpha = .4;
            this.buttonConfirm.interactive = false;
        }

        return this;
    }

    public enableConfirmation() {
        return this.setCanConfirm(true);
    }

    public disableConfirmation() {
        return this.setCanConfirm(false);
    }
};