import { IconType } from 'react-icons';

import { ButtonSize } from '../../common/types/components';

export function QtyButton({
  visible,
  iconType,
  onClick,
  disabled = false,
}: {
  visible: boolean;
  iconType: IconType;
  onClick: () => void;
  disabled?: boolean;
  size?: ButtonSize;
}) {
  if (!visible) {
    return null;
  }

  const icon = iconType({ className: "w-4 h-4" });

  return (
    <button
      type="button"
      className="rounded-full p-0.5 text-theme-text focus:outline-none focus:ring-1 focus:ring-theme-text disabled:opacity-40"
      onClick={onClick}
      disabled={disabled}
    >
      <span aria-hidden="true">{icon}</span>
    </button>
  );
}
