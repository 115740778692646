import { useEffect, useMemo, useState } from "react";
import { HiXMark } from "react-icons/hi2";
import { useSelector } from "react-redux";

import { useAuth } from "../../hooks/useAuth";
import { selectTilesQty } from "../../redux/slices/inventorySlice";
import { RootState } from "../../redux/store";
import { getMaxLandQtyForVillageLevel } from "../../utils/game";

export default function LandInfoToast({ selectedLandQty, open }: { selectedLandQty: number; open: boolean }) {
  const { village } = useAuth();
  const [closedByUser, setClosedByUser] = useState(false);
  const availableLandQty = useSelector(selectTilesQty);
  const placedTilesQty = useSelector((state: RootState) => state.village.placedLandQty);
  const maxLandQtyForVillageLevel = useMemo(() => {
    return getMaxLandQtyForVillageLevel(village?.level ?? 0);
  }, [village?.level]);
  const message = useMemo(() => {
    if (selectedLandQty >= availableLandQty) {
      return "Get more land to extend your village!";
    }
    if (selectedLandQty + placedTilesQty >= maxLandQtyForVillageLevel) {
      return "Upgrade village to place more land!";
    }

    return null;
  }, [selectedLandQty, availableLandQty, placedTilesQty, maxLandQtyForVillageLevel]);

  useEffect(() => {
    if (!open) {
      setClosedByUser(false);
    }
  }, [open]);

  if (!open || closedByUser || !message) {
    return null;
  }

  return (
    <div className="absolute bottom-32 lg:bottom-20 xl:bottom-10 left-1/2 transform -translate-x-1/2 flex items-center w-84 bg-white/25 border border-secondary space-x-3 px-3 py-1">
      <button className="p-1" onClick={() => setClosedByUser(true)}>
        <HiXMark className="w-4 h-4 text-red" />
      </button>
      <p className="text-theme-text">{message}</p>
    </div>
  );
}
