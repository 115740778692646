import {WorkerWithTraits} from "../common/types/entities";
import {PowerUpType} from "../common/types/attributes";
import {attributes} from "../config/rarity-attributes";
import {GiantTrait} from "@elrond-giants/game-db/types/entities";

const hasTrait = (traits: GiantTrait[], traitType: string, traitValue: string): boolean => {
    return traits.some(trait => trait.type === traitType && trait.value === traitValue);
}

export const getGiantPowerUpsForBuilding = (
    giant: Pick<WorkerWithTraits, "traits" | "giant_token">,
    buildingToken: string
): Map<PowerUpType, number> => {
    const powerUpsMap = new Map<PowerUpType, number>([
        [PowerUpType.STORAGE, 0],
        [PowerUpType.HARVEST_SPEED, 0],
    ]);
    const buildingPowerUps = attributes[giant.giant_token!][buildingToken] ?? [];
    for (const {traitType, traitValue, powerUps} of buildingPowerUps) {
        if (hasTrait(giant.traits, traitType, traitValue)) {
            for (const {type, multiplier} of powerUps) {
                powerUpsMap.set(type, powerUpsMap.get(type)! + multiplier);
            }
        }
    }

    return powerUpsMap;

}

export const getGiantsPowerUpsForBuilding = (
    giants: WorkerWithTraits[],
    buildingToken: string
): Map<PowerUpType, number> => {
    const giantsPowerUps = new Map<PowerUpType, number>([
        [PowerUpType.STORAGE, 0],
        [PowerUpType.HARVEST_SPEED, 0],
    ]);
    for (const giant of giants) {
        const giantPowerUps = getGiantPowerUpsForBuilding(giant, buildingToken);
        for (const [type, multiplier] of giantPowerUps) {
            giantsPowerUps.set(type, giantsPowerUps.get(type)! + multiplier);
        }
    }

    return giantsPowerUps;
}