import {RootState} from "../redux/store";
import {useSelector} from "react-redux";
import {HiFingerPrint} from "react-icons/hi";


export default function SignTxInfo() {
    const transactionsToSign = useSelector(
        (state: RootState) => state.transactions.transactionsToSign
    );

    if (transactionsToSign.length === 0) {
        return null;
    }

    return (
        <div className="flex items-center px-3 py-1">
            <HiFingerPrint className="w-6 h-6 mr-4"/>
            <p className="font-semibold text-theme-text text-left">
                Check your device to sign {transactionsToSign.length === 1
                ? "the transaction!"
                : `${transactionsToSign.length} transactions!`
            }
            </p>
        </div>
    );
};