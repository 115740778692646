import {ActionNotificationInfo} from "../../../common/types";
import {classNames, ucFirst} from "../../../utils/presentation";
import {txExplorerUrl} from "../../../utils/transactions";

export default function TxListItem({txInfo}: { txInfo: ActionNotificationInfo }) {
    const url = txInfo.hash ? txExplorerUrl(txInfo.hash) : undefined;

    return (
        <a
            className={classNames(
                "flex items-center justify-between px-4 py-3 w-full text-theme-text",
                url ? "cursor-pointer" : ""
            )}
            href={url}
            target="_blank"
        >
            <div className="flex items-center space-x-3">
                <div className={classNames(
                    "w-3 h-3 rounded-full",
                    iconStatusColor(txInfo)
                )}/>
                <span className="text-left">
                    {ucFirst(txInfo.action.replace(/([A-Z])/g, " $1"))}
                </span>
            </div>
            <span className={textStatusColor(txInfo)}>
                {ucFirst(txInfo.status)}
            </span>
        </a>
    );
};

const iconStatusColor = (txInfo: ActionNotificationInfo): string => {
    switch (txInfo.status) {
        case "failed":
        case "invalid":
            return "bg-red";
        case "success":
            return "bg-[#BBCE84]";
        case "new":
            return "bg-[#666666]";
        case "pending":
            return "bg-secondary"
    }
};

const textStatusColor = (txInfo: ActionNotificationInfo): string => {
    switch (txInfo.status) {
        case "failed":
        case "invalid":
            return "text-red";
        case "success":
            return "text-[#BBCE84]";
        case "new":
        case "pending":
            return "text-[#666666]"
    }
};