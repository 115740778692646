import {
    TOKEN_BUILDING_FOOD,
    TOKEN_BUILDING_STONE,
    TOKEN_BUILDING_WOOD,
    TOKEN_GIANTS,
    TOKEN_GIANTS_FEMALES
} from "./assets";
import {PowerUpType} from "../common/types/attributes";

export const maleAttributes = {
    tshirt: {
        traitType: "Upper Body",
        traitValue: "Elrond T Shirt",
        powerUps: [
            {type: PowerUpType.HARVEST_SPEED, multiplier: 0.06},
            {type: PowerUpType.STORAGE, multiplier: 0.2}
        ]
    },
    skateboard: {
        traitType: "Lower Body",
        traitValue: "Skateboard",
        powerUps: [
            {type: PowerUpType.HARVEST_SPEED, multiplier: 0.05},
        ]
    },
    vr: {
        traitType: "Eyes",
        traitValue: "VR",
        powerUps: [
            {type: PowerUpType.STORAGE, multiplier: 0.1},
        ]
    },
    boxerBody: {
        traitType: "Upper Body",
        traitValue: "Boxer Body",
        powerUps: [
            {type: PowerUpType.HARVEST_SPEED, multiplier: 0.06},
        ]
    },
    crown: {
        traitType: "Headwear",
        traitValue: "Crown",
        powerUps: [
            {type: PowerUpType.STORAGE, multiplier: 0.05},
        ]
    },
    robotLegs: {
        traitType: "Lower Body",
        traitValue: "Robot Legs",
        powerUps: [
            {type: PowerUpType.HARVEST_SPEED, multiplier: 0.04},
        ]
    },
};

export const femaleAttributes = {
    tshirt: {
        traitType: "Upper Body",
        traitValue: "MultiverseX T Shirt",
        powerUps: [
            {type: PowerUpType.HARVEST_SPEED, multiplier: 0.1},
            {type: PowerUpType.STORAGE, multiplier: 0.15}
        ]
    },
    skates: {
        traitType: "Lower Body",
        traitValue: "Skates",
        powerUps: [
            {type: PowerUpType.HARVEST_SPEED, multiplier: 0.05},
        ]
    },
    mask: {
        traitType: "Face",
        traitValue: "Gas Mask",
        powerUps: [
            {type: PowerUpType.STORAGE, multiplier: 0.1},
        ]
    },
    boxer: {
        traitType: "Upper Body",
        traitValue: "Boxer",
        powerUps: [
            {type: PowerUpType.HARVEST_SPEED, multiplier: 0.06},
        ]
    },
    crown: {
        traitType: "Headwear",
        traitValue: "Crown",
        powerUps: [
            {type: PowerUpType.STORAGE, multiplier: 0.05},
        ]
    },
    robot: {
        traitType: "Lower Body",
        traitValue: "Robot",
        powerUps: [
            {type: PowerUpType.HARVEST_SPEED, multiplier: 0.04},
        ]
    },
}


export const attributes = {
    [TOKEN_GIANTS]: {
        [TOKEN_BUILDING_WOOD]: [
            maleAttributes.tshirt,
            maleAttributes.skateboard,
            maleAttributes.vr,

        ],
        [TOKEN_BUILDING_STONE]: [
            maleAttributes.tshirt,
            maleAttributes.skateboard,
            maleAttributes.vr,
            maleAttributes.boxerBody,
            maleAttributes.crown,
            maleAttributes.robotLegs,
        ],
        [TOKEN_BUILDING_FOOD]: [
            maleAttributes.tshirt,
        ]
    },
    [TOKEN_GIANTS_FEMALES]: {
        [TOKEN_BUILDING_WOOD]: [
            femaleAttributes.tshirt,
            femaleAttributes.skates,
            femaleAttributes.mask,
        ],
        [TOKEN_BUILDING_STONE]: [
            femaleAttributes.tshirt
        ],
        [TOKEN_BUILDING_FOOD]: [
            femaleAttributes.tshirt,
            femaleAttributes.skates,
            femaleAttributes.mask,
            femaleAttributes.boxer,
            femaleAttributes.crown,
            femaleAttributes.robot,
        ]
    }
}




