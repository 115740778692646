import {Address, Transaction} from '@multiversx/sdk-core/out';
import {nanoid} from 'nanoid';
import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {InventoryAsset} from '../../common/types/inventory';
import {useAuth} from '../../hooks/useAuth';
import {useAppDispatch} from '../../hooks/useStore';
import {useTransaction} from '../../hooks/useTransaction';
import {
  clearAssetsToTransfer as clearInventoryTransfer,
  fetchInventoryAssets, fetchInventoryGiants,
  fetchLandTiles,
  fetchRewards, fetchSkins, fetchTotalWalletGiants,
  filterInventoryAssets, selectInventoryAssets,
  setAddress as setInventoryAddress,
} from '../../redux/slices/inventorySlice';
import {addTxToSign, removeTxToSign} from '../../redux/slices/transactionsSlice';
import {
  clearAssetsToTransfer as clearWalletTransfer,
  fetchMainWalletAssets,
  setAddress as setMainWalletAddress,
} from '../../redux/slices/walletAssetsSlice';
import {RootState} from '../../redux/store';
import {buildInventoryAssetTx} from '../../utils/inventory';
import GameAssets from './GameAssets';
import WalletAssets from './WalletAssets';
import Popup from "../shared/Popup";
import Tabs from "../shared/tabs/Tabs";
import Tab from "../shared/tabs/Tab";
import ActionButton from "../shared/ActionButton";
import {classNames} from "../../utils/presentation";
import Shop from "../shop/Shop";
import {usePopups} from "../../hooks/usePopups";
import {PopupEnum} from "../../common/types/components";
import Collections from "../marketplace/Collections";

type Props = {
  setCurrentAsset: (asset: InventoryAsset) => void;
};
const TRANSFER_NONE = 0;
const TRANSFER_WALLET_TO_INVENTORY = 1;
const TRANSFER_INVENTORY_TO_WALLET = 2;

export default function Inventory({setCurrentAsset}: Props) {
  const { wallet, mainWallet, account } = useAuth();
  const {isActive, closePopup} = usePopups();
  const { sendTransaction, guardTransactionIfNeeded } = useTransaction();
  const [mode, setMode] = useState<"inventory" | "transfer">("inventory");
  const selectedInventoryAssets = useSelector((state: RootState) => state.inventory.assetsToTransfer);
  const selectedWalletAssets = useSelector((state: RootState) => state.walletAssets.assetsToTransfer);
  const inventoryAssets = useSelector(selectInventoryAssets);
  const walletAssets = useSelector((state: RootState) => state.walletAssets.assets);
  const inventoryAssetsStatus = useSelector((state: RootState) => state.inventory.status);
  const walletAssetsStatus = useSelector((state: RootState) => state.walletAssets.status);
  const rewards = useSelector((state: RootState) => state.inventory.rewards);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const transferType = useMemo(() => {
    if (Object.keys(selectedInventoryAssets).length > 0) {
      return TRANSFER_INVENTORY_TO_WALLET;
    }
    if (Object.keys(selectedWalletAssets).length > 0) {
      return TRANSFER_WALLET_TO_INVENTORY;
    }

    return TRANSFER_NONE;
  }, [selectedInventoryAssets, selectedWalletAssets]);

  useEffect(() => {
    if (account?.game_wallet) {
      dispatch(setInventoryAddress(account.game_wallet));
      if (inventoryAssetsStatus === "idle") {
        dispatch(filterInventoryAssets({ type: "assets" }));
      }
    }
    if (account?.primary_wallet) {
      dispatch(setMainWalletAddress(account.primary_wallet));
      if (walletAssetsStatus === "idle") {
        dispatch(fetchMainWalletAssets({ reset: true }));
      }
    }
    if (account?.game_wallet) {
      dispatch(fetchRewards({ address: account.game_wallet! }));
    }
  }, []);

  const clearSelectedAssets = () => {
    dispatch(clearInventoryTransfer());
    dispatch(clearWalletTransfer());
  };

  const close = () => {
    closePopup(PopupEnum.INVENTORY);
    setMode("inventory");
    clearSelectedAssets();
  };

  const makeTransfer = async (
    tx: Transaction,
    action: string,
    { onBroadcastSuccess = () => {}, onBroadcastError = () => {} }
  ) => {
    const { status } = await sendTransaction(tx, action, {
      onBroadcastSuccess,
      onBroadcastError,
    });
    wallet?.refresh();
    dispatch(fetchInventoryAssets({ address: account!.game_wallet!, reset: true }));
    dispatch(fetchMainWalletAssets({ address: account!.primary_wallet, reset: true }));
    dispatch(fetchRewards({ address: account!.game_wallet! }));
    dispatch(fetchLandTiles());
    dispatch(fetchInventoryGiants({reset: true}));
    dispatch(fetchTotalWalletGiants())
    dispatch(fetchSkins());
    clearSelectedAssets();
    setLoading(false);
  };

  const transferInventoryToWallet = async () => {
    setLoading(true);
    const sender = new Address(account!.game_wallet!);
    const receiver = new Address(account!.primary_wallet);
    const assetsToSend = mapSelectedItems();
    const tx = buildInventoryAssetTx(assetsToSend, sender, receiver);
    await wallet!.refresh();
    tx.setNonce(wallet!.nonce);
    const signedTx = wallet!.signTransaction(tx);
    await makeTransfer(signedTx, "Transfer assets to wallet", {
      onBroadcastSuccess: () => wallet!.incrementNonce(),
      onBroadcastError: () => wallet!.decrementNonce(),
    });
  };

  const transferWalletToInventory = async () => {
    setLoading(true);
    const sender = new Address(account!.primary_wallet);
    const receiver = new Address(account!.game_wallet!);
    const assetsToSend = mapSelectedItems();
    let tx = buildInventoryAssetTx(assetsToSend, sender, receiver);
    await mainWallet!.refresh();
    tx.setNonce(mainWallet!.nonce);
    tx = await guardTransactionIfNeeded(tx, mainWallet!);
    let signedTx: Transaction | null;
    const id = nanoid(5);
    try {
      dispatch(addTxToSign(id));
      signedTx = await mainWallet!.signTransaction(tx);
    } finally {
      dispatch(removeTxToSign(id));
    }
    if (signedTx) {
      await makeTransfer(signedTx, "Transfer assets to inventory", {});
    }
  };

  const mapSelectedItems = (): InventoryAsset[] => {
    const selectedAssets =
      transferType === TRANSFER_INVENTORY_TO_WALLET ? selectedInventoryAssets : selectedWalletAssets;
    const assets = transferType === TRANSFER_INVENTORY_TO_WALLET ? [...inventoryAssets, ...rewards] : walletAssets;

    const assetsToTransfer = [];
    for (let assetIdentifier of Object.keys(selectedAssets)) {
      const asset = assets.find((asset) => asset.identifier === assetIdentifier);
      if (asset) {
        assetsToTransfer.push({ ...asset, quantity: selectedAssets[assetIdentifier] });
      }
    }

    return assetsToTransfer;
  };

  return (
      <Popup
          open={isActive(PopupEnum.INVENTORY)}
          setOpen={close}
          closeOnOutsideClick={true}
          widthClass="w-full md:max-w-xl"
      >
        <Tabs onChange={clearSelectedAssets}>
          <Tab
              label="Inventory"
              onSelected={() => {setMode("inventory")}}
          >
            <div className="flex flex-col items-start w-full flex-grow md:h-120">
              <GameAssets
                  mode={mode}
                  setMode={setMode}
                  setCurrentAsset={setCurrentAsset}
                  enabled={!loading && (mode === "inventory" || transferType !== TRANSFER_WALLET_TO_INVENTORY)}
              />
              {mode === "transfer" && transferType === TRANSFER_INVENTORY_TO_WALLET && (
                  <div
                      className="flex flex-col md:flex-row items-center justify-start gap-4 w-full">
                    <ActionButton
                        onClick={transferInventoryToWallet}
                        disabled={loading}
                        style="primary"
                    >
                       <span className={classNames(loading ? "animate-pulse" : "")}>
                       {loading ? "Sending..." : "Send to wallet"}
                     </span>
                    </ActionButton>
                    <ActionButton
                        onClick={clearSelectedAssets}
                        disabled={loading}
                        style="secondary"
                    >
                      Cancel
                    </ActionButton>
                  </div>
              )}
            </div>
          </Tab>
          <Tab
              label="Wallet"
              onSelected={() => {setMode("transfer")}}
              disabled={transferType === TRANSFER_INVENTORY_TO_WALLET}
          >
            <div className="flex flex-col items-start w-full flex-grow md:h-120">
              <WalletAssets enabled={!loading && transferType !== TRANSFER_INVENTORY_TO_WALLET} />
              {mode === "transfer" && transferType === TRANSFER_WALLET_TO_INVENTORY && (
                  <div
                      className="flex flex-col md:flex-row items-center justify-start gap-4 w-full">
                    <ActionButton
                        onClick={transferWalletToInventory}
                        disabled={loading}
                        style="primary"
                    >
                     <span className={classNames(loading ? "animate-pulse" : "")}>
                       {loading ? "Sending..." : "Add to inventory"}
                     </span>
                    </ActionButton>
                    <ActionButton
                        onClick={clearSelectedAssets}
                        disabled={loading}
                        style="secondary"
                    >
                      Cancel
                    </ActionButton>
                  </div>
              )}
            </div>
          </Tab>
          <Tab label="Shop">
            <div className="flex flex-col items-start w-full flex-grow md:h-120">
              <Shop/>
            </div>
          </Tab>
          <Tab label="xoxno">
            <Collections/>
          </Tab>
        </Tabs>
      </Popup>
  );
}
