import ActionContext from "./ActionContext";
import {gameAction} from "../../config/actions";

export default class RemoveBuildingSkinContext extends ActionContext<"REMOVE_BUILDING_SKIN"> {
    protected type = gameAction.REMOVE_BUILDING_SKIN;
    protected hasTransaction = true;


    public getUsedItems(): Record<string, number> {
        return {};
    }

};