import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { InventoryAsset } from "../../common/types/inventory";
import { useAuth } from "../../hooks/useAuth";
import { useAppDispatch } from "../../hooks/useStore";
import {
  clearAssetsToTransfer as clearInventoryTransfer,
  fetchInventoryAssets, fetchLandTiles,
  filterInventoryAssets,
  hasMoreInventoryAssets,
  selectAssetToTransfer, selectInventoryAssets,
} from "../../redux/slices/inventorySlice";
import { RootState } from "../../redux/store";
import { classNames } from "../../utils/presentation";
import Loader from "../shared/Loader";
import AssetsList from "./AssetsList";
import {AiOutlinePlus} from "react-icons/ai";
import {clearAssetsToTransfer as clearWalletTransfer} from "../../redux/slices/walletAssetsSlice";
import RequestAssetsButton from "../RequestAssetsButton";
import WalletTokensBalance from "./WalletTokensBalance";
import {usePopups} from "../../hooks/usePopups";
import {PopupEnum} from "../../common/types/components";

type Props = {
  mode: "inventory" | "transfer";
  setMode: (mode: "inventory" | "transfer") => void;
  enabled: boolean;
  setCurrentAsset: (asset: InventoryAsset) => void;
};
export default function GameAssets({ mode, setMode, enabled, setCurrentAsset }: Props) {
  const status = useSelector((state: RootState) => state.inventory.status);
  const assets = useSelector(selectInventoryAssets);
  const assetsToTransfer = useSelector((state: RootState) => state.inventory.assetsToTransfer);
  const hasMore = useSelector((state: RootState) => hasMoreInventoryAssets(state));
  const typeFilter = useSelector((state: RootState) => state.inventory.filters.type);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (mode === "inventory" && typeFilter !== "assets") {
      dispatch(filterInventoryAssets({type: "assets"}));
      dispatch(fetchLandTiles());
    }
    if (mode === "transfer") {
      dispatch(filterInventoryAssets({}));
    }
  }, [mode]);

  const onAssetClick = (asset: InventoryAsset) => {
    setCurrentAsset(asset);
  };

  const clearSelectedAssets = () => {
    dispatch(clearInventoryTransfer());
    dispatch(clearWalletTransfer());
  };

  return (
    <div
      className={classNames(
          "flex flex-col items-center justify-start py-4 w-full h-full overflow-auto"
      )}
    >
      <div className="flex items-center justify-between w-full mb-8">
        <WalletTokensBalance mode="inventory"/>
      </div>
      <div className="flex flex-col items-start gap-y-4 w-full mb-6">
        <div className="flex items-center justify-between space-x-4 w-full pr-3">
          <button
              className={classNames(
                  "inline-flex items-center text-sm font-bold hover:underline",
                  mode === "inventory" ? "text-gray-500" : "text-theme-text"
              )}
              onClick={() => {
                setMode(mode === "inventory" ? "transfer" : "inventory");
                clearSelectedAssets();
              }}
          >
            <AiOutlinePlus
                className={classNames(
                    "w-3 h-3 mr-1",
                    mode === "transfer" ? "rotate-45" : ""
                )}
            />
            <span className="">Transfer</span>
          </button>
          {status === "loading" && <div className="ml-2"><Loader size="medium"/></div>}
          <RequestAssetsButton enabled={status !== "loading" && mode !== "transfer"}/>
        </div>
        {mode === "transfer" && (
          <div className="flex items-center space-x-3 font-bold text-sm">
            <button
              className={classNames(typeFilter === undefined ? "text-theme-text" : "text-gray-500 hover:underline")}
              onClick={() => dispatch(filterInventoryAssets({}))}
            >
              All
            </button>
            <button
              className={classNames(typeFilter === "assets" ? "text-theme-text" : "text-gray-500 hover:underline")}
              onClick={() => dispatch(filterInventoryAssets({ type: "assets" }))}
            >
              Assets
            </button>
            <button
              className={classNames(typeFilter === "resources" ? "text-theme-text" : "text-gray-500 hover:underline")}
              onClick={() => dispatch(filterInventoryAssets({ type: "resources" }))}
            >
              Resources
            </button>
            <button
              className={classNames(typeFilter === "giants" ? "text-theme-text" : "text-gray-500 hover:underline")}
              onClick={() => dispatch(filterInventoryAssets({ type: "giants" }))}
            >
              Giants
            </button>
          </div>
        )}
      </div>
      <AssetsList
        enabled={enabled}
        mode={mode}
        items={mode === "inventory" && status !== "complete" ? [] : assets}
        selectedItems={assetsToTransfer}
        next={() => {
          if (status === "loading") {return;}
          dispatch(fetchInventoryAssets({}))
        }}
        hasMore={hasMore}
        onClick={onAssetClick}
        onItemChange={(token: string, qty: number) => {
          dispatch(selectAssetToTransfer({ token, qty }));
        }}
      />
    </div>
  );
}
