import {useSelector} from "react-redux";
import {selectCurrentNpc, setSelectedNpcId} from "../../../redux/slices/questsSlice";
import Popup from "../../shared/Popup";
import {useAppDispatch} from "../../../hooks/useStore";
import Level from "../Level";
import {useEffect, useMemo, useRef, useState} from "react";
import ProgressBar from "../../ProgressBar";
import NpcCountdown from "./NpcCountdown";
import {HiOutlineClock} from "react-icons/hi"
import {getNpcLevelByUser, getNpcXpByUser} from "../../../utils/contractQueries";
import {useAuth} from "../../../hooks/useAuth";
import {npcLevelUpXp, starsForLevel} from "../../../utils/game";
import QuestListItem from "./QuestListItem";
import IconStar from "../../icons/IconStar";
import Scrollbar from "../../shared/Scrollbar";


export default function QuestsPopup() {
    const {account} = useAuth();
    const dispatch = useAppDispatch();
    const npc = useSelector(selectCurrentNpc);
    const [level, setLevel] = useState(0);
    const [xp, setXp] = useState(0);
    const endsAt = npc?.quests[0].valid_until ?? null;
    const [containerHeight, setContainerHeight] = useState(300);
    const [headerHeight, setHeaderHeight] = useState(0);
    const headerRef = useRef<HTMLDivElement>(null);
    const loadNpcLevel = async (address: string, npcId: number) => {
        const level = await getNpcLevelByUser(address, npcId);
        setLevel(level);
    };
    const loadNpcXp = async (address: string, npcId: number) => {
        const xp = await getNpcXpByUser(address, npcId);
        setXp(xp);
    }
    const requiredXp = useMemo(() => {
        return npcLevelUpXp(level);
    }, [level]);

    useEffect(() => {
        if (!npc || !account?.game_wallet) {return;}
        loadNpcLevel(account.game_wallet, npc.npc.contract_id);
        loadNpcXp(account.game_wallet, npc.npc.contract_id);
    }, [account, npc]);

    useEffect(() => {
        if (!headerRef.current) {return;}
        setHeaderHeight(headerRef.current.clientHeight);
    }, [containerHeight]);

    const listHeight = useMemo(() => {
        return containerHeight - headerHeight ;
    }, [containerHeight, headerHeight]);

    if (!npc) {return null;}


    return (
        <Popup
            open={true}
            setOpen={() => dispatch(setSelectedNpcId(null))}
            widthClass="w-120"
            paddingClass="p-0"
            setHeight={setContainerHeight}
        >
            <div className="flex flex-col items-start w-full">
                <div
                    className="w-full flex items-start justify-start p-3 md:p-5 border-b border-secondary"
                    ref={headerRef}
                >
                    <div className="w-28 h-28">
                        <img
                            alt={`Npc: ${npc.npc.name}`}
                            src={`https://giants.fra1.cdn.digitaloceanspaces.com/npcs_bober/${npc.npc.contract_id + 1}.png`}
                            className="w-full h-full object-center object-contain"
                        />
                    </div>
                    <div className="flex flex-col items-start space-y-1 px-4 flex-grow">
                        <div className="flex items-center space-x-1">
                            <HiOutlineClock className="w-4 h-4 text-red"/>
                            {endsAt && <NpcCountdown date={endsAt}/>}
                        </div>
                        <h3 className="text-theme-text text-2xl font-semibold">
                            {npc.npc.name}
                        </h3>
                        <div className="flex items-center justify-start w-full space-x-3">
                            <Level level={level}/>
                            <ProgressBar
                                value={xp}
                                maxValue={requiredXp}
                                height="medium"
                                showTextProgress={requiredXp > 0}
                                textProgressLabel=" xp"
                                label={"Level-up progress"}
                                theme="blue"
                            />
                        </div>
                        <div className="flex items-center justify-between">
                            <span className="text-theme-text text-sm md:text-base text-left">Level-up Rewards</span>
                            <IconStar className="w-4 h-4 text-secondary ml-2"/>
                            <span className="text-theme-text ml-1">
                                {starsForLevel(level)}
                            </span>
                        </div>
                    </div>
                </div>
                <Scrollbar
                    autoHeight={false}
                    minHeight={listHeight + "px"}
                >
                    <ul
                        role="list"
                        className="divide-y divide-secondary w-full border-b md:border-0 border-secondary"
                    >
                    {npc.quests.map(
                        (quest) => <QuestListItem
                            quest={quest}
                            npc={npc.npc}
                            key={quest.id}
                        />
                    )}
                </ul>
                </Scrollbar>
            </div>
        </Popup>
    );
};
