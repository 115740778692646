import ActionContext from "./ActionContext";
import {gameAction} from "../../config/actions";

export default class FinalizeUpgradeBuildingContext extends ActionContext<"COMPLETE_UPGRADE"> {
    protected type = gameAction.COMPLETE_UPGRADE;
    protected hasTransaction = true;


    public getUsedItems(): Record<string, number> {
        return {};
    }

};