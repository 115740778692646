import ActionContext from "./ActionContext";
import {gameAction} from "../../config/actions";

export default class RemoveLandContext extends ActionContext<"REMOVE_LAND"> {
    protected type = gameAction.REMOVE_LAND;
    protected hasTransaction = true;


    public getUsedItems(): Record<string, number> {
        return {};
    }

};