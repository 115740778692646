import {classNames} from "../../utils/presentation";

type Props = {
  level: number;
  theme?: "blue" | "yellow";
}
export default function Level({level, theme = "yellow"}: Props) {
  return <span className={classNames(
    theme === "yellow" ? "bg-primary-dark" : "bg-blue",
      "text-sm text-theme-text-light font-semibold px-1"
  )}>
    Lvl.{level}
  </span>;
}
