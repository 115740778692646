import {
    ITransactionFetcher,
    ITransactionOnNetwork,
    Transaction,
    TransactionVersion,
    TransactionWatcher
} from "@multiversx/sdk-core/out";
import {NetworkEnv, TransactionStatus} from "../common/types";
import {ApiNetworkProvider} from "@multiversx/sdk-network-providers/out";
import {network} from "../config/network";
import {useAppDispatch} from "./useStore";
import {addOrReplaceTransaction} from "../redux/slices/transactionsSlice";
import MainWallet from "../auth/MainWallet";
import {guardTransactions} from "../utils/transactions";
import {Hash} from "@multiversx/sdk-core/out/hash";

export const useTransaction = () => {
    const dispatch = useAppDispatch();
    const sendTransaction = async (
        tx: Transaction,
        action: string = "",
        {onBroadcastSuccess, onBroadcastError}: {
            onBroadcastSuccess?: () => void,
            onBroadcastError?: (e: Error) => void
        } = {}
    ) => {
        const env = process.env.NEXT_PUBLIC_NETWORK_ENV! as NetworkEnv;
        const provider = new ApiNetworkProvider(
            network[env]["api"],
            {timeout: 1000 * 15}
        );
        const txHash = tx.getHash().toString();
        try {
            dispatch(addOrReplaceTransaction({
                id: txHash,
                action,
                status: "pending",
                hash: txHash,
                timestamp: Date.now(),
            }));
            await provider.sendTransaction(tx);
            if (onBroadcastSuccess) {
                onBroadcastSuccess();
            }
        } catch (e: any) {
            dispatch(addOrReplaceTransaction({
                id: txHash,
                action,
                status: "failed",
                hash: txHash,
                timestamp: Date.now(),
            }));
            if (onBroadcastError) {
                onBroadcastError(e);
            }
        }

        // pause for 6 seconds to allow the blast api to get the transaction
        await new Promise(resolve => setTimeout(resolve, 6000));

        const {status, networkTxResult} = await awaitCompleted(tx, provider);

        dispatch(addOrReplaceTransaction({
            id: txHash,
            action,
            status: status,
            hash: txHash,
            timestamp: Date.now(),
        }));

        return {status, txResult: networkTxResult};

    }

    const computeStatus = (txResult: ITransactionOnNetwork): TransactionStatus => {
        // @ts-ignore
        if (txResult.status.isSuccessful()) { return "success"; }
        if (txResult.status.isInvalid()) { return "invalid"; }
        if (txResult.status.isFailed()) { return "failed"; }

        return "pending";

    }

    const awaitCompleted = async (
        tx: { getHash: () => Hash },
        fetcher: ITransactionFetcher,
        options?: {
            pollingIntervalMilliseconds?: number;
            timeoutMilliseconds?: number;
            patienceMilliseconds?: number;
        }
    ) => {
        const watcher = new TransactionWatcher(fetcher, {
            pollingIntervalMilliseconds: 1000,
            timeoutMilliseconds: 1000 * 60 * 3,
            patienceMilliseconds: 1200,
            ...options
        });
        const networkTxResult = await watcher.awaitCompleted(tx);
        const status = computeStatus(networkTxResult);

        return {status, networkTxResult};
    }

    // todo: change when we have guardians on game-wallet
    const guardTransactionIfNeeded = async (tx: Transaction, wallet: MainWallet): Promise<Transaction> => {
        if (!wallet.guardian.guarded) {return tx;}
        // If the account is guarded and the transaction is not guarded,
        // we will add the guardian data to it.
        if (tx.getGuardian().bech32() === "") {
            tx.setGuardian(wallet.guardian.activeGuardian!.address);
            tx.setVersion(TransactionVersion.withTxOptions());
            const options = tx.getOptions();
            if (!options.isWithGuardian()) {
                options.setWithGuardian();
                tx.setOptions(options);
            }
            tx.setGasLimit(tx.getGasLimit().valueOf() + 50_000);
        }
        if (!wallet.shouldApplyGuardianSignature()) {
            return tx;
        }
        const code = prompt("Please enter your 2FA code");
        if (!code) {
            throw new Error("2FA code is required");
        }
        const guardedTx = await guardTransactions([tx], code);

        return guardedTx[0];
    };

    return {sendTransaction, guardTransactionIfNeeded, awaitCompleted};

}