import QuestTypeDeliver from "./QuestTypeDeliver";
import {UserQuest} from "@elrond-giants/game-db/types/entities";
import {QUEST_TYPE_DELIVER} from "../../../../config/assets";

export default function QuestRequirements({quest}: { quest: UserQuest }) {
    if (quest.type === QUEST_TYPE_DELIVER) {
        return <QuestTypeDeliver quest={quest}/>
    }
    return (<div className=""></div>);
};

