import AccountBalance from "./AccountBalance";
import {ApiNetworkProvider} from "@multiversx/sdk-network-providers/out";
import {Address, Transaction} from "@multiversx/sdk-core/out";
import MessageSigner from "./MessageSigner";
import {Signature} from "@multiversx/sdk-core/out/signature";
import {TOKEN_GIANTS_CURRENCY} from "../config/assets";


export default class Wallet {
    private readonly signer: MessageSigner;
    private readonly networkProvider: ApiNetworkProvider;
    private readonly _address: string;
    private _nonce: number = 0;

    // The nonce at the time of the wallet object creation
    private _initialNonce: number | null = null;
    private _balance: AccountBalance = new AccountBalance(0);
    private _giantGoldBalance: AccountBalance = new AccountBalance(0);
    protected onRefresh?: () => void;

    constructor(address: string, signer: MessageSigner, networkProvider: ApiNetworkProvider) {
        this.signer = signer;
        this._address = address;
        this.networkProvider = networkProvider;
    }

    get balance(): AccountBalance {
        return this._balance;
    }

    get giantGoldBalance(): AccountBalance {
        return this._giantGoldBalance;
    }

    get nonce(): number {
        return this._nonce;
    }

    get address(): string {
        return this._address;
    }

    async refresh() {
        const address = Address.fromBech32(this._address);
        const {
            balance,
            nonce
        } = await this.networkProvider.getAccount(address);
        this._nonce = Math.max(this._nonce, nonce.valueOf());
        this._balance = new AccountBalance(balance);
        try {
            const {
                balance: giantsGoldBalance
            } = await this.networkProvider.getFungibleTokenOfAccount(address, TOKEN_GIANTS_CURRENCY);
            this._giantGoldBalance = new AccountBalance(giantsGoldBalance);
        } catch (e) {
            this._giantGoldBalance = new AccountBalance(0);
        }

        if (typeof this.onRefresh === "function") {
            this.onRefresh();
        }
    }

    signTransaction(tx: Transaction): Transaction {
        const signature = this.signer.sign(
            tx.serializeForSigning()
        );
        tx.applySignature(Signature.fromBuffer(Buffer.from(signature)));

        return tx;
    }

    setOnRefreshHandler(handler: () => void) {
        this.onRefresh = handler;

        return this;
    }

    incrementNonce() {
        this._nonce++;
        if (typeof this.onRefresh === "function") {
            this.onRefresh();
        }
        return this;
    }

    decrementNonce() {
        this._nonce--;
        if (typeof this.onRefresh === "function") {
            this.onRefresh();
        }
        return this;
    }
};