import ActionContext from "./ActionContext";
import {gameAction} from "../../config/actions";

export default class ClaimReferralSpendingsContext extends ActionContext<"CLAIM_REFERRAL_SPENDINGS"> {
    protected type = gameAction.CLAIM_REFERRAL_SPENDINGS;
    protected hasTransaction = true;

    getUsedItems(): Record<string, number> {
        return {};
    }

};