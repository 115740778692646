import {classNames} from "../utils/presentation";
import Image from "next/image";

export default function LoadingScreen({showOnTop = false}: { showOnTop?: boolean }) {
    return (
        <div
            className={classNames(
                showOnTop ? "z-50" : "",
                "fixed w-screen h-screen top-0 left-0 flex items-center justify-center"
            )}
        >
            <Image
                src="/auth/loading.jpg"
                alt="Village loading screen"
                fill
                objectFit="cover"
                objectPosition="center"
            />
            <div className="absolute top-0 left-0 w-full h-full bg-[#040932] opacity-60 z-10"></div>
            <img src="/giants-village-logo.svg" alt="Giants"
                 className="h-32 relative z-20 animate-pulse"/>
        </div>
    );
}