import {PropsWithChildren} from "react";
import {classNames} from "../utils/presentation";

export default function ButtonText(
    {children, ...props}: PropsWithChildren<{ [key: string]: any }>
) {
    return (
        <button
            className={classNames(
                "text-theme-text-light underline focus:outline-none focus:ring-1 focus:ring-theme-text-light focus:ring-offset-1",
                "disabled:text-theme-text-light disabled:no-underline disabled:cursor-not-allowed",
            )}
            {...props}
        >
            {children}
        </button>
    );
}