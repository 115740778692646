import {SVGProps} from "react";

export default function IconTrophyFlag({...props}: SVGProps<any>) {
    return (
        <svg viewBox="0 0 54 90" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M3.06547e-07 90L-3.93403e-06 -0.000107548L54 -0.000109908L54 90L27 75.2238L3.06547e-07 90Z" fill="#FFC649"/>
            <g filter="url(#filter0_d_315_8)">
                <path d="M26.8745 70.5561L4.25 83.6837L4.25 5.25L49.75 5.25L49.75 83.6837L27.1255 70.5561L27 70.4833L26.8745 70.5561Z" fill="#FFC649" stroke="#FFF3D9" strokeWidth="0.5"/>
            </g>
            <path d="M39.625 28.25H37.5V26.125C37.5 24.9531 36.5464 24 35.375 24H18.375C17.2036 24 16.25 24.9531 16.25 26.125V28.25H14.125C12.9536 28.25 12 29.2031 12 30.375V35.6875C12 38.6165 14.3835 41 17.3125 41H18.3952C20.1353 43.3085 22.7841 44.8941 25.8125 45.1961V47.375H22.625C20.8674 47.375 19.4375 48.8049 19.4375 50.5625V52.6875C19.4375 53.2743 19.9127 53.75 20.5 53.75H33.25C33.8373 53.75 34.3125 53.2743 34.3125 52.6875V50.5625C34.3125 48.8049 32.8826 47.375 31.125 47.375H27.9375V45.1961C30.9659 44.8941 33.6147 43.3085 35.3548 41H36.4375C39.3665 41 41.75 38.6165 41.75 35.6875V30.375C41.75 29.2031 40.7964 28.25 39.625 28.25ZM32.1875 50.5625V51.625H21.5625V50.5625C21.5625 49.9768 22.0388 49.5 22.625 49.5H31.125C31.7112 49.5 32.1875 49.9768 32.1875 50.5625ZM14.125 35.6875V30.375H16.25V34.625C16.25 36.1292 16.5685 37.5591 17.1348 38.8569C15.4611 38.7626 14.125 37.3843 14.125 35.6875ZM26.875 43.125C22.188 43.125 18.375 39.312 18.375 34.625V26.125H35.375V34.625C35.375 39.312 31.562 43.125 26.875 43.125ZM39.625 35.6875C39.625 37.3843 38.2889 38.7626 36.6155 38.8569C37.1815 37.5591 37.5 36.1292 37.5 34.625V30.375H39.625V35.6875Z" fill="#FFF3D9"/>
            <defs>
                <filter id="filter0_d_315_8" x="0" y="0.999996" width="54" height="87.1178" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_315_8"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_315_8" result="shape"/>
                </filter>
            </defs>
        </svg>

    );
};