import {Assets, Container} from "pixi.js";
import {IPosition, IScene, ScaleSize} from "../../common/types/gameboard";
import {
    assets,
    blankLandPatternSources,
    landPatternSources,
    skinSources
} from "../../config/assets";


export default class InitGameScene extends Container implements IScene {

    constructor() {
        super();
    }

    onTick(delta: number): void {
    }

    private async loadAssets() {
        const assetsData = Object.values(assets)
            .reduce((assets: { src: string, alias: string[] }[], assetsByNonce) => {
                Object.values(assetsByNonce).forEach(assetData => {
                    Object.values(assetData.sources).forEach(source => {
                        assets.push({...source});
                    });
                });

                return assets;
            }, []);
        const skins = Object.values(skinSources)
            .reduce((sources: { src: string, alias: string[] }[], skinsData) => {
                Object.values(skinsData).forEach(skinData => sources.push(skinData));

                return sources;
            }, []);
        assetsData.push(...Object.values(landPatternSources));
        assetsData.push(...Object.values(blankLandPatternSources));
        assetsData.push(...skins);

        return await Assets.load(assetsData);
    }

    onResize():void {}

    onCameraMove(): void {}

    onZoom({scale}: { scale: ScaleSize }): void {}


    async init(): Promise<boolean> {
        await this.loadAssets();

        return true;
    }
};