import {AssetData, IBuildingAssetInfo} from "../../../common/types/gameboard";
import ActionButton from "../../shared/ActionButton";
import {useAuth} from "../../../hooks/useAuth";
import Level from "../Level";
import {usePopups} from "../../../hooks/usePopups";
import {PopupEnum} from "../../../common/types/components";

export default function UpgradeRestrictions(
    {
        assetData,
        level,
        buildingAssetInfo,
        close,
        requiredVillageLevel
    }: {
        assetData: AssetData,
        level: number,
        buildingAssetInfo: IBuildingAssetInfo,
        requiredVillageLevel: number,
        close: () => void
    }
) {
    const {village} = useAuth();
    const {showPopup} = usePopups();

    if (level >= buildingAssetInfo.maxLevel) {
        return (
            <div className="flex flex-col items-center h-20 w-full space-y-2">
                 <span className="text-theme-text font-semibold">
                         Max level reached
                 </span>
                <ActionButton
                    onClick={() => {
                        showPopup(PopupEnum.INVENTORY);
                        close();
                    }}
                    widthClass="w-auto"
                >
                    place another {assetData.title}
                </ActionButton>
            </div>
        );
    }

    if (village?.level !== undefined && requiredVillageLevel > village.level) {
        return (
            <div className="flex flex-col items-center h-20 w-full space-y-2">
                 <span className="text-theme-text font-semibold">
                         Reach Village <Level level={requiredVillageLevel}/> to upgrade {assetData.title}
                 </span>
                <ActionButton
                    onClick={() => {
                        showPopup(PopupEnum.INVENTORY);
                        close();
                    }}
                    widthClass="w-auto"
                >
                    place another {assetData.title}
                </ActionButton>
            </div>
        );
    }

    return null;
};
