import {useAuth} from "../hooks/useAuth";
import {useRouter} from "next/router";
import {useEffect, useState} from "react";

export default function RequiresAuth({children}: { children: any }) {
    const {authenticated, authLoading} = useAuth();
    const router = useRouter();
    const [redirect, setRedirect] = useState(false);
    useEffect(() => {
        if (authenticated || authLoading) {
            return;
        }
        if (redirect) {
            return;
        }
        (async () => {
            setRedirect(true);
            await router.push("/auth");
        })();
    }, [authenticated, router, authLoading]);

    if (authenticated) {
        return <>{children}</>
    }
    return null
}