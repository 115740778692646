import {SVGProps} from "react";

export default function IconBrush({...props}: SVGProps<any>) {
    return (
        <div className="flex items-center justify-center rounded-full p-1" {...props}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.6971 0C12.9759 0 12.2869 0.344455 11.848 0.93041L9.15801 4.75889C8.87107 5.14207 8.24536 5.18646 7.90766 4.84804L7.15196 4.09234C6.85793 3.79831 6.46694 3.63638 6.0511 3.63638H5.94882C5.53298 3.63638 5.14199 3.79831 4.84796 4.09234L3.74283 5.19747C3.60078 5.33953 3.60078 5.56964 3.74283 5.71166L10.2883 12.2571C10.3593 12.3281 10.4524 12.3637 10.5454 12.3637C10.6384 12.3637 10.7315 12.3281 10.8025 12.2571L11.9076 11.152C12.2017 10.858 12.3636 10.467 12.3636 10.0511V9.94886C12.3636 9.53302 12.2017 9.14203 11.9076 8.84799L11.152 8.0923C10.9975 7.93783 10.9091 7.72405 10.9091 7.50566C10.9091 7.24606 11.033 6.99785 11.2319 6.8487L15.0784 4.14522C15.6555 3.71305 16 3.02414 16 2.30288C16 1.03302 14.967 0 13.6971 0ZM13.8182 2.90908C13.4173 2.90908 13.0909 2.58273 13.0909 2.18182C13.0909 1.78091 13.4173 1.45456 13.8182 1.45456C14.2191 1.45456 14.5455 1.78091 14.5455 2.18182C14.5455 2.58273 14.2191 2.90908 13.8182 2.90908Z" fill="currentColor"/>
                <path d="M3.54829 6.54546C3.40623 6.4034 3.17612 6.4034 3.03409 6.54546L0.426137 9.15341C0.151261 9.42826 0 9.79333 0 10.1818C0 10.5703 0.151296 10.9354 0.426137 11.2103L0.984717 11.7692C1.09906 11.8839 1.27698 11.9091 1.41832 11.8299L2.70027 11.1179L1.98829 12.3999C1.90943 12.5416 1.93432 12.7188 2.049 12.8335L4.78978 15.5739C5.06465 15.8488 5.4297 16 5.8182 16C6.2067 16 6.57174 15.8487 6.84662 15.5739L9.45458 12.9659C9.59663 12.8239 9.59663 12.5938 9.45458 12.4517L3.54829 6.54546Z" fill="currentColor"/>
            </svg>
        </div>
    );
}