import {useEffect, useState} from "react";
import {useAuth} from "../hooks/useAuth";
import {classNames} from "../utils/presentation";

export default function Avatar({width = "16", extraClass = ""}: {
    width?: string,
    extraClass?: string
}) {
    const {account} = useAuth();
    const [avatarUrl, setAvatarUrl] = useState<string>("./no-avatar.jpeg");
    useEffect(() => {
        if (account?.primary_wallet) {
            setAvatarUrl(`https://id.maiar.com/users/photos/profile/${account.primary_wallet}`);
        }
    }, [account?.primary_wallet]);

    return (
        <div className={classNames(
            "flex items-center justify-center h-full ",
            `w-${width}`, extraClass
        )}>
            {account?.avatar_url ? (
                <img
                    src={account.avatar_url}
                    className="w-full h-full object-center object-contain"
                    alt="Player avatar"
                />
            ) : (
                <img
                    src={avatarUrl}
                    onError={() => setAvatarUrl("./no-avatar.jpeg")}
                    className="w-full h-full object-center object-contain"
                    alt="Player avatar"
                />
            )}
        </div>
    );
}