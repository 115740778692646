import ButtonSquare from "../../ButtonSquare";
import {useAppDispatch} from "../../../hooks/useStore";
import {selectTxNotificationsNumber, setShowTxList} from "../../../redux/slices/transactionsSlice";
import IconTxNotification from "../../icons/IconTxNotification";
import {useSelector} from "react-redux";

export default function TxNotificationsStatus() {
    const dispatch = useAppDispatch();
    const numberOfTransactions = useSelector(selectTxNotificationsNumber);
    if (numberOfTransactions === 0) return null;
    return (
        <ButtonSquare
            size={"large"}
            onClick={() => {dispatch(setShowTxList(true))}}
        >
            <div className="w-full h-full flex items-center justify-center relative">
                <IconTxNotification className="w-full h-full m-1"/>
                <div
                    className="w-5 h-5 bg-red absolute top-1 -right-3 flex items-center justify-center text-sm font-semibold text-white">
                    {numberOfTransactions}
                </div>
            </div>
        </ButtonSquare>
    );
}