import {
    ActionPayload, GameAction,
    GameActionEnum,
    GameActionKey,
    TxActionPayload
} from "../../common/types/actions";
import {gameAction} from "../../config/actions";


export default abstract class ActionContext<T extends GameActionKey>{
    protected abstract type: typeof gameAction[T];
    protected abstract hasTransaction: boolean;

    protected _payload: TxActionPayload<GameAction<T>>
    protected _timestamp: number;

    constructor(payload: TxActionPayload<GameAction<T>>, timestamp?: number) {
        this._payload = payload;
        this._timestamp = timestamp || Date.now();
    }


    public abstract getUsedItems(): Record<string, number>;

    get actionType() {
        return this.type;
    }

    get actionName(): string {
        return this.type.charAt(0).toUpperCase() + this.type.slice(1);
    }

    get payload(): TxActionPayload<GameAction<T>> {
        return this._payload;
    }

    get requiresTransaction(): boolean {
        return this.hasTransaction;
    }

    get timestamp(): number {
        return this._timestamp;
    }


}