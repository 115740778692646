import {buildings, TOKEN_GIANTS_CURRENCY, TOKEN_VILLAGE_COIN} from "../../../config/assets";
import {useCallback, useEffect, useMemo, useState} from "react";
import {getUpgradeFinishTime} from "../../../utils/contractQueries";
import {useAuth} from "../../../hooks/useAuth";
import Countdown from "react-countdown";
import {formatCountdownText} from "../../../utils/presentation";
import {PartialVillageAsset} from "../../../common/types/repository";
import {useApi} from "../../../hooks/useApi";
import {SignAssetUpgradeResponse} from "../../../common/types/responses";
import Manager from "../../../gameboard/Manager";
import FinalizeUpgradeBuildingContext
    from "../../../services/contexts/FinalizeUpgradeBuildingContext";
import {TokenPayload, TxActionPayload} from "../../../common/types/actions";
import {gameAction} from "../../../config/actions";
import {signAssetUpgradePath} from "../../../utils/api-routes";
import ActionButton from "../../shared/ActionButton";
import RewardResourceIcon from "../RewardResourceIcon";
import BigNumber from "bignumber.js";
import {useSelector} from "react-redux";
import {selectRewardsResources} from "../../../redux/slices/inventorySlice";

type Props = {
    asset: PartialVillageAsset,
};
export default function UpgradeCountdown({asset}: Props) {
    const {account, village, giantGoldBalance} = useAuth();
    const {api} = useApi();
    const [
        upgrading,
        setUpgrading
    ] = useState(asset.status === "finalizing-upgrade");
    const [
        finishTimestamp,
        setFinishTimestamp
    ] = useState(0);
    const isOldUpgradeMethod = asset.upgrade_start_date === null;
    const resources = useSelector(selectRewardsResources);
    const buildingAssetInfo = buildings[asset.asset_token];
    const upgradeRequirements = useMemo(() => {
        return (
            buildingAssetInfo?.getUpgradeRequirements({
                currentLevel: asset.attributes.level.value,
            }) ?? new Map()
        );
    }, [asset.attributes.level.value, buildingAssetInfo]);
    const canUpgradeWithVC = useMemo(() => {
        if (!upgradeRequirements.has(TOKEN_VILLAGE_COIN)) {
            return false;
        }
        return resources[TOKEN_VILLAGE_COIN] >= upgradeRequirements.get(TOKEN_VILLAGE_COIN)!;
    }, [resources, upgradeRequirements]);
    const canUpgradeWithCurrency = useMemo(() => {
        if (!upgradeRequirements.has(TOKEN_GIANTS_CURRENCY)) {
            return false;
        }
        return giantGoldBalance.toDenominated().gte(upgradeRequirements.get(TOKEN_GIANTS_CURRENCY)!);
    }, [giantGoldBalance, upgradeRequirements]);

    const loadFinishTime = useCallback(
        async () => {
            if (asset.status !== "upgrading") {return;}
            if (asset.upgrade_finish_date !== null) {
                setFinishTimestamp(new Date(asset.upgrade_finish_date).getTime());
                return;
            }
            const buildingAssetInfo = buildings[asset.asset_token];
            const time = await getUpgradeFinishTime(
                buildingAssetInfo.contract,
                account!.game_wallet!,
                asset.position
            );
            setFinishTimestamp(time * 1000);
        },
        [account, asset]
    );

    const finalizeUpgrade = async (paymentTokenId: string, instantFinish: boolean) => {
        try {
            setUpgrading(true);
            const {
                data: {
                    signature,
                    payment,
                    instantFinishPayment
                }
            } = await api.post<SignAssetUpgradeResponse>(
                signAssetUpgradePath(village!.id),
                {
                    token: {
                        id: asset.asset_token,
                        nonce: asset.asset_token_nonce,
                    },
                    position: asset.position,
                    paymentTokenId,
                    instantFinish
                }
            );
            const payments = [];
            if (payment) {
                payments.push(payment);
            }
            if (instantFinishPayment) {
                payments.push(instantFinishPayment);
            }
            const payload: TxActionPayload<typeof gameAction.COMPLETE_UPGRADE> = {
                token: {
                    id: asset.asset_token,
                    nonce: asset.asset_token_nonce,
                },
                position: asset.position,
                villageId: village!.id,
                attributes: asset.attributes,
                upgrade_signature: signature,
                upgrade_payments: payments
            };

            await Manager.getInstance().commitAction(new FinalizeUpgradeBuildingContext(payload));
        } catch (e) {
            setUpgrading(false);
        }
    };


    useEffect(
        () => {
            loadFinishTime();
        },
        [loadFinishTime]
    );

    if (upgrading) {
        return (
            <div
                className="flex flex-col justify-center items-center bg-secondary disabled:cursor-not-allowed h-20 w-full"
            >
                <span
                    className="text-theme-text font-semibold uppercase animate-pulse"
                >
                    Finalizing the Upgrade...
                </span>
            </div>
        );
    }

    if (finishTimestamp < 1) {
        return (
            <div
                className="flex flex-col justify-center items-center bg-secondary disabled:cursor-not-allowed h-20 w-full"
            >
                <span
                    className="text-theme-text font-semibold uppercase"
                >
                    Upgrade in progress
                </span>
            </div>
        );
    }



    return (
        <Countdown
            date={finishTimestamp}
            renderer={({completed, ...rest}) => {
                if (completed) {
                    if (asset.status === "finalizing-upgrade") {
                        return (
                            <div
                                className="flex flex-col justify-center items-center bg-secondary disabled:cursor-not-allowed h-20 w-full"
                            >
                                <span
                                    className="text-theme-text font-semibold uppercase animate-pulse"
                                >
                                    Finalizing the Upgrade...
                                </span>
                            </div>
                        );
                    }
                    return (
                        <div
                            className="flex flex-col justify-center items-center w-full"
                        >
                            <span
                                className="text-theme-text font-semibold uppercase"
                            >
                                Finalize Upgrade
                            </span>
                            {isOldUpgradeMethod && (
                                <ActionButton
                                    onClick={() => finalizeUpgrade(TOKEN_VILLAGE_COIN, false)}
                                    style="secondary"
                                >
                                    <div className="flex flex-col items-center">
                                        Finalize
                                    </div>
                                </ActionButton>
                            )}
                            {!isOldUpgradeMethod && (
                                <div
                                    className="flex flex-col md:flex-row gap-2 items-center w-full mt-2">
                                    <ActionButton
                                        onClick={() => finalizeUpgrade(TOKEN_VILLAGE_COIN, false)}
                                        style="primary"
                                        disabled={!canUpgradeWithVC}
                                    >
                                        <div className="flex flex-col items-center">
                                        <span
                                            className="text-white font-semibold text-sm normal-case">
                                            Use Villager coins
                                        </span>
                                            <div
                                                className="flex flex-row items-center space-x-2 mt-0.5">
                                                <RewardResourceIcon
                                                    token={TOKEN_VILLAGE_COIN}
                                                    size="4"
                                                    padding={0.5}
                                                />
                                                <span className="text-white">
                                             {upgradeRequirements.get(TOKEN_VILLAGE_COIN)?.toLocaleString()}
                                            </span>
                                            </div>
                                        </div>
                                    </ActionButton>
                                    <ActionButton
                                        onClick={() => finalizeUpgrade(TOKEN_GIANTS_CURRENCY, false)}
                                        style="secondary"
                                        disabled={!canUpgradeWithCurrency}
                                    >
                                        <div className="flex flex-col items-center">
                                        <span
                                            className="text-theme-text font-semibold text-sm normal-case">
                                            Use $Giant
                                        </span>
                                            <div
                                                className="flex flex-row items-center space-x-2 mt-0.5">
                                                <RewardResourceIcon
                                                    token={TOKEN_GIANTS_CURRENCY}
                                                    size="4"
                                                    padding={0.5}
                                                    background="transparent"
                                                />
                                                <span className="text-white">
                                              {upgradeRequirements.get(TOKEN_GIANTS_CURRENCY)?.toLocaleString()}
                                            </span>
                                            </div>
                                        </div>
                                    </ActionButton>
                                </div>
                            )}
                        </div>
                    );
                } else {
                    return (
                        <div
                            className="flex flex-col justify-center items-center w-full"
                        >
                            <span
                                className="text-theme-text font-semibold uppercase"
                            >
                                Upgrade in progress
                            </span>

                            <span
                                className="text-theme-text text-sm mb-2"
                            >
                                  Time to completion: {formatCountdownText(rest)}
                            </span>
                            {!isOldUpgradeMethod && (
                                <InstantFinishCountdown
                                    asset={asset}
                                    upgrade={finalizeUpgrade}
                                />
                            )}
                        </div>
                    )
                }
            }}
        />
    );
};

function InstantFinishCountdown({asset, upgrade}: {
    asset: PartialVillageAsset,
    upgrade: (tokenId: string, instantUpgrade: boolean) => void
}) {
    const {giantGoldBalance} = useAuth();
    const resources = useSelector(selectRewardsResources);
    const buildingAssetInfo = buildings[asset.asset_token];
    const upgradeRequirements = useMemo(() => {
        return (
            buildingAssetInfo?.getUpgradeRequirements({
                currentLevel: asset.attributes.level.value,
            }) ?? new Map()
        );
    }, [asset.attributes.level.value, buildingAssetInfo]);
    const instantPayment = useMemo(() => {
        const tokenUpgradeTax = buildingAssetInfo.getInstantUpgradeTaxPercentage({
            currentLevel: asset.attributes.level.value
        });
        const tokenUpgradeCost = upgradeRequirements.get(TOKEN_GIANTS_CURRENCY)!;

        return new BigNumber(tokenUpgradeCost)
            .multipliedBy(tokenUpgradeTax);

    }, [
        asset.upgrade_instant_finish_tax_payment,
        asset.attributes.level.value,
        upgradeRequirements
    ]);
    const canUpgradeWithVC = useMemo(() => {
        if (!upgradeRequirements.has(TOKEN_VILLAGE_COIN)) {
            return false;
        }
        const hasVC = resources[TOKEN_VILLAGE_COIN] >= upgradeRequirements.get(TOKEN_VILLAGE_COIN)!;
        const hasGiantGold = giantGoldBalance.toDenominated().gte(instantPayment);

        return hasVC && hasGiantGold;
    }, [upgradeRequirements, resources, giantGoldBalance, instantPayment]);

    const canUpgradeWithCurrency = useMemo(() => {
        if (!upgradeRequirements.has(TOKEN_GIANTS_CURRENCY)) {
            return false;
        }
        const amountWithTax = new BigNumber(instantPayment)
            .plus(upgradeRequirements.get(TOKEN_GIANTS_CURRENCY)!);

        return giantGoldBalance.toDenominated().gte(amountWithTax);
    }, [giantGoldBalance, instantPayment, upgradeRequirements]);

    return (
        <Countdown
            date={new Date(asset.upgrade_instant_finish_date!)}
            renderer={({completed, ...rest}) => {
                if (completed) {
                    return (
                        <div
                            className="flex flex-col justify-center items-center w-full"
                        >
                            <div className="flex flex-col md:flex-row gap-2 items-center w-full">
                                <InstantUpgradeButton
                                    onClick={() => upgrade(TOKEN_VILLAGE_COIN, true)}
                                    disabled={!canUpgradeWithVC}
                                    tokenId={TOKEN_VILLAGE_COIN}
                                    tokenCost={upgradeRequirements.get(TOKEN_VILLAGE_COIN)!.toLocaleString()}
                                    instantFinishCost={instantPayment.toString()}
                                />
                                <InstantUpgradeButton
                                    onClick={() => upgrade(TOKEN_GIANTS_CURRENCY, true)}
                                    disabled={!canUpgradeWithCurrency}
                                    tokenId={TOKEN_GIANTS_CURRENCY}
                                    tokenCost={upgradeRequirements.get(TOKEN_GIANTS_CURRENCY)!.toLocaleString()}
                                    instantFinishCost={instantPayment.toString()}
                                />
                            </div>
                            <span className="text-theme-text-light text-xs mt-2 -mb-1">
                                * Upgrade price + instant finish tax
                            </span>
                        </div>
                    );
                } else {
                    return (
                        <div
                            className="flex items-center justify-center w-full bg-gray-300 h-12">
                            <span className="flex items-center space-x-1 text-theme-text-light">
                                Instant finish in {formatCountdownText(rest)}
                            </span>
                        </div>
                    )
                }
            }}
        />
    );
}

function InstantUpgradeButton({onClick, disabled, tokenCost, tokenId, instantFinishCost}: {
    onClick: () => void,
    disabled: boolean,
    tokenId: string,
    tokenCost: string,
    instantFinishCost: string
}) {
    const tokenTitle = tokenId === TOKEN_VILLAGE_COIN ? "V.C." : "$Giant";

    return (
        <ActionButton
            onClick={onClick}
            style={tokenId === TOKEN_VILLAGE_COIN ? "primary" : "secondary"}
            disabled={disabled}
        >
            <div className="flex flex-col items-center w-full text-white ">
                <span className="text-sm normal-case font-semibold">
                    {`${tokenTitle} instant finish`}
                </span>
                <div className="flex items-center space-x-3 mt-0.5">
                    <div className="flex items-center">
                        <RewardResourceIcon
                            token={tokenId}
                            size="4"
                            padding={0.5}
                            background={tokenId === TOKEN_GIANTS_CURRENCY ? "transparent" : undefined}
                        />
                        <span className="text-sm pl-1">
                            {tokenCost}
                        </span>
                    </div>
                    <div
                        className="flex items-center py-0.5 px-1 rounded-full border border-white"
                    >
                        <span>+</span>
                        <div className="flex items-center">
                            <RewardResourceIcon
                                token={TOKEN_GIANTS_CURRENCY}
                                background="transparent"
                                size="4"
                                padding={0.5}
                            />
                            <span className="text-sm px-1">
                                {instantFinishCost}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ActionButton>
    );
}