import ActionContext from "./ActionContext";
import {gameAction} from "../../config/actions";

export default class RemoveGiantsFromHouseToStakingContext extends ActionContext<"REMOVE_STAKED_GIANTS_FROM_HOUSE"> {
    protected type = gameAction.REMOVE_STAKED_GIANTS_FROM_HOUSE;
    protected hasTransaction = true;


    public getUsedItems(): Record<string, number> {
        return {};
    }

};