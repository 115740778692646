import BigNumber from "bignumber.js";
import {TOTAL_LEADERBOARD_PRIZES_AMOUNT} from "../config/misc";

export const computeBasePrizeAmount = (nbPrizes: number): number => {
    return TOTAL_LEADERBOARD_PRIZES_AMOUNT / (nbPrizes * (nbPrizes + 1) / 2);
}
export const computePrizeAmount = (rank: number, nbPrizes: number): string => {
    if (rank > nbPrizes) { return "0"; }
    const baseAmount = computeBasePrizeAmount(nbPrizes);

    return new BigNumber(baseAmount).times(nbPrizes - rank + 1).shiftedBy(18).toString();
}