import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { PopupEnum } from "../../common/types/components";
import { TOKEN_STAR } from "../../config/assets";
import { useAuth } from "../../hooks/useAuth";
import { usePopups } from "../../hooks/usePopups";
import { selectRewardsResources } from "../../redux/slices/inventorySlice";
import { starsRequiredForLevelUp } from "../../utils/game";
import { formatAddress } from "../../utils/presentation";
import IconSettings from "../icons/IconSettings";
import IconUpgrade from "../icons/IconUpgrade";
import Level from "./Level";
import IconTrophyFlag from "../icons/IconTrophyFlag";
import Avatar from "../Avatar";
import IconWizard from "../icons/IconWizard";
import {useVillageState} from "../../hooks/useVillageState";

export default function PlayerMenuBar() {
  const { account, village } = useAuth();
  const {villageResidentsQty} = useVillageState()
  const { showPopup } = usePopups();
  const resources = useSelector(selectRewardsResources);
  const villageStars = useMemo(() => {
    return resources[TOKEN_STAR] ?? 0;
  }, [resources]);
  const requiredStars = useMemo(() => {
    return starsRequiredForLevelUp((village?.level ?? 0) + 1);
  }, [village?.level]);
  const canUpgrade = useMemo(() => {
    return villageStars >= requiredStars;
  }, [villageStars, requiredStars]);
  const showVillageUpgradePopup = () => {
    showPopup(PopupEnum.VILLAGE_UPGRADE, { removeWhenInactive: true });
  };


  return (
    <div className="flex items-center bg-secondary-lightest w-full md:w-auto pointer-events-auto">
      <div
          className="flex items-center w-full md:w-auto border border-primary-darkest h-16 md:m-1"
      >
        <Avatar/>
        <div
            className="flex flex-col h-full justify-center px-3 lg:min-w-[160px] flex-grow md:flex-grow-0">
          <span className="text-xl text-red font-semibold">{formatAddress(account!.primary_wallet)}</span>
          <div className="flex items-end justify-between">
            <div className="flex items-end space-x-2">
              <button
                  className="flex focus:outline-none focus:ring-2 focus:ring-primary-darkest items-center"
                  onClick={showVillageUpgradePopup}
              >
                <Level level={village?.level ?? 0}/>
              </button>
              {canUpgrade && (
                  <button
                      className="focus:outline-none focus:ring-2 focus:ring-primary-darkest"
                      onClick={showVillageUpgradePopup}
                  >
                    <IconUpgrade className="w-5 h-5"/>
                  </button>
              )}
            </div>
            <div className="flex items-center justify-center space-x-1">
              <span className="text-theme-text-light font-semibold">{villageResidentsQty}</span>
              <IconWizard className="w-5 h-5"/>
            </div>
          </div>
        </div>
        <div className="relative w-12 h-full">
          <button
              className="absolute -top-1 md:-top-1.5 z-30"
              onClick={() => showPopup(PopupEnum.LEADERBOARD)}
          >
            <IconTrophyFlag className="h-[78px] md:h-[85px]"/>
          </button>
        </div>
        <button
          className="flex items-center justify-center w-16 h-full"
          onClick={() => showPopup(PopupEnum.REFERRAL_REWARDS)}
        >
          <IconSettings className="w-6 h-6 text-red" />
        </button>
      </div>
    </div>
  );
}
