import {useEffect} from "react";
import NpcHead from "./NpcHead";
import {useQuestsState} from "../../../hooks/useQuestsState";
import Manager from "../../../gameboard/Manager";
import {AssetEventPayload, GameActionEnum, TxActionPayload} from "../../../common/types/actions";


export default function NpcsBox() {
    const {npcs, questsStatus, loadNpcs, setQuestStatus} = useQuestsState();
    useEffect(() => {
        if ("idle" !== questsStatus) {return;}
        loadNpcs();
    }, []);


    const gameManager = Manager.getInstance();

    useEffect(() => {
        if (!gameManager.initialised) {return;}
        const actionCommitHandler = ({payload}: {
            payload: TxActionPayload<GameActionEnum.COMPLETE_QUEST>
        }) => {
            setQuestStatus(payload.quest.id, "new");
        };

        const gameEventsHandler = (event: AssetEventPayload) => {
            if(event.action.action === GameActionEnum.COMPLETE_QUEST) {
                loadNpcs();
            }
        };

        gameManager.subscribeToAction("GAME_ACTION_COMMIT_ERROR", actionCommitHandler);
        gameManager.subscribeToAction("GAME_ACTION", gameEventsHandler);


        return () => {
            gameManager.unsubscribeFromAction("GAME_ACTION_COMMIT_ERROR", actionCommitHandler);
            gameManager.unsubscribeFromAction("GAME_ACTION", gameEventsHandler);
        }

    }, [gameManager.initialised]);

    if (npcs.length === 0) {return null;}

    return (
        <div className="flex flex-col items-center justify-evenly p-1 bg-white gap-y-1">
            {npcs.map((npc, index) => <NpcHead
                    npc={npc}
                    showTimer={index === 0}
                    key={npc.npc.id}
                />
            )}
        </div>
    );
}