import ActionContext from "./ActionContext";
import {gameAction} from "../../config/actions";

export default class AddGiantsToWorkContext extends ActionContext<"ADD_GIANTS_TO_WORK"> {
    protected type = gameAction.ADD_GIANTS_TO_WORK
    protected hasTransaction = false;

    public getUsedItems(): Record<string, number> {
        return {};
    }

};