import {PartialVillageAsset} from "../../../common/types/repository";
import {buildings} from "../../../config/assets";
import {AssetData, IBuildingAssetInfo} from "../../../common/types/gameboard";
import {useEffect, useMemo, useState} from "react";
import {SkinItem} from "../../../common/types";
import ActionButton from "../../shared/ActionButton";
import {classNames} from "../../../utils/presentation";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {InventoryAsset} from "../../../common/types/inventory";
import {GameActionEnum, IToken, TxActionPayload} from "../../../common/types/actions";
import {useAuth} from "../../../hooks/useAuth";
import Manager from "../../../gameboard/Manager";
import {getSkinItem} from "../../../utils/assets";
import SetBuildingSkinContext from "../../../services/contexts/SetBuildingSkinContext";
import RemoveBuildingSkinContext from "../../../services/contexts/RemoveBuildingSkinContext";

export default function SkinsMenu({asset, assetData, setShowMenu}: {
    asset: PartialVillageAsset;
    assetData: AssetData;
    setShowMenu: (show: boolean) => void;
}) {
    const buildingAssetInfo = buildings[asset.asset_token];
    const allSkins = useSelector((state: RootState) => state.inventory.skins);
    const availableSkins: SkinItem[] = useMemo(() => {
        return allSkins.reduce((availableSkins: SkinItem[], skin: InventoryAsset) => {
            const skinData = getSkinItem(buildingAssetInfo, {
                id: skin.collection,
                nonce: skin.nonce
            });
            if (skinData) {
                return [...availableSkins, skinData];
            }
            return availableSkins;
        }, []);
    }, [allSkins, buildingAssetInfo.skins]);
    const [selectedSkin, setSelectedSkin] = useState<SkinItem | null>(null);
    const [currentSkin, setCurrentSkin] = useState<SkinItem | null>(
        asset.attributes.skin?.value ? getSkinItem(buildingAssetInfo, asset.attributes.skin.value) : null
    );
    const [applying, setApplying] = useState(false);
    const [removing, setRemoving] = useState(false);
    const displayedThumbnail = useMemo(() => {
        if (selectedSkin) {
            return selectedSkin.thumbnail;
        }
        if (currentSkin) {
            return currentSkin.thumbnail;
        }
        return assetData.thumbnail;
    }, [assetData, selectedSkin]);
    const {village} = useAuth();
    const gameManager = Manager.getInstance();

    useEffect(() => {
        if (!gameManager?.initialised) {return;}

    }, [gameManager]);


    const onApply = async () => {
        if (!selectedSkin) {
            return;
        }
        setApplying(true);
        try {
            const actionContext = new SetBuildingSkinContext({
                skinToken: selectedSkin.token,
                buildingToken: {id: asset.asset_token, nonce: asset.asset_token_nonce},
                villageId: village!.id
            });
            await Manager.getInstance().commitAction(actionContext);
            setShowMenu(false);
        } catch (e) {

        } finally {
            setApplying(false);
        }
    }
    const onRemove = async () => {
        if (selectedSkin) {
            setSelectedSkin(null);
            return;
        }
        if (!currentSkin) {
            return;
        }
        setRemoving(true);
        try {
            const actionContext = new RemoveBuildingSkinContext({
                buildingToken: {id: asset.asset_token, nonce: asset.asset_token_nonce},
                villageId: village!.id
            });
            await Manager.getInstance().commitAction(actionContext);
            setShowMenu(false);
        } finally {
            setRemoving(false);
        }
    }

    return (
        <div
            className="flex flex-col items-center flex-grow md:min-h-60 mt-4 w-full border-t border-b border-secondary"
        >
            <div className="flex flex-col items-center justify-center w-full py-8">
                <div
                    className="flex items-center justify-center w-20 h-20 p-0.5 border-2 border-secondary"
                >
                    <img
                        src={displayedThumbnail}
                        alt={`asset thumbnail`}
                        className={classNames(
                            "w-full h-full object-center object-cover",
                            (applying || removing) ? "animate-pulse" : ""
                        )}
                    />
                </div>
                {(currentSkin || selectedSkin) && <button
                    className={classNames(
                        "mt-4 text-red underline focus:outline-none focus:ring-1 focus:ring-red focus:ring-offset-1 text-sm",
                        "disabled:text-theme-text-light disabled:no-underline disabled:cursor-not-allowed",
                        removing ? "animate-pulse" : ""
                    )}
                    disabled={removing || applying}
                    onClick={onRemove}
                >
                    {removing ? "Removing skin..." : selectedSkin ? "Remove skin" : "Remove current skin"}
                </button>
                }
            </div>
            <span className="font-semibold">Available skins</span>
            <div className="flex flex-grow w-full py-4 px-4 mt-2 border-t border-secondary">
                <ul
                    role="list"
                    className="grid grid-cols-3 md:grid-cols-4 gap-y-10 gap-x-4"
                >
                    {availableSkins.map(skin => (
                        <li
                            key={`${skin.token.id}-${skin.token.nonce}`}
                        >
                            <button
                                className="flex items-center justify-center w-20 h-20 border border-secondary p-0.5"
                                onClick={() => {setSelectedSkin(skin)}}
                                disabled={applying || removing}
                            >
                                <img
                                    src={skin.thumbnail}
                                    alt={`skin ${skin.token.id}`}
                                    className="w-full h-full object-center object-cover"
                                />
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <div
                className="flex flex-col items-center justify-center gap-3 w-full py-2 sm:py-4 px-4">
                <ActionButton
                    onClick={onApply}
                    disabled={selectedSkin === null || applying || removing}
                >
                    <span className={applying ? "animate-pulse" : ""}>
                        {applying ? "Applying skin..." : "Apply skin"}
                    </span>
                </ActionButton>
                <ActionButton
                    onClick={() => setShowMenu(false)}
                    disabled={applying || removing}
                    style="secondary"
                >
                    Cancel
                </ActionButton>
            </div>
        </div>
    );
};