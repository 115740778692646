import ProgressBar from "../../ProgressBar";
import {TOKEN_FOOD, TOKEN_STONE, TOKEN_WOOD} from "../../../config/assets";

export default function HarvestProgressBar({value, maxValue, token}: {
    value: number;
    maxValue: number;
    token: string
}) {
    return (
        <ProgressBar
            value={value}
            maxValue={maxValue}
            label={`${token} amount`}
            theme={getProgressColor(token)}
        />
    );
};

const getProgressColor = (token: string) => {
    switch (token) {
        case TOKEN_WOOD:
            return "orange";
        case  TOKEN_FOOD:
            return "yellow";
        case TOKEN_STONE:
            return "green";
    }

    return undefined;
}