import Popup from "../shared/Popup";
import {usePopups} from "../../hooks/usePopups";
import {PopupEnum} from "../../common/types/components";
import {AiOutlineReload} from "react-icons/ai";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {
    classNames,
    formatAddress,
    formatAmount,
    formatCountdownText
} from "../../utils/presentation";
import {useAuth} from "../../hooks/useAuth";
import {useEffect, useMemo, useState} from "react";
import Scrollbar from "../shared/Scrollbar";
import Avatar from "../Avatar";
import {LeaderboardEntry} from "@elrond-giants/game-db/types/entities";
import {useNodeRef} from "../../hooks/useNodeRef";
import LeaderboardPrizeClaim from "./LeaderboardPrizeClaim";
import {useLeaderboard} from "../../hooks/useLeaderboard";
import Countdown from "react-countdown";
import IconGiantCurrency from "../icons/IconGiantCurrency";
import {computePrizeAmount} from "../../utils/leaderboards";
import BigNumber from "bignumber.js";

export default function LeaderboardDialog() {
    const {account} = useAuth();
    const {isActive, closePopup} = usePopups();
    const {
        leaderboard: currentLeaderboard,
        accountEntry: currentAccountEntry,
        isTopN: isCurrentTopN,
        previousLeaderboard,
        previousAccountEntry,
        isPreviousTopN,
        fetchAccountOnLeaderboard,
        fetchAccountOnPreviousLeaderboard
    } = useLeaderboard();
    const isPopupActive = isActive(PopupEnum.LEADERBOARD);
    const leaderboardStatus = useSelector((state: RootState) => state.leaderboard.status);
    const previousLeaderboardStatus = useSelector((state: RootState) => state.leaderboard.previousLeaderboardStatus);
    const [containerNode, containerRef] = useNodeRef();
    const [mode, setMode] = useState<"current" | "previous">("current");
    const leaderboard = useMemo(() => {
        return mode === "current" ? currentLeaderboard : previousLeaderboard;
    }, [currentLeaderboard, mode, previousLeaderboard]);
    const accountEntry = useMemo(() => {
        return mode === "current" ? currentAccountEntry : previousAccountEntry;
    }, [currentAccountEntry, mode, previousAccountEntry]);
    const isTopN = useMemo(() => {
        return mode === "current" ? isCurrentTopN : isPreviousTopN;
    }, [isCurrentTopN, isPreviousTopN, mode]);
    const status = useMemo(() => {
        return mode === "current" ? leaderboardStatus : previousLeaderboardStatus;
    }, [leaderboardStatus, mode, previousLeaderboardStatus]);

    const listHeight = useMemo(() => {
        return containerNode?.clientHeight ?? 300;
    }, [containerNode]);


    useEffect(() => {
        if (!account) {return;}
        if (leaderboardStatus !== "loading") {
            fetchAccountOnLeaderboard(account.primary_wallet);
        }
        if (previousLeaderboardStatus !== "loading") {
            fetchAccountOnPreviousLeaderboard(account.primary_wallet);
        }
    }, [account]);


    return (
        <Popup
            open={isPopupActive}
            setOpen={() => closePopup(PopupEnum.LEADERBOARD)}
            widthClass="w-full md:max-w-[26rem]"
            paddingClass="p-0"
        >
            <div className="flex flex-col w-full h-full pt-12 pb-2">
                <div className="flex flex-col items-start w-full space-y-1 mb-6 px-6">
                <div
                    className="flex items-center justify-between w-full"
                >
                    <div className="flex items-center justify-start space-x-3">
                        <h2 className="text-xl text-theme-text font-bold">
                            Leaderboard
                        </h2>
                        <button
                            className="rounded-full p-1 focus:outline-none focus:ring-primary-darkest group"
                            onClick={() => {
                                if (!account) {return;}
                                fetchAccountOnLeaderboard(account.primary_wallet);
                            }}
                        >
                            <AiOutlineReload
                                className="w-4 h-4 text-primary-darkest group-hover:text-primary-dark"
                            />
                        </button>
                    </div>
                    {previousLeaderboard &&
                        <div className="flex items-center justify-end space-x-3">
                            <button
                                className={classNames(
                                    "text-sm font-semibold",
                                    mode === "current" ? "text-theme-text" : "text-theme-text-light"
                                )}
                                onClick={() => setMode("current")}
                            >
                                Active
                            </button>
                            <button
                                className={classNames(
                                    "text-sm font-semibold",
                                    mode === "previous" ? "text-theme-text" : "text-theme-text-light"
                                )}
                                onClick={() => setMode("previous")}
                            >
                                Previous
                            </button>
                        </div>
                    }
                </div>
                    {currentLeaderboard && mode === "current" && (
                        <Countdown
                            date={currentLeaderboard.segment.end_date}
                            renderer={({completed, ...rest}) => {
                                if (completed) {
                                    return <span>Ended</span>;
                                }
                                return (
                                    <div
                                        className="text-xs font-semibold flex items-center justify-center">
                                        {formatCountdownText({
                                            ...rest,
                                            separator: " : ",
                                        })}
                                    </div>
                                )
                            }}/>
                    )}
                </div>
                <div
                    ref={containerRef}
                    className="flex flex-col flex-grow"
                >
                    <Scrollbar
                        autoHeight={false}
                        minHeight={listHeight + "px"}
                    >
                        <ul role="list"
                            className="divide-y divide-secondary w-full border-t border-b border-secondary ">
                            {!leaderboard && leaderboardStatus === "loading" && (
                                [Array.from({length: 10}).map((_, index) => (
                                    <LoadingRow key={index}/>
                                ))]
                            )}
                            {leaderboard && leaderboard.entries.map((entry) => <EntryItem
                                key={entry.rank}
                                entry={entry}
                                loading={status === "loading"}
                                nbWinners={leaderboard.segment.nb_winners}
                                isCurrentAccount={accountEntry?.account_id === entry.account_id}
                            />)}
                            {leaderboard && accountEntry && !isTopN && (
                                <EntryItem
                                    entry={accountEntry}
                                    loading={status === "loading"}
                                    isCurrentAccount={true}
                                    nbWinners={leaderboard.segment.nb_winners}
                                    addBorder={accountEntry.rank > leaderboard.entries.length + 1}
                                />
                            )}
                        </ul>
                    </Scrollbar>
                </div>
                <LeaderboardPrizeClaim/>
            </div>
        </Popup>
    );
};

function EntryItem({entry, loading, isCurrentAccount, addBorder, nbWinners}: {
    entry: LeaderboardEntry;
    loading: boolean;
    isCurrentAccount: boolean;
    nbWinners: number;
    addBorder?: boolean;
}) {
    return (
        <li>
            <div className={classNames(
                "flex items-center justify-between py-2 px-4 w-full",
                loading ? "animate-pulse" : "",
                isCurrentAccount ? "bg-[#FAF8DF]" : "",
                addBorder ? "border-t-2 border-secondary" : ""
            )}>
                <div className="flex items-center">
                    <div className={classNames(
                        "rounded-full p-1 w-5 h-5 flex items-center justify-center mr-6",
                        entry.rank <= nbWinners ? `${getBgClass(entry.rank)}` : ""
                    )}>
                        <span className={classNames(
                            "text-xs",
                            entry.rank <= 3 ? "text-white" : "text-theme-text"
                        )}>
                            {entry.rank}
                        </span>
                    </div>
                    <Avatar
                        width="10"
                        extraClass={getBorderClass(entry.rank)}
                    />
                    <a
                        href={`https://explorer.multiversx.com/accounts/${entry.account.primary_wallet}`}
                        target="_blank"
                        className={classNames("ml-4 text-sm", `${getTextClass(entry.rank)}`)}
                    >
                      {isCurrentAccount ? "Me" : formatAddress(entry.account.primary_wallet)}
                    </a>
                    {entry.rank <= nbWinners && (
                        <div className="flex items-center space-x-1">
                         <span className="ml-4 text-sm text-theme-text">
                             {formatAmount(
                                 BigNumber(computePrizeAmount(entry.rank, nbWinners))
                                     .shiftedBy(-18)
                                     .toNumber(),
                                 1,
                                 true
                             )}
                         </span>
                            <IconGiantCurrency className="w-4 h-4"/>
                        </div>
                    )}
                </div>
                <span className="text-sm text-theme-text">
                    {entry.score.toLocaleString()}
                </span>
            </div>
        </li>
    );
}

function getBorderClass(rank: number) {
    if (rank === 1) {
        return "border-2 border-[#FFC200]";
    } else if (rank === 2) {
        return "border-2 border-[#D3D0C9]";
    } else if (rank === 3) {
        return "border-2 border-[#DB8A3E]";
    }
    return "";
}

function getBgClass(rank: number) {
    if (rank === 1) {
        return "bg-[#FFC200]";
    } else if (rank === 2) {
        return "bg-[#D3D0C9]";
    } else if (rank === 3) {
        return "bg-[#DB8A3E]";
    }
    return "";
}

function getTextClass(rank: number) {
    if (rank === 1) {
        return "text-[#FFC200]";
    } else if (rank === 2) {
        return "text-[#D3D0C9]";
    } else if (rank === 3) {
        return "text-[#DB8A3E]";
    }
    return "";

}

function LoadingRow() {
    return (
        <li className="">

        </li>
    );
}