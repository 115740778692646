import Wallet from "../../auth/Wallet";
import {GameActionEnum, GameActionKey, TxActionPayload} from "../../common/types/actions";
import TxAction from "./TxAction";
import {ChainConfig} from "../../common/types";
import ActionContext from "../contexts/ActionContext";

export default class TxActionBuilder {
    public async build(
        wallet: Wallet,
        action: ActionContext<GameActionKey>,
        chainConfig: ChainConfig
    ): Promise<TxAction<GameActionKey>> {
        return await this.loadAction(action, wallet, chainConfig);
    }

    protected async loadAction(
        actionContext: ActionContext<GameActionKey>,
        wallet: Wallet,
        chainConfig: ChainConfig
    ): Promise<TxAction<GameActionKey>> {
        try {
            const filename = `${actionContext.actionName}Tx`;
            const action = await import(`../game-actions/${filename}`);

            return new action.default(wallet, actionContext.payload, chainConfig);
        } catch (e) {
            throw new Error(`No Tx Action Class was found for action: ${actionContext.actionName}`);
        }
    }
};