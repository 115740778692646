import ActionContext from "./ActionContext";
import {gameAction} from "../../config/actions";

export default class RemoveBuildingContext extends ActionContext<"REMOVE_BUILDING"> {
    protected type = gameAction.REMOVE_BUILDING;
    protected hasTransaction = true;


    public getUsedItems(): Record<string, number> {
        return {};
    }

};