import {
    fetchAccountOnLeaderboard as fetchAccountOnLeaderboardAction,
    fetchClaimablePrizesAmount as fetchClaimablePrizesAmountAction,
    fetchAccountOnPreviousLeaderboard as fetchAccountOnPreviousLeaderboardAction,
    setClaiming as setClaimingAction
} from "../redux/slices/leaderboardSlice";
import {
    GetClaimablePrizesResponse,
    ShowAccountOnLeaderboardResponse
} from "../common/types/responses";
import {
    getLeaderboardClaimablePrizesPath,
    showLeaderboardPath,
    showPreviousLeaderboardPath
} from "../utils/api-routes";
import {useApi} from "./useApi";
import {useAuth} from "./useAuth";
import {useAppDispatch} from "./useStore";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {useMemo} from "react";

export const useLeaderboard = () => {
    const {api} = useApi();
    const {account} = useAuth();
    const dispatch = useAppDispatch();
    const leaderboard = useSelector((state: RootState) => state.leaderboard.leaderboard);
    const accountEntry = useSelector((state: RootState) => state.leaderboard.accountEntry);
    const claimablePrizesAmount = useSelector((state: RootState) => state.leaderboard.claimablePrizesAmount);
    const isTopN = useMemo(() => {
        if (!leaderboard || !accountEntry) {return false;}
        return accountEntry.rank <= leaderboard.entries.length;
    }, [leaderboard, accountEntry]);
    const previousLeaderboard = useSelector((state: RootState) => state.leaderboard.previousLeaderboard);
    const previousAccountEntry = useSelector((state: RootState) => state.leaderboard.previousAccountEntry);
    const isPreviousTopN = useMemo(() => {
        if (!previousLeaderboard || !previousAccountEntry) {return false;}
        return previousAccountEntry.rank <= previousLeaderboard.entries.length;
    }, [previousLeaderboard, previousAccountEntry]);
    const claiming = useSelector((state: RootState) => state.leaderboard.claiming);

    const fetchAccountOnLeaderboard = async (address: string) => {
        dispatch(fetchAccountOnLeaderboardAction({
            fetch: async () => {
                const {data} = await api.get<ShowAccountOnLeaderboardResponse>(showLeaderboardPath(), {
                    params: {address}
                });

                return data;
            }
        }))
    };

    const fetchAccountOnPreviousLeaderboard = async (address: string) => {
        dispatch(fetchAccountOnPreviousLeaderboardAction({
            fetch: async () => {
                const {data} = await api.get<ShowAccountOnLeaderboardResponse>(showPreviousLeaderboardPath(), {
                    params: {address}
                });

                return data;
            }
        }))
    }


    const fetchClaimablePrizesAmount = async () => {
        dispatch(fetchClaimablePrizesAmountAction({
            fetch: async () => {
                const {data} = await api.get<GetClaimablePrizesResponse>(
                    getLeaderboardClaimablePrizesPath()
                );

                return data.amount;
            }
        }));
    };

    const setClaiming = (claiming: boolean) => {
        dispatch(setClaimingAction(claiming));
    }

    return {
        leaderboard,
        accountEntry,
        isTopN,
        claimablePrizesAmount,
        claiming,
        previousLeaderboard,
        previousAccountEntry,
        isPreviousTopN,
        fetchAccountOnLeaderboard,
        fetchAccountOnPreviousLeaderboard,
        fetchClaimablePrizesAmount,
        setClaiming
    }
};