import {
    Container,
    FederatedPointerEvent,
    Graphics,
    IDestroyOptions,
    ISize,
    Sprite,
    Texture
} from "pixi.js";
import GridAsset from "./components/GridAsset";
import {IPosition, ScaleSize} from "../common/types/gameboard";
import Manager from "./Manager";
import ConfirmationMenu from "./components/menu/ConfirmationMenu";

type PlaceAssetHandler = () => void
export default class PositionAssetContainer extends Container {
    asset: GridAsset;
    protected onMovingComplete: PlaceAssetHandler;
    protected lastMovement = 0;
    protected moving = false;
    protected menu: ConfirmationMenu;
    protected canConfirmPlacement = true;
    protected offset: IPosition;

    constructor(asset: GridAsset, onMovingComplete: PlaceAssetHandler) {
        super();
        this.onMovingComplete = onMovingComplete;
        const manager = Manager.getInstance()
        const screen = manager.screenSize;
        this.offset = manager.stagePosition;
        this.width = screen.width;
        this.height = screen.height;
        this.asset = asset;

        this.asset.setScreenPosition({
            x: (screen.width - asset.screenWidth) / 2,
            y: (screen.height - asset.screenHeight) / 2,
        });
        this.asset.sprite.cursor = "pointer"
        this.asset.sprite.on("pointerdown", this.onPointerDown, this);
        this.asset.sprite.on("pointerup", this.onPointerUp, this);
        this.asset.sprite.on("pointermove", this.onPlaceObjectMove, this);

        this.menu = new ConfirmationMenu(this.onConfirm.bind(this), this.onCancel.bind(this));
        this.computeMenuPosition();
        this.setCanConfirm(false);

        this.addChild(this.asset.sprite);
        this.addChild(this.menu);
    }

    public moved(): boolean {
        // todo: find a better way to achieve this
        return Date.now() - this.lastMovement < 100;
    }

    public setCanConfirm(value: boolean = true) {
        if (this.canConfirmPlacement === value) {return;}
        this.canConfirmPlacement = value;
        this.menu.setCanConfirm(value);

        return this;
    }

    public setAssetPosition({screen, grid}: { screen: IPosition, grid: IPosition }) {
        this.asset.setScreenPosition({
            x: screen.x ,
            y: screen.y ,
        });
        this.asset.position = grid;
        this.computeMenuPosition();
    }

    public onCameraMove({scale, position}: {
        scale: ScaleSize,
        position: IPosition
    }): void {
        this.offset = position;
        // this.asset.setScale(scale.x);
    }

    public onZoom({scale}: {
        scale: ScaleSize,
    }): void {
        // this.asset.setScale(scale.x);
    }

    protected onPlaceObjectMove(e: FederatedPointerEvent) {
        if (!this.moving) {return;}
        e.stopPropagation();
        const pointerPosition = this.toLocal({x: e.x, y: e.y});
        this.lastMovement = Date.now();
        this.asset.setScreenPosition({
            x: pointerPosition.x - this.asset.screenWidth / 2 ,
            y: pointerPosition.y  - (this.asset.sprite.height - this.asset.screenHeight / 2)
        });
        this.computeMenuPosition();
    }

    protected onPointerDown(e: FederatedPointerEvent) {
        e.stopPropagation();
        this.moving = true;
        this.onPlaceObjectMove(e);
    }

    protected onPointerUp(e: FederatedPointerEvent) {
        this.moving = false;
        this.onMovingComplete();
    }

    protected computeMenuPosition() {
        let x = this.asset.screenX + this.asset.screenWidth - this.menu.width - 20;
        let y = this.asset.screenY + 1/3 * this.asset.screenHeight;
        // if (y < Math.abs(this.offset.y)) {
        //    y = Math.abs(this.offset.y) + 10;
        // }
        this.menu.x = x;
        this.menu.y = y;

    }

    protected onCancel() {
        Manager.getInstance().publishEvent("CANCEL_PLACE_ASSET", {});
    }

    protected onConfirm() {
        Manager.getInstance().publishEvent("CONFIRM_PLACE_ASSET", {asset: this.asset});
    }

};