import {useAppDispatch} from "./useStore";
import {PopupEnum, PopupState} from "../common/types/components";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {
    showPopup as showPopupAction,
    closePopup as closePopupAction
} from "../redux/slices/popupManagementSlice";


type StateOptions = Omit<PopupState, "type" | "state"> & {state?: any};
export const usePopups = () => {
    const activePopup = useSelector((state: RootState) => state.popupManagement.active);
    const popupItems = useSelector((state: RootState) => state.popupManagement.items);
    const dispatch = useAppDispatch();
    const isActive = (popup: PopupEnum): boolean => {
        return activePopup?.type === popup;
    };
    const isIdle = (popup: PopupEnum): boolean => {
        return popupItems.find(item => item.type === popup) !== undefined;
    };
    const showPopup = (popup: PopupEnum, options?: StateOptions) => {
        if (isActive(popup)) {
            return;
        }
        dispatch(showPopupAction({type: popup, state: {}, ...options}));
    };
    const closePopup = (popup: PopupEnum) => {
        dispatch(closePopupAction(popup));
    };
    const popupState = (popup: PopupEnum): PopupState & { idle: boolean } | undefined => {
        if (isActive(popup)) {
            return {...activePopup!, idle: false};
        }
        const item = popupItems.find(item => item.type === popup);
        if (item) {
            return {...item, idle: true};
        }
    }

    return {showPopup, closePopup, isActive, isIdle, popupState};

};