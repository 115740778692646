import {useState} from "react";
import Loader from "./shared/Loader";
import {useSelector} from "react-redux";
import {
    fetchLandTiles,
    fetchRewards,
    filterInventoryAssets,
    selectCanRequestAssets
} from "../redux/slices/inventorySlice";
import {
    Address, AddressValue,
    BigUIntValue,
    ContractFunction,
    Interaction, List, ListType,
    SmartContract
} from "@multiversx/sdk-core/out";
import {assetsTransactionsData} from "../utils/transferRegisterAssets";
import {useAuth} from "../hooks/useAuth";
import {Nonce} from "@multiversx/sdk-network-providers/out/primitives";
import {NetworkEnv} from "../common/types";
import {ApiNetworkProvider} from "@multiversx/sdk-network-providers/out";
import {network} from "../config/network";
import {useTransaction} from "../hooks/useTransaction";
import {Hash} from "@multiversx/sdk-core/out/hash";
import {addOrReplaceTransaction} from "../redux/slices/transactionsSlice";
import {useAppDispatch} from "../hooks/useStore";
import {buildExternalMintItem, ExternalMintItemType} from "../utils/customTypes";

export default function RequestAssetsButton({enabled}: { enabled: boolean }) {
    const [loading, setLoading] = useState(false);
    const canRequest = useSelector(selectCanRequestAssets);
    const {wallet, env} = useAuth();
    const {awaitCompleted} = useTransaction();
    const dispatch = useAppDispatch();


    const requestAssets = async () => {
        setLoading(true);
        try {
            await wallet!.refresh();
            const nonce = wallet!.nonce;
            const contract = new SmartContract({address: new Address(process.env.NEXT_PUBLIC_FAUCET_CONTRACT!)});
            const args = assetsTransactionsData.map(
                ({address, method, qty}) => buildExternalMintItem(
                    address,
                    method,
                    wallet!.address,
                    qty,
                )
            );
            const gasLimit = assetsTransactionsData.reduce(
                (acc, {gasLimit}) => acc + gasLimit, 100_000
            );
            const tx = new Interaction(
                contract,
                new ContractFunction("mintGameAssets"),
                [new List(new ListType(ExternalMintItemType), args)]
            )
                .buildTransaction();
            tx.setSender(new Address(wallet!.address));
            tx.setChainID(network[env].chainId);
            tx.setNonce(new Nonce(nonce));
            tx.setGasLimit(gasLimit);
            const signedTx = wallet!.signTransaction(tx);
            const provider = new ApiNetworkProvider(
                network[env]["api"],
                {timeout: 1000 * 15}
            );
            const txHash = await provider.sendTransaction(signedTx);
            dispatch(addOrReplaceTransaction({
                id: txHash,
                action: "Request assets",
                status: "pending",
                hash: txHash,
                timestamp: Date.now(),
            }));
            const {status} = await awaitCompleted(
                {getHash: () => new Hash(txHash)},
                provider,
                {patienceMilliseconds: 2000}
            );
            dispatch(addOrReplaceTransaction({
                id: txHash,
                action: "Request assets",
                status,
                hash: txHash,
                timestamp: Date.now(),
            }));
        } finally {
            setLoading(false);
            dispatch(fetchRewards({address: wallet!.address}));
            dispatch(filterInventoryAssets({type: "assets"}));
            dispatch(fetchLandTiles());
        }
    }

    if (process.env.NEXT_PUBLIC_NETWORK_ENV !== "devnet") {
        return null;
    }

    if (!enabled) {
        return null;
    }

    if (loading) {
        return (
            <div className="flex items-center gap-2">
                <span className="text-sm">
                    Requesting assets...
                </span>
                <Loader size="small"/>
            </div>
        )
    }

    return (
        <button
            className="text-theme-text focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            onClick={requestAssets}
        >
            Request assets
        </button>
    );
}