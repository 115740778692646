import {GetHarvestedResourcesResponse, GetLatestClaimResponse} from "../common/types/responses";
import {getLatestClaimPath, villageHarvestStatePath} from "../utils/api-routes";
import {
    fetchHarvestingState,
    fetchLatestClaim as fetchLatestClaimAction
} from "../redux/slices/resourcesSlice";
import {useApi} from "./useApi";
import {useAppDispatch} from "./useStore";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";

export const useFarmResources = () => {
    const {api} = useApi();
    const dispatch = useAppDispatch();
    const latestClaimFetchingStatus = useSelector((state: RootState) => state.resources.claimFetchStatus);
    const harvestStateFetchingStatus = useSelector((state: RootState) => state.resources.status);

    const fetchLatestClaim = async () => {
        const fetch = async () => {
            const {data: {claim}} = await api.get<GetLatestClaimResponse>(getLatestClaimPath());
            return claim;
        };
        dispatch(fetchLatestClaimAction({fetch}));
    };

    const fetchHarvestedResources = async () => {
        const fetch = async () => {
            const {data: {resources}} = await api.get<GetHarvestedResourcesResponse>(villageHarvestStatePath());
            return resources;
        };
        dispatch(fetchHarvestingState({fetch}));
    }

    return {
        latestClaimFetchingStatus,
        harvestStateFetchingStatus,
        fetchLatestClaim,
        fetchHarvestedResources
    };
}