import {useAppDispatch} from "../../../hooks/useStore";
import {RootState} from "../../../redux/store";
import {useSelector} from "react-redux";
import {useEffect, useMemo, useState} from "react";
import {
    fetchReferralRewards,
    fetchVillageLevels,
    selectClaimableReferralRewards,
    selectHasAnyReferralItems,
    fetchNbReferrals as fetchNbReferralsAction,
    selectClaimableAmount,
    fetchClaimableAmount as fetchClaimableAmountAction,
    fetchReferralSpendingClaim,
    setReferralSpendingClaim
} from "../../../redux/slices/referralRewardsSlice";
import {useApi} from "../../../hooks/useApi";
import {
    createSpendingClaimPath,
    getClaimableAmountPath, getCurrentSpendingClaimPath,
    getNbReferralsPath,
    referralRewardsPath,
    villageLevelsPath
} from "../../../utils/api-routes";
import Popup from "../../shared/Popup";
import Manager from "../../../gameboard/Manager";
import {AssetEventPayload, GameActionEnum} from "../../../common/types/actions";
import {useAuth} from "../../../hooks/useAuth";
import {usePopups} from "../../../hooks/usePopups";
import {PopupEnum} from "../../../common/types/components";
import IconCopy from "../../icons/IconCopy";
import IconHourGlass from "../../icons/IconHourGlass";
import RewardsList from "./RewardsList";
import ActionButton from "../../shared/ActionButton";
import {formatAmount} from "../../../utils/presentation";
import IconGiantCurrency from "../../icons/IconGiantCurrency";
import {ReferralSpendingsClaim} from "@elrond-giants/game-db/types/entities";
import {CreateReferralSpendingClaimResponse} from "../../../common/types/responses";
import ClaimReferralSpendingsContext
    from "../../../services/contexts/ClaimReferralSpendingsContext";
import {gameAction} from "../../../config/actions";
import BigNumber from "bignumber.js";

export default function ReferralsPopup() {
    const dispatch = useAppDispatch();
    const {isActive, closePopup} = usePopups();
    const {api} = useApi();
    const {account, village} = useAuth();
    const isPopupActive = isActive(PopupEnum.REFERRAL_REWARDS);
    const status = useSelector((state: RootState) => state.referralRewards.status);
    const [showCopy, setShowCopy] = useState(false);
    const nbReferrals = useSelector((state: RootState) => state.referralRewards.nbReferrals);
    const nbEligibleReferrals = useSelector((state: RootState) => state.referralRewards.nbEligible);
    const claimableAmount = useSelector(selectClaimableAmount);
    const currentClaim = useSelector((state: RootState) => state.referralRewards.referralSpendingClaim);
    const [loading, setLoading] = useState(false);
    const pending = useMemo(() => {
        if (loading) {return true;}

        return currentClaim?.status === "pending";

    }, [currentClaim, loading]);
    const canClaim = useMemo(() => {
        if (currentClaim) {
            return currentClaim.status !== "pending";
        }

        return !claimableAmount.isZero();
    }, [claimableAmount, currentClaim]);
    const amountToClaim = useMemo(() => {
        if (currentClaim) {
            return new BigNumber(currentClaim.amount);
        }

        return claimableAmount;
    }, [claimableAmount, currentClaim]);


    const gameManager = Manager.getInstance();
    const fetchRewards = () => {
        dispatch(fetchReferralRewards({
            fetch: async () => {
                const {data} = await api.get(referralRewardsPath());

                return data;
            },
        }));
    };
    const fetchLevels = () => {
        dispatch(fetchVillageLevels({
            fetch: async () => {
                const {data} = await api.get(villageLevelsPath());

                return data;
            },
        }))
    };

    const fetchNbReferrals = () => {
        dispatch(fetchNbReferralsAction({
            fetch: async () => {
                const {data} = await api.get(getNbReferralsPath());

                return data;
            },
        }));

    }

    const fetchClaimableAmount = () => {
        dispatch(fetchClaimableAmountAction({
            fetch: async () => {
                const {data} = await api.get(getClaimableAmountPath());

                return data;
            },
        }));
    }

    const fetchCurrentClaim = () => {
        dispatch(fetchReferralSpendingClaim({
            fetch: async () => {
                const {data} = await api.get(getCurrentSpendingClaimPath());

                return data;
            },
        }));
    }

    const fetchSpendingReferralInfo = () => {
        fetchCurrentClaim();
        fetchNbReferrals();
        fetchClaimableAmount();
    }

    const claim = async () => {
        if (loading) {return;}
        setLoading(true);
        let claim: ReferralSpendingsClaim;
        if (currentClaim) {
            claim = currentClaim;
        } else {
            const {data: {claim: _claim}} = await api.post<CreateReferralSpendingClaimResponse>(
                createSpendingClaimPath()
            );
            claim = _claim;
            dispatch(setReferralSpendingClaim({
                claim: {
                    ...claim,
                    status: "pending"
                }
            }));
        }
        const actionContext = new ClaimReferralSpendingsContext({
            claim,
            villageId: village!.id
        });
        await gameManager.commitAction(actionContext);
        setLoading(false);
    }


    useEffect(() => {
        if (isPopupActive && ["idle", "complete"].includes(status)) {
            fetchSpendingReferralInfo();
        }
    }, [isPopupActive]);

    useEffect(() => {
        if (!gameManager.initialised) {return;}
        const actionHandler = () => {
            fetchSpendingReferralInfo();
        };
        const eventHandler = (event: AssetEventPayload) => {
            if (event.action.action === gameAction.CLAIM_REFERRAL_SPENDINGS) {
                fetchSpendingReferralInfo();
            }
        }
        gameManager
            .subscribeToAction("GAME_ACTION_COMMIT_SUCCESS", actionHandler)
            .subscribeToAction("GAME_ACTION_COMMIT_ERROR", actionHandler)
            .subscribeToAction("GAME_ACTION", eventHandler);

        return () => {
            gameManager
                .unsubscribeFromAction("GAME_ACTION_COMMIT_SUCCESS", actionHandler)
                .unsubscribeFromAction("GAME_ACTION_COMMIT_ERROR", actionHandler)
                .unsubscribeFromAction("GAME_ACTION", eventHandler);
        }
    }, [gameManager.initialised]);


    return (
        <Popup
            open={isPopupActive}
            setOpen={() => {closePopup(PopupEnum.REFERRAL_REWARDS)}}
            widthClass="w-full md:max-w-[28rem]"
            paddingClass="p-0"
        >
            <div
                className="flex flex-col items-center pt-10 px-4 md:px-6 pb-2 h-full min-h-[60svh]">
                <h2 className="font-medium text-xl">Referral URL</h2>
                <p className="text-theme-text-light mt-2 text-center">
                    Share your unique url with your friends to earn rewards
                </p>
                <div className="flex items-center justify-center w-full mt-4">
                        <div
                            className="flex items-center justify-between w-full py-1 px-3 border border-secondary bg-white relative"
                        >
                            <span
                                className="text-lg text-theme-text-light w-full select-all"
                            >
                                {`${window.location.origin}?referral=${account!.referral_code}`}
                            </span>
                            {showCopy ? (
                                <span className="text-green-100 bg-[#80B800] rounded-full p-0.5 text-sm absolute right-2 top-1/2 transform -translate-y-1/2">
                                    copied
                                </span>
                            ) : (
                                <button
                                    className="flex items-center justify-center rounded-full absolute right-2 top-1/2 transform -translate-y-1/2"
                                    onClick={async () => {
                                        const url = `${window.location.origin}?referral=${account!.referral_code}`;
                                        await navigator.clipboard.writeText(url);
                                        setShowCopy(true);
                                        setTimeout(() => {
                                            setShowCopy(false);
                                        }, 1000);
                                    }}
                                >
                                    <IconCopy className="w-5 h-5"/>
                                </button>
                            )}
                        </div>
                </div>
                <div className="flex items-center justify-center space-x-2 mt-4 w-full ">
                    <IconHourGlass className="w-5 h-5"/>
                    <p className="text-theme-text-light">
                        Friends invited: <span className="text-[#FF8A17]">{nbReferrals}</span>
                    </p>
                    <p className="text-theme-text-light">
                        Eligible referrals: <span className="text-[#FF8A17]">{nbEligibleReferrals}</span>
                    </p>
                </div>
                <div className="flex flex-col justify-end items-center flex-grow w-full">
                    <ActionButton
                        onClick={claim}
                        disabled={pending || !canClaim}
                    >
                        {pending && "Claiming..."}
                        {!pending && amountToClaim.isZero() && "No rewards to claim"}
                        {!pending && !amountToClaim.isZero() &&
                            <span className="flex items-center space-x-2">
                                <span>Claim</span>
                                <span>
                                {formatAmount(
                                    amountToClaim.shiftedBy(-18).toNumber(),
                                    2
                                )}
                                </span>
                                <IconGiantCurrency className="w-5 h-5"/>
                            </span>
                        }
                    </ActionButton>
                </div>
                {/*{hasRewards && <>*/}
                {/*    <h3 className="font-medium text-xl mt-6 mb-3">Rewards</h3>*/}
                {/*    <RewardsList/>*/}
                {/*</>}*/}

            </div>
        </Popup>
    );
};