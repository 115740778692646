import {SVGProps} from "react";

export default function IconUpgradingBuildings({...props}: SVGProps<any>) {
    return (
        <svg viewBox="0 0 22 22" fill="none"
             xmlns="http://www.w3.org/2000/svg"
             {...props}
        >
            <circle cx="11" cy="11" r="7" fill="#F4E84E"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.11932 10.219L10.7119 8.09763C10.871 7.96746 11.129 7.96746 11.2881 8.09763L13.8807 10.219C14.0398 10.3491 14.0398 10.5602 13.8807 10.6904C13.7216 10.8205 13.4636 10.8205 13.3045 10.6904L11.4074 9.13807L11.4074 13.6667C11.4074 13.8508 11.225 14 11 14C10.775 14 10.5926 13.8508 10.5926 13.6667L10.5926 9.13807L8.69546 10.6904C8.53636 10.8205 8.27842 10.8205 8.11932 10.6904C7.96023 10.5602 7.96023 10.3491 8.11932 10.219Z"
                  fill="#595952"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7.75 10.4547C7.75 10.288 7.82855 10.1338 7.96101 10.0255L10.5536 7.90414C10.8048 7.69862 11.1952 7.69862 11.4464 7.90414L14.039 10.0255C14.1715 10.1338 14.25 10.288 14.25 10.4547C14.25 10.6213 14.1715 10.7755 14.039 10.8838C13.7878 11.0894 13.3974 11.0894 13.1462 10.8838L11.6574 9.66565L11.6574 13.6667C11.6574 14.0334 11.3138 14.25 11 14.25C10.6862 14.25 10.3426 14.0334 10.3426 13.6667L10.3426 9.66565L8.85377 10.8838C8.60258 11.0894 8.2122 11.0894 7.96101 10.8838C7.82855 10.7755 7.75 10.6213 7.75 10.4547ZM8.25 10.4547C8.25 10.4586 8.251 10.4751 8.27764 10.4969C8.34464 10.5517 8.47014 10.5517 8.53714 10.4969L10.8426 8.6105L10.8426 13.6667C10.8426 13.6714 10.8443 13.6877 10.8702 13.7089C10.8967 13.7305 10.9417 13.75 11 13.75C11.0583 13.75 11.1033 13.7305 11.1298 13.7089C11.1557 13.6877 11.1574 13.6714 11.1574 13.6667L11.1574 8.6105L13.4629 10.4969C13.5299 10.5517 13.6554 10.5517 13.7224 10.4969C13.749 10.4751 13.75 10.4586 13.75 10.4547C13.75 10.4507 13.749 10.4342 13.7224 10.4124L11.1298 8.29112C11.0628 8.23629 10.9372 8.23629 10.8702 8.29112L8.27764 10.4124C8.251 10.4342 8.25 10.4507 8.25 10.4547Z"
                  fill="#595952"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M18.7731 16.1772C19.4375 15.1815 19.9157 14.0512 20.158 12.8357H21.0001C21.5524 12.8357 22.0001 12.388 22.0001 11.8357V10.1644C22.0001 9.61209 21.5524 9.16438 21.0001 9.16438H20.1579C19.9156 7.94898 19.4375 6.81855 18.7731 5.82303L19.3691 5.22696C19.7597 4.83644 19.7597 4.20328 19.3691 3.81275L18.1874 2.63096C17.7969 2.24043 17.1637 2.24043 16.7732 2.63095L16.1771 3.22701C15.1815 2.56264 14.0512 2.08438 12.8357 1.84207V1C12.8357 0.447715 12.388 0 11.8357 0H10.1644C9.61215 0 9.16444 0.447716 9.16444 1V1.84207C7.94892 2.08444 6.81842 2.56245 5.8229 3.22694L5.22623 2.59283C4.8396 2.18194 4.1898 2.17205 3.79085 2.57099L2.57094 3.79088C2.17197 4.18984 2.18186 4.83968 2.59279 5.22631L3.22694 5.82296C2.56257 6.81861 2.08432 7.94892 1.842 9.16444L1.08892 9.09721C0.50386 9.04498 0 9.50586 0 10.0932V11.8358C0 12.388 0.447715 12.8358 0.999999 12.8358H1.84194C2.08432 14.0512 2.56239 15.1817 3.22688 16.1773L2.63094 16.7732C2.24042 17.1638 2.24042 17.7969 2.63094 18.1874L3.81271 19.3692C4.20322 19.7597 4.83638 19.7597 5.2269 19.3692L5.8229 18.7732C6.81855 19.4377 7.94886 19.9159 9.16444 20.1582V21.0001C9.16444 21.5524 9.61215 22.0001 10.1644 22.0001H11.8357C12.388 22.0001 12.8357 21.5524 12.8357 21.0001V20.1582C14.0511 19.9157 15.1816 19.4377 16.1772 18.7732L16.7731 19.3692C17.1636 19.7597 17.7968 19.7597 18.1873 19.3692L19.3691 18.1874C19.7596 17.7969 19.7597 17.1637 19.3691 16.7732L18.7731 16.1772ZM10.7907 17.2169C14.2058 17.2169 16.9743 14.4484 16.9743 11.0333C16.9743 7.61829 14.2058 4.84984 10.7907 4.84984C7.37569 4.84984 4.60724 7.61829 4.60724 11.0333C4.60724 14.4484 7.37569 17.2169 10.7907 17.2169Z"
                  fill="#595952"/>
        </svg>

    );
};