import {useApi} from "./useApi";
import {useAppDispatch} from "./useStore";
import {fetchVillageResidents, setVillage as setVillageAction} from "../redux/slices/residentsSlice";
import {useAuth} from "./useAuth";
import {useEffect, useMemo} from "react";
import {
    villageInfoPath,
    villageLandQtyPath,
    villageResidentsPath,
    villageUpgradingBuildingsQtyPath
} from "../utils/api-routes";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {
    GetUpgradingBuildingsQtyResponse,
    VillageInfoResponse,
    VillageLandQtyResponse,
    VillageResidentsResponse
} from "../common/types/responses";
import {
    fetchPlacedLandQty,
    fetchUpgradingBuildingsQty,
    increaseUpgradingBuildingsQty as increaseUpgradingBuildingsQtyAction,
    decreaseUpgradingBuildingsQty as decreaseUpgradingBuildingsQtyAction,
    increasePlacedBuildingsQty as increasePlacedBuildingsQtyAction,
    decreasePlacedBuildingsQty as decreasePlacedBuildingsQtyAction
} from "../redux/slices/villageSlice";
import {selectTilesQty} from "../redux/slices/inventorySlice";
import {
    computeMaxLandQty,
    getMaxUpgradingBuildingsQty
} from "../utils/game";

export const useVillageState = () => {
    const {api} = useApi();
    const {village, setVillage: setAuthVillage} = useAuth();
    const dispatch = useAppDispatch();
    const villageResidents = useSelector((state: RootState) => state.residents.residents);
    const villageResidentsQty = useMemo(
        () => villageResidents.length,
        [villageResidents]
    );
    const availableTilesQty = useSelector(selectTilesQty);
    const placedTilesQty = useSelector((state: RootState) => state.village.placedLandQty);
    const maxVillageLandQty = useMemo(() => {
        return computeMaxLandQty(village?.level ?? 0, placedTilesQty, availableTilesQty);
    }, [availableTilesQty, village, village?.level, placedTilesQty]);
    const upgradingBuildingsQty = useSelector((state: RootState) => state.village.upgradingBuildingsQty);
    const placedBuildingsQty = useSelector((state: RootState) => state.village.placedBuildingsQty);
    const maxUpgradingBuildingsQty = useMemo(() => {
        return getMaxUpgradingBuildingsQty(placedBuildingsQty);
    }, [placedBuildingsQty]);


    const setVillage = (village: {id: string, ownerId:string} | null) => {
        dispatch(setVillageAction(village));
    }

    const loadVillageResidents = () => dispatch(fetchVillageResidents({
        fetch: async (villageId: string) => {
            const {data} = await api.get<VillageResidentsResponse>(villageResidentsPath(villageId));

            return data;
        }
    })).unwrap();

    const reloadVillage = async () => {
        if (village) {
            const {
                data: {
                    village: _village
                }
            } = await api.get<VillageInfoResponse>(villageInfoPath(village.id));
            setAuthVillage(_village);
        }
    }

    const loadVillageLandQty = () => dispatch(fetchPlacedLandQty({
        fetch: async () => {
            const {data} = await api.get<VillageLandQtyResponse>(villageLandQtyPath(village!.id));

            return data.placedLandQty;
        }
    }))

    const loadUpgradingBuildingsQty = () => dispatch(fetchUpgradingBuildingsQty({
        fetch: async () => {
            const {data} = await api.get<GetUpgradingBuildingsQtyResponse>(
                villageUpgradingBuildingsQtyPath(village!.id)
            );

            return data;
        }
    }));

    const increaseUpgradingBuildingsQty = () => dispatch(increaseUpgradingBuildingsQtyAction());
    const decreaseUpgradingBuildingsQty = () => dispatch(decreaseUpgradingBuildingsQtyAction());
    const increasePlacedBuildingsQty = () => dispatch(increasePlacedBuildingsQtyAction());
    const decreasePlacedBuildingsQty = () => dispatch(decreasePlacedBuildingsQtyAction());



    useEffect(() => {
        if (village) {
            setVillage({id: village.id, ownerId: village.owner_id});
            loadVillageLandQty();
            loadVillageResidents();
            loadUpgradingBuildingsQty();
        } else {
            setVillage(null)
        }
    }, [village]);

    return {
        setVillage,
        loadVillageResidents,
        reloadVillage,
        loadVillageLandQty,
        loadUpgradingBuildingsQty,
        increaseUpgradingBuildingsQty,
        decreaseUpgradingBuildingsQty,
        increasePlacedBuildingsQty,
        decreasePlacedBuildingsQty,
        villageResidentsQty,
        maxVillageLandQty,
        upgradingBuildingsQty,
        maxUpgradingBuildingsQty
    };
}