import BigNumber from "bignumber.js";
import { useSelector } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { RootState } from "../../redux/store";
import ActionButton from "../shared/ActionButton";
import {usePopups} from "../../hooks/usePopups";
import {PopupEnum} from "../../common/types/components";

export default function EgldTopUpBar() {
  const { wallet } = useAuth();
  const errorDialog = useSelector((state: RootState) => state.errorDialog);
  const {showPopup} = usePopups();
  const openTopUpDialog = () => {
    showPopup(PopupEnum.TOKEN_TRANSFER, {
      state: {type: "top-up"},
      removeWhenInactive: true,
    });
  };

  if (errorDialog.open) return null;

  if (!wallet?.balance) return null;

  if (new BigNumber(wallet?.balance.toString()).comparedTo(new BigNumber(0.01).shiftedBy(18)) > 0) return null;

  return (
    <div className="flex items-center bg-secondary-lightest w-full md:max-w-lg pointer-events-auto">
      <div className="flex flex-col md:flex-row items-center justify-between border border-primary-darkest gap-x-8 gap-y-4 min-h-16 m-1 w-full px-4 py-4 md:py-0">
        <div className="flex-shrink-0 text-center md:text-left">Make sure to top-up your inventory wallet!</div>
        <div className="flex-auto w-1/2 md:max-w-[120px]">
          <ActionButton onClick={openTopUpDialog}>Top-Up</ActionButton>
        </div>
      </div>
    </div>
  );
}
