import {useMemo, useState} from "react";
import {useAuth} from "../../hooks/useAuth";
import {useAppDispatch} from "../../hooks/useStore";
import IconGiantCurrency from "../icons/IconGiantCurrency";
import {useSelector} from "react-redux";
import {resetItemsToBuy, selectItemsToBuy, selectShopItems} from "../../redux/slices/shopSlice";
import ListItem from "./ListItem";
import ActionButton from "../shared/ActionButton";
import {ShopItem} from "../../common/types";
import {
    Address,
    ContractFunction,
    Interaction,
    List, ListType,
    SmartContract, TokenTransfer
} from "@multiversx/sdk-core/out";
import {
    buildEsdtTokenPayment,
    EsdtTokenPaymentType
} from "../../utils/customTypes";
import {Nonce} from "@multiversx/sdk-network-providers/out/primitives";
import {network} from "../../config/network";
import {useTransaction} from "../../hooks/useTransaction";
import {TOKEN_GIANTS_CURRENCY, TOKEN_LAND} from "../../config/assets";
import BigNumber from "bignumber.js";
import {
    fetchInventoryAssets,
    fetchLandTiles,
    fetchRewards, fetchSkins
} from "../../redux/slices/inventorySlice";
import WalletTokensBalance from "../inventory/WalletTokensBalance";
import Scrollbar from "../shared/Scrollbar";
import {HiExternalLink} from "react-icons/hi";

type ItemWithQty = ShopItem & { qty: number };

export default function Shop() {
    const {wallet, balance, giantGoldBalance, env} = useAuth();
    const {sendTransaction} = useTransaction();
    const dispatch = useAppDispatch();
    const shopItems = useSelector(selectShopItems);
    const selectedItems = useSelector(selectItemsToBuy);
    const [loading, setLoading] = useState(false);


    const itemsToBuy = useMemo(() => {
        return Object.keys(selectedItems).reduce((acc: ItemWithQty[], token) => {
            const qty = selectedItems[token];
            const item = shopItems.find(item => `${item.token}-${item.nonce}` === token);
            if (item) {
                return [...acc, {...item, qty}];
            }
            return acc;
        }, []);
    }, [selectedItems, shopItems]);

    const total = useMemo(() => {
        return itemsToBuy.reduce((acc, item) => {
            return acc + item.price * item.qty;
        }, 0);
    }, [itemsToBuy]);

    const hasEnoughBalance = useMemo(() => {
        return giantGoldBalance.toDenominated().comparedTo(total) > -1;
    }, [giantGoldBalance, total]);

    const buy = async () => {
        setLoading(true);
        try {
            await wallet!.refresh();
            const value = itemsToBuy.reduce((acc, item) => {
                return acc + item.price * item.qty;
            }, 0)
            const contract = new SmartContract({
                address: Address.fromBech32(process.env.NEXT_PUBLIC_SHOP_CONTRACT!),
            });
            const items = itemsToBuy.map(
                item => buildEsdtTokenPayment({
                    id: item.token,
                    quantity: item.qty,
                    nonce: item.nonce,
                })
            );
            const gasLimit = itemsToBuy.reduce((total, item) => {
                const gasPerUnit = 3_500_000;
                // For land, we consider the quantity as 1 because there
                // is no minting action for it.
                if (item.token === TOKEN_LAND) {
                    return total + gasPerUnit;
                }

                return total + 10_000_000 +  item.qty * gasPerUnit;
            }, itemsToBuy.length * 2_000_000);
            const itemList = new List(new ListType(EsdtTokenPaymentType), items);
            const chainId = network[env]["chainId"];
            const interaction = new Interaction(
                contract,
                new ContractFunction("buyFromShop"),
                [itemList]
            )
                .withNonce(new Nonce(wallet!.nonce))
                .withChainID(chainId)
                .withSender(Address.fromBech32(wallet!.address))
                .withGasLimit(gasLimit)
                .withSingleESDTTransfer(TokenTransfer.fungibleFromBigInteger(
                    TOKEN_GIANTS_CURRENCY,
                    new BigNumber(value).shiftedBy(18)
                ));

            const tx = wallet!.signTransaction(interaction.buildTransaction());
            await sendTransaction(tx, "Buy items from shop");
            dispatch(resetItemsToBuy());
            wallet!.refresh();
            dispatch(fetchInventoryAssets({reset: true}));
            dispatch(fetchRewards({address: wallet!.address}));
            dispatch(fetchLandTiles());
            dispatch(fetchSkins());
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }
    const cancel = () => {
        dispatch(resetItemsToBuy());
    }

    return (
        <div className="flex flex-col items-center justify-start pt-4  w-full h-full overflow-auto">
            <div className="flex items-center justify-between w-full mb-8">
                <WalletTokensBalance mode="inventory"/>
            </div>
            <Scrollbar autoHide={false}>
                <ul
                    role="list"
                    className="divide-y divide-secondary w-full"
                >
                    {shopItems.map(item => (
                        <ListItem
                            item={{...item, selected: selectedItems[`${item.token}-${item.nonce}`] ?? 0}}
                            key={`${item.token}-${item.nonce}`}
                        />
                    ))}
                </ul>
            </Scrollbar>
            <div
                className="flex flex-col md:flex-row items-center justify-start gap-4 w-full min-h-20 md:min-h-10"
            >
                {(itemsToBuy.length > 0) && (
                    <>
                        <ActionButton
                            onClick={buy}
                            style="primary"
                            disabled={loading || !hasEnoughBalance}
                        >
                            {loading ? (
                                <span className="animate-pulse">Loading...</span>
                            ) : (
                                <>
                                    <span className="mr-1">Get all for</span>
                                    <IconGiantCurrency className="w-4 h-4"/>
                                    <span className="ml-1">{total}</span>
                                </>
                            )}
                        </ActionButton>
                        <ActionButton
                            onClick={cancel}
                            style="secondary"
                            disabled={loading}
                        >
                            Cancel
                        </ActionButton>
                    </>
                )}
            </div>
        </div>
    );
};

function MintItem({thumbnail, title, mintUrl}: {
    thumbnail: string,
    title: string,
    mintUrl: string
}) {
    return (
        <li className="flex items-center py-4">
            <div className="border-2 border-secondary w-20 h-20">
                <img
                    src={thumbnail}
                    alt={`${title} thumbnail`}
                    className="w-full h-full object-center object-cover"
                />
            </div>
            <div className="flex flex-col items-start ml-4">
                <span className="text-theme-text font-semibold text-lg">{title}</span>
                <a
                    href={mintUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="text-secondary underline inline-flex items-center"
                >
                    <span className="">Mint here</span>
                    <HiExternalLink className="w-4 h-4 ml-1"/>
                </a>
            </div>
        </li>
    );
}