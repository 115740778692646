import {SVGProps} from "react";

export default function IconCopy({...props}: SVGProps<any>) {
    return (
        <div className="flex items-center justify-center rounded-full p-1" {...props}>
            <svg viewBox="0 0 18 19" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.6074 0.850098H5.06117C3.93842 0.850098 3.02504 1.76622 3.02504 2.89235V3.52466H2.38598C1.26323 3.52466 0.349854 4.44079 0.349854 5.56754V16.1077C0.349854 17.2338 1.26323 18.15 2.38598 18.15H12.9325C14.0589 18.15 14.9751 17.2338 14.9751 16.1077V15.4754H15.6074C16.7338 15.4754 17.6503 14.5593 17.6503 13.4325V2.89229C17.6502 1.76622 16.7338 0.850098 15.6074 0.850098ZM13.7251 16.1077C13.7251 16.5447 13.3696 16.8999 12.9325 16.8999H2.38598C1.9526 16.8999 1.59985 16.5447 1.59985 16.1077V5.56747C1.59985 5.13047 1.95267 4.7746 2.38598 4.7746H3.65004H12.9325C13.3695 4.7746 13.7251 5.13041 13.7251 5.56747V14.8503V16.1077ZM16.4002 13.4325C16.4002 13.8695 16.0447 14.2253 15.6074 14.2253H14.975V5.56747C14.975 4.44079 14.0589 3.5246 12.9325 3.5246H4.27498V2.89229C4.27498 2.45529 4.62779 2.10004 5.0611 2.10004H15.6074C16.0447 2.10004 16.4002 2.45529 16.4002 2.89229V13.4325Z"
                    fill="#80B800"/>
            </svg>
        </div>
    );
}