import {classNames} from "../../utils/presentation";
import {useVillageState} from "../../hooks/useVillageState";

export default function LandQty({selected}: { selected: number }) {
    const {maxVillageLandQty} = useVillageState();

    return (
        <div className="h-10 flex items-center justify-center bg-white p-1">
            <div
                className={classNames(
                    "flex items-center justify-center h-full border border-primary-darkest font-semibold px-2",
                    selected >= maxVillageLandQty ? "text-red" : "text-theme-text"
                )}>
                {selected} / {maxVillageLandQty}
            </div>
        </div>
    );
};