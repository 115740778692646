import ActionContext from "./ActionContext";
import {gameAction} from "../../config/actions";

export default class UpgradeBuildingContext extends ActionContext<"INIT_UPGRADE"> {
    protected type = gameAction.INIT_UPGRADE;
    protected hasTransaction = false;


    public getUsedItems(): Record<string, number> {
        return {};
    }

};