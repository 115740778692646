import {useAppDispatch} from "./useStore";
import {useSelector} from "react-redux";
import {closeErrorDialog, openErrorDialog, selectErrorDialog} from "../redux/slices/errorSlice";
import {ErrorDialogActionType} from "../common/types";

export const useError = () => {
    const dispatch = useAppDispatch();
    const error = useSelector(selectErrorDialog);

    const showError = (
        message: string,
        options?: {
            type?: "small" | "big",
            dismissable?: boolean,
            action?: ErrorDialogActionType
        }
    ) => {
        dispatch(openErrorDialog({error: message, ...options}));
    }

    const closeError = () => {
        dispatch(closeErrorDialog());
    }

    return {
        error,
        showError,
        closeError
    };
}