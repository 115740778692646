import {InventoryAsset} from '../../common/types/inventory';
import {buildings, TOKEN_LAND} from '../../config/assets';
import Manager from '../../gameboard/Manager';
import ActionButton from '../shared/ActionButton';
import Popup from '../shared/Popup';
import Level from './Level';
import {getAssetDetails, getSkinItem} from "../../utils/assets";
import {selectTilesQty} from "../../redux/slices/inventorySlice";
import store from "../../redux/store";
import {useAuth} from "../../hooks/useAuth";
import {useMemo} from "react";
import {AiFillLock} from "react-icons/ai";
import {getVillageLevelRequirementForBuilding} from "../../utils/game";
import {usePopups} from "../../hooks/usePopups";
import {PopupEnum} from "../../common/types/components";
import {useVillageState} from "../../hooks/useVillageState";

type Props = {
  onClose: () => void;
};
export default function AssetInfoDialog({onClose}: Props) {
  const {village} = useAuth();
  const {popupState: getPopupState, isActive, closePopup} = usePopups();
  const popupState = getPopupState(PopupEnum.ASSET_INFO);
  const asset: InventoryAsset = popupState?.state?.asset;
  const assetData = useMemo(() => {
    if (!asset) {return null;}
    return getAssetDetails(asset.collection, asset.nonce);
  }, [asset]);
  const buildingAssetInfo = useMemo(() => {
    if (!asset) {return null;}
    return buildings[asset.collection];
  }, [asset]);
  const requiredVillageLevel = useMemo(
      () => {
        if (!asset) {return -1;}
        if (asset.collection === TOKEN_LAND) {return 0;}
        return getVillageLevelRequirementForBuilding(asset.collection, asset.attributes!.level.value);
      },
      [asset]
  );
  const {maxVillageLandQty} = useVillageState();
  const attributes = useMemo(() => {
    if (!asset || !assetData || !buildingAssetInfo) {return [];}
    if (assetData.type === "building") {
      return [
        {
          title: "Giants capacity",
          value: buildingAssetInfo.getGiantsCapacity({currentLevel: asset.attributes!.level.value}),
          img: "/wizard-head.svg",
        },
      ];
    }
    return [];
  }, [asset, assetData, buildingAssetInfo]);

  const unlocked = useMemo(() => {
    if (!village || !assetData) {return false;}
    if (assetData.type === "land") {return true;}
    if (requiredVillageLevel === -1) {return false;}

    return village.level >= requiredVillageLevel;
  }, [village, assetData, requiredVillageLevel]);

  const skin = useMemo(() => {
    if (!asset || !buildingAssetInfo) {return null;}
    if (asset.attributes?.skin?.status !== "complete") {return null;}
    return asset.attributes?.skin?.value ? getSkinItem(buildingAssetInfo, asset.attributes.skin.value) : null
  }, [asset, buildingAssetInfo]);

  const close = () => {
    closePopup(PopupEnum.ASSET_INFO);
    onClose();
  }


  const onPlaceAction = () => {
    if (assetData?.type === "land") {
      Manager.getInstance().publishEvent(
          "INIT_PLACE_LAND",
          {availableTilesQty: maxVillageLandQty}
      );
    } else {
      Manager.getInstance().publishEvent("INIT_PLACE_ASSET", { asset });
    }

    close();
    closePopup(PopupEnum.INVENTORY);
  };

  return (
      <Popup open={isActive(PopupEnum.ASSET_INFO)}
             setOpen={close}
             widthClass="w-full md:max-w-[26rem]"
      >
      <div className="flex flex-col items-start p-2 h-full">
        <div className="flex items-center space-x-4">
          <div className="flex items-center justify-center w-20 h-20 p-0.5 border-2 border-secondary">
            {assetData && <img
                alt={`Asset: ${assetData.title}`}
                src={skin?.thumbnail ?? assetData.thumbnail}
                className="w-full h-full object-center object-contain"
            />}
          </div>
          <div className="flex flex-col items-start space-y-1">
            <h2 className="text-xl text-theme-text font-semibold">{assetData?.title}</h2>
            {asset && asset.collection !== TOKEN_LAND && (
              <Level level={asset.attributes!.level.value} />
            )}
          </div>
        </div>
        <p className="text-theme-text-light mt-6 text-left">{assetData?.description}</p>
        <div className="flex flex-col items-start gap-y-3 mt-3">
          {attributes.map((attribute) => (
              <div className="flex items-center" key={attribute.title}>
                <img src={attribute.img} className="w-6" aria-hidden={true}/>
                <span className="text-sm text-theme-text-light font-semibold ml-3">
                {attribute.title}
                  <span className="font-normal">: {attribute.value}</span>
              </span>
              </div>
          ))}
          {!unlocked && (
              <div className="flex items-center">
                <AiFillLock className="w-6 h-6 text-theme-text-light"/>
                <div
                    className="flex items-center justify-start text-sm text-theme-text-light font-semibold ml-3 space-x-1"
                >
                  <span>{assetData?.title}</span>
                  {asset && <Level level={asset.attributes!.level.value}/>}
                  <span>requires Village</span>
                    <Level level={requiredVillageLevel}/>
                </div>
              </div>
          )}
        </div>
        <div className="flex flex-col flex-grow justify-end w-full min-h-40">
          <div className="flex flex-col md:flex-row items-center justify-center gap-3 w-full mt-2">
            <ActionButton
                onClick={onPlaceAction}
                style={"secondary"}
                disabled={!unlocked}
            >
              place {assetData?.type}
            </ActionButton>
            <ActionButton onClick={close}>cancel</ActionButton>
          </div>
        </div>
      </div>
    </Popup>
  );
}
