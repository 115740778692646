export const villageInfoPath = (id: string) => `villages/${id}`;
export const villageAssetsPath = (id: string) => `villages/${id}/assets`;
export const villageResidentsPath = (id: string) => `villages/${id}/residents`;
export const villageLandQtyPath = (id: string) => `villages/${id}/land`;
export const villageUpgradingBuildingsQtyPath = (id: string) => `villages/${id}/upgrading-buildings`;
export const referralRewardsPath = () => "referral-rewards";
export const villageLevelsPath = () => "referral-rewards/levels";
export const getRewardSignaturePath = (id: number) => `referral-rewards/${id}/signature`;
export const validateQuestPath = (id: number) => `/quests/${id}/validate`;
export const stakedGiantsPath = (address: string) => ``;
export const getNpcsPath = () => "/quests/npcs";
export const farmHarvestStatePath = (id: string) => `/resources/farms/${id}`;
export const villageHarvestStatePath = () => `/resources/harvested`;
export const getLatestClaimPath = () => `/resources/claims/latest`;
export const createClaimPath = () => `/resources/claims`;
export const signAssetUpgradePath = (villageId: string) => `villages/${villageId}/assets/sign-upgrade`;
export const showLeaderboardPath = () => `/leaderboards`;
export const showPreviousLeaderboardPath = () => `/leaderboards/previous`;
export const getLeaderboardClaimablePrizesPath = () => `/leaderboards/prizes`;
export const claimPrizesPath = () => `/leaderboards`;
export const getNbReferralsPath = () => `/referral-rewards/nb-referrals`;
export const getClaimableAmountPath = () => "/referral-rewards/claimable-amount"
export const getCurrentSpendingClaimPath = () => "/referral-rewards/claims/current";
export const createSpendingClaimPath = () => "/referral-rewards/claims";
