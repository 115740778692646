import {useAuth} from "./useAuth";
import {io} from "socket.io-client";
import {useEffect, useState} from "react";
import {
    EventsMap,
    ReservedOrUserEventNames,
    ReservedOrUserListener
} from "@socket.io/component-emitter";

const socket = io(process.env.NEXT_PUBLIC_EVENTS_URL!, {
    autoConnect: false
});

export const useSocket = () => {
    const {token} = useAuth();
    const [connected, setConnected] = useState(socket.connected);

    const connect = () => {
        if (connected) {return;}
        socket.auth = {token}
        socket.connect();
    };

    const on = <Ev extends ReservedOrUserEventNames<EventsMap, EventsMap>>(
        ev: Ev,
        listener: ReservedOrUserListener<EventsMap, EventsMap, Ev>
    ) => {
        socket.on(ev, listener);
    };


    const off = <Ev extends ReservedOrUserEventNames<EventsMap, EventsMap>>(
        ev: Ev,
        listener: ReservedOrUserListener<EventsMap, EventsMap, Ev>
    ) => {
        socket.off(ev, listener);
    }

    useEffect(() => {
        socket.on("connect", () => {
            setConnected(true);
        });

        socket.on("disconnect", () => {
            setConnected(false);
        });

        socket.on("connect_error", (err) => {
            console.log(err);
        });

        return () => {
            socket.off("connect");
            socket.off("disconnect");
            socket.removeAllListeners();
        }

    }, []);


    return {connected, connect, on, off}
};