import ActionContext from "./ActionContext";
import {gameAction} from "../../config/actions";
import {getNftIdFromToken} from "../../utils/nfts";

export default class AssetsTransferContext extends ActionContext<"ASSETS_TRANSFER"> {
    protected type = gameAction.ASSETS_TRANSFER;
    protected hasTransaction = true;


    public getUsedItems(): Record<string, number> {
        return {};
    }

};