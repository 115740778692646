import {ShopItem} from "../../common/types";
import {classNames} from "../../utils/presentation";
import {HiMinus, HiPlus} from "react-icons/hi2";
import {QtyButton} from "../inventory/QtyButton";
import AssetQtyInput from "../inventory/AssetQtyInput";
import {useAppDispatch} from "../../hooks/useStore";
import {setItemToBuy} from "../../redux/slices/shopSlice";
import IconGiantCurrency from "../icons/IconGiantCurrency";

type SelectableItem = ShopItem & { selected: number };
type Props = {
    item: SelectableItem;
};

export default function ListItem({item}: Props) {
    const dispatch = useAppDispatch();
    const changeQty = (qty: number) => {
        dispatch(setItemToBuy({token: item.token, nonce: item.nonce, qty}));
    }

    return (
        <li className="flex items-center py-4">
            <div className="border-2 border-secondary w-20 h-20">
                <img
                    src={item.thumbnail}
                    alt={`${item.title} thumbnail`}
                    className="w-full h-full object-center object-cover"
                />
            </div>
            <div className="flex flex-col items-start ml-4">
                <span className="text-theme-text font-semibold text-lg">{item.title}</span>
                <div className="flex items-center space-x-2">
                    <IconGiantCurrency className="w-4 h-4"/>
                    <span className="text-theme-text-light">{item.price}</span>
                </div>
                <div
                    className="flex items-center justify-between px-1 bg-secondary w-auto h-6"
                >
                    <QtyButton
                        visible={true}
                        iconType={HiMinus}
                        onClick={() => changeQty(item.selected > 0 ? item.selected - 1 : 0)}
                        disabled={item.selected < 1}
                    />
                    <AssetQtyInput value={item.selected} onChange={changeQty} autofocus={false}/>
                    <QtyButton
                        visible={true}
                        iconType={HiPlus}
                        onClick={() => changeQty(item.selected + 1)}
                    />
                </div>
            </div>
        </li>
    );
};