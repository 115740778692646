import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";

import { TOKEN_FOOD, TOKEN_STONE, TOKEN_VILLAGE_COIN, TOKEN_WOOD } from "../../config/assets";
import { useVillageState } from "../../hooks/useVillageState";
import { selectRewardsResources } from "../../redux/slices/inventorySlice";
import IconFood from "../icons/IconFood";
import IconStone from "../icons/IconStone";
import IconVillageCoin from "../icons/IconVillageCoin";
import IconWizard from "../icons/IconWizard";
import IconWood from "../icons/IconWood";
import {formatAmount} from "../../utils/presentation";
import {LiaToolsSolid} from "react-icons/lia";
import IconUpgradingBuildings from "../icons/IconUpgradingBuildings";

export default function ResourcesBar() {
  const resources = useSelector(selectRewardsResources);
  const { upgradingBuildingsQty, maxUpgradingBuildingsQty } = useVillageState();

  const formattedResources = useMemo(() => {
    // Format resource with K, M, B, T, etc.
    const formatted: { [key: string]: string } = {};
    for (const [key, value] of Object.entries(resources)) {
      formatted[key] = formatAmount(value)
    }

    return formatted;
  }, [resources]);

  return (
    <div className="flex items-center bg-secondary-lightest relative pointer-events-auto">
      <div className="flex items-center md:border border-primary-darkest h-10 md:h-16 m-1 px-5">
        <div className="flex items-center justify-between space-x-3 lg:space-x-5 h-full">
          <div
              className="flex items-center justify-center space-x-2"
              data-tooltip-id="resources-tooltip"
              data-tooltip-content={`${(resources[TOKEN_WOOD] ?? 0).toLocaleString()} Wood`}
          >
            <IconWood className="w-5 h-5"/>
            <span
                className="text-theme-text-light font-semibold">{formattedResources[TOKEN_WOOD] ?? 0}</span>
          </div>
          <div
              className="flex items-center justify-center space-x-2"
              data-tooltip-id="resources-tooltip"
              data-tooltip-content={`${(resources[TOKEN_STONE] ?? 0).toLocaleString()} Stone`}
          >
            <IconStone className="w-5 h-5"/>
            <span
                className="text-theme-text-light font-semibold">{formattedResources[TOKEN_STONE] ?? 0}</span>
          </div>
          <div
              className="flex items-center justify-center space-x-2"
              data-tooltip-id="resources-tooltip"
              data-tooltip-content={`${(resources[TOKEN_FOOD] ?? 0).toLocaleString()} Wheat`}
          >
            <IconFood className="w-5 h-5"/>
            <span
                className="text-theme-text-light font-semibold">{formattedResources[TOKEN_FOOD] ?? 0}</span>
          </div>
          <div
              className="flex items-center justify-center space-x-2"
              data-tooltip-id="resources-tooltip"
              data-tooltip-content={`${(resources[TOKEN_VILLAGE_COIN] ?? 0).toLocaleString()} Villager Coin`}
          >
            <IconVillageCoin className="w-5 h-5"/>
            <span
                className="text-theme-text-light font-semibold">{formattedResources[TOKEN_VILLAGE_COIN] ?? 0}</span>
          </div>
          <div
              className="flex items-center justify-center space-x-2"
              data-tooltip-id="resources-tooltip"
              data-tooltip-content={"Upgrading buildings"}
          >
            <IconUpgradingBuildings className="w-6 h-6" />
            <span className="text-theme-text-light font-semibold">
              {upgradingBuildingsQty}/{maxUpgradingBuildingsQty}
            </span>
          </div>
        </div>
      </div>

      <Tooltip id="resources-tooltip" className="base-tooltip z-50"/>
    </div>
  );
}
