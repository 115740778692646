import ActionContext from "./ActionContext";
import {gameAction} from "../../config/actions";

export default class UpgradeVillageContext extends ActionContext<"UPGRADE_VILLAGE"> {
    protected type = gameAction.UPGRADE_VILLAGE;
    protected hasTransaction = true;


    public getUsedItems(): Record<string, number> {
        return {};
    }

};