import {AiOutlineClose, AiOutlineExclamation} from "react-icons/ai";
import {useError} from "../../hooks/useError";
import {ErrorDialogActionType} from "../../common/types";
import ActionButton from "./ActionButton";
import {usePopups} from "../../hooks/usePopups";
import {PopupEnum} from "../../common/types/components";

export default function ErrorBar() {
  const {error, showError, closeError} = useError();
  const {showPopup} = usePopups();
  const actions: {
    [k in ErrorDialogActionType]: {
      label: string;
      onClick: () => void;
    }
  } = {
    "top-up": {
      label: "Top-Up",
      onClick: () => {
        showPopup(PopupEnum.TOKEN_TRANSFER, {
          state: {type: "top-up"},
          removeWhenInactive: true,
        });
        closeError();
      }
    }
  }
  const action = error.action ? actions[error.action] : null;

  if (!error.open) return null;

  return (
      <div className="p-1 bg-secondary-lightest w-full md:max-w-lg flex-auto min-h-16 pointer-events-auto">
        <div className="flex flex-col items-center justify-between md:flex-row w-full border border-primary-darkest p-4">
          <div
              className="flex items-center justify-center w-auto md:max-w-2/3"
          >
            <div className="flex-shrink-0 flex items-center pb-4 pt-2 md:pb-0 md:pt-0">
                <span
                    className="bg-red text-white h-6 w-6 inline-flex font-bold items-center justify-center rounded-full leading-none mr-2"
                >
                  <AiOutlineExclamation className="h-4 w-4"/>
                </span>
              {error.message}
            </div>
            {error.dismissable &&
                <button
                    type="button"
                    className="rounded-full text-primary-darkest focus:outline-none focus:ring-2 focus:ring-primary-darkest"
                    onClick={closeError}
                >
                  <span className="sr-only">Close</span>
                  <AiOutlineClose className="h-4 w-4" aria-hidden="true"/>
                </button>
            }
          </div>
          {action && (
              <div className="flex justify-center w-1/3 md:max-w-[120px]">
                <ActionButton
                    onClick={action.onClick}
                >
                  {action.label}
                </ActionButton>
              </div>
          )}
      </div>
    </div>
  );
}
