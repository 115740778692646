import Scrollbar from "../shared/Scrollbar";
import {collectionItemsInfo} from "../../config/assets";
import {HiExternalLink} from "react-icons/hi";
import {CollectionInfo} from "../../common/types";
import RewardResourceIcon from "../game/RewardResourceIcon";

export default function Collections() {

    return (
        <div className="flex flex-col items-center justify-start w-full h-full overflow-auto md:min-h-100 pt-10">
            <Scrollbar autoHide={false}>
                <div className="flex flex-col items-start w-full flex-grow">
                    <h2 className="text-lg font-bold mb-4">Land and Buildings</h2>
                    <ul
                        role="list"
                        className="grid grid-cols-3 md:grid-cols-4 gap-y-10 gap-x-4"
                    >
                        {collectionItemsInfo.buildings.map(item => (
                            <li
                                key={item.collection}
                            >
                                <a
                                    href={`https://xoxno.com/collection/${item.collection}`}
                                    target="_blank"
                                    className="flex flex-col items-center col-span-1 space-y-2 group"
                                >
                                    <CollectionIcon item={item}/>
                                    <div className="text-center">
                                        <h3 className="font-bold">{item.title}</h3>
                                        <div
                                            className="inline-flex items-center text-sm text-theme-text-secondary group-hover:underline underline-offset-4"
                                        >
                                            <span className="">see on xoxno</span>
                                            <HiExternalLink className="w-4 h-4 ml-1"/>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        ))}
                    </ul>
                    <h2 className="text-lg font-bold mb-4 mt-6">Resources</h2>
                    <ul
                        role="list"
                        className="grid grid-cols-3 md:grid-cols-4 gap-y-10 gap-x-4"
                    >
                        {collectionItemsInfo.resources.map(item => (
                            <li
                                key={item.collection}
                            >
                                <a
                                    href={`https://xoxno.com/collection/${item.collection}`}
                                    target="_blank"
                                    className="flex flex-col items-center col-span-1 space-y-2 group"
                                >
                                    <CollectionIcon item={item}/>
                                    <div className="text-center">
                                        <h3 className="font-bold">{item.title}</h3>
                                        <div
                                            className="inline-flex items-center text-sm text-theme-text-secondary group-hover:underline underline-offset-4"
                                        >
                                            <span className="">see on xoxno</span>
                                            <HiExternalLink className="w-4 h-4 ml-1"/>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </Scrollbar>
        </div>
    );
}

function CollectionIcon({item}: { item: CollectionInfo }) {
    if (item.thumbnail) {
        return (
            <div className="border-2 border-secondary w-24 h-24">
                <img
                    src={item.thumbnail}
                    alt={`${item.title} thumbnail`}
                    className="w-full h-full object-center object-cover group-hover:opacity-80"
                />
            </div>
        )
    }

    return (
        <div className="border-2 border-secondary w-24 h-24 flex items-center justify-center">
            <RewardResourceIcon token={item.collection} size="20" rounded={false} padding={2}/>
        </div>
    );
}