import {useEffect, useState} from "react";
import {classNames} from "../../utils/presentation";

export default function AssetQtyInput({max, value, onChange, autofocus = true}: {
    value: number;
    max?: number;
    onChange: (qty: number) => void;
    autofocus?: boolean;
}) {
    const [timeoutId, setTimeoutId] = useState<number | null>(null);
    const [internalValue, setInternalValue] = useState<number | string>(value);
    useEffect(() => {setInternalValue(value)}, [value]);
    useEffect(() => {
        if (timeoutId) {
            window.clearTimeout(timeoutId);
            setTimeoutId(null);
        }
        const inputValue = parseInt(internalValue as string);
        if (isNaN(inputValue)) {
            const timeout = window.setTimeout(
                () => {setInternalValue(value)},
                1000
            );
            setTimeoutId(timeout);

            return;
        }
        if (inputValue < 0) {
            onChange(0);

            return;
        }

        if (max && inputValue > max) {
            setInternalValue(max);
            return;
        }
        onChange(inputValue);
    }, [max, internalValue]);

    return (

        <input
            className={classNames(
                "w-10 h-4 text-center text-theme-text font-bold text-xs bg-transparent",
                "focus:outline-none focus:ring-1 focus:ring-theme-text",
                "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            )}
            type="number"
            value={internalValue}
            onChange={e => setInternalValue(e.target.value)}

            min={0}
            max={max}
            autoFocus={autofocus}
        />
    );
}