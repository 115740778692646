import {useMemo} from "react";
import {classNames} from "../utils/presentation";
import {ReactComponentLike} from "prop-types";

type Props = {
    value: number;
    maxValue: number;
    label: string;
    height?: 'x-small' | 'small' | 'medium' | 'large';
    showTextProgress?: boolean;
    textProgressLabel?: string;
    theme?: "orange" | "yellow" | "blue" | "green";
    icon?: JSX.Element;
};
export default function ProgressBar(
    {
        value,
        maxValue,
        label,
        height = 'small',
        showTextProgress = false,
        theme = "orange",
        textProgressLabel = "",
        icon
    }: Props
) {
    const progress = useMemo(() => {
        const p = value * 100 / maxValue;
        if (p > 100) {return 100;}
        if (p < 0) {return 0;}

        return p;
    }, [value, maxValue]);

    const heightClass = getHeightClass(height);
    const {backgroundColor, progressColor} = getThemeClass(theme);

    return (
        <div className={classNames("w-full relative", backgroundColor, heightClass)}>
            <div
                className={classNames(progressColor, heightClass)}
                style={{width: `${progress}%`}}
                role="progressbar"
                aria-label={label}
                aria-valuenow={value}
                aria-valuemin={0}
                aria-valuemax={maxValue}
            >
            </div>
            {showTextProgress && (
                <span className="absolute right-1 top-0 text-sm text-theme-text">
                    {value}/{maxValue}{textProgressLabel}
                </span>
            )}
            {icon && (
                <div
                    className="absolute left-0 top-0 flex items-center justify-center h-full"
                >
                    {icon}
                </div>
            )}
        </div>
    );
};

const getHeightClass = (height: Props['height']) => {
    switch (height) {
        case 'small':
            return 'h-3';
        case 'medium':
            return 'h-5';
        case 'large':
            return 'h-6';
        default:
            return 'h-2';
    }
}
const getThemeClass = (theme: Props['theme']) => {
    switch (theme) {
        case 'yellow':
            return {
                backgroundColor: "bg-[#FFFEB5]",
                progressColor: "bg-[#ECEA6E]"
            }
        case 'blue':
            return {
                backgroundColor: "bg-blue-light",
                progressColor: "bg-blue"
            };
        case 'green':
            return {
                backgroundColor: "bg-[#F1FFD2]",
                progressColor: "bg-[#80B800]"
            };
        default:
            return {
                backgroundColor: "bg-[#FFF1D2]",
                progressColor: "bg-[#FF8A17]"
            };
    }
}