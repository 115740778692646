import BaseBuildingAssetDialog from "./BaseBuildingAssetDialog";
import {GameActionEnum, IToken, TxActionPayload} from "../../../common/types/actions";
import Manager from "../../../gameboard/Manager";
import {useAuth} from "../../../hooks/useAuth";
import {useAppDispatch} from "../../../hooks/useStore";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {
    fetchInventoryAssets,
    filterInventoryAssets,
    hasMoreInventoryAssets, selectHasMoreWalletGiants, selectTotalAvailableGiants
} from "../../../redux/slices/inventorySlice";
import {BuildingResident, SelectedResident} from "../../../common/types/inventory";
import {useEffect, useMemo, useState} from "react";
import ResidentsList from "./ResidentsList";
import {mapVillageResidents} from "../../../utils/inventory";
import {buildings} from "../../../config/assets";
import {useVillageState} from "../../../hooks/useVillageState";
import {PartialVillageAsset} from "../../../common/types/repository";
import {selectAvailableHouseResidents} from "../../../redux/slices/residentsSlice";
import {getDisplayInfo} from "../../../utils/nfts";
import {fetchAuthorizedWalletForStaking} from "../../../redux/slices/villageSlice";
import {buildAuthorizationTx} from "../../../utils/transactions";
import {Address} from "@multiversx/sdk-core/out";
import {network} from "../../../config/network";
import {nanoid} from "nanoid";
import {addTxToSign, removeTxToSign} from "../../../redux/slices/transactionsSlice";
import {useTransaction} from "../../../hooks/useTransaction";
import {fetchInventoryGiants} from "../../../redux/slices/inventorySlice";
import {fetchStakedGiants} from "../../../redux/slices/walletAssetsSlice";
import AddGiantsToHouseFromStakingContext
    from "../../../services/contexts/AddGiantsToHouseFromStakingContext";
import AddGiantsToHouseContext from "../../../services/contexts/AddGiantsToHouseContext";
import RemoveGiantsFromHouseToStakingContext
    from "../../../services/contexts/RemoveGiantsFromHouseToStakingContext";
import RemoveGiantsFromHouseContext from "../../../services/contexts/RemoveGiantsFromHouseContext";


type Props = {
    asset: PartialVillageAsset,
    onClose: () => void,
}
export default function HouseAssetDialog({asset, onClose}: Props) {
    const {village, wallet, mainWallet, env} = useAuth();
    const dispatch = useAppDispatch();
    const {sendTransaction, guardTransactionIfNeeded} = useTransaction();
    const giants = useSelector(selectAvailableHouseResidents);
    const hasMoreWalletGiants = useSelector(selectHasMoreWalletGiants);
    const walletGiantsStatus = useSelector((state: RootState) => state.inventory.walletGiantsStatus);
    const isWalletAuthorizedForStaking = useSelector((state: RootState) => state.village.walletAuthorizedForStaking);
    const {loadVillageResidents} = useVillageState();
    const totalAvailableGiants = useSelector(selectTotalAvailableGiants);
    const availableResidents: BuildingResident[] = useMemo(() => {
        return giants.map(giant => {
            const {name, thumbnail} = getDisplayInfo({
                id: giant.collection,
                nonce: giant.nonce,
            });


            return {
                ...giant,
                thumbnail,
                name,
                type: "NonFungibleESDT",
                workTitle: "",
                working: false,
                status: "complete",
                selectable: true,
                traits: []
            }
        });
    }, [giants]);
    const currentResidents = useSelector((state: RootState) => {
        const houseResidents = state.residents.residents
            .filter(resident => resident.house_token === asset.asset_token
                && resident.house_position?.x === asset.position.x
                && resident.house_position?.y === asset.position.y
            );

        return mapVillageResidents(houseResidents).map(resident => {
            return {
                ...resident,
                selectable: true
            }
        });
    });
    const residentsCapacity = useMemo(() => {
        return buildings[asset.asset_token].getGiantsCapacity({
            currentLevel: asset.attributes.level.value
        });
    }, [asset.asset_token, asset.attributes.level]);
    const status = useSelector((state: RootState) => state.inventory.status);
    const [authorizing, setAuthorizing] = useState(false);

    useEffect(() => {
        dispatch(filterInventoryAssets({type: "giants"}));
    }, []);

    const sendAuthorizationTx = async () => {
        if (!wallet || !mainWallet) {return;}
        try {
            setAuthorizing(true);
            const tx = buildAuthorizationTx(wallet.address!);
            tx.setSender(Address.fromBech32(mainWallet.address!));
            tx.setChainID(network[env].chainId);
            tx.setGasLimit(4_000_000);
            await mainWallet.refresh();
            tx.setNonce(mainWallet.nonce);
            const id = nanoid(5);
            dispatch(addTxToSign(id));
            const guardedTx = await guardTransactionIfNeeded(tx, mainWallet);
            const signedTx = await mainWallet.signTransaction(guardedTx);
            dispatch(removeTxToSign(id));
            if (!signedTx) {return;}
            const {status} = await sendTransaction(signedTx, "Authorizing staked giants usage");
            if (status === "success") {
                dispatch(fetchAuthorizedWalletForStaking(wallet.address!));
                dispatch(fetchStakedGiants({}));
            }
            return;
        }catch (e) {
            setAuthorizing(false);
        }
    }


    const addGiants = async (giants: SelectedResident[]) => {
        if (!village || !wallet || !mainWallet) {return;}
        const {staked, notStaked} = splitSelectedResidents(giants);
        if (staked.length > 0 && isWalletAuthorizedForStaking) {
            const actionContext = new AddGiantsToHouseFromStakingContext({
                house: {
                    token: {
                        id: asset.asset_token,
                        nonce: asset.asset_token_nonce
                    },
                    position: asset.position
                },
                giants: staked,
                villageId: village.id
            });
            await Manager.getInstance().commitAction(actionContext);
        }

        if (notStaked.length > 0) {
            const actionContext = new AddGiantsToHouseContext({
                house: {
                    token: {
                        id: asset.asset_token,
                        nonce: asset.asset_token_nonce
                    },
                    position: asset.position
                },
                giants: notStaked,
                villageId: village.id
            });
            await Manager.getInstance().commitAction(actionContext);
        }

        loadVillageResidents();
    };

    const removeGiants = async (giants: SelectedResident[]) => {
        const {staked, notStaked} = splitSelectedResidents(giants);
        if (staked.length > 0) {
            const actionContext = new RemoveGiantsFromHouseToStakingContext( {
                house: {
                    token: {
                        id: asset.asset_token,
                        nonce: asset.asset_token_nonce
                    },
                    position: asset.position
                },
                giants: staked,
                villageId: village!.id
            });
            await Manager.getInstance().commitAction(actionContext);
            dispatch(fetchStakedGiants({}));
        }
        if (notStaked.length > 0) {
            const actionContext = new RemoveGiantsFromHouseContext({
                house: {
                    token: {
                        id: asset.asset_token,
                        nonce: asset.asset_token_nonce
                    },
                    position: asset.position
                },
                giants: notStaked,
                villageId: village!.id
            });
            await Manager.getInstance().commitAction(actionContext);
            dispatch(fetchInventoryGiants({}));
        }
        await loadVillageResidents();
    };

    useEffect(() => {
        if (!wallet || isWalletAuthorizedForStaking) {return;}
        dispatch(fetchAuthorizedWalletForStaking(wallet.address));
    }, [wallet, isWalletAuthorizedForStaking]);

    return <BaseBuildingAssetDialog
        asset={asset}
        onClose={onClose}
        addGiants={addGiants}
        removeGiants={removeGiants}
        residentsCapacity={residentsCapacity}
        currentResidents={currentResidents}
        removable={currentResidents.length < 1}
        availableResidentQty={totalAvailableGiants}
        availableResidentsList={
            <ResidentsList
                residents={availableResidents}
                hasMore={hasMoreWalletGiants}
                next={() => {
                    if (hasMoreWalletGiants && walletGiantsStatus !== "loading") {
                        dispatch(fetchInventoryGiants({}));
                    }
                }}
                selectable={true}
                selectableQty={residentsCapacity - currentResidents.length}
                action={isWalletAuthorizedForStaking ? undefined : {
                    disabled: authorizing,
                    loading: authorizing,
                    onClick: sendAuthorizationTx,
                    label: authorizing ? "Authorizing" : "Authorize staked giants usage"
                }}
            />
        }
    />
};


const splitSelectedResidents = (residents: SelectedResident[]): {
    staked: SelectedResident[],
    notStaked: SelectedResident[]
} => {
    return residents.reduce(
        (
            acc: { staked: SelectedResident[], notStaked: SelectedResident[] },
            resident
        ) => {
            if (resident.staked) {
                acc.staked.push(resident);
            } else {
                acc.notStaked.push(resident);
            }

            return acc;
        },
        {staked: [], notStaked: []}
    );
}

