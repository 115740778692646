import GridTile from "./components/GridTile";
import {GridNeighbours, IGridAsset, IPosition} from "../common/types/gameboard";
import {ColorReplaceFilter} from "@pixi/filter-color-replace";
import {createBlankLand, getGridCols, getGridRows, isBlankLand} from "../utils/assets";
import GridAssetsList from "./GridAssetsList";
import {Graphics} from "pixi.js";

export default class Grid {
    private _grid: GridAssetsList<GridTile>;
    private _width: number = 0;
    private _height: number = 0;
    private _interactive = false;

    constructor(grid: GridAssetsList<GridTile>) {
        this._grid = grid;
        this.computeGridSize();
    }

    public static async fromTiles(tiles: GridTile[]): Promise<Grid> {
        return new Grid(new GridAssetsList<GridTile>(tiles));
    }


    get grid(): GridAssetsList<GridTile> {
        return this._grid;
    }

    get width(): number {
        return this._width;
    }

    get height(): number {
        return this._height;
    }

    public getTile(position: IPosition) {
        return this._grid.get(position);
    }


    public getTilesUnderAsset(asset: IGridAsset, onlyAvailableTiles = false): GridTile[] {
        // const shape = asset.shape.withOffset(asset.getGlobalPosition());
        const container = this._grid.get({x: 0, y: 0})?.sprite.parent;
        if (!container) {
            return [];
        }
        const shape = asset.shape.withOffset(container.toLocal(asset.getGlobalPosition()));
        const tiles = this._grid.findMany(tile => {
            const tileCenter = container.toLocal(tile.getGlobalCenterPosition());
            return shape.contains(tileCenter.x, tileCenter.y);
        });

        if (onlyAvailableTiles) {
            return tiles.filter(tile => tile.isLand && tile.empty && tile.status === "complete");
        }

        return tiles;
    }

    public flagTilesAvailable(tiles: GridTile[]) {
        const filter = new ColorReplaceFilter(0xF3FE84, 0x47FB31);
        tiles.filter(tile => tile.isLand).forEach(tile => {
            tile.sprite.filters = [filter];
        });
    }

    public clearAvailabilityFlag() {
        for (let tile of this._grid) {
            tile.sprite.filters = [];
        }
    }

    public async addTiles(...tiles: GridTile[]) {
        this._grid.add(tiles, {withoutSort: true});

        return this;
    }

    public addOrReplaceTile(tile: GridTile) {
        this._grid.remove(tile.position, {withoutSort: true});
        this._grid.add(tile);
    }

    public setInteractive(value: boolean) {
        this._interactive = value;
        for (let tile of this._grid) {
            if (!tile.isBlankLand) {
                tile.setInteractive(value);
                if (!value) {
                    tile.selected = false;
                }
            }
        }
    }

    public setScale(scale: number) {
        for (let tile of this._grid) {
            tile.setScale(scale);
        }
    }

    protected computeGridSize() {
        const rows = this._grid.reduce((rows: { [key: number]: number }, tile: GridTile) => {
            for (let idx = tile.position.y; idx < (tile.position.y + tile.height); idx++) {
                rows[idx] = (rows[idx] ?? 0) + tile.width;
            }
            return rows;
        }, {})

        this._height = Object.keys(rows).length;
        this._width = Object.values(rows).reduce((size: number, rowSize: number) => {
            return Math.max(size, rowSize);
        }, 0);
    }


    public padGrid() {
        if (this._grid.length < 1) {
            const padTile = createBlankLand({x: 0, y: 0});
            this._grid.add(padTile, {withoutSort: true});

            return;
        }

        const canHaveNeighbours = (tile: GridTile): boolean => tile.status === "complete"
            && (!tile.isBlankLand || (tile.isBlankLand && tile.selected));

        const rows = getGridRows(this._grid);
        for (let rowIdx of Object.keys(rows)) {
            const rowNumber = parseInt(rowIdx);
            const row = rows[rowNumber];
            for (let tile of row.tiles) {
                if (canHaveNeighbours(tile)) {
                    // If the tile is not the most left one, then
                    // we'll add a blank tile on it's left
                    // if there isn't one already.
                    if (tile.position.x > 0) {
                        const leftTile = row.tiles.find(t => t.position.x === tile.position.x - 1);
                        if (!leftTile) {
                            const padTile = createBlankLand({x: tile.position.x - 1, y: rowNumber});
                            this._grid.add(padTile, {withoutSort: true});
                        }
                    }

                    // If there is no tile on current tile's right side
                    // then we'll add a blank one.
                    const rightTile = row.tiles.find(t => t.position.x === tile.position.x + 1);
                    if (!rightTile) {
                        const padTile = createBlankLand({x: tile.position.x + 1, y: rowNumber});
                        this._grid.add(padTile, {withoutSort: true});
                    }
                }
            }

        }

        const cols = getGridCols(this._grid);
        for (let colIdx of Object.keys(cols)) {
            const colNumber = parseInt(colIdx);
            const col = cols[colNumber];
            for (let tile of col.tiles) {
                if (canHaveNeighbours(tile)) {
                    // If the tile is not the top most one, then
                    // we'll add a blank one on top of it,
                    // if there isn't one already placed
                    if (tile.position.y > 0) {
                        const topTile = col.tiles.find(t => t.position.y === tile.position.y - 1);
                        if (!topTile) {
                            const padTile = createBlankLand({x: colNumber, y: tile.position.y - 1});
                            this._grid.add(padTile, {withoutSort: true});
                        }
                    }

                    // If there is no tile bellow the current one, then
                    // we'll add a blank one.
                    const bottomTile = col.tiles.find(t => t.position.y === tile.position.y + 1);
                    if (!bottomTile) {
                        const padTile = createBlankLand({x: colNumber, y: tile.position.y + 1});
                        this._grid.add(padTile, {withoutSort: true});
                    }
                }
            }


        }
        this._grid.forceSort();

    }

    public removePad(keepSelected = false) {
        this._grid.discard((tile) => !tile.isBlankLand
            || (keepSelected && tile.isBlankLand && tile.selected)
        );
    }

    public getPadTiles() {
        return this._grid.reduce((tiles: GridTile[], tile: GridTile) => {
            if (tile.isBlankLand) {
                tiles.push(tile);
            }

            return tiles;
        }, []);
    }

    public getNeighbours(position: IPosition): GridNeighbours {
        const west = this.grid.get({x: position.x - 1, y: position.y}) ?? null;
        const east = this.grid.get({x: position.x + 1, y: position.y}) ?? null;
        const north = this.grid.get({x: position.x, y: position.y - 1}) ?? null;
        const south = this.grid.get({x: position.x, y: position.y + 1}) ?? null;

        return {north, south, west, east};
    }
};