import React, {PropsWithChildren, useState} from "react";
import {classNames, ucFirst} from "../../../utils/presentation";

type Props = {
    onChange?: (label: string) => void;
    children: React.ReactElement[];
};
export default function Tabs({children, onChange}: PropsWithChildren<Props>) {
    const [activeTab, setActiveTab] = useState(children[0].props.label);

    return (
        <div className="flex flex-col items-center flex-grow w-full">
            <div className="flex justify-start items-center space-x-6 w-full">
                {children.map((child: any) => {
                    const {label, disabled, onSelected} = child.props;

                    return (
                        <button
                            key={label}
                            disabled={disabled}
                            className={classNames(
                                "py-2 text-xl",
                                "disabled:cursor-not-allowed disabled:opacity-50 disabled:no-underline",
                                activeTab === label
                                    ? "text-theme-text font-semibold"
                                    : "text-theme-text/50 underline underline-offset-4",
                            )}
                            onClick={() => {
                                setActiveTab(label);
                                if (onChange) {onChange(label);}
                                if (onSelected) {onSelected();}
                            }}
                        >
                            {ucFirst(label)}
                        </button>
                    );
                })}
            </div>
            <div className="flex flex-col flex-grow w-full bg-primary-lighter">
                {children.map((child: any) => {
                    if (child.props.label === activeTab) return child.props.children;
                })}
            </div>
        </div>
    );
}