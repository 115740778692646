import {SVGProps} from "react";

export default function IconHourGlass({...props}: SVGProps<any>) {
    return (
        <div className="flex items-center justify-center rounded-full p-1" {...props}>
            <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.7887 9.6875C18.7887 14.34 15.0037 18.125 10.3512 18.125C6.25562 18.125 2.82687 15.1763 2.0725 11.2912L1.7925 11.6475C1.57875 11.9194 1.185 11.9656 0.915 11.7525C0.64375 11.5388 0.59625 11.1462 0.81 10.875L2.04687 9.30125C2.14937 9.17062 2.3 9.08625 2.465 9.06687C2.62937 9.04562 2.79625 9.09437 2.92562 9.1975L4.51187 10.4525C4.78312 10.6669 4.82875 11.06 4.61437 11.33C4.40062 11.6013 4.00812 11.6481 3.73687 11.4325L3.30437 11.0906C3.96125 14.3813 6.875 16.875 10.3512 16.875C14.3144 16.875 17.5387 13.6506 17.5387 9.6875C17.5387 5.72437 14.3137 2.5 10.3512 2.5C10.0056 2.5 9.72625 2.22062 9.72625 1.875C9.72625 1.52938 10.0056 1.25 10.3512 1.25C15.0037 1.25 18.7887 5.035 18.7887 9.6875Z" fill="#FF8A17"/>
                <path d="M7.68308 2.14502C7.59495 1.81127 7.79433 1.4694 8.12808 1.38127C8.45683 1.29377 8.8037 1.4919 8.89183 1.82627L8.9762 2.14565C9.06433 2.4794 8.86495 2.82127 8.5312 2.9094C8.2012 2.9969 7.8562 2.80127 7.76745 2.4644L7.68308 2.14502Z" fill="#FF8A17"/>
                <path d="M5.81692 3.08944C5.64629 2.78944 5.75129 2.40756 6.05067 2.23694C6.35004 2.06694 6.73192 2.17069 6.90317 2.47069L7.06629 2.75694C7.23692 3.05694 7.13192 3.43881 6.83254 3.60944C6.53317 3.77944 6.15129 3.67569 5.98004 3.37569L5.81692 3.08944Z" fill="#FF8A17"/>
                <path d="M4.27752 4.49745C4.03314 4.25308 4.03314 3.85808 4.27752 3.6137C4.52189 3.36933 4.91689 3.36933 5.16127 3.6137L5.38252 3.83495C5.62689 4.07933 5.62689 4.47433 5.38252 4.7187C5.13814 4.96308 4.74314 4.96308 4.49877 4.7187L4.27752 4.49745Z" fill="#FF8A17"/>
                <path d="M2.9037 5.39863C3.0712 5.09738 3.45245 4.98926 3.7537 5.15738L4.0337 5.31363C4.33558 5.48176 4.4437 5.86239 4.27558 6.16364C4.10808 6.46426 3.72808 6.57301 3.42558 6.40489L3.14558 6.24864C2.8437 6.08051 2.73558 5.69988 2.9037 5.39863Z" fill="#FF8A17"/>
                <path d="M2.04497 7.46133C2.13497 7.12696 2.48122 6.93008 2.81122 7.02008L3.12372 7.10446C3.45747 7.19446 3.65497 7.53696 3.56497 7.87071C3.47435 8.20696 3.1281 8.40071 2.79872 8.31196L2.48622 8.22758C2.15247 8.13821 1.9556 7.79508 2.04497 7.46133Z" fill="#FF8A17"/>
                <path d="M6.875 5.625C6.875 5.97 7.15437 6.24937 7.49937 6.25C7.50125 6.80813 7.53625 7.52625 7.69 7.89688C7.95562 8.53313 8.57625 9.1075 9.29937 9.6875C8.57625 10.2675 7.95562 10.8419 7.69 11.4787C7.53562 11.8487 7.50125 12.5675 7.49937 13.125C7.15437 13.125 6.875 13.4044 6.875 13.75C6.875 14.0956 7.15438 14.375 7.5 14.375H13.125C13.4706 14.375 13.75 14.0956 13.75 13.75C13.75 13.405 13.4706 13.1256 13.1256 13.125C13.1237 12.5669 13.0887 11.8487 12.9344 11.4781C12.6687 10.8419 12.0481 10.2675 11.325 9.6875C12.0481 9.1075 12.6687 8.53312 12.9344 7.89625C13.0887 7.52625 13.1231 6.8075 13.1256 6.25C13.4706 6.24937 13.75 5.97 13.75 5.625C13.75 5.27938 13.4706 5 13.125 5H7.5C7.15438 5 6.875 5.27938 6.875 5.625ZM11.875 13.125H8.75C8.75312 12.58 8.78938 12.0969 8.84438 11.9594C9.0225 11.5325 9.66 10.9925 10.3131 10.48C10.9656 10.9925 11.6038 11.5325 11.7813 11.9588C11.8363 12.0963 11.8719 12.5794 11.875 13.125ZM10.3125 8.895C9.66 8.3825 9.02188 7.8425 8.84438 7.41625C8.78875 7.27875 8.75312 6.79563 8.75 6.25H11.875C11.8719 6.795 11.8356 7.27812 11.7806 7.41562C11.6031 7.84312 10.965 8.3825 10.3125 8.895Z" fill="#FF8A17"/>
            </svg>
        </div>
    );
}