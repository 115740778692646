import {useEffect, useMemo, useState} from "react";
import {HiCheck, HiXMark} from "react-icons/hi2";
import {useSelector} from "react-redux";
import {
  ActionCommitError, ActionCommitPending,
  ActionCommitSuccess,
  AssetEventPayload,
  GameActionEnum,
  GameActionKey,
} from "../common/types/actions";
import {IPosition} from "../common/types/gameboard";
import {InventoryAsset} from "../common/types/inventory";
import {PartialVillageAsset} from "../common/types/repository";
import ButtonSquare from "../components/ButtonSquare";
import SelectedAssetDialog from "../components/game/asset-dialog/SelectedAssetDialog";
import AssetInfoDialog from "../components/game/AssetInfoDialog";
import LandQty from "../components/game/LandQty";
import TransferEgldDialog from "../components/game/TransferEgldDialog";
import TxNotificationsBox from "../components/game/tx-notifications/TxNotificationsBox";
import TxNotificationsStatus from "../components/game/tx-notifications/TxNotificationsStatus";
import Inventory from "../components/inventory/Inventory";
import RequiresAuth from "../components/RequiresAuth";
import TilesMenu from "../components/TilesMenu";
import {TOKEN_LAND} from "../config/assets";
import GridAsset from "../gameboard/components/GridAsset";
import GridTile from "../gameboard/components/GridTile";
import Manager from "../gameboard/Manager";
import InitGameScene from "../gameboard/scenes/InitGameScene";
import VillageScene from "../gameboard/scenes/VillageScene";
import {useApi} from "../hooks/useApi";
import {useAuth} from "../hooks/useAuth";
import {useSocket} from "../hooks/useSocket";
import {useAppDispatch} from "../hooks/useStore";
import {useVillageState} from "../hooks/useVillageState";
import {
  fetchInventoryAssets,
  fetchLandTiles,
  fetchRewards,
  fetchInventoryGiants,
  setAddress as setInventoryAddress,
  fetchSkins,
  addLockedItems,
  removeLockedItems,
  fetchTotalWalletGiants,
} from "../redux/slices/inventorySlice";
import {addOrReplaceTransaction} from "../redux/slices/transactionsSlice";
import {
  setSelectedAsset as setSelectedAssetState
} from "../redux/slices/villageSlice";
import {setAddress as setWalletAddress, fetchStakedGiants} from "../redux/slices/walletAssetsSlice";
import store, {RootState} from "../redux/store";
import TxActionBuilder from "../services/game-actions/TxActionBuilder";
import TxActionService from "../services/game-actions/TxActionService";
import VillageService from "../services/VillageService";
import { getActionEventChannelName, getTxBroadcastChannelName } from "../utils/events";
import {buildActionNotificationInfo, getChainConfig} from "../utils/transactions";
import {classNames} from "../utils/presentation";
import LoadingScreen from "../components/LoadingScreen";
import NpcsBox from "../components/game/quests/NpcsBox";
import QuestsPopup from "../components/game/quests/QuestsPopup";
import UpgradeVillageDialog from "../components/game/asset-dialog/UpgradeVillageDialog";
import {useRouter} from "next/router";
import {storeReferralCode} from "../utils/referrals";
import ReferralsPopup from "../components/game/referral-rewards/ReferralsPopup";
import {ChainConfig} from "../common/types";
import TxNotEnoughFundsError from "../errors/TxNotEnoughFundsError";
import {useError} from "../hooks/useError";
import ActionAreaTop from "../components/game/ActionAreaTop";
import Head from "next/head";
import {usePopups} from "../hooks/usePopups";
import {PopupEnum} from "../common/types/components";
import LandInfoToast from "../components/game/LandInfoToast";
import PlaceLandContext from "../services/contexts/PlaceLandContext";
import RemoveLandContext from "../services/contexts/RemoveLandContext";
import ClaimResourcesDialog from "../components/game/resource-harvesting/ClaimResourcesDialog";
import {gameAction} from "../config/actions";
import {useFarmResources} from "../hooks/useFarmResources";
import LeaderboardDialog from "../components/leaderboards/LeaderboardDialog";
import {useLeaderboard} from "../hooks/useLeaderboard";
import { buildContextFromRequestParams } from "../utils/actions";
import { useQuestsState } from "../hooks/useQuestsState";


export default function Home() {
  const router = useRouter();
  const {wallet, village, account} = useAuth();
  const {api} = useApi();
  const socket = useSocket();
  const {showError} = useError();
  // todo: use redux
  const [selectedTiles, setSelectedTiles] = useState<IPosition[]>([]);
  const [placingLand, setPlacingLand] = useState(false);
  const [placingAsset, setPlacingAsset] = useState(false);
  const [removingLand, setRemovingLand] = useState(false);
  const [currentAsset, setCurrentAsset] = useState<InventoryAsset | null>(null);
  const selectedAsset = useSelector((state: RootState) => state.village.selectedAsset);
  const isBuildingMode = useMemo(() => {
    return placingLand || placingAsset || removingLand || !!currentAsset || !!selectedAsset;
  }, [placingAsset, placingLand, removingLand, currentAsset, selectedAsset]);
  const { waitingForQuests, setWaitingForQuests } = useQuestsState();
  const {
    loadVillageResidents,
    reloadVillage,
    loadVillageLandQty,
    increaseUpgradingBuildingsQty,
    decreaseUpgradingBuildingsQty,
    increasePlacedBuildingsQty,
    decreasePlacedBuildingsQty
  } = useVillageState();
  const {fetchLatestClaim, fetchHarvestedResources} = useFarmResources();
  const dispatch = useAppDispatch();
  const setSelectedAsset = (asset: PartialVillageAsset | null) => {
    dispatch(setSelectedAssetState(asset));
  };
  const [chainConfig, setChainConfig] = useState<ChainConfig | null>(null);
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  const {isActive, showPopup, closePopup} = usePopups();
  const {setClaiming, fetchClaimablePrizesAmount} = useLeaderboard();

  const setCurrentAssetInfo = (asset: InventoryAsset | null) => {
    setCurrentAsset(asset);
    if (asset) {
      showPopup(PopupEnum.ASSET_INFO, {state: {asset}});
    } else {
      closePopup(PopupEnum.ASSET_INFO);
    }
  }

  const itemsLocker = {
    addLockedItems: (items: Record<string, number>) => dispatch(addLockedItems(items)),
    removeLockedItems: (items: Record<string, number>) => dispatch(removeLockedItems(items)),
  };

  useEffect(() => {
    if (!router.isReady) {return;}
    const {referral} = router.query;
    if (referral) {
      storeReferralCode(referral as string);
    }
  }, [router.isReady]);

  useEffect(() => {
    (async () => {
      const config = await getChainConfig();
      setChainConfig(config);
    })();
  }, []);

  useEffect(() => {
    if (account?.game_wallet) {
      dispatch(setInventoryAddress(account.game_wallet));
      dispatch(fetchLandTiles());
    }
    if (account?.primary_wallet) {
      dispatch(setWalletAddress(account.primary_wallet));
    }
  }, [account]);

  useEffect(() => {
    const manager = Manager.getInstance();
    if (!manager.initialised) {
      return;
    }
    const onAssetClicked = ({asset}: { asset: GridAsset | null }) => {
      if (placingAsset) {
        return;
      }
      if (!asset) {
        if (isActive(PopupEnum.SELECTED_ASSET)) {
          closePopup(PopupEnum.SELECTED_ASSET);
        }
        setSelectedAsset(null);
        return;
      }
      setSelectedAsset({
        asset_token: asset.token!.id,
        asset_token_nonce: asset.token!.nonce,
        position: {...asset.position},
        status: asset.status,
        type: asset.type,
        attributes: asset.attributes,
        upgrade_start_date: asset.upgradeState.startDate,
        upgrade_finish_date: asset.upgradeState.finishDate,
        upgrade_payment: asset.upgradeState.payment,
        upgrade_instant_finish_date: asset.upgradeState.instantFinishDate,
        upgrade_instant_finish_tax_payment: asset.upgradeState.instantFinishTaxPayment,
      });
      showPopup(PopupEnum.SELECTED_ASSET);
    };
    manager.subscribeToAction("ASSET_CLICKED", onAssetClicked);

    return () => {
      manager.unsubscribeFromAction("ASSET_CLICKED", onAssetClicked);
    };
  });

  useEffect(() => {
    if (!wallet || !api || !village || !chainConfig) {
      return;
    }
    const manager = Manager.getInstance();

    if (manager.initialised || manager.initialising) {
      return;
    }
    const villageService = new VillageService(api);

    (async () => {
      const txActionService = new TxActionService(api, new TxActionBuilder(), chainConfig);
      await manager.init(wallet!, txActionService, itemsLocker);
      await manager.changeScene(new InitGameScene());
      await manager.changeScene(new VillageScene(village.id, villageService));
      setShowLoadingScreen(false);
      socket.connect();

      const actionEventHandler = (payload: AssetEventPayload) => {
        Manager.getInstance().publishEvent("GAME_ACTION", payload);
      };
      const txBroadcastEventHandler = (payload: any) => {
        if (payload.success) {
          dispatch(addOrReplaceTransaction(buildActionNotificationInfo(
              payload.data.action,
              payload.jobId
          )));
        } else {
          const actionContext = buildContextFromRequestParams(payload.data.action);
          Manager.getInstance().publishEvent(
              "GAME_ACTION_COMMIT_ERROR",
              { actionContext, error: payload.message } satisfies ActionCommitError<GameActionKey>
          )
        }
      }
      const resizeHandler = () => {
        Manager.getInstance().onResize();
      };

      socket.on(getActionEventChannelName(village.id), actionEventHandler);
      socket.on(getTxBroadcastChannelName(village.id), txBroadcastEventHandler)
      window.addEventListener("resize", resizeHandler);
      manager.subscribeToAction("LAND_TILE_SELECTED", ({asset}: { asset: GridTile }) => {
        setSelectedTiles((tiles) => [...tiles, asset.position]);
      });
      manager.subscribeToAction("LAND_TILE_UNSELECTED", ({asset}: { asset: GridTile }) => {
        const position = asset.position;
        setSelectedTiles((tiles) => {
          const t = [...tiles];
          const idx = t.findIndex((p) => p.x === position.x && p.y === position.y);
          if (idx > -1) {
            t.splice(idx, 1);
          }
          return t;
        });
      });

      manager.subscribeToAction("INIT_PLACE_LAND", () => {
        setPlacingLand(true);
      });
      manager.subscribeToAction("INIT_PLACE_ASSET", () => {
        setPlacingAsset(true);
      });
      manager.subscribeToAction("INIT_REMOVE_LAND", () => {
        setRemovingLand(true);
      });

      manager.subscribeToAction("CANCEL_PLACE_LAND", () => {
        setSelectedTiles([]);
        setPlacingLand(false);
      });

      manager.subscribeToAction("CONFIRM_PLACE_LAND", () => {
        setSelectedTiles([]);
        setPlacingLand(false);
      });

      manager.subscribeToAction("CANCEL_REMOVE_LAND", () => {
        setSelectedTiles([]);
        setRemovingLand(false);
      });

      manager.subscribeToAction("CONFIRM_REMOVE_LAND", () => {
        setSelectedTiles([]);
        setRemovingLand(false);
      });

      manager.subscribeToAction("CANCEL_PLACE_ASSET", () => setPlacingAsset(false));
      manager.subscribeToAction("CONFIRM_PLACE_ASSET", () => setPlacingAsset(false));

      manager.subscribeToAction("GAME_ACTION", (event: AssetEventPayload) => {
        dispatch(addOrReplaceTransaction(buildActionNotificationInfo(event.action)));
        // todo: handle failed txs
        if (event.action.status !== "complete") {
          return;
        }
        switch (event.action.action) {
          case gameAction.PLACE_BUILDING:
            dispatch(fetchInventoryAssets({reset: true}));
            increasePlacedBuildingsQty();
            break;
          case gameAction.PLACE_LAND:
            dispatch(fetchInventoryAssets({reset: true}));
            dispatch(fetchLandTiles());
            loadVillageLandQty();
            break;
          case gameAction.ADD_GIANTS_TO_HOUSE:
          case gameAction.REMOVE_GIANTS_FROM_HOUSE:
            dispatch(fetchInventoryAssets({reset: true}));
            dispatch(fetchInventoryGiants({reset: true}));
            dispatch(fetchTotalWalletGiants());
            loadVillageResidents();
            break;
          case gameAction.ADD_STAKED_GIANTS_TO_HOUSE:
          case gameAction.REMOVE_STAKED_GIANTS_FROM_HOUSE:
            dispatch(fetchStakedGiants({}));
            loadVillageResidents();
            break;
          case gameAction.ADD_GIANTS_TO_WORK:
          case gameAction.REMOVE_GIANTS_FROM_WORK:
            loadVillageResidents();
            break;
          case gameAction.REMOVE_BUILDING:
            dispatch(fetchInventoryAssets({reset: true}));
            dispatch(fetchRewards({address: account!.game_wallet!}));
            decreasePlacedBuildingsQty();
            break;
          case gameAction.CLAIM_REWARDS:
          case gameAction.ASSETS_TRANSFER:
            dispatch(fetchRewards({address: account!.game_wallet!}));
            dispatch(fetchInventoryAssets({reset: true}));
            dispatch(fetchLandTiles());
            break;
          case gameAction.REMOVE_LAND:
            dispatch(fetchInventoryAssets({reset: true}));
            dispatch(fetchLandTiles());
            loadVillageLandQty();
            break;
          case gameAction.COMPLETE_UPGRADE:
            setSelectedAssetFromEvent(event);
            decreaseUpgradingBuildingsQty();
            break;
          case gameAction.INIT_UPGRADE:
            setSelectedAssetFromEvent(event);
            increaseUpgradingBuildingsQty();
            break;
          case gameAction.UPGRADE_VILLAGE:
            reloadVillage();
            break;
          case gameAction.SET_BUILDING_SKIN:
          case gameAction.REMOVE_BUILDING_SKIN:
            dispatch(fetchSkins());
            break;
          case gameAction.CLAIM_RESOURCES:
            fetchLatestClaim();
            fetchHarvestedResources();
            break;
          case gameAction.COMPLETE_QUEST:
            wallet?.refresh();
            break;
          case gameAction.CLAIM_LEADERBOARD_PRIZES:
            fetchClaimablePrizesAmount();
            setClaiming(false);
            break;
        }
      });

      manager
          .subscribeToAction(
              "GAME_ACTION_COMMIT_SUCCESS",
              ({ actionContext, action, txHash }: ActionCommitSuccess<GameActionKey>) => {
                if (actionContext.actionType === gameAction.COMPLETE_QUEST) {
                  setWaitingForQuests(false);
                }
                dispatch(
                    addOrReplaceTransaction({
                      id: action!.transaction_hash || action!.id,
                      action: actionContext.actionType,
                      hash: txHash || "",
                      status: action?.status === "complete" ? "success" : "pending",
                      timestamp: actionContext.timestamp,
                    })
                );
              }
          )
          .subscribeToAction(
              "GAME_ACTION_COMMIT_PENDING",
              ({ actionContext, jobId }: ActionCommitPending<GameActionKey>) => {
                if (actionContext.actionType === gameAction.COMPLETE_QUEST) {
                  setWaitingForQuests(false);
                }
                dispatch(
                    addOrReplaceTransaction({
                      id: jobId,
                      action: actionContext.actionType,
                      hash: "",
                      status: "pending",
                      timestamp: actionContext.timestamp,
                    })
                );
              }
          )
          .subscribeToAction(
              "GAME_ACTION_COMMIT_ERROR",
              ({actionContext, error}: ActionCommitError<GameActionKey>) => {
                if (actionContext.actionType === gameAction.COMPLETE_QUEST) {
                  setWaitingForQuests(false);
                }
                console.log(error);
                if (error instanceof TxNotEnoughFundsError) {
                  showError("Not enough funds", {
                    dismissable: false,
                    action: "top-up"
                  });
                }
                dispatch(
                    addOrReplaceTransaction({
                      id: actionContext.actionType + actionContext.timestamp,
                      action: actionContext.actionType,
                      hash: "",
                      status: "failed",
                      timestamp: actionContext.timestamp,
                    })
                );
          });

      return () => {
        socket.off(getActionEventChannelName(village.id), actionEventHandler);
        socket.off(getTxBroadcastChannelName(village.id), txBroadcastEventHandler);
        window.removeEventListener("resize", resizeHandler);
        Manager.getInstance().stop();
      };
    })();
  }, [wallet, chainConfig]);

  const setSelectedAssetFromEvent = (event: AssetEventPayload) => {
    const asset = (event.assets?.length ?? 0) > 0 ? event.assets![0] : null;
    if (!asset) {
      return;
    }
    const selectedAsset = store.getState().village.selectedAsset;
    if (!selectedAsset) {
      return;
    }
    if (
        selectedAsset.position.x === asset.position.x &&
        selectedAsset.position.y === asset.position.y &&
        selectedAsset.asset_token === asset.asset_token
    ) {
      setSelectedAsset(asset);
    }
  }

  const placeLand = async () => {
    const actionContext = new PlaceLandContext({
      token: {
        id: TOKEN_LAND,
        nonce: 1,
        quantity: selectedTiles.length,
      },
      positions: selectedTiles,
      villageId: village!.id,
    });
    Manager.getInstance().publishEvent("CONFIRM_PLACE_LAND", actionContext.payload);
    await Manager.getInstance().commitAction(actionContext);
  };

  const removeLand = async () => {
    const actionContext = new RemoveLandContext({
      token: {
        id: TOKEN_LAND,
        nonce: 1,
        quantity: selectedTiles.length,
      },
      positions: selectedTiles,
      villageId: village!.id,
    });
    Manager.getInstance().publishEvent("CONFIRM_REMOVE_LAND", actionContext.payload);
    await Manager.getInstance().commitAction(actionContext);
  };

  useEffect(() => {
    if (account?.primary_wallet) {
      dispatch(fetchStakedGiants({address: account.primary_wallet}));
    }
    if (account?.game_wallet) {
      dispatch(fetchInventoryGiants({address: account.game_wallet, reset: true}));
      dispatch(fetchTotalWalletGiants());
      dispatch(fetchSkins());
    }
  }, [account]);

  const toggleInventory = () => {
    if (isActive(PopupEnum.INVENTORY)) {
      closePopup(PopupEnum.INVENTORY);
    } else {
      showPopup(PopupEnum.INVENTORY);
    }
  }

  return (
      <RequiresAuth>
        <Head>
          <meta name="theme-color" media="(prefers-color-scheme: light)" content="#FFFEF0"/>
        </Head>
        {showLoadingScreen && <LoadingScreen showOnTop={true}/>}
        <div
            className="h-[100svh] w-[100svw]"
        >
          <div
              id="gameboard-container"
              className="h-full w-full relative overflow-hidden"
          >
            <ActionAreaTop/>
            <canvas id="gameboard" className="absolute top-0 left-0"/>
            <AssetInfoDialog onClose={() => {setCurrentAssetInfo(null)}}/>
            <SelectedAssetDialog
                onClose={() => setSelectedAsset(null)}
            />
            <Inventory setCurrentAsset={setCurrentAssetInfo}/>
            <TransferEgldDialog/>
            <TxNotificationsBox/>
            <QuestsPopup/>
            <UpgradeVillageDialog/>
            <div className="absolute left-2 top-32 p-2 z-10">
              <TxNotificationsStatus/>
            </div>
            <ReferralsPopup/>
            <ClaimResourcesDialog/>
            <LeaderboardDialog/>

            {/*Right Menu*/}
            <div
                className="absolute top-36 right-2 flex flex-col items-end justify-start p-2 space-y-6 z-10">
              <NpcsBox/>
            </div>
            {/*Bottom Left menu*/}
            <div
                className="absolute bottom-6 left-2 flex items-end justify-start p-2 space-x-6 z-10">
              {/*<button*/}
              {/*    className="focus:outline-none focus:outline-primary-darkest"*/}
              {/*    style={{*/}
              {/*      outlineOffset: "-14px",*/}
              {/*    }}*/}
              {/*    onClick={() => {*/}
              {/*      const confirm = window.confirm("Are you sure you want to leave the village?");*/}
              {/*      if (confirm) {*/}
              {/*      window.location.href = "https://app.giantsvillage.com";*/}
              {/*    }*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <IconG className="w-24 h-24"/>*/}
              {/*</button>*/}
              <ButtonSquare size="large" onClick={toggleInventory}>
                <img src="/inventory-icon.png" alt="Inventory icon"/>
              </ButtonSquare>
              <TilesMenu disabled={isBuildingMode}/>
              {(placingLand || removingLand) && (
                  <div className="flex items-center space-x-2">
                    {placingLand && <LandQty selected={selectedTiles.length}/>}
                    <ButtonSquare
                        size="medium"
                        disabled={selectedTiles.length < 1}
                        onClick={() => {
                          if (placingLand) {
                            placeLand();
                          }
                          if (removingLand) {
                            removeLand();
                          }
                        }}
                    >
                      <HiCheck className={classNames(
                          selectedTiles.length < 1 ? "text-gray-400" : "text-green-500",
                          "w-full h-full"
                      )}/>
                    </ButtonSquare>
                    <ButtonSquare
                        size="medium"
                        onClick={() => {
                          if (placingLand) {
                            Manager.getInstance().publishEvent("CANCEL_PLACE_LAND", true);
                          }

                          if (removingLand) {
                            Manager.getInstance().publishEvent("CANCEL_REMOVE_LAND", true);
                          }
                        }}
                    >
                      <HiXMark className="w-full h-full text-red"/>
                    </ButtonSquare>
                  </div>
              )}
            </div>

            {process.env.NEXT_PUBLIC_NETWORK_ENV === "devnet" && (
            <div className="absolute bottom-0 w-full flex justify-center">
              <a
                  href="https://blog.giantsvillage.com/giants-village-devnet-sneak-peek-a33d340d098a"
                  target="_blank"
                  className=" p-2 opacity-50 text-xs uppercase underline"
              >
                Running devnet release 3
              </a>
            </div>
            )}

            {/*Bottom Right menu*/}
            <div
                className="absolute bottom-2 right-2 p-2 flex flex-col items-end justify-end space-y-4">

            </div>
            <LandInfoToast selectedLandQty={selectedTiles.length} open={placingLand}/>
          </div>
        </div>
      </RequiresAuth>
  );
};
