import {Container, Sprite, Texture} from "pixi.js";
import {IPosition} from "../../../common/types/gameboard";

type CreateButtonProps = {
    icon: string | Sprite;
    color?: number;
    width?: number;
    height?: number;
}

export default class Button extends Container {
    private background: Sprite;
    private icon: Sprite | null = null;

    constructor(width: number, height: number) {
        super();
        this.background = new Sprite(Texture.WHITE)
        this.background.width = width;
        this.background.height = height;
        this.background.x = 0;
        this.background.y = 0;
        this.addChild(this.background);
        this.eventMode = "static";
        this.cursor = "pointer";
    }

    public static create(
        {
            icon,
            color,
            width = 30,
            height = 30
        }: CreateButtonProps
    ) {
        const button = new Button(width, height);
        button.setIcon(icon);
        if (color) {
            button.setColor(color);
        }
        return button;
    }

    public setColor(color: number) {
        this.background.tint = color;
        return this;
    }

    public setIcon(icon: Sprite | string) {
        if (this.icon) {
            this.removeChild(this.icon);
        }
        if (icon instanceof Sprite) {
            this.icon = icon;
        } else {
            this.icon = this.createIcon(icon);
        }
        this.addChild(this.icon);

        return this;
    }

    public setPosition(x: number, y: number) {
        this.x = x;
        this.y = y;

        return this;
    }

    public setClickHandler(handler: () => void) {
        this.on("pointertap", handler);

        return this;
    }

    protected createIcon(filepath: string) {
        const icon = Sprite.from(filepath);
        icon.scale.set(0.8);
        icon.x = this.width / 2;
        icon.y = this.height / 2;
        icon.anchor.set(0.5);

        return icon;
    }
};