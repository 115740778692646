import {Container, Graphics, Sprite, Texture} from "pixi.js";
import {AssetType, IGridAsset, IPosition, IScene, ScaleSize} from "../../common/types/gameboard";
import Grid from "../Grid";
import {
    computeAssetScreenPosition,
    TILE_HEIGHT,
    TILE_WIDTH,
    validNeighbourTile
} from "../../utils/game";
import GridAsset from "./GridAsset";
import GridAssetsList from "../GridAssetsList";
import GridTile from "./GridTile";
import {isBlankLand} from "../../utils/assets";
import {type} from "os";
import {intersect} from "@hapi/hoek";
import Array = intersect.Array;
import Manager from "../Manager";
import {IToken} from "../../common/types/actions";

// todo: split the IScene interface. This class is not a scene.
export default class Village extends Container implements IScene {
    protected land: Grid;
    protected assets!: GridAssetsList<GridAsset>;
    protected isBlankLandVisible = false;

    constructor(land: Grid, assets: GridAsset[] = []) {
        super();
        this.land = land;
        this.drawGrid();
        this.initAssets(assets);
        this.markOccupiedLand([...this.assets]);
        this.redrawAssets();
        this.land.setInteractive(false);
    }

    /**
     * Width in grid tiles
     */
    get width(): number {
        return this.land.width;
    }

    /**
     * Height in grid tiles
     */
    get height(): number {
        return this.land.height;
    }

    /**
     * Width in pixels
     */
    get screenWidth(): number {
        return this.width * TILE_WIDTH;
    }

    /**
     * Height in pixels
     */
    get screenHeight(): number {
        return this.height * TILE_HEIGHT;
    }


    public hooverAsset(asset: GridAsset) {
        this.clearLandAvailability();
        const hooveredTiles = this.land.getTilesUnderAsset(asset, true)
            .filter(tile => !isBlankLand(tile));
        if (hooveredTiles.length) {
            this.land.flagTilesAvailable(hooveredTiles);
        }
    }

    public placeAsset(asset: GridAsset):  GridAsset | null {
        if (asset.type === "building") {
            return this.placeBuilding(asset);
        }

        return this.placeLand(asset);
    }


    public addAssets(assets: GridAsset[]) {
        const {
            buildingAssets,
            landAssets
        } = assets.reduce(
            (assets: { buildingAssets: GridAsset[], landAssets: GridTile[] }, asset) => {
                if (asset instanceof GridTile) {
                    assets.landAssets.push(asset);
                } else {
                    assets.buildingAssets.push(asset);
                }

                return assets;
            },
            {buildingAssets: [], landAssets: []}
        );

        if (landAssets.length) {
            this.land.grid.add(landAssets);
            this.land.padGrid();
            this.redrawVillage();
        }

        if (buildingAssets.length) {
            const _assets = this.computeSpritesPosition(buildingAssets);
            this.assets.add(_assets);
            this.markOccupiedLand(_assets);
            this.redrawAssets();
        }

    }

    public removeAsset(
        {
            position,
            type,
            redraw
        }: { position: IPosition, type: AssetType, redraw?: boolean }
    ): boolean {
        const _redraw = redraw ?? true;
        if (type === "land") {
            const asset = this.land.grid.get(position);
            if (!asset) {return false;}
            this.land.grid.remove(position);
            this.removeChild(asset.sprite);
            if (_redraw) {
                if (this.isBlankLandVisible) {
                    this.removePad(true);
                    this.land.padGrid();
                }
                this.redrawVillage();
            }

            return true;
        }

        const asset = this.assets.get(position);
        if (!asset) {return false;}
        if (!this.assets.remove(position)) {
            return false;
        }
        this.markOccupiedLand([asset], false);

        this.removeChild(asset.sprite);
        if (_redraw) {
            this.redrawAssets();
        }

        return true;

    }

    public removeAssets(
        assets: { position: IPosition, type: AssetType }[],
        redraw: boolean = true
    ) {
        for (let asset of assets) {
            this.removeAsset({...asset, redraw: false});
        }

        if (redraw) {
            this.redrawAssets();
        }
    }

    public showBlankLand() {
        if (this.isBlankLandVisible) {return;}
        this.isBlankLandVisible = true;
        this.land.padGrid();

        this.redrawVillage();
    }

    public hideBlankLand(keepSelected = false) {
        if (!this.isBlankLandVisible) {return;}
        if (keepSelected) {
            this.removePad(true);
            this.redrawVillage();
            return;
        }
        this.isBlankLandVisible = false;
        // The order of the methods matters
        this.redrawVillage();
        this.land.removePad();
    }


    public getNeighbours(asset: GridTile) {
        return this.land.getNeighbours(asset.position);
    }


    public canRemoveLand(asset: GridTile) {
        if (asset.status !== "complete") {return false;}
        const {north, south, west, east} = this.getNeighbours(asset);
        const validNeighbours = [north, south, west, east].filter(t => validNeighbourTile(t));
        if (validNeighbours.length > 0 && asset.position.x === 0 && asset.position.y === 0) {
            return false;
        }

        if (validNeighbours.length < 2) {return true;}
        for (let neighbour of validNeighbours) {
            const visited = new Set<string>().add(`${asset.position.x}-${asset.position.y}`);
            const canReachMainPoint = this.hasPathToMainTile(neighbour!, visited);
            if (!canReachMainPoint) {return false;}
        }

        return true;
    }

    public setInteractive(value: boolean) {
        this.land.setInteractive(value);
    }

    protected removePad(keepSelected = false) {
        let padTiles = this.land.getPadTiles();
        if (keepSelected) {
            // Remove only tiles that are not selected
            padTiles = padTiles.filter(t => !t.selected);
        }
        this.land.removePad(keepSelected);
        this.removeChild(...padTiles.map(t => t.sprite));
    }

    public hasPathToMainTile(tile: GridTile, visited: Set<string> = new Set()) {
        const isMainTile = (t: GridTile) => t.position.x === 0 && t.position.y === 0;
        const positionKey = (t: GridTile) => `${t!.position.x}-${t!.position.y}`;

        // We'll get all neighbours of this tile except itself,
        // and we'll do so until we reach the main
        // point or a dead end.
        const neighbours = Object.values(this.getNeighbours(tile))
            // Reject the unselected blank tiles, the tile itself,
            // and the already visited tiles
            .filter(t => validNeighbourTile(t)
                && (t!.position.x !== tile.position.x || t!.position.y !== tile.position.y)
                && !visited.has(positionKey(t!))
            );

        for (let neighbour of neighbours) {
            visited.add(positionKey(neighbour!));
            if (isMainTile(neighbour!)) {return true;}
            const hasPath = this.hasPathToMainTile(neighbour!, visited);
            if (hasPath) {return true;}
        }

        return false;

    }

    protected placeLand(asset: GridAsset): GridAsset | null {
        return null;
    }

    public canPlaceAsset(asset: GridAsset): boolean {
        return null !== this.findAssetPlaceTiles(asset);
    }

    public clearLandAvailability() {
        this.land.clearAvailabilityFlag();
    }

    public findAssetPlaceTiles(asset: GridAsset): GridTile[] | null {
        const availableTiles = this.land.getTilesUnderAsset(asset, true)
            .filter(tile => !isBlankLand(tile));
        const assetArea = asset.width * asset.height;
        // todo: Should also check the "grid shape"
        if (availableTiles.length < assetArea) {
            return null;
        }

        return availableTiles;
    }

    public getLandTile(position: IPosition) {
        return this.land.getTile(position);
    }

    public getAssetByToken(token: IToken): GridAsset | null {
        // Since the assets are NFTs, we can uniquely identify them by their token id and nonce
        const assets = this.assets
            .findMany(asset => asset.token?.id === token.id && asset.token?.nonce === token.nonce);

        return assets.length ? assets[0] : null;
    }

    protected placeBuilding(asset: GridAsset): GridAsset | null {
        const availableTiles = this.findAssetPlaceTiles(asset);
        if (availableTiles === null) {return null;}

        let newAsset = asset.clone();
        const firstTile = availableTiles[0];
        newAsset.position = firstTile.position;
        newAsset = this.computeAssetSpritePosition(newAsset)!;

        this.assets.add(newAsset);
        this.redrawAssets();

        availableTiles.forEach(tile => tile.setEmpty(false));
        this.clearLandAvailability();

        return newAsset;

    }

    protected drawAssets() {
        const sprites = this.assets.reduce((assets: Sprite[], tile) => {
            assets.push(tile.sprite);

            return assets;
        }, []);

        if (sprites.length) {
            this.addChild(...sprites);
        }
    }

    protected redrawAssets() {
        this.removeAllAssets();
        this.drawAssets();
    }

    protected removeAllAssets() {
        const sprites = this.assets.reduce((assets: Sprite[], tile) => {
            assets.push(tile.sprite);

            return assets;
        }, []);
        this.removeChild(...sprites);
    }

    protected removeGridTiles() {
        const children = this.land.grid.reduce((assets: Sprite[], tile) => {
            assets.push(tile.sprite);

            return assets;
        }, []);
        this.removeChild(...children);
    }

    protected redrawVillage() {
        this.removeGridTiles();
        this.removeAllAssets();
        this.drawGrid();
        this.drawAssets();
    }


    protected drawGrid() {
        const children = this.land.grid.reduce((assets: Sprite[], tile) => {
            if (!this.isBlankLandVisible && tile.isBlankLand) {
                return assets;
            }

            assets.push(tile.sprite);

            return assets;
        }, []);
        if (children.length) {
            this.addChild(...children);
        }
    }

    init(): Promise<boolean> {
        return Promise.resolve(true);
    }

    onResize(): void {
    }

    onTick(delta: number): void {
    }

    onCameraMove(): void {}

    onZoom({scale}: { scale: ScaleSize }): void {
        this.land.setScale(scale.x);
    }



    

    private addBuildings(assets: GridAsset[]) {
        const _assets = this.computeSpritesPosition(assets);
        this.assets.add(_assets);
        this.redrawAssets();
    }


    private computeAssetSpritePosition(asset: GridAsset): GridAsset | null {
        const tile = this.land.getTile(asset.position);
        if (!tile) {
            return null;
        }
        const position = tile.sprite.parent.toLocal(computeAssetScreenPosition(
            asset,
            tile.getGlobalPosition(),
            Manager.getInstance().stageScale
        ));
        asset.setScreenPosition(position);

        return asset;
    }

    private initAssets(assets: GridAsset[]) {
        const _assets = this.computeSpritesPosition(assets);

        this.assets = new GridAssetsList(_assets);
    }

    /**
     * Computes asset sprites screen coordinates AND removes the assets that are "off grid"
     * @param assets
     * @private
     */
    private computeSpritesPosition(assets: GridAsset[]): GridAsset[] {
        return assets.reduce((assets: GridAsset[], asset) => {
            const _asset = this.computeAssetSpritePosition(asset);
            if (_asset) {assets.push(_asset);}

            return assets;
        }, []);
    }

    private markOccupiedLand(assets: GridAsset[], occupied = true) {
        for (const asset of assets) {
            for (let position of asset.allPositions) {
                const tile = this.land.getTile(position);
                tile?.setEmpty(!occupied);
            }
            // const assetTiles = this.land.getTilesUnderAsset(asset);
            // assetTiles.forEach(tile => tile.setEmpty(!occupied));
        }
    }
};