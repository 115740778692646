import {PowerUpType} from "../../../common/types/attributes";
import {FcAlarmClock, FcDeployment} from "react-icons/fc";

export default function ResidentPowerUps({powerUps}: { powerUps: Map<PowerUpType, number> }) {
    const extraStorage = 100 * (powerUps.get(PowerUpType.STORAGE) ?? 0);
    const extraSpeed = 100 * (powerUps.get(PowerUpType.HARVEST_SPEED) ?? 0);

    return (
        <div className="flex items-center space-x-1">
            {extraSpeed > 0 && (
                <div
                    className="flex items-center text-xs"
                    data-tooltip-id="building-popup-tooltip"
                    data-tooltip-content={`Increase in production speed`}
                >
                    <FcAlarmClock className="mr-0.5 h-4 w-4"/>+{extraSpeed}%
                </div>
            )}
            {extraStorage > 0 && (
                <div
                    className="flex items-center text-xs"
                    data-tooltip-id="building-popup-tooltip"
                    data-tooltip-content={`Increase in storage`}
                >
                    <FcDeployment className="mr-0.5 h-4 w-4"/>+{extraStorage}%
                </div>
            )}
        </div>
    );

};