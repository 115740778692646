import {UserQuest} from "@elrond-giants/game-db/types/entities";
import {useSelector} from "react-redux";
import {selectRewardsResources} from "../../../../redux/slices/inventorySlice";
import {useMemo} from "react";
import ProgressBar from "../../../ProgressBar";
import RewardResourceIcon from "../../RewardResourceIcon";

type Requirement = {
    required: number;
    current: number;
    token: string;
    tokenNonce: number;
}

export default function QuestTypeDeliver({quest}: { quest: UserQuest }) {
    const resources = useSelector(selectRewardsResources);
    const requirements = useMemo((): Requirement[] => {
        if (!resources) {return [];}
        // @ts-ignore
        return (quest.requirements.payments).map(requirement => ({
            required: requirement.amount,
            current: Math.min(resources[requirement.token_identifier] ?? 0, requirement.amount),
            token: requirement.token_identifier,
            tokenNonce: requirement.token_nonce
        }));
    }, [quest.requirements, resources]);

    return (
        <div className="flex flex-col items-start space-y-1 w-full">
            {requirements.map(requirement => (
                <div className="flex items-center space-x-3 w-10/12" key={requirement.token}>
                    <ProgressBar
                        value={requirement.current}
                        maxValue={requirement.required}
                        height="x-small"
                        showTextProgress={false}
                        label={"Requirement progress"}
                        theme="blue"
                    />
                    <div className="flex items-center space-x-1 w-2/12">
                        <RewardResourceIcon token={requirement.token} size="3"/>
                        <div className="text-theme-text text-xs">
                            {requirement.current}/{requirement.required}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}