import {useSelector} from "react-redux";
import {selectTransactions} from "../../../redux/slices/transactionsSlice";
import Scrollbars from 'react-custom-scrollbars-2';
import TxListItem from "./TxListItem";
import Scrollbar from "../../shared/Scrollbar";

export default function TxList() {
    const transactions = useSelector(selectTransactions);

    return (
        <Scrollbar>
            <ul role="list" className="divide-y divide-secondary w-full">
                {transactions.map(tx => <li key={tx.hash + tx.timestamp}>
                    <TxListItem txInfo={tx}/>
                </li>)}
            </ul>
        </Scrollbar>
    );
}