import TxList from "./TxList";
import ButtonText from "../../ButtonText";
import {useAppDispatch} from "../../../hooks/useStore";
import {clearAllTransactions, setShowTxList} from "../../../redux/slices/transactionsSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import Popup from "../../shared/Popup";

export default function TxNotificationsBox() {
    const dispatch = useAppDispatch();
    const hasTransactions = useSelector(
        (state: RootState) => state.transactions.transactions.length > 0
    );
    const open = useSelector((state: RootState) => state.transactions.showList);
    const setOpen = () => {
        dispatch(setShowTxList(false));
    }

    return (
        <Popup
            open={open}
            setOpen={setOpen}
            paddingClass="p-0"
            widthClass="w-full md:w-100"
        >
            <div
                className="flex flex-col items-start w-full"
            >
                <div
                    className="w-full flex flex-col items-start  border-b border-secondary px-4 pt-8 pb-2">
                    <span className="text-2xl font-semibold text-theme-text">
                        Your Transactions
                    </span>
                    <div className="flex items-center justify-end w-full space-x-2">
                        {hasTransactions && <ButtonText
                            onClick={() => {
                                dispatch(setShowTxList(false))
                                dispatch(clearAllTransactions())
                            }}
                        >
                            clear
                        </ButtonText>
                        }
                    </div>
                </div>
                {hasTransactions && <div className="w-full">
                    <TxList/>
                </div>
                }
            </div>
        </Popup>
    );
};