import ActionContext from "./ActionContext";
import {gameAction} from "../../config/actions";
import {getNftIdFromToken} from "../../utils/nfts";

export default class PlaceBuildingContext extends ActionContext<"PLACE_BUILDING"> {
    protected type = gameAction.PLACE_BUILDING;
    protected hasTransaction = true;


    public getUsedItems(): Record<string, number> {
        return {
            [getNftIdFromToken(this.payload.token)]: 1
        };
    }

};