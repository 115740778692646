import {addressFromHexPublicKey, keysFromMnemonic} from "../auth/create-wallet";
import MessageSigner from "../auth/MessageSigner";
import axios from "axios";
import {network} from "../config";
import BigNumber from "bignumber.js";
import {
    Address,
    BigUIntValue, GasEstimator,
    SmartContract,
    TokenTransfer,
    Transaction, TransferTransactionsFactory
} from "@multiversx/sdk-core/out";
import {Nonce} from "@multiversx/sdk-network-providers/out/primitives";
import {Signature} from "@multiversx/sdk-core/out/signature";
import {TOKEN_GIANTS_CURRENCY} from "../config/assets";

const egldAmount = new BigNumber(0.5).shiftedBy(18);
const giantTokenAmount = new BigNumber(50000).shiftedBy(18);

export const transferRegisterAssets = async (walletAddress: string) => {
    const nonce = await transferRegisterEgld(walletAddress);
    await transferRegisterGiantToken(walletAddress, nonce);
}
const transferRegisterEgld = async (walletAddress: string): Promise<number> => {
    const wallet = buildFaucetWallet();
    const {balance, nonce} = await getWalletInfo(wallet.address);
    if (balance.lt(egldAmount.plus(0.001))) {
        return nonce;
    }
    const tx = new Transaction({
        sender: Address.fromBech32(wallet.address),
        receiver: Address.fromBech32(walletAddress),
        value: egldAmount,
        nonce: new Nonce(nonce),
        gasLimit: 50000,
        chainID: "D",
    });

    await sendTransaction(wallet.sign(tx));

    return nonce + 1;

}

const transferRegisterGiantToken = async (walletAddress: string, nonce: number) => {
    const wallet = buildFaucetWallet();
    const value = TokenTransfer.fungibleFromBigInteger(TOKEN_GIANTS_CURRENCY, giantTokenAmount);
    const factory = new TransferTransactionsFactory(new GasEstimator());
    const tx = factory.createESDTTransfer({
        chainID: "D",
        nonce: new Nonce(nonce),
        receiver: Address.fromBech32(walletAddress),
        sender: Address.fromBech32(wallet.address),
        tokenTransfer: value,
    });

    return sendTransaction(wallet.sign(tx));

}

const buildFaucetWallet = () => {
    const mnemonic = process.env.FAUCET_WALLET_MNEMONIC!;
    const {publicKey, secretKey} = keysFromMnemonic(mnemonic);
    const signer = new MessageSigner(secretKey);
    const senderAddress = addressFromHexPublicKey(publicKey);

    return {
        address: senderAddress,
        sign: (tx: Transaction): Transaction => {
            const signature = signer.sign(
                tx.serializeForSigning()
            );
            tx.applySignature(Signature.fromBuffer(Buffer.from(signature)));

            return tx;
        }
    };
}

const sendTransaction = async (tx: Transaction): Promise<string> => {
    const apiUrl = network["devnet"]["api"];
    const {data: {txHash}} = await axios.post(`${apiUrl}/transactions`, tx.toSendable());

    return txHash;
}

const getWalletInfo = async (address: string): Promise<{ balance: BigNumber, nonce: number }> => {
    const apiUrl = network["devnet"]["api"];
    const {data: {balance, nonce}} = await axios.get(`${apiUrl}/accounts/${address}`);

    return {
        balance: new BigNumber(balance),
        nonce
    };
}

export const assetsTransactionsData: {
    address: string;
    method: string;
    gasLimit: number;
    qty: number;
}[] = [
    {
        address: process.env.NEXT_PUBLIC_FAUCET_CONTRACT!,
        method: "mintGiants",
        gasLimit: 20_000_000,
        qty: 5
    },
    {
        address: process.env.NEXT_PUBLIC_FAUCET_CONTRACT!,
        method: "mintFemaleGiants",
        gasLimit: 20_000_000,
        qty: 5
    },
    {
        address: process.env.NEXT_PUBLIC_WOOD_FARM_CONTRACT!,
        method: "adminRewardsToken",
        gasLimit: 10_000_000,
        qty: 100
    },
    {
        address: process.env.NEXT_PUBLIC_STONE_FARM_CONTRACT!,
        method: "adminRewardsToken",
        gasLimit: 10_000_000,
        qty: 100
    },
    {
        address: process.env.NEXT_PUBLIC_FOOD_FARM_CONTRACT!,
        method: "adminRewardsToken",
        gasLimit: 10_000_000,
        qty: 100
    },
    {
        address: process.env.NEXT_PUBLIC_QUESTS_CONTRACT!,
        method: "adminVcToken",
        gasLimit: 5_000_000,
        qty: 5000
    },
    {
        address: process.env.NEXT_PUBLIC_QUESTS_CONTRACT!,
        method: "adminStarToken",
        gasLimit: 5_000_000,
        qty: 15
    }
];
