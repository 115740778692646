import {formatCountdownText} from "../../../utils/presentation";
import Countdown from "react-countdown";

export default function NpcCountdown({date, onComplete = () => {}}: {
    date: string | number | Date,
    onComplete?: () => void
}) {
    return (
        <Countdown
            date={date}
            renderer={({completed, ...rest}) => {
                if (completed) {return null;}
                return (
                    <div
                        className="text-xs text-red font-semibold flex items-center justify-center">
                        {formatCountdownText({
                            ...rest,
                            separator: " : "
                        })}
                    </div>
                )
            }}
            onComplete={onComplete}
        />
    );
}