import ActionContext from "./ActionContext";
import {gameAction} from "../../config/actions";

export default class RemoveGiantsFromWorkContext extends ActionContext<"REMOVE_GIANTS_FROM_WORK"> {
    protected type = gameAction.REMOVE_GIANTS_FROM_WORK;
    protected hasTransaction = false;


    public getUsedItems(): Record<string, number> {
        return {};
    }

};