import {HiMinus, HiPlus} from 'react-icons/hi2';
import {SelectableInventoryAsset} from '../../common/types/inventory';
import {classNames} from '../../utils/presentation';
import {QtyButton} from './QtyButton';
import AssetQtyInput from "./AssetQtyInput";
import {useMemo} from "react";
import {getSkinItem} from "../../utils/assets";
import {buildings} from "../../config/assets";

export default function AssetsListItem(
    {
        item,
        onChange,
        enabled,
        onClick,
    }: {
        item: SelectableInventoryAsset;
        onChange: (qty: number) => void;
        onClick: () => void;
        enabled: boolean;
    }
) {

    const skin = useMemo(() => {
        if (!item || !item.attributes?.skin?.value) {return null;}

        return getSkinItem(buildings[item.collection], item.attributes.skin.value);
    }, [item]);

    return (
        <div
            className={classNames(
                "flex flex-col items-start border w-24 h-24 hover:shadow-md col-span-1",
                item.selected > 0 ? "border-secondary" : "border-transparent"
            )}
        >
            <div className="relative border border-secondary w-full h-full">
                <img
                    src={skin?.thumbnail ?? item.thumbnail}
                    alt={`${item.type} ${item.identifier}`}
                    className="w-full h-full object-center object-cover"
                />
                {(item.attributes?.level?.value ?? 0) > 1 && (
                    <div
                        className="absolute top-0 left-0 min-w-5 h-5 bg-[#FFF1D1] flex items-center justify-start text-theme-text text-xs font-bold text-center px-1">
                        <span className=""> Lvl.{item.attributes?.level?.value}</span>
                    </div>
                )}
                {item.quantity > 1 && (
                    <div
                        className="absolute top-0 right-0 min-w-5 h-5 bg-secondary flex items-center justify-center text-theme-text text-xs font-bold text-center px-1">
                        {item.quantity}
                    </div>
                )}
                {enabled && (
                    <div
                        className="absolute left-0 bottom-0 w-full h-full flex items-end justify-center">
                        {item.selected === 0 ? (
                            <button className="w-full h-full opacity-0" onClick={onClick}></button>
                        ) : (
                            <div
                                className="flex items-center justify-between px-1 bg-secondary w-full h-6">
                                <QtyButton
                                    visible={true}
                                    iconType={HiMinus}
                                    onClick={() => onChange(item.selected > 0 ? item.selected - 1 : 0)}
                                    disabled={item.selected < 1}
                                />
                                {item.quantity === 0 ? (
                                    <div
                                        className="w-6 h-6 flex items-center justify-center text-theme-text font-bold text-xs">
                                        {item.selected}
                                    </div>
                                ) : (
                                    <AssetQtyInput
                                        value={item.selected}
                                        max={item.quantity}
                                        onChange={onChange}
                                    />
                                )}
                                <QtyButton
                                    visible={true}
                                    iconType={HiPlus}
                                    onClick={() => onChange(item.selected + 1)}
                                    disabled={item.selected >= item.quantity}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
