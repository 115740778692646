import { useCallback, useEffect, useMemo, useState } from "react";
import { FcAlarmClock, FcDeployment, FcSupport } from "react-icons/fc";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import {
  AssetStatus,
  TokenPayload
} from "../../../common/types/actions";
import { BuildingResident, SelectedResident } from "../../../common/types/inventory";
import { PartialVillageAsset } from "../../../common/types/repository";
import {
  buildings,
  TOKEN_BUILDING_FOOD,
  TOKEN_BUILDING_HOUSE,
  TOKEN_BUILDING_STONE,
  TOKEN_BUILDING_WOOD,
  TOKEN_GIANTS_CURRENCY,
  TOKEN_VILLAGE_COIN,
} from "../../../config/assets";
import Manager from "../../../gameboard/Manager";
import { useAuth } from "../../../hooks/useAuth";
import { useAppDispatch } from "../../../hooks/useStore";
import { useVillageState } from "../../../hooks/useVillageState";
import { fetchInventoryAssets, selectRewardsResources } from "../../../redux/slices/inventorySlice";
import { clearSelected } from "../../../redux/slices/residentsSlice";
import { RootState } from "../../../redux/store";
import {
  getAssetDetails,
  getFarmBaseProductionImprovement,
  getFarmStorage,
  getSkinItem,
  getUpgradeTime,
} from "../../../utils/assets";
import { getVillageLevelRequirementForBuilding } from "../../../utils/game";
import { classNames, formatUpgradeTime } from "../../../utils/presentation";
import ButtonText from "../../ButtonText";
import IconBrush from "../../icons/IconBrush";
import IconWizard from "../../icons/IconWizard";
import ActionButton from "../../shared/ActionButton";
import Popup from "../../shared/Popup";
import Level from "../Level";
import RewardResourceIcon from "../RewardResourceIcon";
import ResidentsList from "./ResidentsList";
import SkinsMenu from "./SkinsMenu";
import UpgradeCountdown from "./UpgradeCountdown";
import UpgradeRestrictions from "./UpgradeRestrictions";
import RemoveBuildingContext from "../../../services/contexts/RemoveBuildingContext";
import UpgradeBuildingContext from "../../../services/contexts/UpgradeBuildingContext";
import {AiOutlineWarning} from "react-icons/ai";

type Props = {
  asset: PartialVillageAsset;
  onClose: () => void;
  addGiants: (giants: SelectedResident[]) => Promise<any>;
  removeGiants: (giants: SelectedResident[]) => Promise<any>;
  availableResidentQty: number;
  availableResidentsList: JSX.Element;
  claimingBox?: JSX.Element;
  currentResidents: BuildingResident[];
  residentsCapacity: number;
  removable: boolean;
};

export default function BaseBuildingAssetDialog(
    {
      asset,
      onClose,
      addGiants,
      removeGiants,
      availableResidentsList,
      availableResidentQty,
      currentResidents,
      claimingBox,
      residentsCapacity,
      removable
    }: Props
) {
  const [assetStatus, setAssetStatus] = useState<AssetStatus>(asset.status);
  const { account, village, giantGoldBalance } = useAuth();
  const {loadVillageResidents, maxUpgradingBuildingsQty, upgradingBuildingsQty} = useVillageState();
  const dispatch = useAppDispatch();
  const [removing, setRemoving] = useState(false);
  const [initialisingUpgrade, setInitialisingUpgrade] = useState(false);
  const assetData = getAssetDetails(asset.asset_token, asset.asset_token_nonce);
  const requiredVillageLevelForUpgrade = getVillageLevelRequirementForBuilding(
    asset.asset_token,
    asset.attributes.level.value + 1
  );
  const buildingAssetInfo = buildings[asset.asset_token];
  const upgradeRequirements = useMemo(() => {
    return (
      buildingAssetInfo?.getUpgradeRequirements({
        currentLevel: asset.attributes.level.value,
      }) ?? new Map()
    );
  }, [asset.attributes.level.value, buildingAssetInfo]);

  const resources = useSelector(selectRewardsResources);
  const canUpgradeWithVC = useMemo(() => {
    if (!upgradeRequirements.has(TOKEN_VILLAGE_COIN)) {
      return false;
    }
    return resources[TOKEN_VILLAGE_COIN] >= upgradeRequirements.get(TOKEN_VILLAGE_COIN)!;
  }, [resources, upgradeRequirements]);
  const canUpgradeWithCurrency = useMemo(() => {
    if (!upgradeRequirements.has(TOKEN_GIANTS_CURRENCY)) {
      return false;
    }
    return giantGoldBalance.toDenominated().gte(upgradeRequirements.get(TOKEN_GIANTS_CURRENCY)!);
  }, [giantGoldBalance, upgradeRequirements]);
  const hasRequiredVillageLevelForUpgrade = useMemo(() => {
    if (requiredVillageLevelForUpgrade === -1) {
      return false;
    }

    return requiredVillageLevelForUpgrade <= (village?.level ?? 0);
  }, [requiredVillageLevelForUpgrade, village?.level]);
  const [addingResidents, setAddingResidents] = useState(false);
  const [removingResidents, setRemovingResidents] = useState(false);
  const [committingResidents, setCommittingResidents] = useState(false);
  const selectedResidents = useSelector((state: RootState) => state.residents.selected);
  const removableResidents = useMemo(() => {
    return currentResidents.filter((resident) => resident.status === "complete");
  }, [currentResidents]);
  const resetSelectedResidents = () => dispatch(clearSelected());
  const [showingSkinsMenu, setShowingSkinsMenu] = useState(false);
  const skin = useMemo(() => {
    return asset.attributes.skin?.value ? getSkinItem(buildingAssetInfo, asset.attributes.skin.value) : null;
  }, [asset.attributes.skin, buildingAssetInfo]);
  const maxUpgradesReached = useMemo(() => {
    if (upgradingBuildingsQty === null) { return true;}
    return upgradingBuildingsQty >= maxUpgradingBuildingsQty;
  }, [upgradingBuildingsQty, maxUpgradingBuildingsQty]);

  const onRemove = async () => {
    if (!village) {
      return;
    }
    setRemoving(true);
    try {
      const actionContext = new RemoveBuildingContext({
        position: asset.position,
        token: {
          id: asset.asset_token,
          nonce: asset.asset_token_nonce,
          quantity: 1,
        },
        villageId: village.id,
        attributes: asset.attributes,
        upgradeState: {
          startDate: asset.upgrade_start_date,
          finishDate: asset.upgrade_finish_date,
          payment: asset.upgrade_payment as Required<TokenPayload> | null,
          instantFinishDate: asset.upgrade_instant_finish_date,
          instantFinishTaxPayment: asset.upgrade_instant_finish_tax_payment as Required<TokenPayload> | null,
        }
      });

      await Manager.getInstance().commitAction(actionContext);
      dispatch(fetchInventoryAssets({ address: account!.game_wallet!, reset: true }));
    } finally {
      setRemoving(false);
      onClose();
    }
  };

  const initUpgrade = async () => {
    if (!village) {
      return;
    }
    setInitialisingUpgrade(true);
    try {
      const payload = {
        asset_token: {
          id: asset.asset_token,
          nonce: asset.asset_token_nonce,
        },
        position: asset.position,
        villageId: village?.id,
        attributes: asset.attributes,
      };
      await Manager.getInstance().commitAction(new UpgradeBuildingContext(payload));
    } finally {
      setAssetStatus("upgrading");
      setInitialisingUpgrade(false);
    }
  };

  const addGiantsToBuilding = useCallback(async () => {
    try {
      setCommittingResidents(true);
      await addGiants(selectedResidents);
      loadVillageResidents();
    } finally {
      resetSelectedResidents();
      setAddingResidents(false);
      setCommittingResidents(false);
    }
  }, [addGiants, selectedResidents]);

  const removeGiantsFromBuilding = useCallback(async () => {
    try {
      setCommittingResidents(true);
      await removeGiants(selectedResidents);
    } finally {
      resetSelectedResidents();
      setRemovingResidents(false);
      setCommittingResidents(false);
    }
  }, [removeGiants, selectedResidents]);

  useEffect(() => {
    if (asset.status !== assetStatus) {
      setAssetStatus(asset.status);
    }
  }, [asset]);

  const showUpgrading = useMemo(
      () => {
        const upgrading = assetStatus === "upgrading"
            || assetStatus === "finalizing-upgrade"
            || (asset.upgrade_start_date !== null && asset.upgrade_finish_date !== null);

        return upgrading && !addingResidents && !removingResidents;
      },
      [assetStatus, asset, addingResidents, removingResidents]
  );

  return (
    <Popup open={true} setOpen={onClose} widthClass="w-full md:max-w-[28rem]" paddingClass="">
      <div className="flex flex-col items-start w-full h-full">
        <div className="flex items-center space-x-4 p-4 w-full">
          <div className="flex items-center justify-center w-20 h-20 p-0.5 border-2 border-secondary">
            <img
              src={skin?.thumbnail || assetData.thumbnail}
              className="w-full h-full object-center object-contain"
              alt={`${assetData.title} thumbnail`}
            />
          </div>
          <div className="flex flex-col items-start space-y-1 flex-grow">
            <h2 className="text-2xl text-theme-text font-bold">{assetData.title}</h2>
            <div className="flex items-center space-x-6">
              <Level level={asset.attributes.level.value} />
              <div className="flex items-center space-x-2">
                <IconWizard className="w-6 h-6" />
                <span className="text-theme-text-light text-sm font-semibold">
                  {currentResidents.length} / {residentsCapacity}
                </span>
              </div>
            </div>
            <div className="flex items-center justify-between w-full">
              <button
                className={classNames(
                  "text-red underline focus:outline-none focus:ring-1 focus:ring-red focus:ring-offset-1 text-sm",
                  "disabled:text-theme-text-light disabled:no-underline disabled:cursor-not-allowed",
                  removing ? "animate-pulse" : ""
                )}
                disabled={removing || assetStatus !== "complete" || !removable}
                onClick={onRemove}
              >
                {removing ? "Moving to inventory..." : "Move to inventory"}
              </button>
              {buildingAssetInfo.skins.length > 0 && (
                <button
                  onClick={() => setShowingSkinsMenu(!showingSkinsMenu)}
                  disabled={removing || addingResidents || removingResidents}
                  className={classNames(
                    "flex items-center space-x-1 rounded-full border border-secondary",
                    "py-1 px-2 focus:outline-none focus:ring-1 focus:ring-secondary focus:ring-offset-1",
                    showingSkinsMenu ? "bg-secondary text-white" : "bg-transparent text-secondary"
                  )}
                >
                  <IconBrush className="w-4 h-4" />
                  <span className="text-xs font-medium">SKINS</span>
                </button>
              )}
            </div>
          </div>
        </div>
        {claimingBox ?? null}
        <div className="flex items-center text-sm px-4 space-x-6">
          {/*<span className="text-theme-text-light font-bold">Residents</span>*/}
          {/*<span className="text-theme-text-light ml-2">{currentResidents.length} / 20</span>*/}
          {!removingResidents && (
            <div className="flex items-center">
              <ButtonText
                disabled={assetStatus === "pending" || showingSkinsMenu}
                onClick={() => {
                  resetSelectedResidents();
                  setAddingResidents(!addingResidents);
                }}
              >
                {addingResidents ? "Cancel" : "Add Giants"}
              </ButtonText>
              <span className="text-theme-text-light ml-2">
                {addingResidents ? `${selectedResidents.length} selected` : `${availableResidentQty} available`}
              </span>
            </div>
          )}
          {!addingResidents && (
            <div className="flex items-center">
              <ButtonText
                disabled={assetStatus === "pending" || showingSkinsMenu}
                onClick={() => {
                  resetSelectedResidents();
                  setRemovingResidents(!removingResidents);
                }}
              >
                {removingResidents ? "Cancel" : "Remove Giants"}
              </ButtonText>
              {removingResidents && (
                <span className="text-theme-text-light ml-2">{selectedResidents.length} selected</span>
              )}
            </div>
          )}
        </div>

        {/*Residents List*/}
        {!showingSkinsMenu && (
          <div className="flex flex-grow md:min-h-60 mt-4 w-full border-t border-b border-secondary">
            {addingResidents ? (
              availableResidentsList
            ) : removingResidents ? (
              <ResidentsList
                residents={removableResidents}
                hasMore={false}
                next={() => {}}
                selectable={removingResidents && !committingResidents}
                selectableQty={removableResidents.length}
              />
            ) : (
              <ResidentsList
                residents={currentResidents}
                hasMore={false}
                next={() => {}}
                selectable={false}
                selectableQty={currentResidents.length}
              />
            )}
          </div>
        )}

        {showingSkinsMenu && <SkinsMenu asset={asset} assetData={assetData} setShowMenu={setShowingSkinsMenu} />}

        {!showingSkinsMenu && (
          <div className="flex flex-col items-center justify-center w-full py-2 sm:py-4 px-4">
            {!addingResidents && !removingResidents && (
              <UpgradeRestrictions
                assetData={assetData}
                buildingAssetInfo={buildingAssetInfo}
                level={asset.attributes.level.value}
                requiredVillageLevel={requiredVillageLevelForUpgrade}
                close={onClose}
              />
            )}
            {assetStatus === "complete" &&
              buildingAssetInfo.maxLevel > asset.attributes.level.value &&
              upgradeRequirements.size > 0 &&
              hasRequiredVillageLevelForUpgrade &&
              !addingResidents &&
              !removingResidents && (
                    <div className="flex flex-col items-center justify-start w-full">
                      <div
                          className="text-sm md:text-base text-theme-text font-semibold uppercase relative"
                      >
                        Upgrade {assetData.title}

                        {maxUpgradesReached && (
                            <div className="absolute -right-8 top-0 h-full ">
                              <AiOutlineWarning
                                  className="w-5 h-5 text-red"
                                  data-tooltip-id="building-popup-tooltip"
                                  data-tooltip-content={`Max number of upgrading buildings reached`}
                              />
                            </div>
                        )}
                      </div>
                      <span
                          className="text-theme-text text-xs md:text-sm mt-1 flex items-center space-x-4">
                    <div
                        className="flex items-center"
                        data-tooltip-id="building-popup-tooltip"
                        data-tooltip-content={`Upgrade duration`}
                    >
                      <FcSupport className="mr-2 h-5 w-5"/>
                      {formatUpgradeTime(getUpgradeTime(asset.attributes.level.value))}
                    </div>
                        {asset.asset_token === TOKEN_BUILDING_HOUSE && (
                            <div
                                className="flex items-center"
                                data-tooltip-id="building-popup-tooltip"
                                data-tooltip-content={`Increase Giants capacity`}
                            >
                              <IconWizard className="w-4 h-4 mr-1"/> +1
                            </div>
                        )}
                        {(asset.asset_token === TOKEN_BUILDING_WOOD ||
                            asset.asset_token === TOKEN_BUILDING_FOOD ||
                            asset.asset_token === TOKEN_BUILDING_STONE) && (
                            <>
                              <div
                                  className="flex items-center"
                                  data-tooltip-id="building-popup-tooltip"
                                  data-tooltip-content={`Increase in production speed`}
                              >
                                <FcAlarmClock className="mr-2 h-5 w-5"/> +
                                {getFarmBaseProductionImprovement(asset.attributes.level.value)}%
                              </div>
                              <div
                                  className="flex items-center"
                                  data-tooltip-id="building-popup-tooltip"
                                  data-tooltip-content={`Increase in storage`}
                              >
                                <FcDeployment className="mr-2 h-5 w-5"/> +
                                {getFarmStorage(asset.attributes.level.value + 1) -
                                    getFarmStorage(asset.attributes.level.value)}
                              </div>
                            </>
                        )}
                  </span>
                      <div className="flex flex-col items-center gap-3 mt-4 w-full">
                        <ActionButton
                            onClick={initUpgrade}
                            disabled={
                                !(canUpgradeWithVC || canUpgradeWithCurrency)
                                || removing
                                || initialisingUpgrade
                                || maxUpgradesReached
                            }
                            style="secondary"
                        >
                          <div className="flex flex-col w-full space-y-1">

                            <div
                                className={classNames(
                                    "flex flex-row w-full gap-1 md:gap-3",
                                    "items-center justify-center text-white font-semibold"
                                )}
                            >
                              <div className="flex items-center">
                                <RewardResourceIcon
                                    token={TOKEN_VILLAGE_COIN}
                                    size="4"
                                    padding={0.5}
                                />
                                <span className="text-sm px-1">
                                  {upgradeRequirements.get(TOKEN_VILLAGE_COIN)}
                                </span>
                                {/*<span className="text-sm">V. Coin</span>*/}
                              </div>
                              <span className="text-xs">OR</span>
                              <div className="flex items-center">
                                <RewardResourceIcon
                                    token={TOKEN_GIANTS_CURRENCY}
                                    background="transparent"
                                    size="4"
                                    padding={0}
                                />
                                <span className="text-sm px-1">
                                 {upgradeRequirements.get(TOKEN_GIANTS_CURRENCY)}
                                </span>
                                {/*<span className="text-sm">$GIANT</span>*/}
                              </div>
                            </div>
                          </div>
                        </ActionButton>
                      </div>
                      <span className="text-theme-text-light text-xs mt-2">
                    * Payment is required once you finish the upgrade
                  </span>
                    </div>
                )}

            {showUpgrading && <UpgradeCountdown asset={asset}/>}

            {addingResidents && (
                <button
                    className="flex flex-col justify-center items-center bg-secondary disabled:bg-gray-300 disabled:cursor-not-allowed py-7 w-full"
                disabled={selectedResidents.length < 1 || committingResidents}
                onClick={addGiantsToBuilding}
              >
                <span className="text-theme-text font-semibold uppercase">
                  {committingResidents ? "Adding " : "Add "}
                  {selectedResidents.length}
                  {selectedResidents.length !== 1 ? " Giants" : " Giant"}
                </span>
              </button>
            )}

            {removingResidents && (
              <button
                className="flex flex-col justify-center items-center bg-secondary disabled:bg-gray-300 disabled:cursor-not-allowed py-7 w-full"
                disabled={selectedResidents.length < 1 || committingResidents}
                onClick={removeGiantsFromBuilding}
              >
                <span className="text-theme-text font-semibold uppercase">
                  {committingResidents ? "Removing " : "Remove "}
                  {selectedResidents.length}
                  {selectedResidents.length !== 1 ? " Giants" : " Giant"}
                </span>
              </button>
            )}
          </div>
        )}
      </div>

      <Tooltip id="building-popup-tooltip" className="base-tooltip" />
    </Popup>
  );
}
