import PlayerMenuBar from "./PlayerMenuBar";
import ResourcesBar from "./ResourcesBar";
import EgldTopUpBar from "./EgldTopUpBar";
import ErrorBar from "../shared/ErrorBar";

export default function ActionAreaTop() {
    return (
        <div className="w-full absolute z-20 flex flex-col pointer-events-none">
            <div className="px-2 pt-2 pb-0 md:pb-2 bg-secondary-lightest md:bg-transparent pointer-events-none md:relative">
                <div
                    className="w-full flex flex-col md:flex-row items-center md:justify-between  pointer-events-none"
                >
                    <PlayerMenuBar/>
                    <ResourcesBar/>
                </div>
            </div>
            <div
                className="w-full flex items-center justify-center xl:absolute px-1 pb-1 lg:mt-2 bg-secondary-lightest md:bg-transparent"
            >
                <EgldTopUpBar/>
                <ErrorBar/>
            </div>
        </div>
    );
};