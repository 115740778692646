import {useAuth} from "../../hooks/useAuth";
import {useLeaderboard} from "../../hooks/useLeaderboard";
import IconGiantCurrency from "../icons/IconGiantCurrency";
import {formatAmount} from "../../utils/presentation";
import BigNumber from "bignumber.js";
import {useEffect} from "react";
import ActionButton from "../shared/ActionButton";
import Manager from "../../gameboard/Manager";
import ClaimLeaderboardPrizesContext from "../../services/contexts/ClaimLeaderboardPrizesContext";
import {useApi} from "../../hooks/useApi";
import {ClaimPrizeResponse} from "../../common/types/responses";
import {claimPrizesPath} from "../../utils/api-routes";
import {RootState} from "../../redux/store";
import {useSelector} from "react-redux";
import {ActionCommitError} from "../../common/types/actions";
import {gameAction} from "../../config/actions";

export default function LeaderboardPrizeClaim() {
    const {account, village} = useAuth();
    const {api} = useApi();
    const {
        claimablePrizesAmount,
        claiming,
        fetchClaimablePrizesAmount,
        setClaiming
    } = useLeaderboard();
    const status = useSelector((state: RootState) => state.leaderboard.prizeStatus);
    const gameManager = Manager.getInstance();
    useEffect(() => {
        if (account) {
            fetchClaimablePrizesAmount();
        }
    }, [account]);
    useEffect(() => {
        if (!gameManager.initialised || !setClaiming) {return;}
        const errHandler = (payload: ActionCommitError<any>) => {
            if (payload.actionContext.actionType === gameAction.CLAIM_LEADERBOARD_PRIZES) {
                setClaiming(false);
            }
        }
        gameManager.subscribeToAction("GAME_ACTION_COMMIT_ERROR", errHandler);

        return () => {
            gameManager.unsubscribeFromAction("GAME_ACTION_COMMIT_ERROR", errHandler);
        }

    }, [gameManager, setClaiming]);

    const claim = async () => {
        setClaiming(true);
        try {
            const {data: {prizes}} = await api.post<ClaimPrizeResponse>(claimPrizesPath(), {});
            const context = new ClaimLeaderboardPrizesContext({
                prizeClaims: prizes,
                villageId: village!.id
            });
            await Manager.getInstance().commitAction(context);
        } catch (e) {
            setClaiming(false);
        }
    };


    if (Number(claimablePrizesAmount) < 1) {return null;}

    return (
        <div
            className="flex flex-col items-center justify-center w-full py-3 px-6 border-t border-secondary"
        >
            <div className="flex items-center space-x-2">
                <span className="text-sm text-theme-text-light">
                    Tokens available to claim
                </span>
                <div className="flex items-center space-x-1">
                    <IconGiantCurrency className="w-4 h-4"/>
                    <span className="text-theme-text text-xs font-semibold">
                            {
                                formatAmount(
                                    new BigNumber(claimablePrizesAmount!).shiftedBy(-18).toNumber(),
                                    1,
                                )
                            }
                        </span>
                </div>
            </div>
            <div className="flex items-center justify-center mt-2 w-full">
                <ActionButton
                    onClick={claim}
                    disabled={claiming || status === "loading"}
                    style="secondary"
                >
                    <span className={(claiming || status === "loading") ? "animate-pulse" : ""}>
                        {claiming ? "Claiming Prizes..." : "Claim all Prizes"}
                    </span>
                </ActionButton>
            </div>
        </div>
    );
}