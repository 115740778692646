import ActionContext from "./ActionContext";
import {gameAction} from "../../config/actions";

export default class RemoveGiantsFromHouseContext extends ActionContext<"REMOVE_GIANTS_FROM_HOUSE"> {
    protected type = gameAction.REMOVE_GIANTS_FROM_HOUSE;
    protected hasTransaction = true;


    public getUsedItems(): Record<string, number> {
        return {};
    }

};