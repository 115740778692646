import { SVGProps } from "react";

export default function IconTxNotification({ ...props }: SVGProps<any>) {
  return (
      <svg width="61" height="62" viewBox="0 0 61 62" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_505_33)">
          <path d="M42.6079 50.3147C42.3609 50.3147 42.1583 50.1159 42.1583 49.8737V26.4484C42.1583 26.2062 42.3609 26.0074 42.6079 26.0074C42.8549 26.0074 43.0576 26.2062 43.0576 26.4484V49.8737C43.0576 50.1159 42.8549 50.3147 42.6079 50.3147Z" fill="#E28B5A"/>
          <path d="M42.6079 22.6191C42.3609 22.6191 42.1583 22.4204 42.1583 22.1782V11.4343C42.1583 11.1921 42.3609 10.9934 42.6079 10.9934C42.8549 10.9934 43.0576 11.1921 43.0576 11.4343V22.1782C43.0576 22.4204 42.8549 22.6191 42.6079 22.6191Z" fill="#E28B5A"/>
          <path d="M19.8861 43.138C19.6391 43.138 19.4364 42.9393 19.4364 42.6971V10.9064C19.4364 10.6642 19.6391 10.4667 19.8861 10.4667C20.1331 10.4667 20.3345 10.6655 20.3345 10.9064V42.6971C20.3345 42.9393 20.1318 43.138 19.8861 43.138Z" fill="#E28B5A"/>
          <path d="M19.8861 50.4463C19.6391 50.4463 19.4364 50.2476 19.4364 50.0054V46.5264C19.4364 46.2841 19.6391 46.0854 19.8861 46.0854C20.1331 46.0854 20.3345 46.2841 20.3345 46.5264V50.0054C20.3345 50.2476 20.1318 50.4463 19.8861 50.4463Z" fill="#E28B5A"/>
          <path d="M38.5659 23.0811V11.787H23.4341V23.0811C23.4341 25.6373 24.7704 27.9028 26.7921 29.2381C26.7921 29.2381 28.3539 30.3385 26.7921 31.7619C24.7704 33.0971 23.4341 35.3627 23.4341 37.9188V49.2129H38.5672V37.9188C38.5672 35.3627 37.2308 33.0971 35.2092 31.7619C33.6474 30.3385 35.2092 29.2381 35.2092 29.2381C37.2308 27.9028 38.5672 25.6373 38.5672 23.0811H38.5659Z" fill="#EFE9D0"/>
          <path d="M19.8595 8H42.1405C43.1665 8 44 8.81728 44 9.82335V9.9637C44 10.9698 43.1665 11.7871 42.1405 11.7871H19.8595C18.8335 11.7871 18 10.9698 18 9.9637V9.82335C18 8.81728 18.8335 8 19.8595 8Z" fill="#C16B3D"/>
          <path d="M20.7943 11.5225C20.3193 11.5225 19.9317 11.1412 19.9317 10.6767V9.94758C19.9317 9.48181 20.3206 9.10174 20.7943 9.10174H44V9.05951C44 8.47698 43.5149 8.00127 42.9208 8.00127H19.0792C18.4851 8.00003 18 8.47574 18 9.05827V10.7276C18 11.3101 18.4851 11.7858 19.0792 11.7858H42.9208C43.1931 11.7858 43.4414 11.6852 43.6314 11.5213H20.7943V11.5225Z" fill="#D67550"/>
          <path d="M19.8595 49.2142H42.1405C43.1665 49.2142 44 50.0315 44 51.0375V51.1779C44 52.184 43.1665 53.0012 42.1405 53.0012H19.8595C18.8335 53 18 52.184 18 51.1779V51.0375C18 50.0315 18.8335 49.2142 19.8595 49.2142Z" fill="#C16B3D"/>
          <path d="M20.1559 52.6485C19.7366 52.6485 19.3921 52.3119 19.3921 51.8995V51.5033C19.3921 51.0922 19.7354 50.7544 20.1559 50.7544H44V50.2712C44 49.6887 43.5149 49.213 42.9208 49.213H19.0792C18.4851 49.213 18 49.6887 18 50.2712V51.9418C18 52.5243 18.4851 53 19.0792 53H42.9208C43.2387 53 43.525 52.8634 43.7226 52.6473H20.1559V52.6485Z" fill="#D67550"/>
          <path d="M38.5659 37.9201V49.2142H36.8597C36.8508 46.0171 35.6589 43.2312 33.8956 41.7531V37.6804C33.8956 35.5316 32.9203 33.5952 31.3813 32.2774V30.7099C31.3813 30.4453 31.4307 30.182 31.5383 29.9386C31.7777 29.3946 32.1717 29.1884 32.1717 29.1884C33.2268 28.9611 34.168 28.4357 34.8976 27.7066C35.6272 26.9775 36.144 26.0435 36.3543 25.0014C36.4594 24.4747 36.0452 23.9841 35.498 23.9841H33.8652C33.8855 23.7655 33.8969 23.5444 33.8969 23.3209V17.2012C33.8969 14.8674 31.9677 12.9757 29.5876 12.9757H23.4341V11.787H38.5672V23.0811C38.5672 24.3592 38.2328 25.564 37.6463 26.6173C37.0598 27.6706 36.22 28.5698 35.2092 29.2381C35.2092 29.2381 33.6474 30.3385 35.2092 31.7632C37.2308 33.0984 38.5672 35.3639 38.5672 37.9201H38.5659Z" fill="#D3CCAB"/>
          <path d="M36.8597 49.2142H25.1403C25.1403 48.8105 25.1619 48.4143 25.1986 48.0255H28.8606C31.6422 48.0255 33.8969 45.8147 33.8969 43.0871V41.7531C35.6601 43.2312 36.8521 46.0171 36.861 49.2142H36.8597Z" fill="#EFA829"/>
          <path d="M35.4967 23.9841C36.0439 23.9841 36.4582 24.4735 36.353 25.0014C36.144 26.0435 35.6259 26.9775 34.8963 27.7066C34.1667 28.4357 33.2256 28.9623 32.1704 29.1884C32.1704 29.1884 31.7765 29.3946 31.5371 29.9386C31.4294 30.182 31.38 30.4453 31.38 30.7099V32.2774C31.1393 32.07 30.8835 31.8787 30.6162 31.7036V30.7087C30.6162 30.4441 30.5668 30.1808 30.4591 29.9373C30.3958 29.792 30.3198 29.6715 30.2451 29.5722C30.2869 29.5262 30.3287 29.4877 30.3654 29.4542C32.2946 28.3003 33.6436 26.2969 33.8614 23.9841H35.4942H35.4967Z" fill="#EFA829"/>
          <path d="M33.8957 41.7531V43.0871C33.8957 45.8147 31.641 48.0255 28.8593 48.0255H25.1973C25.3493 46.4506 25.7914 45.0123 26.4399 43.8323C27.2494 42.3605 28.3805 41.2886 29.6776 40.8489C29.6776 40.8489 30.6175 40.5023 30.6175 39.4863V31.7048C30.8847 31.8799 31.1394 32.0712 31.3813 32.2786V39.4888L31.3838 39.4863C31.3838 40.5023 32.3237 40.8489 32.3237 40.8489H32.3212C32.881 41.0389 33.4093 41.347 33.8957 41.7543V41.7531Z" fill="#FFC85A"/>
          <path d="M26.5032 23.9841H33.8652C33.6473 26.2968 32.2971 28.3003 30.3692 29.4542C30.3324 29.4877 30.2906 29.5275 30.2488 29.5722C30.183 29.4852 30.1158 29.4144 30.0563 29.3585C29.9284 29.2393 29.8296 29.1871 29.8296 29.1871C27.7193 28.7338 26.0662 27.0843 25.6482 25.0001C25.5431 24.4735 25.9573 23.9829 26.5045 23.9829L26.5032 23.9841Z" fill="#FFC85A"/>
          <path d="M30.6187 30.7087V31.7036C30.5554 31.6626 30.4921 31.6216 30.4275 31.5831C29.4737 30.69 29.8993 29.9448 30.2476 29.5734C30.3236 29.6728 30.3983 29.7945 30.4617 29.9386C30.5693 30.182 30.6187 30.4454 30.6187 30.7099V30.7087Z" fill="#D3CCAB"/>
          <path d="M23.4341 48.0255H25.1998C25.1618 48.4143 25.1416 48.8105 25.1416 49.2142H23.4353V48.0255H23.4341Z" fill="#D3CCAB"/>
          <path d="M26.8605 25.7951C26.7541 25.2672 27.167 24.7753 27.7155 24.7753H36.367C36.3353 24.3418 35.9667 23.9829 35.498 23.9829H26.5033C25.9548 23.9829 25.5419 24.4747 25.6483 25.0026C25.9827 26.6645 27.1011 28.0494 28.611 28.7723C27.7269 28.0097 27.0973 26.9725 26.8605 25.7951Z" fill="#FFD592"/>
          <path d="M31.0228 31.988V40.6278C31.0228 40.6278 31.0291 40.6278 31.0329 40.6278C31.4763 40.6303 31.907 40.7073 32.3212 40.8476C32.876 41.0352 33.4016 41.3395 33.883 41.7419C33.1648 41.2799 32.3718 41.0228 31.5384 41.0228C28.4008 41.0228 25.8383 44.6521 25.6863 49.2129H25.1403C25.1517 45.1352 27.0859 41.7258 29.6789 40.8464C29.6789 40.8464 29.6814 40.8464 29.6852 40.8439C29.765 40.8116 30.6187 40.4514 30.6187 39.4839V31.7023" fill="#FFD592"/>
          <path d="M25.4545 20.3511C25.1441 20.3511 24.8933 20.1052 24.8933 19.8009V14.9568C24.8933 14.6525 25.1441 14.4066 25.4545 14.4066C25.7648 14.4066 26.0156 14.6525 26.0156 14.9568V19.8009C26.0156 20.1052 25.7648 20.3511 25.4545 20.3511Z" fill="#FFF9E1"/>
          <path d="M25.4316 23.3233C25.1213 23.3233 24.8705 23.0774 24.8705 22.7731V21.6726C24.8705 21.3683 25.1213 21.1224 25.4316 21.1224C25.742 21.1224 25.9928 21.3683 25.9928 21.6726V22.7731C25.9928 23.0774 25.742 23.3233 25.4316 23.3233Z" fill="#FFF9E1"/>
          <path d="M25.1175 37.1488C25.0732 37.1488 25.0289 37.1438 24.9833 37.1326C24.6818 37.0606 24.4981 36.7625 24.5716 36.4669C25.1087 34.3131 26.9568 32.9916 27.0353 32.9357C27.2874 32.7581 27.6382 32.8152 27.8181 33.0624C27.9992 33.3095 27.941 33.6524 27.6902 33.83C27.6674 33.8461 26.098 34.9814 25.6622 36.729C25.6001 36.9799 25.3696 37.1488 25.1175 37.1488Z" fill="#FFF9E1"/>
          <path d="M25.1175 40.0552C24.8072 40.0552 24.5564 39.8093 24.5564 39.505V38.1847C24.5564 37.8803 24.8072 37.6344 25.1175 37.6344C25.4279 37.6344 25.6787 37.8803 25.6787 38.1847V39.505C25.6787 39.8093 25.4279 40.0552 25.1175 40.0552Z" fill="#FFF9E1"/>
        </g>
        <path d="M59.1768 32.8232C59.0791 32.7256 58.9209 32.7256 58.8232 32.8232L57.2322 34.4142C57.1346 34.5118 57.1346 34.6701 57.2322 34.7678C57.3299 34.8654 57.4882 34.8654 57.5858 34.7678L59 33.3536L60.4142 34.7678C60.5118 34.8654 60.6701 34.8654 60.7678 34.7678C60.8654 34.6701 60.8654 34.5118 60.7678 34.4142L59.1768 32.8232ZM58.75 33C58.75 48.3217 46.1062 60.75 30.5 60.75V61.25C46.374 61.25 59.25 48.6062 59.25 33H58.75ZM30.5 60.75C14.8938 60.75 2.25 48.3217 2.25 33H1.75C1.75 48.6062 14.626 61.25 30.5 61.25V60.75Z" fill="#BCB53F"/>
        <path d="M1.82322 29.1768C1.92085 29.2744 2.07915 29.2744 2.17678 29.1768L3.76777 27.5858C3.8654 27.4882 3.8654 27.3299 3.76777 27.2322C3.67014 27.1346 3.51184 27.1346 3.41421 27.2322L2 28.6464L0.585786 27.2322C0.488155 27.1346 0.329864 27.1346 0.232233 27.2322C0.134602 27.3299 0.134602 27.4882 0.232233 27.5858L1.82322 29.1768ZM2.25 29C2.25 13.6783 14.8938 1.25 30.5 1.25V0.75C14.626 0.75 1.75 13.3938 1.75 29H2.25ZM30.5 1.25C46.1062 1.25 58.75 13.6783 58.75 29H59.25C59.25 13.3938 46.374 0.75 30.5 0.75V1.25Z" fill="#BCB53F"/>
        <defs>
          <clipPath id="clip0_505_33">
            <rect width="26" height="45" fill="white" transform="translate(18 8)"/>
          </clipPath>
        </defs>
      </svg>

  );
}
