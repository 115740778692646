import { NetworkEnv } from "@elrond-giants/erdjs-auth/dist/types";
import {ApiNetworkProvider} from "@multiversx/sdk-network-providers/out";
import { decode, encode } from "base64-arraybuffer";
import { bech32 } from "bech32";
import { generateMnemonic, mnemonicToSeedSync, validateMnemonic } from "bip39";
import { derivePath } from "ed25519-hd-key";
import { scrypt } from "scrypt-js";
import * as tweetnacl from "tweetnacl";

import { network } from "../config";
import MessageSigner from "./MessageSigner";
import Wallet from "./Wallet";
import {randomBytes} from "crypto";
import {mn} from "date-fns/locale";
import InvalidWalletPassword from "../errors/InvalidWalletPassword";

const MNEMONIC_LENGTH = 256;
const HD_PREFIX = "m/44'/508'/0'/0'";
const ERD = "erd";
const HD_PATH = `${HD_PREFIX}/0'`;

interface GenerateWalletResponse {
  mnemonic: string;
  address: string;
}

const computeShardID = (pubKey: Buffer) => {
  const startingIndex = pubKey.length - 1;

  const usedBuffer = pubKey.slice(startingIndex);

  let addr = 0;
  for (let i = 0; i < usedBuffer.length; i++) {
    addr = (addr << 8) + usedBuffer[i];
  }

  let n = Math.ceil(Math.log2(3));
  let maskHigh = (1 << n) - 1;
  let maskLow = (1 << (n - 1)) - 1;

  let shard = addr & maskHigh;
  if (shard > 2) {
    shard = addr & maskLow;
  }

  return shard;
};

const generateMnemonicInShard = (expectedShard: number): Promise<GenerateWalletResponse> =>
  new Promise((resolve, reject) => {
    while (true) {
      const mnemonic = generateMnemonic(MNEMONIC_LENGTH);
      const { publicKey, secretKey } = keysFromMnemonic(mnemonic);

      const shardID = computeShardID(Buffer.from(secretKey));

      if (shardID !== expectedShard) {
        continue;
      }

      resolve({
        mnemonic,
        address: addressFromHexPublicKey(publicKey),
      });

      break;
    }
  });

export const createWallet = () => {
  const shardId = parseInt(process.env.NEXT_PUBLIC_GAME_SHARD_ID as string);

  return generateMnemonicInShard(shardId);
};

export const keysFromMnemonic = (mnemonic: string) => {
  if (!validateMnemonic(mnemonic)) {
    throw new Error("Invalid mnemonic format");
  }
  const seed = mnemonicToSeedSync(mnemonic);
  const { key } = derivePath(HD_PATH, seed.toString("hex"));
  const privateKey = Uint8Array.from(key);
  const kp = tweetnacl.sign.keyPair.fromSeed(privateKey);

  return {
    publicKey: kp.publicKey,
    secretKey: kp.secretKey,
  };
};

export const addressFromHexPublicKey = (publicKey: Uint8Array) => {
  let words = bech32.toWords(publicKey);
  return bech32.encode(ERD, words);
};

const getCryptoKey = async (s: Uint8Array) => {
  return window.crypto.subtle.importKey("raw", s, "AES-GCM", false, ["encrypt", "decrypt"]);
};

const getKeys = async (password: Buffer, salt: Buffer) => {
  const h = await scrypt(password, salt, 16384, 16, 1, 28);
  const k1 = h.slice(0, 16);
  const k2 = h.slice(16);

  return [k1, k2];
};

export const encryptMnemonic = async (mnemonic: string, password: string, salt: Buffer) => {
  const [key, iv] = await getKeys(Buffer.from(password), salt);
  const eKey = await getCryptoKey(key);

  return window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv,
    },
    eKey,
    new TextEncoder().encode(mnemonic)
  );
};

export const decryptMnemonic = async (mnemonic: ArrayBuffer, password: string, salt: Buffer) => {
  const [key, iv] = await getKeys(Buffer.from(password), salt);
  const eKey = await getCryptoKey(key);
  try {
    const decrypted = await window.crypto.subtle.decrypt(
        {
          name: "AES-GCM",
          iv,
        },
        eKey,
        mnemonic
    );

    return new TextDecoder().decode(decrypted);
  } catch (e) {
    throw new InvalidWalletPassword();
  }
};

export const encryptPKey = async (mnemonic: string, password: string): Promise<string> => {
  const salt = randomBytes(16);
  const encrypted = await encryptMnemonic(mnemonic, password, salt);

  return encode(concatSaltMnemonic(salt, encrypted));

};

export const decryptPKey = async (mnemonic: string, password: string) => {
  const arrKeys = decode(mnemonic);
  const salt = Buffer.from(arrKeys.slice(0, 16));
  const arrKey = arrKeys.slice(16);
  const decryptedMnemonic = await decryptMnemonic(arrKey, password, salt);

  return keysFromMnemonic(decryptedMnemonic);
};

const concatSaltMnemonic = (salt: Buffer, mnemonic: ArrayBuffer) => {
  const t = new Uint8Array(salt.byteLength + mnemonic.byteLength);
  t.set(new Uint8Array(salt), 0);
  t.set(new Uint8Array(mnemonic), salt.byteLength);

  return t.buffer;
}

export const buildWallet = async (eKey: string, password: string, env: NetworkEnv): Promise<Wallet> => {
  const {publicKey, secretKey} = await decryptPKey(eKey, password);
  const signer = new MessageSigner(secretKey);
  const address = addressFromHexPublicKey(publicKey);
  const networkProvider = new ApiNetworkProvider(network[env]["api"], {timeout: 10000});

  return new Wallet(address, signer, networkProvider);
};
