import {NpcWithUserQuests} from "../../../common/types/entities";
import NpcCountdown from "./NpcCountdown";
import {useQuestsState} from "../../../hooks/useQuestsState";


export default function NpcHead({npc, showTimer}: { npc: NpcWithUserQuests, showTimer: boolean }) {
    const {setCurrentNpc, loadNpcs, clearQuests} = useQuestsState();
    const questsNumber = npc.quests
        .filter(q => q.status === 'new')
        .length;

    const endsAt = npc.quests[0].valid_until ?? null;

    return (
        <>
            <button
                onClick={() => {setCurrentNpc(npc.npc)}}
                className="w-14 h-14 md:h-16 md:w-16 relative focus:outline-none focus:ring-2 focus:ring-primary-darkest"
            >
                <img
                    alt={`Npc: ${npc.npc.name}`}
                    src={`https://giants.fra1.cdn.digitaloceanspaces.com/npcs_bober/${npc.npc.contract_id + 1}.png`}
                    className="w-full h-full object-center object-contain"
                />
                {questsNumber > 0 && <div
                    className="w-5 h-5 bg-red flex items-center justify-center absolute -left-2 top-2">
                    <span className="text-sm text-white font-semibold">{questsNumber}</span>
                </div>
                }
            </button>
            {showTimer && endsAt && <div className="">
                <NpcCountdown
                    date={endsAt}
                    onComplete={() => {
                        clearQuests();
                        loadNpcs();
                    }}
                />
            </div>}
        </>
    );
}