import {useApi} from "../../../hooks/useApi";
import {useAuth} from "../../../hooks/useAuth";
import {usePopups} from "../../../hooks/usePopups";
import {PopupEnum} from "../../../common/types/components";
import Popup from "../../shared/Popup";
import ActionButton from "../../shared/ActionButton";
import {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {
    CreateClaimResponse,
    GetHarvestedResourcesResponse,
    GetLatestClaimResponse
} from "../../../common/types/responses";
import {
    createClaimPath,
    getLatestClaimPath,
    villageHarvestStatePath
} from "../../../utils/api-routes";
import {useAppDispatch} from "../../../hooks/useStore";
import {
    fetchHarvestingState,
    fetchLatestClaim as fetchLatestClaimAction, setClaim
} from "../../../redux/slices/resourcesSlice";
import ResourceHarvestItem from "./ResourceHarvestItem";
import {Tooltip} from "react-tooltip";
import ResourceClaimItem from "./ResourceClaimItem";
import {ResourceClaim} from "@elrond-giants/game-db/types/entities";
import Manager from "../../../gameboard/Manager";
import ClaimResourcesContext from "../../../services/contexts/ClaimResourcesContext";
import {useFarmResources} from "../../../hooks/useFarmResources";


export default function ClaimResourcesDialog() {
    const {
        harvestStateFetchingStatus,
        latestClaimFetchingStatus,
        fetchLatestClaim,
        fetchHarvestedResources
    } = useFarmResources();
    const dispatch = useAppDispatch();
    const {isActive, closePopup} = usePopups();
    const isPopupActive = isActive(PopupEnum.CLAIM_RESOURCES);
    const latestClaim = useSelector((state: RootState) => state.resources.claim);

    useEffect(() => {
        if (latestClaimFetchingStatus === "loading") {return;}
        fetchLatestClaim();
    }, []);

    useEffect(() => {
        if (!isPopupActive) {return;}
        if (latestClaimFetchingStatus !== "loading") {fetchLatestClaim();}
        if (harvestStateFetchingStatus !== "loading") {fetchHarvestedResources();}
    }, [isPopupActive]);

    const claim = async (claim: Required<ResourceClaim>): Promise<void> => {
        if (claim.status === "complete") {return;}
        dispatch(setClaim({...claim, status: "pending"}));
        const actionContext = new ClaimResourcesContext({claim, villageId: claim.village_id});
        await Manager.getInstance().commitAction(actionContext);
    };


    return (
        <Popup
            open={isActive(PopupEnum.CLAIM_RESOURCES)}
            setOpen={() => closePopup(PopupEnum.CLAIM_RESOURCES)}
            widthClass="w-full md:max-w-[22rem]"
        >
            {(latestClaim && latestClaim.status !== "complete")
                ? <ClaimingState claim={claim}/>
                : <HarvestState claim={claim}/>
            }
            <Tooltip id="extra-resources-tooltip" className="base-tooltip"/>
        </Popup>
    );


};

function HarvestState({claim}: { claim: (claim: Required<ResourceClaim>) => Promise<void> }) {
    const harvestedResources = useSelector((state: RootState) => state.resources.harvestingState);
    const fetchingHarvestStatus = useSelector((state: RootState) => state.resources.status);
    const {api} = useApi();
    const [claiming, setClaiming] = useState(false);
    const loading = useMemo(() => {
        if (fetchingHarvestStatus === "loading") {return true;}
        return claiming
    }, [claiming, fetchingHarvestStatus]);
    const claimableResources = useMemo(() => {
        return harvestedResources.filter((resource) => resource.harvested_amount > 0);
    }, [harvestedResources]);

    const createClaim = async () => {
        setClaiming(true);
        try {
            const {data: {claim: pendingClaim}} = await api.post<CreateClaimResponse>(createClaimPath());
            await claim(pendingClaim);
        } finally {
            setClaiming(false);
        }
    }

    return (
        <div className="w-full h-full pt-6 pb-2 px-2">
            <div className="flex flex-col justify-center min-h-80">
                <p className="text-theme-text text-xl">
                    Claim resources from all farms
                </p>
                <p className="text-theme-text-light">
                    {harvestedResources.length === 0
                        ? "There are no resources to claim"
                        : "Claim all resources"
                     }
                </p>
                <div className="flex flex-col items-center flex-grow gap-y-6 mt-10">
                    {harvestedResources.map((resource) => <ResourceHarvestItem
                        key={`${resource.token}-${resource.token_nonce}`}
                        resource={resource}
                        loading={fetchingHarvestStatus === "loading"}
                    />)}
                </div>
            </div>
            <div className="flex items-center justify-center mt-2">
                <ActionButton
                    onClick={createClaim}
                    disabled={loading || claimableResources.length === 0}
                    style="secondary"
                >
                    {loading ? "Loading..." : claimableResources.length === 0 ? "Nothing to claim" : "Claim all"}
                </ActionButton>
            </div>
        </div>
    );

}

function ClaimingState({claim}: { claim: (claim: Required<ResourceClaim>) => Promise<void> }) {
    const latestClaim = useSelector((state: RootState) => state.resources.claim);


    if (!latestClaim) {return null;}

    return (
        <div className="w-full h-full pt-6 pb-2 px-2">
            <div className="flex flex-col justify-center min-h-80">
                <p className="text-theme-text text-xl">
                    {latestClaim.status === "pending" ? "Claiming all resources" : "Pending claim"}
                </p>
                <p className="text-theme-text-light">
                    {latestClaim.status === "pending" && "Claiming in progress..."}
                    {latestClaim.status === "signed" && "You have an incomplete claim"}
                </p>
                <div className="flex flex-col items-center flex-grow gap-y-6 mt-10">
                    {latestClaim.items.map((resource) => <ResourceClaimItem
                        key={`${resource.resource_token}-${resource.resource_token_nonce}`}
                        resource={resource}
                        loading={latestClaim.status === "pending"}
                    />)}
                </div>
            </div>
            <div className="flex items-center justify-center mt-2">
                <ActionButton
                    onClick={() => claim(latestClaim)}
                    disabled={latestClaim.status === "pending"}
                    style="secondary"
                >
                    {latestClaim.status === "pending" ? "Claiming..." : "Complete the claim"}
                </ActionButton>
            </div>
        </div>
    );
}