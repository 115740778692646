import ActionContext from "./ActionContext";
import {gameAction} from "../../config/actions";

export default class ClaimLeaderboardPrizesContext extends ActionContext<"CLAIM_LEADERBOARD_PRIZES"> {
    protected hasTransaction = true;
    protected type = gameAction.CLAIM_LEADERBOARD_PRIZES;

    getUsedItems(): Record<string, number> {
        return {};
    }

};