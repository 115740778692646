import RewardResourceIcon from "../RewardResourceIcon";
import type {ResourceClaimItem} from "@elrond-giants/game-db/types/entities";
import HarvestProgressBar from "./HarvestProgressBar";
import {classNames} from "../../../utils/presentation";

export default function ResourceClaimItem({resource, loading}: {
    resource: ResourceClaimItem;
    loading?: boolean;
}) {
    return (
        <div className={classNames("w-full", loading ? "animate-pulse" : "")}>
            <div className="flex items-center justify-between pb-2">
                <div className="flex items-center">
                    <RewardResourceIcon token={resource.resource_token}/>
                    <span className="ml-3 text-theme-text-light font-semibold text-sm">
                        {Number(resource.amount)}
                    </span>
                </div>
            </div>
            <HarvestProgressBar
                value={Number(resource.amount)}
                maxValue={Number(resource.amount)}
                token={resource.resource_token}
            />
        </div>
    );
};