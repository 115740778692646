import {SVGProps} from "react";

export default function IconStar({...props}: SVGProps<any>) {
    return (
        <div
            className="flex items-center justify-center rounded-full p-1"
        >
            <svg width="990" height="1024" viewBox="0 0 990 1024" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <g clipPath="url(#clip0_624_804)">
                    <path d="M533.77 24.1101L663.24 286.44L952.73 328.51C988.21 333.66 1002.38 377.27 976.7 402.29L767.22 606.48L816.67 894.8C822.73 930.14 785.64 957.09 753.91 940.4L494.98 804.27L236.05 940.4C204.32 957.09 167.23 930.14 173.29 894.8L222.74 606.48L13.2598 402.28C-12.4102 377.26 1.74981 333.65 37.2298 328.5L326.72 286.43L456.19 24.1101C472.06 -8.03994 517.9 -8.03994 533.77 24.1101Z" fill="url(#paint0_radial_624_804)"/>
                    <path d="M774.05 911.85C772.57 911.85 771.1 911.46 769.56 910.65L494.98 766.3L220.41 910.65C218.87 911.46 217.4 911.85 215.91 911.85C213.17 911.85 210.29 910.44 208.38 908.17C206.54 905.99 205.9 903.48 206.42 900.48L258.86 594.74L36.7298 378.21C33.1798 374.75 33.4398 370.93 34.2798 368.33C35.1298 365.73 37.1598 362.48 42.0698 361.76L349.05 317.16L486.34 38.9901C488.53 34.5501 492.25 33.6201 494.98 33.6201C497.71 33.6201 501.44 34.5501 503.62 38.9901L640.91 317.17L947.89 361.77C952.8 362.48 954.83 365.73 955.68 368.34C956.52 370.94 956.79 374.76 953.24 378.22L731.1 594.75L783.54 900.49C784.05 903.49 783.41 906 781.58 908.18C779.67 910.44 776.78 911.86 774.05 911.86V911.85Z" fill="#FFC840"/>
                    <path d="M222.74 606.48L13.2599 402.28C3.03989 392.32 -0.78011 379.42 0.45989 367.17C0.30989 367.17 0.14989 367.17 0.14989 367.17C-0.19011 367.46 0.14989 445.6 0.14989 445.6C0.14989 445.6 0.35989 445.67 0.44989 445.7C-0.75011 457.92 3.06989 470.77 13.2599 480.7L211.21 673.66L222.74 606.46V606.48Z" fill="url(#paint1_linear_624_804)"/>
                    <path d="M817.06 906.72C814.12 935.8 781.95 955.14 753.91 940.4L494.98 804.27L236.05 940.4C208.29 955 176.42 936.2 172.94 907.6V976.72C169.82 1010.29 205.38 1034.96 236.05 1018.83L488.95 885.87L496.47 883.49L753.91 1018.83C784.56 1034.95 820.1 1010.32 817.02 976.78L817.06 906.73V906.72Z" fill="#C66628"/>
                    <path d="M989.5 367.18C990.74 379.43 986.92 392.32 976.7 402.28L767.22 606.47L778.75 673.67L976.7 480.71C986.85 470.82 990.69 458.04 989.53 445.87L989.81 445.6V367.17H989.5V367.18Z" fill="url(#paint2_linear_624_804)"/>
                    <path style={{"mixBlendMode":"screen"}} d="M955.68 368.33C954.83 365.73 952.8 362.48 947.89 361.76L640.91 317.16L503.63 38.9901C501.44 34.5501 497.72 33.6201 494.99 33.6201C492.26 33.6201 488.53 34.5501 486.35 38.9901L349.06 317.17L42.0799 361.77C37.1799 362.48 35.1399 365.73 34.2899 368.34C33.4499 370.94 33.1799 374.76 36.7399 378.22L109.31 448.96C228.78 392.84 388.86 384 548.4 436.3C633.62 464.24 708.63 506.34 769.68 557.16L953.25 378.22C956.8 374.76 956.53 370.94 955.69 368.34L955.68 368.33Z" fill="url(#paint3_radial_624_804)"/>
                    <path d="M953.24 378.21C956.79 374.75 956.52 370.93 955.68 368.33C954.83 365.73 952.8 362.48 947.89 361.76L640.91 317.16L503.63 38.9901C501.44 34.5501 497.72 33.6201 494.99 33.6201C492.26 33.6201 488.53 34.5501 486.35 38.9901L349.06 317.17L42.0799 361.77C37.1799 362.48 35.1399 365.73 34.2899 368.34C33.4499 370.94 33.1799 374.76 36.7399 378.22L258.87 594.75L206.43 900.49C205.91 903.49 206.56 906 208.39 908.18C210.3 910.45 213.18 911.86 215.92 911.86C217.4 911.86 218.87 911.47 220.42 910.66L494.99 766.31L769.57 910.66C771.11 911.47 772.57 911.86 774.06 911.86C776.8 911.86 779.68 910.45 781.59 908.18C783.43 906 784.07 903.49 783.55 900.49L731.11 594.75L953.25 378.22L953.24 378.21ZM775.15 902.76C774.78 903.2 774.25 903.42 774.04 903.45L494.98 756.81L215.93 903.45C215.73 903.43 215.19 903.21 214.7 901.91L267.88 591.82L42.5899 372.19C42.0999 371.71 42.0399 371.65 42.2699 370.93C42.5099 370.19 42.5899 370.18 43.2699 370.08L354.62 324.84L493.88 42.7001C494.18 42.0901 494.21 42.0201 494.99 42.0201C495.77 42.0201 495.8 42.1001 496.1 42.7101L635.34 324.85L946.69 370.09C947.37 370.19 947.45 370.2 947.69 370.93C947.93 371.66 947.85 371.73 947.38 372.2L722.08 591.82L775.16 902.77L775.15 902.76Z" fill="url(#paint4_linear_624_804)"/>
                </g>
                <defs>
                    <radialGradient id="paint0_radial_624_804" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(494.98 472.73) scale(483.91 483.91)">
                        <stop stopColor="#F9C020"/>
                        <stop offset="0.46" stopColor="#F5891F"/>
                        <stop offset="1" stopColor="#FBE13E"/>
                    </radialGradient>
                    <linearGradient id="paint1_linear_624_804" x1="112.76" y1="280.58" x2="108.56" y2="920.63" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#EA8D23"/>
                        <stop offset="1" stopColor="#B26728"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_624_804" x1="880.1" y1="285.62" x2="875.9" y2="925.67" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#EA8D23"/>
                        <stop offset="1" stopColor="#B26728"/>
                    </linearGradient>
                    <radialGradient id="paint3_radial_624_804" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(558.044 673.492) scale(525.901 525.901)">
                        <stop stopColor="#FBED96"/>
                        <stop offset="1" stopColor="#010101"/>
                    </radialGradient>
                    <linearGradient id="paint4_linear_624_804" x1="489.76" y1="51.0401" x2="503.77" y2="910.97" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FDD736"/>
                        <stop offset="1" stopColor="#F9C12B"/>
                    </linearGradient>
                    <clipPath id="clip0_624_804">
                        <rect width="989.81" height="1023.89" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

        </div>
    );
}
