import { useState } from "react";
import { IoIosRefresh } from "react-icons/io";

import { PopupEnum } from "../../common/types/components";
import { useAuth } from "../../hooks/useAuth";
import { usePopups } from "../../hooks/usePopups";
import { classNames } from "../../utils/presentation";
import IconGiantCurrency from "../icons/IconGiantCurrency";

export default function WalletTokensBalance({ mode }: { mode: "inventory" | "wallet" }) {
  const { balance, giantGoldBalance, mainWallet, wallet } = useAuth();
  const [loadingRefresh, setLoadingRefresh] = useState(false);
  const { showPopup } = usePopups();
  const openEgldTransferDialog = () => {
    showPopup(PopupEnum.TOKEN_TRANSFER, {
      state: { type: mode === "inventory" ? "top-up" : "withdraw" },
      removeWhenInactive: true,
    });
  };

  const doBalanceRefresh = () => {
    setLoadingRefresh(true);
    wallet!.refresh();
    mainWallet!.refresh();

    setTimeout(() => {
      setLoadingRefresh(false);
    }, 500);
  };

  return (
    <div className="flex items-center">
      <div className="py-1 px-2 border border-secondary text-sm flex items-center text-gray-500 mr-2">
        <img src="/inventory/egld-icon.svg" alt="EGLD" className="w-4 h-4 mr-1" />
        <span className="ml-1 mr-3">
          {mode === "inventory" ? balance.toDenominatedString(2) : mainWallet?.balance.toDenominatedString(2)}
        </span>
        <IconGiantCurrency className="w-4 h-4" />
        <span className="mx-1">
          {mode === "inventory"
            ? giantGoldBalance.toDenominatedString(2)
            : mainWallet?.giantGoldBalance.toDenominatedString(2)}
        </span>
        <img
          src={mode === "inventory" ? "/inventory/top-up-icon.svg" : "/inventory/withdraw-icon.svg"}
          alt={mode === "inventory" ? "Top Up Inventory" : "Withdraw to wallet"}
          className="h-6 ml-4 w-auto cursor-pointer"
          onClick={openEgldTransferDialog}
        />
      </div>
      <button onClick={doBalanceRefresh}>
        <IoIosRefresh
          className={classNames(
            "h-5 w-5 transition ease-in-out hover:rotate-12",
            loadingRefresh ? "text-green-500" : "text-secondary"
          )}
        />
      </button>
    </div>
  );
}
