import {REFERRAL_CODE_STORAGE_KEY, REFERRAL_CODE_VALID_DAYS} from "../config/misc";
import {addDays, isBefore} from "date-fns";

export const storeReferralCode = (referralCode: string) => {
    localStorage.setItem(REFERRAL_CODE_STORAGE_KEY, JSON.stringify({
        code: referralCode,
        timestamp: Date.now(),
    }));
};

export const getReferralCode = (): string | undefined => {
    const referral = localStorage.getItem(REFERRAL_CODE_STORAGE_KEY);
    if (referral) {
        const {code, timestamp} = JSON.parse(referral);
        if (isBefore(new Date(timestamp), addDays(new Date(), REFERRAL_CODE_VALID_DAYS))) {
            return code;
        }
    }
};

export const clearReferralCode = () => {
    localStorage.removeItem(REFERRAL_CODE_STORAGE_KEY);
};