import {GridAssetUpgradeState, IAsset, IPosition} from "./gameboard";
import {
    AssetAttributes,
    Npc,
    UserQuest,
    VillageAction,
    VillageAsset,
    VillageResident,
    VillageWorker,
    ResourceClaim,
    LeaderboardPrizeClaim,
    ReferralSpendingsClaim
} from "@elrond-giants/game-db/types/entities";
import {PartialVillageAsset} from "./repository";
import {ReferralTierRewardToken} from "@elrond-giants/game-db/types/entities";
import { TokenType, WithRequired } from "./index";
import {gameAction} from "../../config/actions";
import ActionContext from "../../services/contexts/ActionContext";
import {Transaction} from "@multiversx/sdk-core/out";


export enum GameActionEnum {
    PLACE_LAND = "placeLand",
    PLACE_BUILDING = "placeBuilding",
    REMOVE_LAND = "removeLand",
    REMOVE_BUILDING = "removeBuilding",
    INIT_UPGRADE = "upgradeBuilding",
    COMPLETE_UPGRADE = "finalizeUpgradeBuilding",
    CLAIM_REWARDS = "claimRewards",
    ADD_GIANTS_TO_HOUSE = "addGiantsToHouse",
    ADD_STAKED_GIANTS_TO_HOUSE = "addGiantsToHouseFromStaking",
    REMOVE_GIANTS_FROM_HOUSE = "removeGiantsFromHouse",
    REMOVE_STAKED_GIANTS_FROM_HOUSE = "removeGiantsFromHouseToStaking",
    ADD_GIANTS_TO_WORK = "addGiantsToWork",
    REMOVE_GIANTS_FROM_WORK = "removeGiantsFromWork",
    ASSETS_TRANSFER = "assetsTransfer",
    COMPLETE_QUEST = "completeQuest",
    UPGRADE_VILLAGE = "upgradeVillage",
    CLAIM_REFERRAL_REWARDS = "claimReferralRewards",
    SET_BUILDING_SKIN = "setBuildingSkin",
    REMOVE_BUILDING_SKIN = "removeBuildingSkin",
}

export type ActionStatus = "pending" | "failed" | "complete" | "reverted";
export type AssetStatus = "pending" | "upgrading" | "finalizing-upgrade" | "complete";
export type ResidentStatus = AssetStatus | "pending removal";

export interface IToken {
    id: string,
    nonce: number,

}

export type TokenPayload = IToken & {
    quantity?: number
};

export type ActionPayload = {
    [gameAction.PLACE_LAND]: {
        positions: IPosition[];
        token: TokenPayload;
    };
    [gameAction.REMOVE_LAND]: {
        positions: IPosition[];
        token: TokenPayload;
    };
    [gameAction.PLACE_BUILDING]: {
        position: IPosition;
        token: TokenPayload;
        attributes: AssetAttributes;
    };
    [gameAction.REMOVE_BUILDING]: {
        position: IPosition;
        token: TokenPayload;
        attributes: AssetAttributes;
        upgradeState: GridAssetUpgradeState;
    };
    [gameAction.INIT_UPGRADE]: {
        position: IPosition;
        asset_token: TokenPayload;
        attributes: AssetAttributes;
    };
    [gameAction.COMPLETE_UPGRADE]: {
        position: IPosition;
        token: TokenPayload;
        attributes: AssetAttributes;
        upgrade_signature: string;
        upgrade_payments: TokenPayload[];

    };
    [gameAction.ADD_GIANTS_TO_HOUSE]: {
        house: {
            position: IPosition;
            token: TokenPayload;
        },
        giants: IToken[];
    };
    [gameAction.ADD_STAKED_GIANTS_TO_HOUSE]: {
        house: {
            position: IPosition;
            token: TokenPayload;
        },
        giants: IToken[];
    };
    [gameAction.REMOVE_GIANTS_FROM_HOUSE]: {
        house: {
            position: IPosition;
            token: TokenPayload;
        },
        giants: IToken[];
    };
    [gameAction.REMOVE_STAKED_GIANTS_FROM_HOUSE]: {
        house: {
            position: IPosition;
            token: TokenPayload;
        },
        giants: IToken[];
    };
    [gameAction.ADD_GIANTS_TO_WORK]: {
        building: {
            position: IPosition;
            token: TokenPayload;
        },
        giants: IToken[];
    };
    [gameAction.REMOVE_GIANTS_FROM_WORK]: {
        building: {
            position: IPosition;
            token: TokenPayload;
        },
        giants: IToken[];
    };
    [gameAction.CLAIM_REWARDS]: {};
    [gameAction.CLAIM_RESOURCES]: {
        claim: Required<ResourceClaim>
    };
    [gameAction.ASSETS_TRANSFER]: {};
    [gameAction.COMPLETE_QUEST]: {
        npc: Pick<Npc, "id" | "contract_id">;
        quest: UserQuest;
    };
    [gameAction.UPGRADE_VILLAGE]: {
        payment: Required<TokenPayload>;
    };
    [gameAction.CLAIM_REFERRAL_REWARDS]: {
        id: number;
        signature: string;
        rewards: ReferralTierRewardToken[];
    };
    [gameAction.SET_BUILDING_SKIN]: {
        buildingToken: IToken;
        skinToken: IToken;
    };
    [gameAction.REMOVE_BUILDING_SKIN]: {
        buildingToken: IToken;
    },
    [gameAction.CLAIM_LEADERBOARD_PRIZES]: {
        prizeClaims: LeaderboardPrizeClaim[]
    },
    [gameAction.CLAIM_REFERRAL_SPENDINGS]: {
        claim: ReferralSpendingsClaim
    }
}


export type TxActionPayload<T extends GameActionValue> = ActionPayload[T] & { villageId: string };

export type EventVillageAction =
    Pick<VillageAction, "action" | "status" | "village_id" | "transaction_hash"> & {
    id?: string
    created_at?: string
};

export interface AssetEventPayload {
    action: EventVillageAction;
    assets?: PartialVillageAsset[];
    residents?: Omit<VillageResident, "action_id" | "created_at" | "updated_at">[];
}


export type ActionCommitSuccess<T extends GameActionKey> = GameApiActionCommitResult & {
    actionContext: ActionContext<T>;
    tx?: Transaction;
    txHash?: string;
    jobId?: string;
};

export type ActionCommitPending<T extends GameActionKey> = {
    actionContext: ActionContext<T>;
    jobId: string;
}

export type ActionCommitError<T extends GameActionKey> = {
    actionContext: ActionContext<T>;
    error: any;
};

export type ActionHistoryState = Array<VillageAsset | VillageResident | VillageWorker | {}>;

export type GameApiActionCommitResult = {
    action?: VillageAction;
    state?: ActionHistoryState;
    txHash?: string;
    jobId?: string;

};

export type GameActionKey = keyof typeof gameAction;
export type GameActionValue = typeof gameAction[GameActionKey];
export type GameAction<T extends GameActionKey> = typeof gameAction[T];

