import {
  Address,
  GasEstimator,
  TokenTransfer,
  Transaction,
  TransferTransactionsFactory,
} from "@multiversx/sdk-core/out";
import BigNumber from "bignumber.js";
import { nanoid } from "nanoid";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import AccountBalance from "../../auth/AccountBalance";
import MainWallet from "../../auth/MainWallet";
import { NetworkEnv } from "../../common/types";
import { PopupEnum } from "../../common/types/components";
import { TOKEN_GIANTS_CURRENCY } from "../../config/assets";
import { network } from "../../config/network";
import { useAuth } from "../../hooks/useAuth";
import { usePopups } from "../../hooks/usePopups";
import { useAppDispatch } from "../../hooks/useStore";
import { useTransaction } from "../../hooks/useTransaction";
import { addTxToSign, removeTxToSign, selectShowTxSignInfo } from "../../redux/slices/transactionsSlice";
import ActionButton from "../shared/ActionButton";
import Popup from "../shared/Popup";
import SignTxInfo from "../SignTxInfo";

const topUpMethods: any = {
  egld: {
    "top-up": ["wallet", "card"],
    withdraw: ["wallet"],
  },
  gold: {
    "top-up": ["wallet"],
    withdraw: ["wallet"],
  },
};

export default function TransferEgldDialog() {
  const { wallet, mainWallet } = useAuth();
  const { popupState, isActive, closePopup } = usePopups();
  const state = popupState(PopupEnum.TOKEN_TRANSFER);
  const dispatch = useAppDispatch();
  const { sendTransaction, guardTransactionIfNeeded } = useTransaction();
  const [tokenAmount, setTokenAmount] = useState("");
  const [tokenType, setTokenType] = useState("egld");
  const [topUpMethod, setTopUpMethod] = useState("wallet");
  const [loading, setLoading] = useState(false);
  const showSignTxInfo = useSelector(selectShowTxSignInfo);
  const isPopupActive = isActive(PopupEnum.TOKEN_TRANSFER);
  const walletBalance = useMemo(() => {
    if (!state) {
      return new AccountBalance(0);
    }
    let balance;
    if (tokenType === "egld") {
      balance = state.state.type === "top-up" ? mainWallet?.balance : wallet?.balance;
    } else {
      balance = state.state.type === "top-up" ? mainWallet?.giantGoldBalance : wallet?.giantGoldBalance;
    }

    return balance ?? new AccountBalance(0);
  }, [tokenType, state, mainWallet?.balance, mainWallet?.giantGoldBalance, wallet?.balance, wallet?.giantGoldBalance]);

  const onClose = () => {
    closePopup(PopupEnum.TOKEN_TRANSFER);
  };

  const doTransaction = async () => {
    if (!tokenAmount) return;
    setLoading(true);

    const env = process.env.NEXT_PUBLIC_NETWORK_ENV! as NetworkEnv;
    const chainId = network[env].chainId;

    const value = new BigNumber(tokenAmount).shiftedBy(18);
    const sender = new Address(state?.state.type === "top-up" ? mainWallet!.address! : wallet!.address);
    const receiver = new Address(state?.state.type === "top-up" ? wallet?.address : mainWallet?.address!);

    let tx: Transaction;
    if (tokenType === "egld") {
      tx = new Transaction({
        chainID: chainId,
        gasLimit: 60_000,
        receiver,
        sender,
        value,
      });
    } else {
      tx = new TransferTransactionsFactory(new GasEstimator()).createESDTTransfer({
        chainID: chainId,
        receiver,
        sender,
        tokenTransfer: TokenTransfer.fungibleFromBigInteger(TOKEN_GIANTS_CURRENCY, value),
      });
    }

    const walletToSign = state?.state.type === "top-up" ? mainWallet : wallet;

    try {
      await walletToSign!.refresh();
      tx.setNonce(walletToSign!.nonce);
      let signedTx: Transaction | null;
      const id = nanoid(5);
      try {
        if (walletToSign instanceof MainWallet) {
          signedTx = await guardTransactionIfNeeded(tx, walletToSign);
          dispatch(addTxToSign(id));
        }
        signedTx = await walletToSign!.signTransaction(tx);
      } finally {
        dispatch(removeTxToSign(id));
      }

      if (signedTx) {
        const { status } = await sendTransaction(signedTx, state?.state.type === "top-up" ? "Top up" : "Withdraw");
      }
    } finally {
      wallet!.refresh();
      mainWallet!.refresh();
      setLoading(false);
      onClose();
    }
  };

  useEffect(() => {
    setTokenAmount("");
  }, [tokenType]);

  useEffect(() => {
    setTokenAmount("");
  }, [isPopupActive]);

  const openRamp = () => {
    const url = `${process.env.NEXT_PUBLIC_TRADESILVANIA_RAMP_URL}/?partnerId=${process.env.NEXT_PUBLIC_TRADESILVANIA_PARTNER_ID}&networkTo=egld&addressTo=${wallet?.address}`;
    const windowRef = window.open(url, "_blank ");

    onClose();
  };

  return (
    <Popup open={isPopupActive} setOpen={onClose} widthClass="w-full md:max-w-[26rem]">
      <div className="flex flex-col items-start p-2">
        <div className="flex items-center space-x-4">
          <div className="flex flex-col items-start space-y-1">
            <h2 className="text-xl text-theme-text font-semibold">
              {state?.state.type === "top-up" ? "Top-up Inventory" : "Withdraw from Inventory"}
            </h2>
          </div>
        </div>
        <p className="text-theme-text-light mt-2 text-left">
          {state?.state.type === "top-up"
            ? `Transfer ${tokenType} from your wallet to your inventory`
            : `Transfer ${tokenType} from your inventory to your wallet`}
        </p>
        {showSignTxInfo && <SignTxInfo />}
        {!showSignTxInfo && (
          <>
            <div className="flex justify-between w-full mt-6">
              <select
                className="p-1 mr-1 h-10 bg-transparent focus:outline-none focus:font-semibold flex-1"
                value={tokenType}
                onChange={({ target: { value } }) => {
                  setTokenType(value);
                  setTopUpMethod(topUpMethods[value][state?.state?.type][0]);
                }}
              >
                <option value="egld">EGLD</option>
                <option value="gold">GIANT</option>
              </select>
              <select
                className="p-1 mr-1 h-10 bg-transparent focus:outline-none focus:font-semibold flex-1 capitalize"
                value={topUpMethod}
                onChange={({ target: { value } }) => setTopUpMethod(value)}
              >
                {topUpMethods[tokenType][state?.state?.type]?.map((method: string) => (
                  <option value={method} key={method}>
                    {method}
                  </option>
                ))}
              </select>
            </div>
            {topUpMethod === "wallet" && (
              <div className="flex items-center mt-2 w-full border border-secondary">
                <input
                  type="number"
                  className="w-full h-10 text-theme-text bg-secondary-lightest px-4 text-sm focus:ring-0 focus:ring-offset-0 outline-none read-only:cursor-not-allowed"
                  placeholder="1.68513"
                  max={walletBalance.toDenominatedString(5)}
                  min={state?.state.type === "top-up" ? "0.1" : "0.0001"}
                  value={tokenAmount}
                  onChange={({ target: { value } }) => {
                    const val = new BigNumber(value);
                    if (val.gt(walletBalance.toDenominatedString(5))) {
                      setTokenAmount(walletBalance.toDenominatedString(5));
                    } else {
                      setTokenAmount(value);
                    }
                  }}
                  readOnly={loading}
                />
              </div>
            )}
          </>
        )}
        {topUpMethod === "wallet" && (
          <div className="text-xs text-theme-text-light mt-1 mb-6">
            {state?.state.type === "top-up" ? "Wallet Balance" : "Inventory Balance"}:{" "}
            {walletBalance.toDenominatedString(5)} {tokenType.toUpperCase()}
          </div>
        )}
        {topUpMethod === "card" && (
          <div className="text-xs text-theme-text-light text-left mt-2 mb-4 leading-snug">
            <span className="text-theme-text">Note:</span> You will be redirected to Tradesilvania to complete the
            transaction using your credit card or bank transfer.
          </div>
        )}
        <div className="flex flex-col flex-grow justify-end w-full">
          <div className="flex flex-col md:flex-row items-center justify-center gap-3 w-full mt-2">
            <ActionButton onClick={topUpMethod === "wallet" ? doTransaction : openRamp} disabled={loading}>
              {loading ? (
                <span className="animate-pulse">Sending...</span>
              ) : state?.state.type === "top-up" ? (
                "Top Up"
              ) : (
                "Withdraw"
              )}
            </ActionButton>
            <ActionButton onClick={onClose} style={"secondary"} disabled={loading}>
              cancel
            </ActionButton>
          </div>
        </div>

        {state?.state.type === "top-up" && (
          <div className="text-xs text-theme-text-light text-left mt-4 leading-snug">
            Giants Village is fully on-chain so you need EGLD in your inventory wallet to cover the transactions fee. We
            recommend to always have at least 0.01 EGLD in your inventory wallet.
          </div>
        )}
      </div>
    </Popup>
  );
}
