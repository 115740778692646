import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {BuildingResident} from '../../../common/types/inventory';
import {useAppDispatch} from '../../../hooks/useStore';
import {
    clearSelected,
    deselectResident,
    selectResident
} from '../../../redux/slices/residentsSlice';
import {RootState} from '../../../redux/store';
import {classNames} from '../../../utils/presentation';
import InfiniteScroller from "../../shared/InfiniteScroller";
import ActionButton from "../../shared/ActionButton";
import IconStaked from "../../icons/IconStaked";
import {PowerUpType} from "../../../common/types/attributes";
import ResidentPowerUps from "./ResidentPowerUps";

type ResidentWithPowerUps = BuildingResident & { powerUps?: Map<PowerUpType, number> };
type Props = {
    residents: ResidentWithPowerUps[];
    hasMore: boolean;
    next: () => void;
    selectable: boolean;
    selectableQty?: number;
    action?: {
        label: string;
        onClick: () => Promise<void>;
        disabled?: boolean;
        loading?: boolean;
    };
};
export default function ResidentsList(
    {
        residents,
        next,
        hasMore,
        selectable,
        action,
        selectableQty = 0
    }: Props
) {
    const dispatch = useAppDispatch();
    const selected = useSelector((state: RootState) => state.residents.selected);
    useEffect(() => {
        return () => {
            dispatch(clearSelected());
        };
    }, []);

    return (
        <InfiniteScroller
            next={next}
            hasMore={hasMore}
        >
            <>
                <ul role="list" className="divide-y divide-secondary w-full">
                    {residents.map((resident) => (
                        <li key={resident.identifier}>
                            <div className={classNames(
                                "flex items-center justify-between py-2 pl-4 pr-2 w-full",
                                pending(resident.status) ? "animate-pulse" : ""
                            )}>
                                <div className="flex items-center justify-start space-x-2 w-full">
                                    <img
                                        src={resident.thumbnail}
                                        className="w-8 h-8"
                                        alt={`${resident.name} thumbnail`}
                                    />
                                    <div className="flex flex-col items-start ">
                                        <span
                                            className="text-secondary text-sm">{resident.name}</span>
                                        <span
                                            className={classNames(resident.working ? "text-red" : "text-blue-500", "text-sm")}
                                        >
                                              {resident.workTitle}
                                        </span>
                                    </div>
                                    {resident.staked && (
                                        <IconStaked className="w-4 h-4"/>
                                    )}
                                    {resident.powerUps && (
                                        <ResidentPowerUps powerUps={resident.powerUps}/>
                                    )}
                                </div>
                                <div
                                    className="flex items-center justify-end space-x-2  text-xs pr-4">
                                    {!selectable && pending(resident.status) &&
                                        <span className="text-secondary">pending</span>}
                                    {selectable && resident.selectable && (
                                        <>
                                            {selected.find((t) => t.id === resident.collection && t.nonce === resident.nonce) ? (
                                                <button
                                                    className="w-5 h-5 bg-secondary focus:outline-none focus:ring-1 focus:ring-primary-darkest focus:ring-offset-2"
                                                    onClick={() => {
                                                        dispatch(
                                                            deselectResident({
                                                                id: resident.collection,
                                                                nonce: resident.nonce,
                                                            })
                                                        );
                                                    }}
                                                />
                                            ) : (
                                                selected.length < selectableQty && <button
                                                    className="w-5 h-5 bg-gray-300 focus:outline-none focus:ring-1 focus:ring-primary-darkest focus:ring-offset-2"
                                                    onClick={() => {
                                                        dispatch(
                                                            selectResident({
                                                                id: resident.collection,
                                                                nonce: resident.nonce,
                                                                staked: resident.staked ?? false,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                {action && <div className="flex justify-center px-4 py-2">

                    <ActionButton
                        onClick={action.onClick}
                        disabled={action.disabled}

                    >
                        <span className={action.loading ? "animate-pulse" : ""}>
                            {action.label}
                        </span>
                    </ActionButton>
                </div>}
            </>
        </InfiniteScroller>
    );
}

const pending = (status: string) => status === "pending" || status === "pending removal"
