import {useAppDispatch} from "./useStore";
import {
    fetchQuests,
    setSelectedNpcId,
    setQuestStatus as setQuestStatusAction,
    clearQuests as clearQuestsAction, setWaitingToCompleteQuest
} from "../redux/slices/questsSlice";
import {GetQuestsActionResponse} from "../common/types/responses";
import {useApi} from "./useApi";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {Npc} from "@elrond-giants/game-db/types/entities";

export const useQuestsState = () => {
    const {api} = useApi();
    const dispatch = useAppDispatch();
    const questsStatus = useSelector((state: RootState) => state.quests.status);
    const npcs = useSelector((state: RootState) => state.quests.items);
    const waitingForQuests = useSelector((state: RootState) => state.quests.waitingToCompleteQuest);
    const loadNpcs = () => dispatch(fetchQuests({
        fetch: async () => {
            const {data} = await api.get<GetQuestsActionResponse>('/quests');

            return data;
        }
    }));

    const setCurrentNpc = (npc: Npc) => {
        return dispatch(setSelectedNpcId(npc.id));
    }

    const setQuestStatus = (userQuestId: number, status: string) => {
        return dispatch(setQuestStatusAction({userQuestId, status}));
    }

    const clearQuests = () => {
        return dispatch(clearQuestsAction());
    }

    const setWaitingForQuests = (waiting: boolean) => {
        dispatch(setWaitingToCompleteQuest(waiting));
    }

    return {
        questsStatus,
        npcs,
        waitingForQuests,
        loadNpcs,
        setCurrentNpc,
        setQuestStatus,
        clearQuests,
        setWaitingForQuests
    };
};
