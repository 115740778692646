import {useEffect, useMemo, useState} from 'react';
import {FcInfo} from 'react-icons/fc';
import {useSelector} from 'react-redux';
import {Tooltip} from 'react-tooltip';

import {IToken} from '../../../common/types/actions';
import {PartialVillageAsset} from '../../../common/types/repository';
import {buildings} from '../../../config/assets';
import Manager from '../../../gameboard/Manager';
import {useAuth} from '../../../hooks/useAuth';
import {useAppDispatch} from '../../../hooks/useStore';
import {useVillageState} from '../../../hooks/useVillageState';
import {clearSelected, selectAvailableFarmResidents} from '../../../redux/slices/residentsSlice';
import {RootState} from '../../../redux/store';
import {getFarmBaseProductionSpeed} from '../../../utils/assets';
import {mapVillageResidents} from '../../../utils/inventory';
import {formatUpgradeTime} from '../../../utils/presentation';
import ProgressBar from '../../ProgressBar';
import ActionButton from '../../shared/ActionButton';
import RewardResourceIcon from '../RewardResourceIcon';
import BaseBuildingAssetDialog from './BaseBuildingAssetDialog';
import ResidentsList from './ResidentsList';
import AddGiantsToWorkContext from "../../../services/contexts/AddGiantsToWorkContext";
import RemoveGiantsFromWorkContext from "../../../services/contexts/RemoveGiantsFromWorkContext";
import {FarmHarvestStatus} from "../../../common/types/resources";
import {useApi} from "../../../hooks/useApi";
import {GetFarmHarvestedResourcesResponse} from "../../../common/types/responses";
import {farmHarvestStatePath} from "../../../utils/api-routes";
import {getNftIdFromVillageAsset} from "../../../utils/nfts";
import {usePopups} from "../../../hooks/usePopups";
import {PopupEnum} from "../../../common/types/components";
import {getGiantPowerUpsForBuilding} from "../../../utils/attributes";

type Props = {
  asset: PartialVillageAsset;
  onClose: () => void;
};

export default function FarmAssetDialog({ asset, onClose }: Props) {
  const { village, account } = useAuth();
  const {api} = useApi();
  const dispatch = useAppDispatch();
  const {showPopup} = usePopups();
  const availableFarmResidents = useSelector(selectAvailableFarmResidents);
  const availableResidents = useMemo(
      () => mapVillageResidents(availableFarmResidents).map((resident) => ({
        ...resident,
        powerUps: getGiantPowerUpsForBuilding(
            {
              traits: resident.traits,
              giant_token: resident.collection,
            },
            asset.asset_token
        ),
      })),
      [asset.asset_token, availableFarmResidents]
  );
  const { loadVillageResidents } = useVillageState();
  const buildingAssetInfo = buildings[asset.asset_token];
  const [resourceProductionTime, setResourceProductionTime] = useState(0);
  const [harvestState, setHarvestState] = useState<FarmHarvestStatus | null>(null);
  const currentResidents = useSelector((state: RootState) => {
    const buildingResidents = state.residents.residents.filter(
      (resident) =>
        resident.worker?.building_token === asset.asset_token &&
        resident.worker?.building_position?.x === asset.position.x &&
        resident.worker?.building_position?.y === asset.position.y
    );

    return mapVillageResidents(buildingResidents).map((resident) => ({
      ...resident,
      powerUps: getGiantPowerUpsForBuilding(
          {
            traits: resident.traits,
            giant_token: resident.collection,
          },
          asset.asset_token
      ),
    }));
  });

  const residentsCapacity = useMemo(() => {
    return buildings[asset.asset_token].getGiantsCapacity({
      currentLevel: asset.attributes.level.value,
    });
  }, [asset.attributes.level.value]);

  const addGiants = async (giants: IToken[]) => {
    if (!village) {
      return;
    }
    const actionContext = new AddGiantsToWorkContext({
      building: {
        token: {
          id: asset.asset_token,
          nonce: asset.asset_token_nonce,
        },
        position: asset.position,
      },
      giants: giants,
      villageId: village.id,
    });
    await Manager.getInstance().commitAction(actionContext);
    dispatch(clearSelected());
    fetchHarvestState();
  };


  const timeUntilFull = useMemo(() => {
    if (currentResidents.length === 0) {return null;}
    if (!harvestState) {return null;}
    if (harvestState.harvested_amount >= harvestState.max_amount) {return '(farm is full)';}
    if (harvestState.seconds_until_full === 0) {return '';}
    return `(${formatUpgradeTime(harvestState.seconds_until_full)} until full)`;
  }, [harvestState, currentResidents.length]);

  const fetchHarvestState = async () => {
    const {data: {resource}} = await api.get<GetFarmHarvestedResourcesResponse>(
        farmHarvestStatePath(getNftIdFromVillageAsset(asset))
    );
    setHarvestState(resource);
  }


  useEffect(() => {
    fetchHarvestState();
  }, [asset]);


  const removeGiants = async (giants: IToken[]) => {
    const actionContext = new RemoveGiantsFromWorkContext({
      building: {
        token: {
          id: asset.asset_token,
          nonce: asset.asset_token_nonce,
        },
        position: asset.position,
      },
      giants: giants,
      villageId: village!.id,
    });

    await Manager.getInstance().commitAction(actionContext);
    fetchHarvestState();
  };

  return (
    <BaseBuildingAssetDialog
      asset={asset}
      onClose={onClose}
      addGiants={addGiants}
      removeGiants={removeGiants}
      residentsCapacity={residentsCapacity}
      removable={true}
      currentResidents={currentResidents}
      availableResidentQty={availableResidents.length}
      availableResidentsList={
        <ResidentsList
          selectable={true}
          selectableQty={residentsCapacity - currentResidents.length}
          residents={availableResidents}
          hasMore={false}
          next={() => {}}
        />
      }
      claimingBox={
        <div className="p-4 w-full">
          <div className="flex items-end w-full">
            <div className="flex flex-col w-10/12 pr-6">
              <div className="flex items-center space-x-3 mb-2">
                <RewardResourceIcon token={buildingAssetInfo.rewardsToken!} />
                <span className="text-theme-text-light font-semibold text-sm">
                 {harvestState && `${harvestState.harvested_amount} / ${harvestState.max_amount}` }
                  {timeUntilFull && (
                    <span className="pl-2 text-xs inline-flex items-center">
                      {timeUntilFull}{" "}
                      {/*<FcInfo*/}
                      {/*  className="w-4 h-4 ml-2"*/}
                      {/*  data-tooltip-id="farm-popup-tooltip"*/}
                      {/*  data-tooltip-content={`${giantsProductionBoost}% faster production due to Giants`}*/}
                      {/*/>*/}
                    </span>
                  )}
                </span>
              </div>
              <ProgressBar
                  value={harvestState?.harvested_amount ?? 0}
                  maxValue={harvestState?.max_amount ?? 100}
                  label="Claimable amount"
              />
            </div>
            <div className="flex items-end w-2/12">
              <ActionButton onClick={() => showPopup(PopupEnum.CLAIM_RESOURCES)} style="secondary">
                CLAIM
              </ActionButton>
            </div>
          </div>
          <Tooltip id="farm-popup-tooltip" className="base-tooltip" />
        </div>
      }
    />
  );
}
