import {IGridAsset, IPosition} from "../common/types/gameboard";
import {compareAssetsGridPositions} from "../utils/game";

export default class GridAssetsList<T extends IGridAsset> implements Iterable<T> {
    protected assets: T[];

    constructor(assets: T[] = []) {
        this.assets = assets;
        this.sort();
    }

    [Symbol.iterator](): Iterator<T> {
        return this.assets.values();
    }

    get length() {
        return this.assets.length;
    }

    public add(assets: T | T[], {withoutSort}: { withoutSort?: boolean } = {}) {
        let _assets = Array.isArray(assets) ? assets : [assets];
        this.assets.push(..._assets);
        if (!withoutSort) {
            this.sort();
        }

        return this.assets.length;
    }

    public remove(position: IPosition, {withoutSort}: { withoutSort?: boolean } = {}) {
        const idx = this.assets.findIndex(asset => {
            return asset.position.x === position.x && asset.position.y === position.y;
        });

        if (idx < 0) {return false;}
        this.assets.splice(idx, 1);

        if (!withoutSort) {
            this.sort();
        }

        return true;
    }


    public get(position: IPosition): T | undefined {
        return this.assets.find(
            asset => asset.position.x === position.x && asset.position.y === position.y
        );
    }

    public findMany(filter: (value: T, index: number, array: T[]) => boolean): T[] {
        return this.assets.filter(filter);
    }

    /**
     * Keeps only the elements that pass the test implemented by the filter function
     * @param filter Callback function to test each element of the list.
     * @param options
     */
    public discard(
        filter: (value: T, index: number, array: T[]) => boolean,
        {withoutSort}: { withoutSort?: boolean } = {}
    ): ThisType<GridAssetsList<T>> {
        this.assets = this.assets.filter(filter);

        if (!withoutSort) {
            this.sort();
        }

        return this;
    }

    public reduce<S>(reducer: (previousValue: S, currentValue: any, currentIndex: number, array: any[]) => any, initialValue: any): S {
        return this.assets.reduce(reducer, initialValue);
    }

    public forceSort() {
        return this.sort();
    }

    /**
     * Sorts the assets by row and column position
     * @private
     */
    private sort(): void {
        this.assets.sort(compareAssetsGridPositions);
    }
};