import ActionContext from "./ActionContext";
import {gameAction} from "../../config/actions";

export default class ClaimResourcesContext extends ActionContext<"CLAIM_RESOURCES"> {
    protected type = gameAction.CLAIM_RESOURCES;
    protected hasTransaction = true;


    public getUsedItems(): Record<string, number> {
        return {};
    }

};