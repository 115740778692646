import ActionContext from "./ActionContext";
import {gameAction} from "../../config/actions";
import {getNftIdFromToken} from "../../utils/nfts";

export default class PlaceLandContext extends ActionContext<"PLACE_LAND"> {
    protected type = gameAction.PLACE_LAND;
    protected hasTransaction = true;


    public getUsedItems(): Record<string, number> {
        return {
            [getNftIdFromToken(this.payload.token)]: this.payload.positions.length
        };
    }

};