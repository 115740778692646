import { SVGProps } from "react";

export default function IconUpgrade({ ...props }: SVGProps<any>) {
  return (
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect width="19" height="19" fill="#E65E5E"/>
        <path d="M10.0303 4.21902C9.73744 3.92613 9.26256 3.92613 8.96967 4.21902L4.1967 8.99199C3.90381 9.28488 3.90381 9.75976 4.1967 10.0526C4.48959 10.3455 4.96447 10.3455 5.25736 10.0526L9.5 5.81001L13.7426 10.0526C14.0355 10.3455 14.5104 10.3455 14.8033 10.0526C15.0962 9.75976 15.0962 9.28488 14.8033 8.99199L10.0303 4.21902ZM10.25 15.041L10.25 4.74935L8.75 4.74935L8.75 15.041L10.25 15.041Z" fill="white"/>
      </svg>
  );
}
