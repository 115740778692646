import {InventoryAsset} from "../../common/types/inventory";
import AssetsListItem from "./AssetsListItem";
import InfiniteScroller from "../shared/InfiniteScroller";

type Props = {
    items: InventoryAsset[];
    onItemChange: (token: string, qty: number) => void;
    onClick: (asset: InventoryAsset) => void;
    enabled: boolean;
    selectedItems: { [key: string]: number };
    next: () => void;
    hasMore: boolean;
    mode?: "inventory" | "transfer",
};

export default function AssetsList(props: Props) {
    const {
        items,
        onItemChange,
        enabled,
        selectedItems,
        next,
        hasMore,
        onClick,
        mode = "transfer"
    } = props;

    const onItemClick = (asset: InventoryAsset) => {
        if (mode === "inventory") {
            onClick(asset);
        }else {
            onItemChange(asset.identifier, 1);
        }
    };

    return (
        <InfiniteScroller
            next={next}
            hasMore={hasMore}
        >
            <ul
                role="list"
                className="grid grid-cols-3 md:grid-cols-4 gap-y-10 gap-x-4"
            >
                {items
                    .map(item => ({...item, selected: selectedItems[item.identifier] ?? 0}))
                    .map(item => <AssetsListItem
                        enabled={enabled}
                        item={item}
                        onChange={(qty) => onItemChange(item.identifier, qty)}
                        onClick={() => {onItemClick(item)}}
                        key={item.identifier}
                    />)}
            </ul>
        </InfiniteScroller>
    );
};